export const locale = {
  lang: 'es',
  data: {
    PIN: {
      error: {
        incomplete: 'Error: ingrese el código completo',
        invalid: 'Error: código no válido'
      }
    },
    Common: {
      title: 'Rulla – Cuotas en línea para todos',
      countryCode: '34',
      uah: 'uah',
      pln: 'pln',
      eur: 'eur',
      items: 'cantidad.'
    },
    Errors: {
      server: 'Se produjo un error durante el procesamiento de la solicitud, póngase en contacto con el soporte técnico, o inténtelo más tarde'
    },
    Validation:{
      required: 'Este campo es obligatorio',
      format_error: 'El formato no es válido',
      server_busy: 'El servidor está ocupado, inténtelo de nuevo más tarde',
      iban_invalid: 'El formato correcto IBAN: "ES" y 22 cifras',
    },
    Cookies:{
      consent_text: 'Utilizamos cookies para mejorar el sitio web, comparar audiencias,mejorar la calidad de los anuncios publicitarios basados en su actividad, opiniones e intereses en nuestros sitios web y de terceros. Al continuar trabajando con el sitio web, acepta el uso de cookies.',
       agree: 'Aceptar',
       more_details: 'Más información'
    },
    Statuses: {
      1: 'Nuevo',
      3: 'Pendiente de confirmación',
      4: 'Pago pendiente',
      5: 'En espera de transferencia',
      6: 'En espera de reembolso',
      7: 'El dinero ha sido devuelto',
      8: 'Pagado',
      9: 'Enviado',
      10: 'Entregado',
      11: 'Cancelado'
    },
    SnackBar: {
      copied: 'Copiado',
    },
    Pending: {
      data_processing: 'Procesamiento de datos',
      loading: 'Cargando, por favor espere..'
    },
    Header: {
      rulla_for_clients: 'Rulla para los clientes',
      rulla_for_business: 'Rulla para las empresas',
      registration: 'Registro',
      log_in: 'Entrar',
    },
    HeaderLoginMenu: {
      personal_cabinet_business: 'Para las tiendas',
       personal_cabinet_business_description: 'Inicie sesión para administrar' +
        'sus pedidos, informes de pago, estadísticas de la tienda' +
        'y configuración general.',
      personal_cabinet_customer: 'Para los compradores',
      personal_cabinet_customer_description: 'Inicie sesión y' +
        'obtenga información sobre sus próximos pagos, sus compras' +
        'y pague fácilmente con un clic.'
    },
    HeaderBusinessMenu: {
      sell_with_rulla: 'Vender con Rulla',
      support_seller: 'Apoyo al vendedor',
      three_payments: 'Pago en 3 partes'
    },
    HeaderClientMenu: {
      pay_later: 'Paga más tarde con Rulla',
      about_company: 'Sobre la compañía',
      customer_service: 'Servicio al cliente',
 blog: 'Blog',
      rulla_shops: 'Las tiendas de Rulla',
      categories: 'Categorías',
      watch_all: 'Ver todo'
    },
    NotFound: {
      heading: '¡Parece que algo salió mal!',
      text1: 'La página web que está solicitando no existe. Probablemente' +
        'fue eliminado o ingresó la dirección incorrecta.' +
        'Ve a nuestra',
      text2: ' e busque allí la información que necesita.',
      main_page: 'página principal',
    },
    OrderConfirmed: {
      heading: 'Pedido confirmado',

      confirmed: 'Ahora el comprador puede proceder al pago del pedido',
      thanks: 'Gracias por utilizar el servicio Rulla.',
    },
    BankID: {
      heading: 'Bank ID',
      success: 'Validación de BankID realizada con éxito',
      failure: 'Parece que algo salió mal...'
    },
    Diia: {
      successHeading: 'Éxito!',
      success: 'Verificación de datos a través del sistema de identificación electrónica XXX realizada con éxito.',
      action: 'Puede volver a la página con su pedido para seguir pagando',
      failureHeading: 'Parece que algo salió mal...',
      failure: 'Ocurrió un error al verificar los datos a través del sistema de identificación electrónica XXX'
    },
    Footer: {
      legal: 'Información legal',
      all_ukraine: 'Trabajamos en toda España',
      contact_us: 'Contacta con nosotros',
      about_us: 'Sobre nosotros',
      about_company: 'Sobre la compañía',
      shops: 'Las tiendas',
      pay_later: 'Paga más tarde con Rulla',
      blog: 'Blog',
      for_business: 'Para las empresas',
      sell_with_rulla: 'Vender con Rulla',
      for_clients: 'Para los clientes',
      seller_support: 'Apoyo al vendedor',
      customer_service: 'Servicio al cliente',
      terms_and_conditions: 'Reglas y condiciones',
      press: 'Para la prensa',
      financial_services: 'Información sobre el contrato de prestación de servicios financieros'
    },
    CustomerService: {
      header: {
        support: 'Soporte',
        text: 'Te damos la oportunidad de comprar lo que mereces'
      },
      categories: {
        heading: 'Categorías',
        first: {
          title: 'Envío y Devolución',
          subtitle: 'Devolución | Envío | Cancelación',
          text: 'El plazo de entrega de los operadores postales suele oscilar entre 1 y 3 días. ' +
            'Puede rastrear sus pedidos en su área de cliente en la sección "Historial de pedidos". ' +
            'Dispone de 14 días desde la fecha de compra, tiene derecho a devolver las mercancías sin dar razones,' +
            'siempre que esté en buenas condiciones. La devolución de mercancías se regula entre la tienda y el cliente. Si ' +
            'la tienda confirma la devolución de la mercancía,la cuota se cancela automáticamente y ' +
            'el primer pago se devuelve al cliente.'
        },
        second: {
          title: 'Fagos',
          subtitle: 'Realizar y gestionar pagos. Aplazamiento',
          text: 'El primer pago lo hace el cliente al realizar una compra, los pagos posteriores se deducen de' +
            'la tarjeta automáticamente. El cliente tiene derecho a cambiar el proceso de pago y realizar pagos de forma independiente. ' +
            'Para cambiarlo tiene que entrar en la sección “Mi perfil” en su área de cliente. En su área de cliente' +
            'también encontrará el historial de todas sus compras y pagos, vencimientos y gastos de compra' +
            'realizados a través de Rulla.'
        },
        third: {
          title: 'Productos y Servicios',
          subtitle: 'Cómo usar Rulla |  Opciones del pago',
          text: 'Rulla es un instrumento de pago que permite pagar las mercancías en varios plazos para' +
            'aliviar a los clientes de la carga del presupuesto. Rulla ofrece pago en 3 partes, el primero' +
            'de los cuales se deduce al realizar la compra y el siguiente cada 30 días. El cliente puede emitir' +
            'una cantidad limitada de pagos de Rulla para compartir sus costos en un mes.'
        },
        forth: {
          title: 'Usuario y los datos personales',
          subtitle: 'Gestión de los datos de pago. Gestión de los datos de contacto',
          text: 'Durante el primer pago a través de Rulla, se forma el área personal del cliente, ' +
            'en la que hay información sobre todas las cuotas emitidas, información sobre comercios y condiciones ' +
            'de pago. También en su área de cliente puede cambiar sus datos personales:el número de  teléfono, correo ' +
            'o número de la cuenta bancaria.'      }
      }
    },
    PayLater: {
      header: {
        h1: 'Compre ahora - pague después',
        subheader: 'Elige el pago Rulla en tus tiendas favoritas',
        start_button: 'Empezar',
        more_button: 'Más información'
      },
      subheader: 'Rulla es la forma más segura de obtener lo que necesitas ' +
        'ahora y pagar después. Divide el pago en pagos mensuales. ' +
        'Permítete más.',
      more: {
        heading: '¿Quieres más Rulla en tu vida?',
        item1: {
          p: 'Catálogo de tiendas',
          h2: 'Encuentra miles de tiendas #rulla',
          button: 'Encontrar minoristas'
        },
        item2: {
          p: 'Únete a la comunidad',
          h2: 'Síguenos en Instagram @rullacom',
          button: 'Únete a la comunidad'
        }
      },
      widget: {
        header: 'Es fácil comenzar. ¡Inténtalo!',
        subheader: 'Usar Rulla es tan fácil como ' +
          'pagar las compras con una tarjeta.  ' +
          'Regístrate y obtén cuotas instantáneas. ',
        step1: {
          header: 'Elija pago a través de Rulla',
          text: 'Elige productos en tu tienda favorita. Al realizar un pedido, selecciona Rulla como método de pago.',
        },
        step2: {
          header: 'Confirma el número de móvil',
          text: 'Pasa la verificación de Rulla en un par de minutos y realiza una compra. Sin formularios largos.',
        },
        step3: {
          header: 'Acepta los términos',
          text: 'Recibirá una decisión de aprobación inmediatamente y tu pedido será entregado donde nos has indicado.',
        },
      },

      how_works: {
        header: '¿Cómo funciona Rulla?',
        transparent_payment: {
          header: 'Método de pago transparente y flexible',
          subheader: 'Sin sobrepagos. Rulla es una excelente alternativa a los préstamos que lo ayudará a reducir costos y obtener todo lo que desea.',
          item1: 'Divida el precio de compra en 3 pagos sin intereses ' +
            'que se deducen de su cuenta bancaria cada mes.',
          item2: 'Conoces la cantidad exacta de cada pago',
          item3: 'No hay cargos adicionales si pagas a tiempo.'
        },
        modern_payment: {
          header: 'Instrumento de pago cómodo y moderno',
          subheader: 'Pagar en cuotas te permite repartir el costo a lo largo ' +
            'del tiempo para que puedas comprar lo que desees ' +
            'sin comprometer tu presupuesto.',
          item1: 'Administra tus compras y verifica los pedidos anticipados en tu área de cliente.',
          item2: 'Los pagos se cargan automáticamente cada 30 días.'
        }
      },
      buy_now: {
        header: 'Compra ahora - paga después',
        subheader: 'Millones de usuarios de soluciones de pago flexibles en todo el mundo ' +
          'ya han apreciado sus ventajas sobre los métodos de pago tradicionales.',
        interest_free_header: 'Cuota honesta sin intereses',
        interest_free_text: 'Divide el precio de compra en 3 partes. El primer pago' +
          ' se realiza al momento de la compra, y el último se carga ' +
          'automáticamente a la tarjeta cada 30 días. Sin sobrecostos, ' +
          'nada extra. Solo más tiempo para pagar por lo que te gusta.',
        legal_information: 'Información legal'
      },
      shops_categories: {
        shops_header: 'Las tiendas',
        shops_subheader: 'Las mejores ofertas de los socios de Rulla están siempre a tu alcance.',
        shops_button: 'Las tiendas',
        categories_header: 'Categorías',
        categories_subheader: 'Encuentra lo que necesites y paga de la manera más conveniente.',
        categories_button: 'Ver todo',
      },
      questions: {
        more_profitable_then_cards: {
          header: '¿Por qué Rulla es mejor que las tarjetas de crédito?',
          text: 'Usando tarjetas de crédito, entras en un círculo vicioso.' +
  ' La mayoría de los bancos cobran intereses, así como varios tipos de' +
            ' comisiones y cargos por servicios. Por lo tanto, cuanto más tiempo uses ' +
            'el saldo de tu tarjeta de crédito, más costosas serán tus compras. ' +
            'En su lugar, Rulla ofrece una solución de pago flexible con ' +
            'términos transparentes.No cobramos ni comisiones, ni intereses.Cada vez ' +
            'que tomas una decisión de compra informada, tomas exactamente todo' +
            ' lo que necesitas. Siempre sabes exactamente cuánto debes pagar y cuándo terminas de pagar la compra.'
        },
        different_from_bank: {
          header: '¿En qué se diferencia Rulla de las cuotas bancarias?',
          text: 'Todos los productos bancarios, incluyendo las cuotas, suelen tener diversas ' +
            'comisiones. Estas pueden incluir multas por pago atrasado, cargos por pago ' +
            'anticipado, pagos regulares por abrir o cerrar su cuenta por transferir ' +
            'o retirar fondos, etc. Dependiendo del costo del producto, la duración del plan de cuotas y' +
            'los términos de la tienda donde está comprando, su plan de pago también puede incluir intereses. ' +
            'Con Rulla nunca deberás pagar más de lo que aceptas ' +
            'pagar por adelantado. El precio de compra se divide en 3 pagos iguales, ' +
            ' el primero de los cuales se paga inmediatamente, y los demás se cargan automáticamente ' +
            'después de 30 y 60 días. Si estos pagos se realizan a tiempo, no va a tener pagos extras.'
        },
        checks_my_solvency: {
          header: '¿Cómo evalúa Rulla mi solvencia?',
          text: 'Queremos asegurarnos de ayudar a nuestros clientes a tomar las decisiones financieras correctas, ' +
            'teniendo en cuenta sus circunstancias de vida. Por lo tanto, recopilamos y procesamos información ' +
            'sobre los usuarios de Rulla para asegurarnos de que no tienen ninguna dificultad' +
            'en pagar sus compras en cuotas. ' +
            'Cuando eliges por primera vez el pago en una tienda online con tres pagos sin intereses  ' +
            'de Rulla, evaluamos tu solvencia utilizando tecnologías basadas en Big' +
            ' Data. La verificación en el servicio Rulla no afecta su calificación crediticia. ' +
            'Comprar con Rulla es fácil si tiene más de 18 años y una fuente ' +
            'de ingresos constante.'
        }
      }
    },
    Shops: {
      header: 'Tiendas + Rulla',
      subheader: 'El pago a plazos de Rulla es de 3 pagos mensuales sin intereses. ' +
        'Solución de pago flexible para su presupuesto. Ahora en España.',
      categories: 'Categorías',
      favorite_shops: 'Tiendas seleccionadas',
      sales_text: '* Los descuentos son válidos solo por un período de tiempo limitado y no están controlados por Rulla. Rulla puede obtener una comisión.',
      show_more: 'Ver más',
      filter_items: {
        search: 'Buscar',
        all: 'Todo',
        health: 'Salud',
        cloth_shoes: 'Moda y zapatos',
        beauty: 'Belleza',
        house: 'Hogar',
        hobbies: 'Aficiones ',
        car: 'Auto'
      },
    },
    SubFooterBusiness: {
      text: 'Tiene un negocio y desea vender con RULLA?',
      button: 'RULLA para tu negocio',
    },
    InfoTiles: {
      tiles: {
        header: 'Contacta con nosotros',
        help_questions: 'Te ayudamos en cualquier pregunta.',
        made_rulla_purchase: 'Has realizado una compra usando RULLA? Visita nuestra página de ',
        customer_service_page: 'Soporte al cliente.',
        when_will_pay: '¿Cuándo recibiré el dinero por los productos que pagué con RULLA?',
        start_sell_rulla: '¿Queréis vender más con RULLA?',
        start_right_now: '¡Empieza ahora mismo!'
      },
      lower_tiles: {
        header_hours: 'Horas de trabajo',
        header_business: 'Servicio al cliente RULLA para negocio',
        monday_friday: 'Lunes - Viernes',
        phone: 'Número de contacto:',
        mail: 'Correo electrónico:'
      },
    },
    Blog: {
      header: 'Blog',
      subtitle: 'En este blog conocerás las nuevas tendencias e ideas que harán tus compras impecables',
      newsCategories: 'Categoría de novedades',
      rullaNews: 'Novedades de RULLA',
      allNews: 'Todas las novedades',
      loadMore: 'Ver más',
      shareArticle: 'Compartir artículo',
      readNext: 'Leer más artículos',
      all: 'Todo',
      another_categories: 'Otras categorías del blog',
    },
    Home: {
      favorite_stores_header: 'Tiendas seleccionadas',
      popular_stores_header: 'Categorías populares',
      fashion_cloth_header: 'Moda y ropa',
      beauty_header: 'Belleza',
      home_header: 'Hogar',
      watch_all: 'Ver todo',
      shops_partners: 'Tiendas asociadas',
      shopping_pleasure: {
        header: 'Nuevo formato de compras online con más ventajas',
        subheader: 'Elige Rulla para comprar más fácil. Paga de una manera más cómoda',
        button: 'Más información',
        no_overpayment: 'Compra ahora, paga después',
        transparent_process: 'Condiciones transparentes',
        get_now: '100% sin pagos extras',
      },
      beauty: {
        header: 'Crea belleza de una manera natural',
        subheader: 'Spray y sérum para la reparación y nutrición del cabello',
        button: 'Ver mas',
      },
    },
    SellWithRulla: {
      popup: {
        header1: 'Solicitud',
        header2: 'de colaboración',
        website_adress: 'Enlace de la página web',
        contact_person: 'Contacto',
        email: 'Correo electrónico',
        phone: 'Número de contacto',
        button: 'Enviar',
      },
      popup_thanks: {
        header: 'Gracias por tu solicitud',
        text: 'Contactaremos contigo en un plazo de 24 horas.',
      },
      header: {
        h1: 'Activa Rulla y recibe más ventas',
        button_start: 'Empieza ahora!',
        button_more: 'Ver más',
      },
      benefits: {
        header: 'Sin riesgos, solo ventajas:',
        button_go_over: 'Pasar',
        items: {
          more_customers: {
            header: 'Más compradores',
            text: 'Al publicar tu tienda en Rulla.com ' +
              'atraes audiencia absolutamente gratis'
          },
          coverage: {
            header: 'Apoyo al vendedor',
            text: 'Nuestro atención al cliente responderá a todas tus preguntas.'
          },
          guard_store: {
            header: 'Seguridad de la tienda',
            text: 'No hay riesgos. Rulla se encarga de revisar la solvencia de los clientes.'
          },
          guard_customer: {
            header: 'Protección al consumidor',
            text: 'Nuestro servicio al cliente resolverá rápidamente los problemas de los compradores.'
          },
          conditions: {
            header: 'Condiciones de pago convenientes',
            text: 'Una solución de pago conveniente sin sobrepago aumenta la confianza en tu negocio.'
          },
          money: {
            header: 'Primero el pago',
            text: 'La tienda recibe inmediatamente el pago completo de la compra de Rulla.'
          }

        }
      },
      integration: {
        header: 'Integración de Rulla en cualquier tienda online',
        text: 'Conexión directa del servicio de pago Rulla' +
          'a plataformas e-commerce a través de API. Siga las instrucciones y ' +
          'lea la documentación o póngase en contacto con nosotros',
        button_apply: 'Enviar solicitud',
        button_manual: 'Instrucciones',
      },
      start_now: {
        header: 'Empieza ya!',
        application_item: {
          header: 'Contacta con nosotros',
          text: 'Conectar Rulla toma un mínimo de tiempo. Empieza a vender más lo antes posible.'
        },
        widget_item: {
          header: 'Flexibilidad de pago',
          text: 'Ofrece a tus clientes un sistema de pago transparente ' +
            'con el que podrán pagar menos. Sin créditos ni sobre pagos.'
        },
        orders_item: {
          header: 'Recoge tu pedido',
          text: 'Tus clientes pueden dividir el importe de la compra en' +
            ' 3 partes y pagar de la manera más conveniente. Tu tienda recibe ' +
            'inmediatamente todo el pago de Rulla'
        },
        charts_item: {
          header: 'Optimiza el negocio',
          text: 'Tienes el acceso a la analítica de las ventas mediante Rulla ' +
            ' en tu área de cliente. Estudia los datos ' +
            'y úsalos para el desarrollo.'
        },
      },
      how_it_works: {
        header: '¿Cómo funciona?',
        text: 'Rulla se agrega a tu tienda online como método de pago. ' +
          ' Cuando el comprador elige el pago a través de Rulla tiene la oportunidad de ' +
          'dividir el precio de compra en 3 pagos mensuales. Tu tienda ' +
          'recibe inmediatamente todo el pago de Rulla y envía la mercancía al cliente. Para el vendedor, ' +
          'esto no es diferente de una venta normal. '

      },
      partnership: {
        text: 'Asociarse con Rulla es la forma más fácil de convertir a los visitantes ' +
          'de tu tienda online en clientes. Obtiene nuevos clientes que compran ' +
          'cada vez más con más frecuencia. Siempre se paga el precio completo inmediatamente, y' +
          ' tus clientes compran como les sea más conveniente.',
        volume_increase: 'Aumento del volumen de ventas',
        conversion_increase: 'Aumento de la conversión visitante-cliente',
        check_increase: 'Aumento de la facturación media',
        upto: 'hasta'
      },
      statistics: {
        header: 'Las estadísticas mundiales muestran una tendencia creciente de los servicios de pago a plazos:',
        transactions: 'transacciones por día',
        users: 'usuarios',
        countries: 'países',
        mln: 'mln'
      },
      content: {
        header: 'La conveniencia del cliente es el foco',
        subheader: 'Comprar con Rulla fortalece la confianza de los clientes en tu marca. ' +
          'Permite que tus clientes paguen sus compras en tres cuotas mensuales ' +
          'sin intereses ni cargos ocultos. Un pago honesto: ' +
          'instantáneo y sin sobrepago. Es conveniente controlar las compras y pagos en la oficina ' +
          'de servicio personal. Las condiciones transparentes de pago de Rulla son la mejor ' +
          'alternativa a las tarjetas de crédito y los préstamos. Construye tu modelo de negocio con  ' +
          'una excelente experiencia de usuario y consigue miles de clientes leales.',
        text1: 'Las personas de entre 20 y 35 años prefieren los servicios de pago en línea a plazos/en cuotas..' +
          ' Agrega tu tienda a Rulla y conseguirás nuevos clientes.',
        text2: 'Registrarse por primera vez en Rulla toma un par de minutos. Decisión instantánea sobre ' +
          'cuotas a través del móvil al reservar una compra. Los pagos se cargan automáticamente. ',
        text3: 'Los usuarios registrados de Rulla realizan compras a nuevos vendedores con la comodidad' +
          ' de los clientes habituales. Tales compradores no dudan. Gastan más y regresan más ' +
          'a menudo.'
      },
      news: {
        header: 'Casos y noticias en el blog de Rulla',
        subheader: 'Los compradores pagan como mejor les parezca. Los vendedores obtienen clientes más felices. Rulla se encarga de todo.',
        button_further: 'Leer más',
        blog: 'Blog Rula',
      }
    },
    AboutUs: {
      header: 'Sobre nosotros',
      subheader:
        'Hemos creado el servicio de pago a plazos de Rulla\n' +
        'como una alternativa conveniente a las tarjetas de crédito y préstamos,\n' +
        'que te obligan a pagar de más.\n' +
        'Rulla te da más tiempo para pagar tus compras en línea,\n' +
        'sin cargos ocultos.',
      content_center: {
        header: '¿Quienes somos?',
        text1: 'La solución de pago tecnológico de Rulla es un nuevo desarrollo de creadores experimentados de productos fintech para los mercados europeos. El propósito de este producto es hacer que las compras en línea sean más fáciles y accesibles.',
        text2:
          'Nuestra misión es dar a todos la oportunidad de vender\n' +
          'y comprar en línea de la manera más conveniente, segura y rentable posible. ',
        text3: 'El servicio de pago flexible de Rulla es un nuevo instrumento financiero para España, que funciona con el modelo "Compre ahora, pague después". Rulla ahora ofrece a los clientes de las tiendas online el pago a plazos. Los usuarios del servicio tienen la oportunidad de pagar la compra en tres pagos, que se deducen automáticamente de la tarjeta cada 30 días sin intereses ni tarifas adicionales.',

        textBank: 'Tamga finance S.L. no es una institución bancaria. El proceso de registro y verificación de usuarios requiere un mínimo de tiempo y datos. Las últimas tecnologías en Big Data se utilizan para el procesamiento de aplicaciones y la automatización de procesos',
      },
      contacts: {
        header: 'Contactos:',
        hot_line: '(Línea directa)',
        sales_department: '(Departamento de ventas)',
        project_manager: '(jefe de proyecto)',
        address:
          'Tamga Finance S.L.\n' +
          'Avenida de Brasil, 17,\n' +
          'Entreplanta,\n' +
          '28020, Madrid, ES',
        email: 'info@prestamorapido.es',
        phone: '+38 066 885 4373',
        phoneNo: '+380668854373'
      }
    },
    EmailConfirm: {
      header: 'Confirmación de correo electrónico',
      content: {
        in_process: 'Estamos revisando el correo electrónico...',
        success: 'Correo electrónico verificado con éxito',
        failure: 'Algo salió mal'
      }
    },
    TokenRedirect:{
      content:"Entramos en su área de cliente..."
    },
    SupportSeller: {
      header: {
        h1: '¡Felicidades! ¿Cómo podemos ayudarte?',
        p: 'Aquí encontrará respuestas a preguntas sobre los productos Rulla, cómo empezar ' +
          'y cómo vender con Rulla. Póngase en contacto con nosotros si necesita ayuda.'
      },
      categories: {
        contact_us: 'Contactar con nosotros',
        common: {
          become_partner: {
            header: '¿Cómo convertirse en socio de Rulla?',
            text: 'Para empezar a trabajar con Rulla, es necesario rellenar un formulario simple de solicitud. ' +
              'Nos pondremos en contacto con usted dentro de las 24 horas siguientes.'
          },
          term_of_action: {
            header: '¿Tiene el contrato un período de vigencia?',
            text: 'La duración del contrato es de 12 meses. ' +
              'El contrato se renovará automáticamente un mes antes del final del período de validez actual. ' +
              'Rulla se reserva el derecho de rescindir este acuerdo en cualquier momento.'
          },
          integration: {
            header: '¿Cómo integrar el widget Rulla en la tienda online?',
            text: 'Después  ',
            link: 'de registrar una cuenta comercial,',
            text1: ' vaya a la sección "Integración" en su área de cliente, ' +
              'para obtener sus credenciales de la API de Rulla. Debe agregarlos a su plataforma de comercio electrónico, ' +
              'para comenzar a registrar compras.',
            step1: 'Paso número 1.',
            generate_token: 'Genere un token JWT único. Guarde su token de forma segura.',
            step2: 'Paso número 2.',
            send_request: 'Envíe una solicitud para crear una sesión de widget a: ',
            authorization_header1: 'En el titulo por Autorización debe especificar el valor de Bearer TOKEN, donde en lugar de TOKEN ',
            authorization_header2: 'use el token generado.  ',
            request_body_example: 'Ejemplo de solicitud',
            detailed_information: 'Puedes encontrar información detallada sobre la solicitud en la página:',
            success_request: 'Si tienes éxito, la respuesta contendrá el identificador de la sesión creada:',
            step3: 'Paso número 3.',
            add_widget_on_page: 'Agrega un widget a la página, especificando el src que contiene el ID recibido',
            problems_with_integration: 'Si tienes dificultades con la integración, contacta con el soporte comercial de Rulla.',
            code: `{
  "country": "ES",
  "locale": "es",
  "client": {
    "phone": "34780370920",
    "email": "customer@rulla.com",
    "surname": "Montilla",
    "name": "Enrique",
    "patronymic": "García-Berro",
    "dni": "12345678A",
    "livingAddress": "01426 Álava, Valdegovía, José matía 79",
    "deliveryAddress": "01426 Álava, Valdegovía, José matía 79"
  },
  "order": {
    "id": "2021-06-08-032992",
    "orderLines": [
      {
        "id": "21312312312",
        "name": "NVIDIA GEFORCE RTX 2080 Ti",
        "quantity": 1,
        "unitPrice": 1200.00,
        "imageUrl": "https://via.placeholder.com/350x150",
        "productUrl": "https://example.com"
      }
    ],
    "currency": "EUR",
    "total": 1225.00
  }
}`,
          },
          changed_account: {
            header: 'Cambié la cuenta corriente de la organización. ¿Qué necesito hacer?',
            text: 'Puedes administrar tus datos de pago en la sección "Cuentas". Si tienes alguna dificultad, comunícate con nuestro soporte comercial de Rulla.'
          }
        },
        seller_buyer: {
          header: 'Vendedor-Comprador',
          questions: {
            0 : {
              header: '¿Cuándo recibiré el dinero de las mercancías pagadas con Rulla?',
              text: 'En el contrato se especifica el tiempo durante el cual Rulla transfiere dinero' +
                ' al vendedor por los bienes pagados a través del servicio. ' +
                'El tiempo estándar de la cuenta de Rulla  ' +
                'es de 1 a 3 días. ' +
                'Si tienes alguna pregunta sobre el pago,' +
                ' consulta tu contrato o ponte en contacto con nuestro',
              link: 'servicio de soporte.'
            },
            1 : {
              header: '¿Cuándo se reembolsará el pago al cliente después de una devolución?',
              text: 'Rulla procesa los reembolsos después de registrar una devolución o ' +
                'cancelar un pedido. Normalmente, Rulla tarda de ' +
                '1 a 3 días hábiles en devolver el dinero al comprador. Tan pronto como el vendedor registra la devolución, el valor de las mercancías' +
                ' se deducirá del importe total de la deuda del cliente. En caso de devolución o cancelación  ' +
                'de todo el pedido, se devolverá el primer pago completo al cliente y se cancelará el resto.'
            },
            2 : {
              header: '¿ Con qué moneda puedo pagar a través de Rulla?',
              text: 'Rulla realiza todos los pagos en euros. Si la moneda' +
                ' del comprador es diferente, los pagos se cargarán' +
                ' al valor actual equivalente de la cantidad.'
            },
            3 : {
              header: 'Un comprador está interesado en saber cuánto más debe pagar. ¿Qué contestar?',
              text: 'Informa a tu cliente que puede encontrar toda la información de pago en ' +
                'su área de cliente en Rulla.com. También puede ponerse en contacto con ',
              link: 'el servicio de atención al cliente.',
              text1: ' Rulla para obtener información adicional.'
            },
            4 : {
              header: '¿Qué hacer si el cliente reclama sus pagos?',
              text: 'Si el cliente reclama sus pagos ' +
                'dírígele a',
              link: 'Servicio al cliente',
              text1: ' Rulla para que nuestros expertos puedan resolver su problema.',
            },
            5 : {
              header: '¿Qué ocurre cuando el cliente devuelve la compra?',
              text: 'Si el comprador devuelve una compra pagada con ayuda de Rulla, ' +
                'tiene que hacerlo de acuerdo con la ley de España en esos terminos ____ ' +
                'Después de registrar la devolución , Rulla ' +
                ' reembolsa el pago a la tarjeta del comprador. ' +
                ' Siempre pida al cliente guardar los justificantes de envíos y' +
                'entregas de las compras  para resolver cuestiones polémicas.'
            },
            6 : {
              header: '¿Dónde puedo encontrar mis pedidos?',
              text: 'Para ver todos los pedidos ubicados en su tienda ' +
                ' entre al apartado “Pedidos” en su cuenta de negocios.'
            },
            7 : {
              header: '¿Cómo puedo empezar a vender en Rulla?',
              text: 'Empieza a trabajar con Rulla ya rellenando el formulario en nuestro sitio web',
              link: 'aquí.'
            },
            8 : {
              header: '¿Qué diferencia hay entre el pago a plazos y Rulla?',
              text: 'Rulla - es una solución de pago para tiendas online que permite' +
                ' a sus clientes pagar por las compras online en unas cuantas cuotas. ' +
                'Usan el modelo “Compra ahora - paga después”, aumenta la conversión ' +
                'y el cheque porque le da a los clientes formas más flexibles de pago.',
              list: {
                0: {
                  header: 'Rulla vs. pago a plazo inmediato',
                  text: 'El pago a plazos es un crédito e influye en el historial crediticio.' +
                    'En comparación con el pago a plazos tradicional  en Rulla no hay ' +
                    'comisiones y aprobación del importe que debe pagarse en tres pagos' +
                    'se acepta a base de la tecnología  machine learning.',
                },
                1: {
                  header: 'Rulla vs. pago a plazos del banco',
                  text: 'Condiciones de uso del servicio Rulla están claras, transparentes y únicas para todos. ' +
                    'Para este método de pago se ajustan las tarjetas de débito y por eso, ningún sobrepago ' +
 'a los bancos por uso de la tarjeta de crédito. El comprador enseguida sabe cuánto y cuándo debe pagar.'
                }
              }
            },
            9 : {
              header: '¿Hay alguna restricción para vender en Rulla?',
              text: 'Si vendes tabaco, medicinas, pasajes, servicios ' +
                'o usas Marketplace, pedimos que contacte con ',
              link: 'el departamento de ventas Rulla.'
            },
            10 : {
              header: '¿Qué es pago a plazos de Rulla?',
              text: 'Rulla - es una solución de pago flexible para e-commerce, ' +
                'que es un nuevo método de pago para España : ' +
                'con tres pagos sin comisiones con plazos de dos meses. ' +
                'Rulla se actualiza constantemente con la ayuda de mejoraciones ' +
                'y funciones que aumentan la conversión y facilitan ' +
                'la vida tanto a usted como a otros clientes.'
            },
          }
        },
        account_management: {
          header: 'Uso del perfil',
          questions: {
            0: {
              header: '¿Cómo actualizar mis datos de contacto?',
              text:
                'Usted puede actualizar sus datos personales en su perfil del comprador en el apartado “Ajustes”, “datos de contacto”. ' +
                'Si tiene alguna dificultad contacta con Soporte al vendedor y te ayudaremos con gusto.'
            },
            1: {
              header: 'He cambiado la cuenta de pago de la organización. ¿Qué debo hacer?',
              text: 'Puedes administrar lo referente a los pagos en el apartado “Cuentas” Si has tenido alguna dificultad contacta con Soporte comercial de Rulla.'
            },
            2: {
              header: '¿Qué es la cuenta de negocio?',
              text: 'La cuenta de negocio es la entrada al área personal del vendedor (cuenta de negocios).' +
                ' A través de ella los vendedores podrán acceder al sistema de proceso de los pedidos' +
                ' pagados con ayuda de Rulla. En la cuenta de negocio las tiendas - socios pueden:',
              list: {
                 0: {
                  text: 'Recibir y procesar los pedidos.'
                },
                1: {
                  text: 'Redactar informes detallados sobre sus ventas de Rulla.'
                },
                2: {
                  text: 'Revisar el historial de los pedidos pagados por Rulla.'
                },
                3: {
                  text: 'Cambiar los ajustes.'
                }
              }
            },
            3: {
              header: '¿Cómo entrar al área del vendedor?',
              text: 'Para entrar en tu área de vendedor usa el correo y la contraseña que recibiste al firmar el contrato de socios con Rulla. El botón de “Entrar” está en la parte superior de la página web.',
               text1:
              'Para entrar en su área de vendedor usa el correo y la contraseña, ' +
              'recibidos al firmar el contrato de socios con Rulla. Pinchar',
              link1: 'aquí',
              text2: ' y introducir datos.'
            },
            4: {
              header: '¿Hay fecha de vencimiento en el contrato?',
              text: ' Límite del contrato con Rulla son 12 meses' +
                ' El contrato se renovará automáticamente' +
                ' un mes antes del periodo corriente. Rulla ' +
                'tiene el derecho de resolver el contrato en cualquier momento'
            },
            5: {
              header: '¿Cómo hacer un informe resumido?',
              text: 'En la cuenta de negocio de forma predeterminada se refleja' +
                ' el informe de último mes pero usted puede seleccionar ' +
                'el periodo de ventas que necesita. Eso se puede hacer' +
                ' filtrando los datos según la fecha en el apartado “pagos”.'
            },
            6: {
              header: '¿Qué hay en el apartado de “Integración”?',
              text: 'En el apartado “Integración” se pueden generar los registros ' +
                'API que se usan para establecer conexión ' +
                'entre su tienda y Rulla.'
            },
            7: {
              header: '¿Qué hay en el apartado de “Pagos”?',
              text: 'En el apartado “Pagos” puedes encontrar datos sobre las ventas' +
                ' de Rulla. Aquí puedes ajustar tus propios rangos ' +
                'y filtrar la información según los parámetros para analizar ' +
                'índicadores que necesites.'
            },
            8: {
              header: '¿Qué hay en el apartado de “Pedidos”?',
              text: 'El apartado “Pedidos” te permite ver todos los pedidos ' +
                'y su historial y también registrar las devoluciones.'
            },
            9: {
              header: '¿Qué hay en el apartado de “Notificaciones”?',
              text: 'Puedes ajustar las notificaciones a través de la vía más cómoda: e-mail, SMS, o por Red social. También puede personalizar diferentes canales, activar o disactivar notificaciones para diferentes empresas legales dentro de su tienda.'
            },
            10: {
              header: '¿Cómo se puede obtener la información inicial para entrar en el área de negocios?',
              text: 'Cuando firmes el acuerdo con Rulla nosotros creamos' +
                ' una cuenta en el sistema (cuenta de negocio). ' +
                'El correo electrónico indicado en el acuerdo será ' +
                'utilizado como nombre de usuario. ' +
                'Recibirás una carta de bienvenida con el usuario' +
                ' y contraseña para utilizarla solo una vez para ' +
                'entrar en tu cuenta.'
            },
            11: {
              header: '¿Cómo cambiar el idioma en la cuenta de negocio?',
              text: 'Pase el apartado “Ajustes” y elige el idioma .'
            },
            12: {
              header: '¿En qué países se puede utilizar Rulla?',
              text: 'El servicio Rulla como medio de pago a plazos de compras en línea está disponible en España'
            },
            13: {
              header: '¿Qué organización financiera regula Rulla?',
              text: 'SOCIEDAD DE RESPONSABILIDAD LIMITADA “RULLA ESPAÑA” es una organización microfinanciera cuyas actividades se regulan por el Banco de España.'
            },
            14: {
              header: '¿Tengo alguna persona de contacto?',
              text: 'En la etapa inicial del trabajo con Rulla va a tener ' +
                'una determinada persona de contacto que le ayudará en caso de alguna complicación.' +
                ' Después podrá acudir a nuestro ',
              link: 'servicio de atención al vendedor',
              text1: ' por teléfono o correo electrónico para obtener ayuda.'
            },
          }
        },
        integration: {
          header: 'Integración técnica',
          questions: {
            0: {
              header: '¿Dónde puedo encontrar los registros Rulla API  ?',
              text: 'Puedes generar nuevos registros Rulla API ' +
                ' en el apartado “Integración” en su área de ' +
                'vendedor (cuenta de negocio). No olvides guardar' +
                ' enseguida los nuevos datos Rulla API ya que aparecen solo una vez.'
            },
            1: {
              header: '¿Dónde puedo ver el logo Rulla?',
              text: ' El logo Rulla puedes ubicarlo en su página web y descargarlo' +
                ' “Presiona” o pregunta a tu contacto en Rulla.'
            },
            2: {
              header: '¿Cómo funciona la integración?',
              text: ' El primer paso es obtener los datos del registro Rulla API ,' +
                ' que después tendrá que añadir en su plataforma para empezar ' +
                ' a registrar las compras. Para obtener tus datos del registro  ' +
                'Rulla API, necesitas entrar en tu cuenta de negocio' +
                ' en el apartado “Integración”. Ahí puedes generar tus datos' +
                'del registro Rulla API.'
            },
            3: {
              header: '¿Qué se necesita para que la integración de Rulla con mi tienda sea aceptada?',
              text: 'Para empezar a vender con Rulla tu página web tiene que ser  ' +
                'activa y contener la siguiente información:',
              list: {
                0: {
                  text: 'Información sobre la empresa: nombre de la compañía registrada, ' +
                    'dirección de la compañía registrada, número de registro de la empresa.'
                },
                1: {
                  text: 'Estado y condiciones que contienen información sobre la entrega ' +
                    'y orden de la devolución de las compras. Si la entrega es pagada' +
                    'es necesario indicarlo correctamente.'
                },
                2: {
                  text: 'Número de contacto de su Atención al cliente, ' +
                    'y el correo electrónico.'
                },
                3: {
                  text: 'Precio de los productos y el importe de la entrega correspondientes.'
                },
              }
            },
            4: {
              header: '¿Qué hay que hacer antes de empezar a integrarme con Rulla?',
              text: 'Para empezar la integración con Rulla, debes tener' +
                ' una tienda online que funcione. Esto significa que ' +
                'tus productos/ servicios deben estar disponibles en el sitio web,' +
                ' así como la posibilidad de añadirlos a la cesta.'
            },
            5: {
              header: '¿Rulla tiene una guía de integración?',
              text: 'Sí, en la sección "Integración" de tu cuenta comercial encontrarás' +
                ' información sobre cómo integrar Rulla. Si necesitas' +
                ' ayuda, comunícate con tu agente de Rulla o con el servicio' +
                ' de soporte de ventas.'
            }
          }
        },
      },
      back_button: 'Regresar a la página de soporte',
      questions_header: 'Preguntas más frecuentes',
    },
    TermsAndConditions: {
      header: 'Reglas y condiciones',
      documents: {
        rules_20210301: 'Reglas para prestar dinero',
        rules_20210714: 'Adiciones a las reglas de'
      },
      payments: {
        header: 'Opciones de pago',
        content: 'Pago con tarjetas' // (Visa y MasterCard)
      },
      delivery: {
        header: 'Entrega en España',
        time: 'Por lo general, la entrega tarda de 1 a 4 días en toda España.',
        cost: 'El costo de la entrega depende de las condiciones del proveedor individual. '
      },
      refund: {
        header: 'Devolución y cambio',
        text1: 'De acuerdo con la Ley de España "sobre la protección de los derechos del consumidor", el comprador tiene derecho a cambiar la mercancía por otra equivalente en un plazo de 14 días, si la mercancía adquirida no se ajusta a su forma, dimensiones, estilo, color, tamaño u otros motivos, y si el producto ha conservado su aspecto, las etiquetas y el documento de facturación emitido por el vendedor junto con la mercancía. Excepción tienen  los productos no retornables por ley, cuya lista puede consultar haciendo',
        text2: 'clic aquí.'
      },
      contacts: {
        header: 'Datos de contacto',
        companyName: 'SOCIEDAD DE RESPONSABILIDAD LIMITADA “RULLA ESPAÑA”»',
        companyNumber: 'ЄДРПОУ 43213857',
        address: 'Україна, 69035, Запорізька обл., м. Запоріжжя,\nвул. Феросплавна, будинок 38, кв. 31',
        emailLabel: 'E-mail:',
        email: 'sales@rulla.com',
        phoneLabel: 'Tel:',
        phoneNumber: '+38 066-885-43-73'
      }
    },
    ThreePayments: {
      header_block: {
        header: 'Tres pagos sin intereses',
        subheader: 'Una nueva forma conveniente de comprar en línea',
        button: 'Contáct con nosotros'
      },
      business: {
        header: 'Rulla desarrolla tu negocio',
        subheader: 'Una solución de pago que aumenta las ventas es la opción perfecta para tu tienda online.',
        items: {
          conversion: {
            header: 'Conversiones',
            text: 'Una forma conveniente de pago a plazos sin comisiones aumenta la probabilidad de compra.'
          },
          average_check: {
            header: 'Cheque promedio',
            text: 'Rulla da a sus clientes más tiempo para pagar, así que compran más.'
          },
          regular_customers: {
            header: 'Clientes habituales',
            text: 'Una buena experiencia de compras rentables genera más ventas repetidas.'
          },
          attendance: {
            header: 'Asistencia',
            text: 'Los usuarios de Rulla llegan a su sitio directamente desde el servicio de pago.'
          }
        },
        implemented_top_brands: 'El pago por partes introdujo las mejores marcas en todo el mundo.'
      },
      purchases_control: {
        header: 'Compras bajo control',
        subheader: 'Todas las compras están en un solo  lugar, en su área de cliente de Rulla.',
        item1: 'Tus clientes pueden ver su historial de pedidos, gestionar pagos de forma flexible y planificar presupuestos.',
        item2: 'La capacidad de dividir el costo de las compras en 3 partes sin intereses, brinda confianza y libertad financiera.',
        item3: 'Al ofrecer una alternativa rentable al crédito y a las cuotas bancarias, ' +
          'la solución de pago de Rulla aumenta las ventas y la lealtad del cliente.',
        button_start_now: 'EMPEZAR AHORA MISMO'
      },
      guarantee: {
        header: 'Garantías a los participantes del contrato',
        text: 'Usar Rulla para comprar en línea es tan fácil ' +
          'como pagar con una tarjeta. Ambos lados ' +
          ' se benefician. Los compradores aumentan su solvencia de forma segura. ' +
          'Un algoritmo de estimación no gastará más de lo que puede pagar. ' +
          'Los vendedores trabajan como de costumbre: después de hacer el pedido, reciben ' +
          ' el importe total por adelantado y entregan la mercancía. ' +
          'Salió algo mal?' ,
        link: 'Rulla resolverá todo.'
      },
      statistics: {
        header: 'Rulla da a tus clientes la oportunidad de hacer más compras.',
        subheader: '¿Comprar o no comprar? El pago a plazos solucionará el problema de la falta de fondos  ' +
          'y dará la experiencia de una cómoda compra. Más compras y menos cestas abandonadas  ' +
          'con servicio de pago flexible de Rulla. ',
        info_pleased: 'Los que utilizan la función de pago en múltiples pagos están satisfechos con esta experiencia',
        info_think: 'los usuarios de servicios de pago consideran de esta manera en los plazos de la mejor',
        info_refused: 'los clientes de tiendas en línea se negarían a comprar sin la opción de pago a plazos*',
        info_refused1: '*Según los servicios de pago'
      },
      how_arranged: {
        header: '¿Cómo se organiza el pago en 3 pagos?',
        subheader: 'Compre ahora y pague después. Para facilitar las compras, ' +
          'Rulla ofrece un sistema de pago a plazos transparente. Sin sobrepagos.',
        order_registration: {
          header: 'Formalización del pedido',
          text: 'En la tienda online el comprador selecciona el pago a plazos de Rulla y realiza el primer pago' +
            ' utilizando la tarjeta bancaria. El widget muestra las cantidades y el calendario de pagos.'
        },
        delivery: {
          header: 'Entrega',
          text: 'Después de procesar el pedido, Rulla paga al vendedor (tienda) el costo total de la compra.  ' +
            'Nos encargaremos de recibir  los pagos adicionales ' +
            'del comprador.'
        },
        after_purchase: {
          header: 'Tras la compra',
          text: 'Después de la compra su cliente tiene una idea clara ' +
            'de sus compras y puede gestionar fácilmente sus pedidos ' +
            'en la oficina personal de Rulla.'
              },
      connecting: {
        header: 'Conexión Rulla',
        subheader: 'La instalación del widget de pago en 3 plazos en el sitio web de la tienda online ' +
          'tarda varias horas. Para conectarse, contáctenos a través del formulario en el sitio web.',
        details_table: {
          header: 'Más información',
          market_availability: {
            header: 'Disponibilidad del mercado',
            text: 'España'
          },
          payment_options: {
            header: 'Opciones de pago',
            text: 'Tarjeta de débito o crédito'
},
          payment_period_starts: {
            header: 'Comienza el período de pago',
            text: 'Al procesar el pedido'
          },
          number_of_payments: {
            header: 'Número de pagos',
            text: '3'
          },
          time_between_payments: {
            header: 'Tiempo entre pagos',
            text: '30 días (es estándar)'
          },
          annual_interest_rate: {
            header: 'Tasa de interés anual',
            text: '0,00001%'
          },
          additional_expenses: {
            header: 'Gastos adicionales',
            text: 'Sin comisiones.Sin cargos cuando se paga a tiempo'
          },
          payment_method: {
            header: 'Forma de pago',
            text: 'Paso a pérdidas y ganancias automático de la tarjeta registrada'
          },
          delay_in_payment: {
            header: 'Retraso (morosidad)',
            text: 'Puede cobrar una pequeña cuota'
          },
          view_pay_purchases: {
            header: 'Vista previa de compras y pago',
            text: 'Es necesario autorizar en tu área de cliente en',
          },
          seller_protection: {
            header: 'Protección del vendedor',
            text: 'Sí'
          },
          buyer_protection: {
            header: 'Protección del comprador',
            text: 'Sí'
          }

        },
        integration_table: {
          header: 'Integración',
          direct_integration: {
            header: 'Integración directa',
            text: 'API'
          },
          automatic_updates: {
            header: 'Actualizaciones automáticas',
            text: 'Activadas'
          },
          developer_resources: {
            header: 'Recursos para desarrolladores',
            text: 'Instrucción de integración'
          }
        },
        trade_agreement_table: {
          header: 'Acuerdo comercial',
          standard_terms: {
            header: 'Términos estándar del acuerdo',
            text: '12 meses (continúa automáticamente)'
          },
          model_pricing: {
            header: 'Precio / el plan de costo ',
            text: 'Contáctenos para más información'
          }
        }
      }
      }
    },
    Legal: {
      h1: 'INFORMACIÓN JURÍDICA',
      h2: 'SOCIEDAD DE RESPONSABILIDAD LIMITADA “RULLA ESPAÑA”',
      text1: 'Код ЄДРПОУ 43213857',
      text2: 'Код КВЕД 64.92 Otros tipos de crédito (básico).',
      text3: 'Tipo de servicio financiero: préstamos, incluidos los términos de un préstamo financiero.',
      text4: 'Certificado de registro de una institución financiera ФК №1420 від 26.05.2020 р.',
      text5: 'Datos de las licencias y permisos expedidos a la institución financiera:',
      text6: 'El tipo de actividad económica para la que se ha expedido una licencia.' +
        'PROVISIÓN DE FONDOS EN PRÉSTAMO, INCLUIDAS LAS CONDICIONES DE CRÉDITO FINANCIERO' +
        '  Ліцензія на здійснення діяльності з надання коштів у позику, в тому' +
        '  числі і на умовах фінансового кредиту від 11.06.2020 р.' +
        '  (Розпорядження НКФУ № 1323 від 11.06.2020 р.)',
      text7: 'El estado de la licencia es válido.',
      text8: 'Es válido por tiempo indefinido.',
      text9: 'Datos sobre el inicio de procedimiento de quiebra, sanciones:',
      text10: 'No está en proceso de liquidación.',
      text11: 'Fundadores (participantes) de la compañía:',
      text12: 'ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ «ІНСТИТУТ ДОСЛІДЖЕНЬ СТАНДАРТІВ ЯКОСТІ»',
      text13: 'Код ЄДРПОУ засновника: 36977252',
      text14: '69002, Запорізька обл., місто Запоріжжя, Олександрівський район, вулиця Запорізька, будинок 6-А, квартира 47',
      text15: 'ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ «ЦЕНТР ПРАВОВИХ РІШЕНЬ»',
      text16: 'Код ЄДРПОУ засновника: 36977247',
      text17: '69002, Запорізька обл., місто Запоріжжя, Олександрівський район, вулиця Запорізька, будинок 6-А, квартира 47',
      text18: 'Beneficiario final (controlador)',
      text19: 'ТИХОНОВА ОЛЕНА ОЛЕКСІЇВНА',
      text20: 'Україна, 69002, місто Запоріжжя, вулиця Запорізька, будинок 6-А, квартира 47',
      text21: 'Información sobre los órganos administrativos de la entidad jurídica:',
      text22: 'Reunión general de los miembros de la compañía.',
      text23: 'El órgano ejecutivo de la Compañía — Director (имя директора).',
      text24: 'No se ha establecido una junta de vigilancia',
      text25: 'No hay unidades separadas.',
      text26: 'Horario',
      text27: 'De Lunes a Viernes 9.00-18.00',
      text28: 'Sin descanso y fines de semana.',
      text29: 'Datos de contacto',
      text30: 'Dirección:',
      text31: 'Україна, 69035, Запорізька обл., місто Запоріжжя, вул. Феросплавна, будинок 38, квартира 31',
      text32: 'Код території за КОАТУУ 2310136900',
      text33: 'Correo electrónico:',
      text34: 'Número de teléfono:',
      text35: 'Mecanismos para proteger los derechos de los consumidores de servicios financieros',
      text36: 'Las reclamaciones de los consumidores se reciben en:',
      text37: '1) Dirección  legal de la Compañía:',
      text38: 'Україна, 69035, Запорізька обл., місто Запоріжжя, вул.Феросплавна, будинок 38, квартира 31',
      text39: '2) Banco nacional de España:',
      text39_1: 'Dirección: 01601, м. Київ, вул. Інститутська, 9',
      text39_2: 'Teléfono de atención al ciudadano: 0 800 505 240',
      text39_3: 'Correo electrónico : nbu@bank.gov.ua',
      text39_4: 'https://bank.gov.ua/ua/consumer-protection',
      text41: '3) Головним управлінням Держпродспоживслужби в Запорізькій області:',
      text41_1: ' Dirección: 69002 м. Запоріжжя, вул. Гоголя, 105 а, кабінет 203',
      text41_2: 'Número de teléfono: (061) 228-04-64 (consultorio)',
      text41_3: 'Correo electrónico: office@gudpss-zp.gov.ua',
      text43: ' Autoridad que regula las actividades de una persona que presta servicios financieros:',
      text44: 'Banco nacional de España',
      text44_1: 'Direccion: 01601, м. Київ, вул. Інститутська, 9',
      text44_2: 'Teléfono de atención al ciudadano: 0 800 505 240',
      text44_3: 'Correo electrónico: nbu@bank.gov.ua',
      text45: 'El coste del servicio financiero se determina en relación con el importe del préstamo' +  ' y se especifica en el contrato celebrado entre la Sociedad y el Cliente.' +
        ' La tasa de interés anual puede oscilar entre el 0,00001% y el 0,1%.',
      text46: 'La compañía no contrata intermediarios de crédito',
      text47: 'No hay fondos de garantía ni planes de compensación' +
        ' en la prestación de servicios financieros para la concesión de préstamos, incluso en' +
        ' condiciones de crédito financiero.',
      text48: 'Esquemas de préstamos existentes y posibles: anualidad',
      text49: 'Como resultado de recibir servicios financieros, no se proporciona ningún pago de impuestos o tarifas a expensas de una persona.',
      text50: 'Documentos:',
      text51: 'NORMAS PARA LA CONCESIÓN DE PRÉSTAMOS, INCLUIDAS LAS CONDICIONES DE CRÉDITO FINANCIERO',
      text52: 'CONTRATO DE SERVICIOS FINANCIEROS',
      text53: 'CERTIFICADO DE REGISTRO DE UNA INSTITUCIÓN FINANCIERA',
      text54: 'ORDEN DE INSCRIPCIÓN EN EL REGISTRO ESTATAL DE ENTIDADES FINANCIERAS',
      text55: 'EXTRACTO DEL REGISTRO ESTATAL UNIFICADO DE PERSONAS JURÍDICAS',
      text56: 'ORDEN DE EXPEDICIÓN DE LICENCIA PARA REALIZAR ACTIVIDAD ECONÓMICA DE PRESTACIÓN DE SERVICIOS FINANCIEROS',
    },
    PersonalCabinet: {
      Auth: {
        customer: 'Comprador',
        business: 'Tienda',
        business_login: 'Entrada de la tienda',
        profile_login: 'Entrada del comprador',
        new_login: 'Unirse a Rulla',
        enter_number_to_login: 'Ingrese su número para iniciar sesión en su área de cliente',
        start_with_rulla: 'Comience a comprar con Rulla: ingrese su número de teléfono para iniciar sesión en su cuenta personal',
        enter_number_to_recover: 'Ingrese su número y le enviaremos un enlace a la página donde puede establecer una nueva contraseña',
        password_saved: 'Su contraseña ha sido cambiada. Puede pasar al enlace para entrar en su área de cliente',
        password_sent: 'El enlace se envió al número indicado',
        enter_new_password: 'Ingrese una nueva contraseña para iniciar sesión en su área de cliente',
        phone: 'Número de teléfono',
        password: 'Contraseña',
        new_password: 'Contraseña nueva',
        confirm_password: 'Confirmar la contraseña',
        login: 'Entrar',
        forgot_password: '¿Ha olvidado la contraseña?',
        sign_up: 'Registrarse',
        return_to_login: 'Volver a iniciar sesión.',
        your_phone_number: 'Su número de teléfono',
        sending_speeds: 'Se pueden utilizar tarifas de mensajes y datos',

        change: 'Cambiar',
        send_code: 'Solicitar el código',
        send_password: 'Solicitar',
        send_on: 'Enviado a',
        send_one_more_time: 'Enviar el código de nuevo',
        enter_sms_code: 'Introduce el código del SMS',
        back: 'Volver/atras',
        wait: 'Espera',
        seconds: 'unos segundos',
        invalid_password: 'Error: contraseña inválida',
        invalid_phone_number: 'Error: formato de número +34 XX XXX XXXX',
        invalid_email: 'Error: ingrese el correo electrónico en formato a@a.com',
        empty_password: 'Error: ingrese la contraseña',
        user_not_found: 'Error: usuario no encontrado'
      },
      Layouts: {
        Sidebar: {
          main: 'Principio',
          orders: 'Pedido',
          settings: 'Notificación',
          settlements: 'Pagos/Transferencias',
          integration: 'Integración',
          hotline: 'Soporte',
          favorites: 'Elegido',
          purchases: 'Compras',
          payments: 'Pagos',
          bank_accounts: 'Cuentas',
          offline: 'QR / SMS',
          profile_settings: 'Configuración',
          personal: 'Mis datos'
        },
        Footer: {
          main: 'Principio',
          orders: 'Pedidos',
          settings: 'Notificación',
          settlements: 'Pagos/Transferencias',
          favorites: 'Elegido',
          purchases: 'Compras',
          payments: 'Pagos',
        },
        MobileMenu: {
          integration: 'Integración',
          bank_accounts: 'Cuentas',
          offline: 'QR / SMS',
          hotline: 'Soporte',
          profile_settings: 'Configuración'
        }
      },
      Business: {
        profile_button: 'Perfil/ Área de cliente',
        Telegram: {
          telegram: 'Telegram',
          add_account: 'Agregar una cuenta/ un perfil',
          popup_title: 'Verificación de la cuenta/ del perfil',
          popup_1: 'Para empezar a recibir notificaciones en el Telegram es necesario enviar un mensaje con un código de confirmación a nuestro bot',
          popup_2: 'Inicie un chat con el bot por enlace:',
          popup_3: 'Haga clic en el botón "Inicio" y envíele el siguiente mensaje:',
        },
        Integration: {
          header: 'Configuración de integración',
          controls: {
            withdraw: 'Retirar fichas emitidas',
            generate: 'Generar'
          },
          popup: {
            your_token: 'Su ficha: ',
            save_token: 'Guárdala para una mayor integración con Rulla API',
            copy: 'Copiar'
          },
          text: {
            how_integrate: '¿Cómo integrar el widget?',
            step_1: 'Paso 1.',
            send_request: 'Envíe una solicitud para crear una sesión de widget a:',
            heading_set_token: 'En el título por Autorización debe especificar el valor de Bearer TOKEN, donde en lugar de TOKEN, usar el token generado.',
            request_body_example: 'Ejemplo de la solicitud',
            detailed_information: 'La información detallada sobre la solicitud se puede encontrar en la página API:',
            legal: 'Significación legalEntityId y bankAccountId se pueden encontrar en la sección "Cuentas"',
            success_request: 'Si la solicitud tiene éxito, la respuesta contendrá el ID de la sesión creada:',
            step_2: 'Paso 2.',
            add_widget_on_page: 'Agregue un widget a la página, especifique src, que contiene la ID recibida.',
            example: 'Por ejemplo',
            example_name: 'Pedro ',
            example_surname: 'Lopez',
            example_patronymic: 'Fernandez',
            example_address: ' Madrid, Calle Brasil',
          },
        },
        Settings: {
          header: 'Notificación',
          hints: [
            'Puede configurar notificaciones a través de cualquier canal que le resulte conveniente: correo electrónico, SMS o messenger.',
            'También puede configurar diferentes canales por separado, habilitar o desactivar notificaciones para diferentes entidades legales dentro de tu tienda.',
            'Por defecto, los canales personales coincidirán con la configuración de la tienda. Puede agregar y verificar nuevos contactos en la sección "Configuración"'
          ],
          store_defaults: '-- Configuración de la tienda --',
          legal_entities: 'Empresa',
          phone_number: 'Número de teléfono',
          email: 'Correo electrónico',
          what_notify_about: '¿Qué quieres que informe?',
          new_orders: 'Nuevos pedidos',
          order_confirmation: 'Confirmación del pedido',
          expiring_orders: 'Pedidos vencidos',
          paid_orders: 'Pedidos pagados para los que necesita enviar la mercancía',
          unknown_orders: 'Pedidos desconocidos',
          platform_not_notified: 'Los pedidos que no son notificados por la plataforma o el sistema de comercio electrónico ' +
            ' pueden indicar un error de integración entre la API de Rulla y su sistema.',
          when_notify: '¿Cuándo informar?',
          everyday: 'Cada día',
          everyday_morning: 'Obtenga un informe diario todas las mañanas',
          turn_off_blank: 'Desactivar correos en blanco',
          dont_send_blank: 'No le enviaremos correos electrónicos cuando no haya nada que informar',
          every_week: 'Semanalmente',
          every_week_monday: 'Obtenga un informe semanal todos los lunes',
          dropdownLabel: 'Idioma'
        },
        Settlements: {
          header: 'Informes de pago',
          download: 'Descargar CSV',
          period: 'Período de venta',
          empty_table: 'Los pagos se mostrarán aquí',
          table: {
            date: 'Fecha',
            number: 'Número',
            customer_name: 'Comprador',
            customer_phone: 'Número de teléfono',
            sum: 'Importe'
          }
        },
        Orders: {
          total: 'Total',
          header: 'Todos los pedidos',
          download_button: 'Descargar XLS',
          feedback_button: 'Retroalimentación',
          search_field: 'Nombres y apellidos completos del cliente, correo electrónico, el número del pedido',
          period: 'Período de venta',
          shop: 'Tienda',
          all_statuses: 'Todos los estados',
          status: 'Estado',
          statuses: {
            new: 'Nuevo',
            waiting_for_payment: 'Está esperando el pago',
            paid: 'Está esperando la transferencia',
             refunding: 'Reembolso',
            refunded: 'El dinero ha sido devuelto',
            repaid: 'Pagado',
            shipping: 'Enviado',
            shipped: 'Entregado',
            canceled: 'Cancelado',
          },
          apply_button: 'Usar',
          QrModal: {
            header: 'Pagar el pedido',
            order_sum: 'Pedido por la cantidad',
            send_button: 'Enviar',
          },
          NewOrderModal: {
            trigger_button: 'Realizar un pedido',
            header: 'Nuevo pedido',
            phone_label: 'Número de teléfono',
            name_label: 'Nombre de los pedidos',
            price_label: 'La cantidad',
            delete_line: 'Eliminar un pedido',
            add_line: 'Agregar otro pedido',
            skip_confirmation: 'No necesita validación',
            modal_text: 'Para crear un pedido, introduzca el número de teléfono del cliente, el nombre y el precio del producto.',
            create_order: 'Crear pedido',
            all: 'En total',
            create_error: 'Hay un error',
            amount_invalid: 'Esta modalidad de pago está disponible para pedidos de {{min}} a {{max}}'
          },
          DetailsModal: {
            order_details: 'Detalles del pedido',
            rulla_order_number: 'Número de pedido en Rulla:',
            store_order_number: 'Número de pedido en tienda:',
            store_subdivision: 'Tienda:',
            order_date: 'Fecha y hora de la compra:',
            order_total: 'El importe total:',
            customer_contacts: 'Datos de contacto del comprador',
            surname: 'Primer Apellido:',
            name: 'Nombre:',
            patronymic: 'Segundo apellido:',
            phone: 'Teléfono :',
            email: 'Correo :'
          }
        },
        Offline: {
          Header: 'Realizar un pedido',
          PhoneNumber: 'Número de teléfono',
          BankAccount: 'Cuenta para pagar',
          NoAccount: '- No elegido -',
          Send: 'Enviar',
          Sent: 'Enviado',
        },
        BankAccounts: {
          Header: 'Cuenta para pagos',
          AddLegalEntity: 'Nueva empresa',
          AddBankAccount: 'Añadir nueva cuenta',
          SortCode: 'МФО',
          LegalEntityModal: {
            Header: 'Nuevas empresas',
            Name: 'Nombre ',
            Code: 'ЄДРПОУ',
            Close: 'Cerrar',
            Save: 'Añadir nuevas empresas'
          },
          BankAccountModal: {
            Header: 'Nueva cuenta',
            AccountNumber: 'Número de cuenta',
            BankName: 'Banco ',
            BankNamePlaceholder: 'ПриватБанк',
            Currency: 'Divisa',
            SortCode: 'МФО',
            Close: 'Cerrar ',
            Save: 'Añadir cuenta'
          }
        },
        Main: {
          header: 'Página principal',
          roll_up: 'Cerrar',
          watch_all: 'Ver todo',
          orders: 'Pedido ',
          sales_volume: 'Cantidad de ventas,',
          average_check: 'Cheque medio,',
          statistic: 'Estadística ',
          dropdown: {
            1: 'Ventas de hoy',
            2: 'Ventas de semana',
            3: 'Ventas de mes',
            4: 'Ventas del trimestre'
          },
          sorters: {
            1: 'Por fecha',
            2: 'Por precio',
            3: 'Por tienda'
          }
        },
        Profile: {
          heading: 'Perfil',
          contact_data: 'Datos de contacto ',
          logout: 'Salir',
          phone: 'Teléfono',
          email: 'Correo electrónico',
          add_phone: 'Añadir correo nuevo',
          add_email: 'Añadir correo nuevo',
          confirm: 'Confirmar ',
          not_confirmed: 'Sin confirmar',
          change: 'Cambiar',
          verify_phone: {
            enter_code: 'Introduzca el código de SMS',
            send_on: 'Enviado a',
            send_one_more: 'Enviar código de nuevo',
            wait: 'Esperen ',
            seconds: 'unos segundos ',
            send_code: 'Solicitar código',
            back: 'Volver ',
            next: 'Continuar',
            your_number: 'Su número de teléfono',
            data_speed: 'Puede utilizarse para transmitir mensajes y datos a gran velocidad',
            on_what_number: 'Necesitamos confirmar que es usted. Díganos a qué número podemos enviar el código.',
             phone: 'Teléfono'
          },
          verify_email: {
            check_email: 'Revisa su correo electrónico',
            on_your_mail1: 'Hemos enviado a su correo electrónico',
            on_your_mail2: 'un enlace de confirmación. Si no puede encontrar el mensaje, revise carpeta de "Spam"',
            send_one_more: 'Enviar de nuevo',
            personal_cabinet: 'Área de cliente',
            your_email: 'Su correo electrónico',
            send_link: 'Enviar un elance',
            back: 'Volver',
            email: 'Correo electrónico',
            what_you_can: 'También puede recibir notificaciones, así como noticias y promociones de nuestros colaboradores.',
          },
        },
      },
      Profile: {
        Main: {
          heading: 'Página principal',
          pay: 'Pagar',
          delivery: 'Entrega ',
          purchases_history: 'Historial de las compras',
          no_purchases: 'Todavía no ha realizado ninguna compra',
           goods: 'Productos ',
          total_amount: 'El precio total',
          order_number: 'Número de pedido'
        },
        Favorites: {
          heading: 'Elegido',
          search: 'Producto o tienda',
          stores: 'Tiendas ',
          deliveries: 'Entregas',
          empty_stores: 'No encontramos ninguna tienda',
          start_shopping: 'Busca y añade tus tiendas favoritas a la lista',
          empty_deliveries: 'No tienen entregas pendientes',
          check_delivery_here: 'Si tiene una compra y está en el proceso de entrega puede comprobar aquí.',
        },
        Orders: {
          all: 'En total: ',
          paid: 'Pagado: ',
          nextPayment: 'Próximo pago: ',
          status: 'Estado',
          statuses: {
            new: 'Nuevo',
            waiting_for_payment: 'Espera de pago',
            paid: 'Pagado ',
            refunding: 'Devolución de pago',
            refunded: 'Reembolso',
            repaid: 'Pagado',
            shipped: 'Entregado',
            canceled: 'Cancelado',
          },
          description: 'Descripción',
          order_number: 'Pedido',
          date: 'Fecha ',
          cost: 'Precio ',
          payments: 'Pagos',
          store: 'Tienda ',
          payments_heading: 'Pagos ',
          products_heading: 'Productos'
        },
        Purchases: {
          heading: 'Compras ',
          overview: 'Revisar ',
          all_purchases: 'Todas las compras',
          balance: 'Saldo ',
          expenses_by_month: 'Gastos por meses:',
          charts_statistics: 'Habrá análisis de gastos con gráficos',
          spent: 'Gastado ',
          dont_have_purchases: 'No tiene ninguna compra .',
          here_expenses: 'Cuando los haga, habrá costes por categoría de producto',
          spent_by_period: 'Gastado por ',
          expenses_by_period: 'Compras por ',
          expenses_by_store: 'Gastos por las tiendas:',
          current_purchases: 'Compras pendientes',
          all_empty: {
            header: 'Aún no has comprado en Rulla',
            text1: 'No se han registrado compras relacionadas con ',
            text2: ' Compruebe si ha utilizado una dirección de correo electrónico diferente',
          },
          balance_empty: {
            header: 'Compras pendientes ',
            text: 'Aquí se reflejaran los productos que están sin pagar'
          }
        },
        Payments: {
          header: 'Pagos',
          relax: 'Póngase cómodo y relájese',
          find_it_here: 'Si necesita hacer algo, puede encontrarlo aquí.',
          all: 'Total :',
          pay: 'Pagar',
          close: 'Cerrar',
          paid: 'Pagado',
          not_paid: 'Impago',
          payment_schedule: 'Calendario de pagos',
          payment_history: 'Historial de los pagos',
          number: 'Número :',
          sum: 'Summa',
          indebtedness: 'Deuda:',
          fines: 'Penilizaciones:',
          total_paid: 'Total pagado:',
          total_to_pay: 'Total para pagar :',
          pay_header: 'Pagos',
          next_payment: 'Próximo pago:',
          since: 'desde',
        },
        Personal: {
          contact_data: 'Datos de contacto',
          personal_data: 'Datos personales',
          passport_data: 'Datos de su dni',
          address_data: 'Dirección ',
          surname: 'Primer apellido',
          patronymic: 'Segundo apellido',
          name: 'Nombre ',
          birth: 'Fecha de nacimiento',
          taxNumber: 'Número de identidad',
          passport: 'DNI',
          id_card: 'ID картка',
          series: 'Серія',
          number: 'Numero de dni',
          passport_date: 'Fecha de expedición',
          passport_issued_by: 'Autoridad',
          biometric_passport_issued_by: 'Орган який видав',
          registration_address: 'Dirección de registro',
          addresses_same: 'Dirección de registro y actual son las mismas',
          living_adress: 'Dirección actual',
          save: 'Guardar ',
          saved: 'Datos guardados',
          edit_contacts: 'Modificar sus datos'
       },
        Settings: {
          heading: 'Configuracion',
          main: 'Generales ',
          contact_data: 'Datos de contacto',
          subscribe_on_news: 'Subscripción a noticias',
          payment: 'Pago',
          payment_methods: 'Modos de pago',
          upload_photo: 'Descargar foto',
          logout: 'Salir ',
          phone: 'Teléfono',
          email: 'Correo electrónico',
          add_phone: 'Introducir un nuevo número',
          main_phone: 'General',
          select_as_main: 'Elegir actual ',
          legal_entities: 'Contactos de otras empresas',
          delete: 'Eliminar',
          confirm: 'Confirmar ',
          not_confirmed: 'Sin confirmar',
          change: 'Cambiar',
          enter_sms_code: 'Indique el código de SMS',
          send_on: 'Enviado a',
          send_one_more: 'Enviar código SMS de nuevo',
          wait: 'Esperen',
          seconds: ' unos segundos',
          send_code: 'Enviar código',
          back: 'Volver',
          next: 'Continuar',
          your_number: 'Su número de teléfono',
          make_sure_thats_you: 'Necesitamos verificar que es usted. Díganos a qué número debemos enviar el código.',
          speeds_on_sms: 'Se puede utilizar la "transmisión rápida de mensajes y datos"',
          your_email: 'Su correo electrónico',
          on_it_notifications: 'En tu correo puedes recibir consejos, así como noticias y promociones de nuestros colaboradores',
          send_link: 'Enviar un enlace',
          personal_cabinet: 'Área de cliente',
          send_one_more_time: 'Enviar de nuevo',
          just_sent: 'Acabamos de enviar a su correo electrónico',
          check_email: 'Revise su correo electrónico ',
          on_your_mail1: 'A su correo electrónico',
          on_your_mail2: 'hemos  enviado un mensaje de confirmación. Si no lo encuentra, revise su carpeta de "Spam"'
        },
        Modals: {
          refund: {
            name: 'Devolución ',
            header: 'Devolución del producto',
            text: 'Para devolver hay que devolver el dinero que pagó por esta compra',
            button: 'Devolver'
          },
          cancel: {
            name: 'Cancelar ',
            header: 'Cancelar el pedido',
            text: 'Para cancelar el pedido deberá devolver el dinero que pagó por esta compra',
            button: 'Confirmar ',
          },
          confirm: {
            name: 'Confirmacion',
            link: 'Confirmar ',
            header: 'Confirmación del pedido',
            text: 'Usted confirma la disponibilidad del producto en el pedido y el comprador puede hacer el pago',
            button: 'Confirmar',
          },
          status: {
            name: 'Estado ',
            header: 'Estado de su pedido',
            text: 'Elija el estado de la solicitud:',
            button: 'Confirmar '
          },
          pay: {
            name: 'Pagar '
          },
          cancel_button: 'Cerrar'
        }
      }
    },
    Press: {
      header: 'Rulla para la prensa',
      subheader:
        'Aquí puede ver los artículos de prensa de Rulla y el material correspondiente, ' +
        'como la información de la empresa y la biblioteca de imágenes',
      publications: {
        header: 'Rulla en las publicaciones',
        links: [
          {
            title: 'Cómo el servicio de pago a plazos Rulla está desarrollando una tendencia global de e-commerce en España',
            url: 'https://ain.ua/2021/05/05/kak-servis-oplaty-pokupok-chastyami-rulla-razvivaet-v-ukraine-globalnyj-trend-e-commerce/'
          },
          {
            title: 'El servicio de compras a pagos parciales sin procentaje de Rulla: cómo funciona',
            url: 'https://ua-news.liga.net/all/news/servis-oplati-pokupok-chastinami-bez-vidsotkiv-vid-rulla-yak-tse-pratsyue'
          },
          {
            title: 'El servicio de compras más ventajoso  en línea de Rulla ahora está en España',
            url: 'https://rau.ua/novyni/servis-onlajn-shopingu-rulla/'
          },
          {
            title: 'Rulla, un servicio de pago para compras online ventajosas, se lanza en España — ain.ua',
            url: 'https://ain.ua/2021/03/04/v-ukraine-zapuskaetsya-rulla-platezhnyj-servis-dlya-vygodnogo-onlajn-shopinga/'
          }
        ]
      },
      releases: {
        header: 'Últimos comunicados de prensa',
        links: [
          {
            title: 'El servicio de compras más ventajoso  en línea de Rulla ahora está en España',
            url: 'https://rulla.com/ua/blog/servis-dlia-vygidnogo-onlajn-shopingu-rulla-teper-v-ukrajini'
          }
        ]
      },
      show_more: 'Descargar más',
      image_library: 'Biblioteca de imágenes',
      download: 'Descargar ',
      company_info: 'Informacion sobre la empresa',
      download_presentation: 'Descargar la presentación',
      text1:
        'La solución tecnológica de pago de Rulla es un nuevo desarrollo creado por los expertos en' +
        'productos de tecnología financiera para los mercados europeos. El objetivo de este producto es  ' +
        'hacer compras por internet más rápidas y fáciles. ',
      text2: 'Nuestra misión es ofrecer a todos los que quieren vender o ' +
        'comprar en línea la mejor manera posible, de forma segura y rentable. ',
      text3:
        'Rulla es un instrumento financiero nuevo en España, ' +
        'que trabaja según el modelo "Compre ahora, pague después"(Buy Now Pay Later). ' +
        'Rulla ofrece ahora a los clientes de la tienda online la posibilidad de pagar a plazos. Los clientes ' +
        ' tienen la posibilidad de pagar en tres plazos, que se cobran automáticamente en ' +
        'su tarjeta cada 30 días, sin intereses ni comisiones adicionales.',
        text4:
        'SOCIEDAD DE RESPONSABILIDAD LIMITADA “RULLA ESPAÑA” no es una institución bancaria y no utiliza ' +
        'una base de datos sobre el historial de crédito. El proceso de registro y verificación de los clientes ' +
        'requiere un mínimo de tiempo y datos. Las solicitudes se revisan y los procesos se automatizan ' +
        'se utilizan las nuevas tecnologías en el área de Big Data.',
      media_requests: 'Pedidos de Medios de comunicacion ',
      request_here: 'Enviar sus consultas al correo electrónico:',
      address_only_media: 'Tenga en cuenta que esta dirección de contacto es solo para los representantes de los medios de comunicación que estén interesados en estas publicaciones.',
      have_questions: ' Si tiene dudas sobre la compra, póngase en contacto con',
      support: ' el servicio de asistencia.'
    }
  }
}
