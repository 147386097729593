import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MyTranslationLoader } from '../services/translation-loader.service';
import { RouteLocalizer } from './localized-routes';

@Injectable()
export class LocalizedRouterService {

  get language() { return this.translator.currentLang; }
  get languages() { return Object.keys(this.loader.translations); }

  constructor(private translator: TranslateService, private loader: MyTranslationLoader) { }

  translateRoute(route: string | any[]) {
    const after = this._translateRoute(route, this.language);
    return after;
  }

  changeLanguage(path: string, language: string) {
    const keys = RouteLocalizer.invertPath(path);
    const url = keys.length === 1 && keys[0] === '' ? '/' : keys.join('/');
    const after = this._translateRoute(url, language);
    return after;
  }

  private _translateRoute(route: string | any[], language: string) {
    if (typeof route === 'string') {

      const segments = RouteLocalizer.translatePath(route, language);
      const prefixed = route.startsWith('/') ? ['', language, ...segments.splice(1)] : [...segments];
      return prefixed.join('/');

    } else {
      if (route.length === 0) return '';

      const segments = route.map(path => RouteLocalizer.translatePath(path, language));
      const prefixed = route[0].startsWith('/') ? ['', language, ...segments.splice(1)] : [...segments];
      return prefixed.join('/');
    }
  }
}
