import {MissingTranslationHandler, MissingTranslationHandlerParams} from '@ngx-translate/core';

export class MyMissingTranslationHandler implements MissingTranslationHandler {
    handle(params: MissingTranslationHandlerParams) {
      // console.warn(`Missing: ${params.key}`);
      const match = params.key.match(/^ROUTES\.([\w\-]+)$/);
      if (match) {
        return match[1];
      }
      return null;
    }
}
