import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent  {
  get language() { return  this.translate.currentLang; }
  constructor(private translate: TranslateService) { }

  currentYear: number = new Date().getFullYear();
}
