<div *ngIf="visible" class="cover" #cover>
  <div class="modal-container">
    <div class="modal" #modal>
      
      <img class="modal-close" src="assets/icons/close_grey.svg" (click)="close()">
      <div *ngIf="title" class="modal-header">
        <h2>{{title}}</h2>
      </div>
      <ng-content>
      </ng-content>
    </div>
  </div>
</div>
