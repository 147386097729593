<div class="header__lang" [class.hidden]="!visible">
  <p class="active lang_symbol"
     (click)="changeLang('ru')"
     [ngClass]="{'active': language === 'ru'}">
     RU
   </p>
  <p class="header__lang_delimiter">|</p>
  <p class="lang_symbol"
     (click)="changeLang('ua')"
     [ngClass]="{'active': language === 'ua'}">
     UA
   </p>
</div>
