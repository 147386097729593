import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {ReplaySubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss'],
})
export class WrapperComponent implements OnInit, OnDestroy {
  isAuthPage = false;
  showLoginMenu = false;
  showDesktopMenu = false;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private router: Router,
    private route: ActivatedRoute) {

    this.isAuthPage = this.getAuthFlag(route)
    this.router.events
      .pipe(takeUntil(this.destroy))
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.isAuthPage = this.getAuthFlag(route)
        }
      });
  }

  ngOnInit() {
  }

  toggleDesktopMenu(event: string) {
    this.showDesktopMenu = !!event;
  }

  ngOnDestroy(): void {
    this.destroy.next(null);
    this.destroy.complete();
  }

  private getAuthFlag(route: ActivatedRoute): boolean {
    let r = route.snapshot; 
    while (r.firstChild) {
      r = r.firstChild;
    }
    return r.data.auth;
  }
}
