import { ReplaySubject } from 'rxjs';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { AuthMode, AuthType, SwitchRequest } from '../models/auth.model';
import { AreaSignInResult, OtpSignInResult } from 'src/app/core/models/auth.model';
import { CustomerAreaSignInResult, CustomerOtpSignInResult } from 'src/app/core/auth/customer-auth.service';
import { CustomerTokenService } from 'src/app/core/auth/customer-token.service';
import { BusinessTokenService } from 'src/app/core/auth/business-token.service';

import { AuthLoginComponent } from '../auth-login/auth-login.component';
import { AuthSignUpComponent } from '../auth-sign-up/auth-sign-up.component';
import { AuthRecoverComponent } from '../auth-recover/auth-recover.component';
import { BusinessTenantService } from '../../../core/tenancy/business-tenant.service';
import { CustomerTenantService } from '../../../core/tenancy/customer-tenant.service';


@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit, OnDestroy {

  @ViewChild('signIn')
  signInPage: AuthLoginComponent;

  @ViewChild('signUp')
  signUpPage: AuthSignUpComponent;

  @ViewChild('recover')
  recoverPage: AuthRecoverComponent;

  @Input() authType: AuthType;

  mode: AuthMode = 'sign-in';
  phoneNumber = null;
  password = null;
  verificationId = null;
  version = null;
  otpSent = false;
  recover = false;
  pending = false;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    private router: Router,
    private customer: CustomerTokenService,
    private business: BusinessTokenService,
    private businessTenant: BusinessTenantService,
    private customerTenant: CustomerTenantService) { }

  ngOnInit() {
  }

  switchToSignIn(request: SwitchRequest) {
    this.mode = "sign-in";
    this.phoneNumber = request.phoneNumber;
  }

  switchToSignUp(request: SwitchRequest) {
    this.mode = "sign-up";
    this.phoneNumber = request.phoneNumber;
  }

  switchToRecover(request: SwitchRequest) {
    this.mode = "recover";
    this.phoneNumber = request.phoneNumber;
  }

  onOtpSent(result: AreaSignInResult) {
    this.otpSent = true;
    this.phoneNumber = result.phoneNumber,
    this.verificationId = result.verificationId;
    this.version = result.version;
  }

  onSignIn(result: AreaSignInResult | OtpSignInResult) {

    function isCustomer(response: AreaSignInResult | OtpSignInResult): response is CustomerAreaSignInResult | CustomerOtpSignInResult {
      return 'hasUnpaidOrders' in response;
    }

    switch(this.authType) {
      case 'customer':
      const url = this.mode == 'sign-up'
        ? '/customer/personal'
        : isCustomer(result) && result.hasUnpaidOrders
          ? '/customer/payments'
          : '/customer';

        localStorage.setItem(`customer.language`, result.locale);
        this.customerTenant.tenant = result.tenant;
        this.customer.token = result.token;
        this.router.navigateByUrl(url);
        break;

      case 'business':
        localStorage.setItem(`business.language`, result.locale);
        this.businessTenant.tenant = result.tenant;
        this.business.token = result.token;
        this.router.navigateByUrl('/business');
        break;
    }
  }

  changeType(type: AuthType) {
    this.authType = type;
    localStorage.authType = type;
  }

  ngOnDestroy() {
    this.destroy.next(null);
    this.destroy.complete();
  }
}
