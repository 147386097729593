import { ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { AreaSignInResult, OtpSignInData, OtpSignInResult, SignInStatus } from 'src/app/core/models/auth.model';
import { CustomerAuthService } from 'src/app/core/auth/customer-auth.service';
import { BusinessAuthService } from 'src/app/core/auth/business-auth.service';
import { AuthMode, AuthType } from '../models/auth.model';
import { CustomerSignUpService } from 'src/app/core/sign-up/customer-sign-up.service';

@Component({
  selector: 'app-auth-code',
  templateUrl: './auth-code.component.html',
  styleUrls: ['./auth-code.component.scss'],
})
export class AuthCodeComponent implements OnInit, OnDestroy{

  @Input() authType: AuthType;
  @Input() authMode: AuthMode;
  @Input() phoneNumber: string;
  @Input() verificationId: number;
  @Input() version: number;
  
  @Output() otpSent = new EventEmitter<AreaSignInResult>();
  @Output() signedIn = new EventEmitter<OtpSignInResult>();
  @Output() backClicked = new EventEmitter<any>();

  timerTotalSeconds = 30;
  timerValue = this.timerTotalSeconds;
  authError = false;
  alreadyAuthed = false;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);

  controls = {
    pin: new FormControl('', [Validators.required])
  };

  form = new FormGroup(this.controls);

  get pinCode() {
    return this.controls.pin.value;
  }

  constructor(
    private customer: CustomerAuthService,
    private signUpService: CustomerSignUpService,
    private business: BusinessAuthService) { }

  ngOnInit() {
    this.startTimer();
    this.controls.pin.valueChanges
      .pipe(distinctUntilChanged((a, b) => a === b))
      .pipe(filter(a => a.length == 4))
      .subscribe(_ => this.verifyCode());
  }

  verifyCode() {
    if (this.pinCode.length !== 4) {
      this.authError = true;
      return;
    }

    const data = <OtpSignInData> {
      verificationId: this.verificationId,
      version: this.version,
      pin: this.pinCode
    }

    switch(this.authType) {
      case 'customer':
        if (this.authMode == 'sign-in') this.verifyCustomerCode(data);
        if (this.authMode == 'sign-up') this.verifyCustomerSignUpCode(data);
        break;
      
      case 'business':
        this.verifyBusinessCode(data);
        break;
    }
  }

  resend() {
    this.business
      .resendCode({ phoneNumber: this.phoneNumber })
      .subscribe(result => this.otpSent.emit(result));

    this.startTimer();
  }

  back() {
    this.backClicked.emit();
  }

  private verifyCustomerCode(data: OtpSignInData) {
    this.customer
      .verifyCode(data)
      .subscribe(result => this.handleVerificationResult(result));
  }

  private verifyCustomerSignUpCode(data: OtpSignInData) {
    this.signUpService
      .verifyCode(data)
      .subscribe(
        result => this.handleVerificationResult(result),
        result => this.handleVerificationError(result));
  }

  private verifyBusinessCode(data: OtpSignInData) {
    this.business
      .verifyCode(data)
      .subscribe(result => this.handleVerificationResult(result));
  }

  private handleVerificationResult(result: OtpSignInResult) {
    switch(result.status) {
      case SignInStatus.Failure:
        this.authError = true;
        break;

      case SignInStatus.Success:
        this.signedIn.emit(result);
        break;
    }
  }

  private handleVerificationError(response: any) {
    switch (response.status) {
      case 401:
        this.authError = true;
        break;
      case 400:
        this.alreadyAuthed = true;
        break;
    }
  }

  private startTimer() {
    this.timerValue = this.timerTotalSeconds;
    const intervalId = setInterval(() => {
      this.timerValue = this.timerValue - 1;
      if (this.timerValue === 0) {
        clearInterval(intervalId);
      }
    }, 1000);
  }

  ngOnDestroy() {
    this.destroy.next(null);
    this.destroy.complete();
  }
}
