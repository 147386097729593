import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule } from 'ngx-mask';

import '@angular/common/locales/global/es';
import '@angular/common/locales/global/pl';
import '@angular/common/locales/global/ru';
import '@angular/common/locales/global/uk';

import { CoreModule } from './core/core.module';
import { MyMissingTranslationHandler } from './core/services/translation-handler.service';

import { BaseUrlInterceptor } from './core/interceptors/base-url.interceptor';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { AuthErrorInterceptor } from './core/interceptors/auth-error.interceptor';

import { environment } from 'src/environments/environment';

import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';
import { MyTranslationLoader } from './core/services/translation-loader.service';
import { LanguageInterceptor } from './core/interceptors/language.interceptor';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutes,
    TranslateModule.forRoot({ 
      defaultLanguage: environment.i18n.language,
      loader: {
        provide: TranslateLoader, 
        useClass: MyTranslationLoader
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler, 
        useClass: MyMissingTranslationHandler
      }
    }),
    NgxMaskModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    HttpClientModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseUrlInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthErrorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
