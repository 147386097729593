import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Extensions } from 'src/app/core/core.extensions';
import { AreaSignInResult, SignInStatus } from 'src/app/core/models/auth.model';
import { CustomerSignUpService } from 'src/app/core/sign-up/customer-sign-up.service';
import { environment } from 'src/environments/environment';
import { SwitchRequest } from '../models/auth.model';

@Component({
  selector: 'app-auth-sign-up',
  templateUrl: './auth-sign-up.component.html',
  styleUrls: ['../auth.scss', './auth-sign-up.component.scss']
})
export class AuthSignUpComponent implements OnInit {

  @Input() phoneNumber: string;

  @Output() otpSent = new EventEmitter<AreaSignInResult>();
  @Output() loginRequested = new EventEmitter<SwitchRequest>();

  ctrl = {
    phoneNumber: new FormControl('', [Validators.required]),
  }
  
  form = new FormGroup(this.ctrl);
  loading = false;

  get countryCode() { return this.translate.instant("Common.countryCode"); }
  get validPhoneNumber() { 
    return this.ctrl.phoneNumber.valid 
      ? this.ctrl.phoneNumber.value 
      : null;
  }
  
  constructor(
    private service: CustomerSignUpService,
    private translate: TranslateService) { }

  ngOnInit() {
    if (this.phoneNumber)
      this.ctrl.phoneNumber.setValue(this.phoneNumber);
  }

  switchToLogin() {
    this.loginRequested.emit({ authType: 'customer', phoneNumber: this.validPhoneNumber });
    this.form.reset();
  }

  signUp() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.loading = true;
    this.service
      .signUp({ phoneNumber: Extensions.toPhoneNumber(this.countryCode, this.ctrl.phoneNumber.value) })
      .subscribe(
        response => this.onSuccess(response),
        response => this.onError(response));
  }

  private onSuccess(response: AreaSignInResult) {

    switch(response.status) {
      case SignInStatus.Failure:
        this.ctrl.phoneNumber.setErrors({'server': true});
        this.ctrl.phoneNumber.markAsTouched();
        break;
      case SignInStatus.OtpSent:

        this.otpSent.emit(response);
        break;
    }
    
    this.loading = false;
  }

  private onError(response: any) {
    switch (response.status) {
      case 400:
        this.ctrl.phoneNumber.setErrors({'server': true});
        break;

      case 422:
        this.ctrl.phoneNumber.setErrors({'message': response.error.userMessage});
        break;

      case 429:
        this.ctrl.phoneNumber.setErrors({'message': this.translate.instant('Validation.server_busy')})
        break;
    }
    this.ctrl.phoneNumber.markAsTouched();
    this.loading = false; 
  }
}
