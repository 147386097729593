<button [disabled]="disabled"
        [ngClass]="{
        'chevron': arrowType === 'chevron' || arrowType === 'arrow',
        'chevron-circle': arrowType === 'chevron_cirlce',
        'arrow': arrowType === 'arrow'}">

  <ng-container *ngIf="arrowType === 'arrow';else chevronImage">
    <img [ngStyle]="{'transform' : leftArrow ? 'rotate(180deg)' : ''}"
         class="common" src="assets/icons/arrow.svg">
    <img [ngStyle]="{'transform' : leftArrow ? 'rotate(180deg)' : ''}"
         class="hover" src="assets/icons/arrow_hover.svg">
    <img [ngStyle]="{'transform' : leftArrow ? 'rotate(180deg)' : ''}"
         class="focus" src="assets/icons/arrow_focus.svg">
  </ng-container>
  <ng-template #chevronImage>
    <img [ngStyle]="{'transform' : leftArrow ? 'rotate(180deg)' : ''}"
         class="common" src="assets/icons/chevron-right.svg">
    <img [ngStyle]="{'transform' : leftArrow ? 'rotate(180deg)' : ''}"
         class="hover" src="assets/icons/chevron-right_hover.svg">
    <img [ngStyle]="{'transform' : leftArrow ? 'rotate(180deg)' : ''}"
         class="focus" src="assets/icons/chevron-right_focus.svg">
  </ng-template>

</button>
