<div class="menu_container"
  [class.business]="activeMenu === 'business'"
  [class.customer]="activeMenu === 'customer'">

  <app-menu-customer *ngIf="activeMenu === 'customer'"
    (linkClicked)="onLinkClick()">
  </app-menu-customer>

  <app-menu-business *ngIf="activeMenu === 'business'"
    (linkClicked)="onLinkClick()">
  </app-menu-business>
</div>
