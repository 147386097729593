import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class LanguageSelectionGuard implements CanActivate {
  constructor(private translate: TranslateService) {}

  canActivate(route, state): boolean {

    const languages = 
      environment.locations[location.hostname]?.languages ??
      environment.i18n.languages;

    const language = 
      environment.locations[location.hostname]?.language ??
      environment.i18n.language;

    if (languages.includes(route.data.language)) {
      this.translate.use(route.data.language);
    } else if (languages.includes(localStorage["language"])) {
      this.translate.use(localStorage["language"]);
    } else {
      this.translate.use(language);
    }
    return true;
  }
}
