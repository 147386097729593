import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-button-text',
  templateUrl: './button-text.component.html',
  styleUrls: ['./button-text.component.scss'],
})
export class ButtonTextComponent {
  @Input() disabled = false;
  @Input() withArrow = false;
  @Input() arrowLeft = false;
}
