export const locale = {
  lang: 'pl',
  data: {
    PIN: {
      error: {
        incomplete: 'Error: code is incomplete',
        invalid: 'Error: code is invalid'
      }
    },
    Common: {
      countryCode: '48',
      uah: 'uah',
      pln: 'zł',
      eur: 'eur.',
      items: 'szt.'
    },
    Validation:{
      required: 'To pole jest wymagane',
      format_error: 'Nieprawidłowy format'
    },
    Cookies:{
      consent_text: 'Używamy plików cookie, aby ulepszać witrynę, mierzyć naszą publiczność, poprawiać jakość reklam w oparciu o Twoją aktywność przeglądania i zainteresowania na naszych i stronach zewnętrznych. Kontynuując przeglądanie strony, zgadzasz się na używanie przez nas plików cookie.',
      agree: 'Zaakceptować',
      more_details: 'Więcej'
    },
    Statuses: {
      1: 'Nowe',
      3: 'Oczekiwanie na potwierdzenie',
      4: 'Oczekiwanie na płatność',
      5: 'Czekanie na wysyłkę',
      6: 'Zwrot kosztów',
      7: 'Zwrócone',
      8: 'Spłacone',
      9: 'Wysyłka',
      10: 'Wysłane',
      11: 'Anulowane'
    },
    SnackBar: {
      copied: 'Skopiowano',
    },
    Pending: {
      data_processing: 'Przetwarzanie danych',
      loading: 'Trwa ładowanie. Proszę poczekać...'
    },
    Header: {
      rulla_for_clients: 'Rulla dla klientów',
      rulla_for_business: 'Rulla dla biznesu',
      registration: 'Rejestracja',
      log_in: 'Wejście',
    },
    HeaderLoginMenu: {
      personal_cabinet_business: 'Login firmowy',
      personal_cabinet_business_description: ' Zaloguj się do systemu, żeby zarządzać ' +
        ' swoimi zamówieniami, potwierdzeniami płatności, statystyką sklepu ' +
        'i ustawieniami.',
      personal_cabinet_customer_description: 'Zaloguj się do systemu, żeby ' +
        'otrzymać informacje na temat swoich zakupów i oczekujących płatności oraz żeby łatwo' +
        ' je rozliczyć jednym kliknięciem myszy.',
      personal_cabinet_customer: 'Logowanie',
    },
    HeaderBusinessMenu: {
      sell_with_rulla: 'Sprzedaż z Rulla',
      support_seller: 'Wsparcie sprzedawcy',
      three_payments: 'Płatności odroczone'
    },
    HeaderClientMenu: {
      pay_later: 'Zapłać później z Rulla',
      about_company: 'O firmie',
      customer_service: 'Obsługa klientów',
      blog: 'Blog',
      rulla_shops: 'Sklepy Rulla',
      categories: 'Kategorie',
      watch_all: 'Zobacz wszystko'
    },
    NotFound: {
      heading: 'Chyba coś poszło nie tak!',
      text1: 'Strona, którą chcesz otworzyć, nie istnieje. Możliwe, ' +
        'że została usunięta albo wpisałeś nieprawidłowy adres. ' +
        'Przejdź na ',
      text2: ' i tam spróbuj znaleźć informacje, których szukasz.',
      main_page: 'stronę główną',
    },
    Footer: {
      legal: 'Informacje prawne',
      all_ukraine: 'Pracujemy w całej Polsce',
      contact_us: 'Skontaktuj się z nami',
      about_us: 'O nas',
      about_company: 'O firmie',
      shops: 'Sklepy',
      pay_later: 'Zapłać później z Rulla',
      blog: 'Blog',
      for_business: 'Dla biznesu',
      sell_with_rulla: 'Sprzedaż z Rulla',
      for_clients: 'Dla klientów',
      seller_support: 'Wsparcie sprzedawcy',
      customer_service: 'Obsługa klientów',
      terms_and_conditions: 'Zasady i warunki',
      press: 'Dla prasy'
    },
    CustomerService: {
      header: {
        support: 'Pomoc',
        text: 'Dajemy ci przyjemność z zakupów, na jaką zasługujesz'
      },
      categories: {
        heading: 'Kategorie',
        first: {
          title: 'Dostawa i zwroty',
          subtitle: null,
          text: null
        },
        second: {
          title: null,
          subtitle: null,
          text: null
        },
        third: {
          title: null,
          subtitle: null,
          text: null
        },
        forth: {
          title: 'Konto i dane osobowe',
          subtitle: 'Zarządzanie szczegółami płatności | Zarządzanie danymi kontaktowymi',
          text: 'Podczas pierwszej płatności za pośrednictwem Rulla zostaje utworzone osobiste konto klienta, które ' +
            'zawiera informacje o wszystkich towarach, informacje o sklepach i warunkach ' +
            'płatności. Na koncie możesz również zmienić swoje dane osobowe: telefon kontaktowy, mail lub ' +
            'numer konta bankowego.'
        }
      }
    },
    PayLater: {
      header: {
        h1: 'Kupuj teraz – płać później',
        subheader: 'Wybierz płatności z Rulla podczas zakupów w ulubionych sklepach',
        start_button: ' Zacząć',
        more_button: 'więcej szczegółów'
      },
      subheader: 'Rulla to najbezpieczniejszy sposób na zdobycie tego, czego potrzebujesz, ' +
        'teraz i zapłacić później. Zapłać pełną kwotę później lub podziel płatność na comiesięczne raty. ' +
        'Pozwól sobie na więcej.',
      more: {
        heading: 'Chcesz więcej Rulla w swoim życiu?',
        item1: {
          p: 'Katalog sklepów',
          h2: 'Znajdź tysiące sklepów, w których możesz #rulla ',
          button: 'Znajdź sprzedawców'
        },
        item2: {
          p: 'Dołącz do społeczności',
          h2: 'Obserwuj nas na Instagramie; @rulla.com',
          button: 'Dołącz do społeczności'
        }
      },
      widget: {
        header: 'Łatwo jest zacząć. Spróbuj!',
        subheader: 'Korzystanie z Rulla jest tak proste, ' +
          'jak płacenie za zakupy kartą. ' +
          'Wybierz Rulla jako sposób płatności i kupuj towary z odroczoną płatnością.',
        step1: {
          header: ' Wybierz płatność za pośrednictwem Rulla ',
          text: 'Wybierz towary w swoim ulubionym sklepie. Opłacając zamówienie, jako metodę płatności należy wybrać Rulla.',
        },
        step2: {
          header: 'Potwierdź numer telefonu',
          text: 'Zweryfikuj się w serwisie Rulla w ciągu kilku minut i dokonaj zakupu. Żadnych długich formularzy.',
        },
        step3: {
          header: ' Zaakceptuj warunki',
          text: 'Natychmiast otrzymasz decyzję o zatwierdzeniu, a Twoje zamówienie zostanie dostarczone jak zwykle.',
        },
      },
      how_works: {
        header: 'Jak działa Rulla?',
        transparent_payment: {
          header: 'Transparentna i elastyczna metoda płatności',
          subheader: 'Żadnych nadpłat. Rulla to świetna alternatywa dla pożyczek, która pomoże Ci obniżyć wydatki i uzyskać wszystko, czego chcesz.',
          item1: 'Wybierz produkt i zapłać za niego później w wybranym dla siebie terminie, ' +
            'w przypadku, gdy nie możesz od razu zapłacić pełnej kwoty, koszt towaru podzielimy na kilka miesięcznych płatności tak, abyś mógł wygodnie i łatwo zwrócić kwotę do nas.',
          item2: 'Od razu znasz dokładną kwotę płatności.',
          item3: 'Brak dodatkowych opłat, jeśli płacisz na czas.'
        },
        modern_payment: {
          header: 'Wygodny i nowoczesny instrument płatniczy ',
          subheader: 'Odroczona płatność umożliwia rozłożenie kosztów ' +
            'w czasie, dzięki czemu można kupić to, co chcesz, ' +
            'bez szkody dla budżetu.',
          item1: 'Zarządzaj zakupami i sprawdzaj zamówienia w przedsprzedaży na koncie osobistym.',
          item2: 'Przed terminem płatności za towar z wyprzedzeniem przypomnimy o kwocie i podamy dane naszego konta do przelania środków.'
        }
      },
      buy_now: {
        header: 'Kupuj teraz – płać później',
        subheader: 'Miliony użytkowników elastycznych rozwiązań płatniczych na całym świecie ' +
          'doceniły już ich przewagę nad tradycyjnymi metodami płatności.',
        interest_free_header: 'Uczciwe raty bezodsetkowe',
        interest_free_text: 'Wybierz swój ulubiony towar, kupuj to za pomocą Rulla,' +
          ' zapłać później - za 35 lub 60 dni,' +
          ' lub podziel płatność na kilka małych miesięcznych rat. Bez nadpłat, ' +
          'bez dodatkowych opłat. Po prostu więcej czasu na zapłacenie za to, co lubisz.',
        legal_information: 'Informacje prawne'
      },
      shops_categories: {
        shops_header: 'Sklepy',
        shops_subheader: 'Najlepsze oferty od partnerów Rulla są zawsze dostępne.',
        shops_button: 'do sklepów',
        categories_header: 'Kategorie',
        categories_subheader: 'Znajdź to, czego potrzebujesz i płać jak wygodnie.',
        categories_button: 'zobaczyć wszystko',
      },
      questions: {
        more_profitable_then_cards: {
          header: 'Dlaczego Rulla jest bardziej wygodna niż karty kredytowe?',
          text: 'Używając kart kredytowych do płacenia za towary, wpadasz w błędne koło.' +
            ' Większość banków pobiera od nich odsetki, a także różnego rodzaju' +
            ' opłaty i prowizje za usługi. W związku z tym im dłużej korzystasz ' +
            'ze środków na karcie kredytowej, tym droższe stają Twoje zakupy. ' +
            'Zamiast tego Rulla oferuje elastyczne rozwiązanie płatnicze z przejrzystymi ' +
            'warunkami. Nie pobieramy prowizji ani odsetek. Za każdym razem, ' +
            'gdy podejmujesz świadomą decyzję o zakupie i bierzesz dokładnie tyle,' +
            ' ile potrzebujesz. Zawsze wiesz dokładnie, ile jesteś winien i kiedy skończysz płacić za zakup.'
        },
        different_from_bank: {
          header: 'Czym Rulla różni się od rat bankowych?',
          text: 'Wszystkie produkty bankowe, w tym raty, są dostępne w ramach różnych ' +
            'prowizji. Mogą to być kary za opóźnienia w płatnościach, opłaty za wcześniejszą ' +
            'spłatę, regularne płatności za otwarcie lub zamknięcie konta, przelew ' +
            'lub wypłatę środków itd. W zależności od kosztu produktu, długości raty ' +
            'i warunków sklepu, w którym robisz zakupy, Twój plan płatności może również zawierać odsetki.' +
            'Z Rulla nigdy nie będziesz winien więcej, niż zgodziłeś się ' +
            'zapłacić na początku. ' +
            ' Odbierz towar już dziś, a zapłać później - w wybranym terminie - ' +
            'za 35 lub 60 dni. Jeśli dokonasz płatności w terminie, Twoja nadpłata wynosi zero.'
        },
        checks_my_solvency: {
          header: 'W jaki sposób Rulla sprawdza moją wypłacalność?',
          text: 'Chcemy mieć pewność, że pomagamy naszym klientom podejmować właściwe decyzje finansowe ' +
            'w oparciu o ich sytuację życiową. Z tego powodu zbieramy i przetwarzamy informacje ' +
            'o użytkownikach usługi Rulla, aby upewnić się, że nie mają oni trudności z  ' +
            'opłaceniem zakupów. ' +
            'Kiedy po raz pierwszy decydujesz się zapłacić za towar z odroczoną płatnością ' +
            'od Rulla w sklepie internetowym, oceniamy wypłacalność za pomocą technologii opartych na Big' +
            ' Data. Weryfikacja w usłudze Rulla nie wpływa na Twoją zdolność kredytową. ' +
            'Kupowanie z Rulla jest łatwe, jeśli masz ukończone 18 lat i masz ' +
            'stałe źródło dochodu.'
        }
      }
    },
    Shops: {
      header: 'Sklepy + Rulla',
      subheader: 'Kupowanie towarów z Rulla to łatwy sposób na zdobycie towarów już dziś i zapłacenie później. ' +
        'Elastyczne rozwiązanie płatnicze dla Twojego budżetu.',
      categories: 'Kategorie',
      favorite_shops: 'Wybrane sklepy',
      sales_text: '* Zniżki obowiązują tylko przez ograniczony czas ' +
        'i nie podlegają kontroli Rulla. Rulla może otrzymać prowizję. ',
      show_more: 'Pokaż więcej',
      filter_items: {
        search: 'Szukaj',
        all: 'Wszystko',
        health: 'Zdrowie',
        cloth_shoes: 'Odzież i obuwie',
        beauty: 'Uroda',
        house: 'Dom',
        hobbies: 'Hobby',
        car: 'Auto'
      },
    },
    SubFooterBusiness: {
      text: 'Masz firmę i chcesz sprzedawać z RULLA?',
      button: 'Rulla dla biznesu',
    },
    InfoTiles: {
      tiles: {
        header: 'Skontaktuj się z nami',
        help_questions: 'Pomożemy Ci w razie jakichkolwiek pytań.',
        made_rulla_purchase: ' Dokonałeś zakupu za pomocą Rulla? Odwiedź naszą stronę ',
        customer_service_page: 'Obsługi klienta.',
        when_will_pay: 'Kiedy otrzymam pieniądze za towary opłacone w Rulla?',
        start_sell_rulla: ' Chcesz sprzedawać więcej z Rulla?',
        start_right_now: 'Zacznij teraz!',
      },
      lower_tiles: {
        header_hours: 'Godziny pracy',
        header_business: 'wsparcia sprzedawców',
        monday_friday: 'Poniedziałek - Piątek',
        phone: 'Telefon kontaktowy:',
        mail: 'E-mail:'
      },
    },
    Blog: {
      header: 'Blog',
      subtitle: 'Z tego bloga dowiesz się o nowych trendach i pomysłach, dzięki którym Twoje zakupy będą idealne.',
      newsCategories: 'Kategorie nowości',
      rullaNews: 'Nowości w Rulla',
      allNews: 'Wszystkie nowości',
      loadMore: 'Załaduj więcej',
      shareArticle: 'Podziel się artykułem z innymi',
      readNext: 'Czytaj następne artykuły',
      all: 'Wszystki',
      another_categories: 'Inne kategorie blogu ',
    },
    Home: {
      favorite_stores_header: 'Wybrane sklepy ',
      popular_stores_header: 'Popularne kategorie',
      fashion_cloth_header: 'Moda i odzież',
      beauty_header: 'Uroda',
      home_header: 'Dom',
      watch_all: 'Zobać wszystko',
      shops_partners: 'Sklepy-partnery',
      shopping_pleasure: {
        header: 'Nowy format zakupów online ',
        subheader: 'Wybierz Rulla, aby kupować łatwo. Płać kiedy jest wygodno.',
        button: 'Więcej szczegółów',
        no_overpayment: 'Kup teraz, zapłać później',
        transparent_process: 'Transparentne warunki ',
        get_now: '100% bez nadpłat',
      },
      beauty: {
        header: 'Twórz urodę w naturalny sposób',
        subheader: 'Spraye i serum do odbudowy i odżywiania włosów. Kosmetyki na naturalnych minerałach',
        button: 'Więcej szczegółów',
      },
    },
    SellWithRulla: {
      popup: {
        header1: 'Zgłoszenie',
        header2: 'do współpracy',
        website_adress: 'Adres sklepu internetowego',
        contact_person: 'Osoba do kontaktu',
        phone: 'Telefon',
        button: 'Wyślij',
      },
      popup_thanks: {
        header: 'Dziękujemy za zgłoszenie',
        text: 'Skontaktujemy się z Tobą w ciągu 24 godzin.',
      },
      header: {
        h1: 'Połącz z Rulla i zwiększ sprzedaż',
        button_start: 'Zacznij teraz',
        button_more: 'Więcej szczegółów',
      },
      benefits: {
        header: 'Bez ryzyka. Tylko korzyści:',
        button_go_over: 'przejść',
        items: {
          more_customers: {
            header: 'Więcej klientów',
            text: 'Umieszczając swój sklep na Rulla.com, ' +
              'przyciągasz do swojego sklepu docelowych klientów za darmo.'
          },
          coverage: {
            header: 'Wsparcie dla sprzedawców',
            text: 'Nasze biuro obsługi klienta jest gotowe odpowiedzieć na każde Twoje pytanie.'
          },
          guard_store: {
            header: 'Bezpieczeństwo sklepu',
            text: 'Zero ryzyka. Rulla zobowiązuje się do sprawdzania wypłacalności klientów.'
          },
          guard_customer: {
            header: 'Bezpieczeństwo klienta',
            text: 'Nasza obsługa klienta szybko rozwiąże problemy Twoich klientów.'
          },
          conditions: {
            header: 'Korzystne warunki płatności',
            text: 'Wygodne rozwiązanie płatnicze bez nadpłat zwiększa wiarygodność Twojej firmy.'
          },
          money: {
            header: 'Najpierw opłata',
            text: 'Sklep natychmiast otrzymuje pełną cenę zakupu od firmy Rulla.'
          }

        }
      },
      integration: {
        header: 'Integracja Rulla w dowolny sklep internetowy',
        text: 'Bezpośrednie połączenie usługi płatności Rulla z  ' +
          'platformami e-commerce za pośrednictwem interfejsu API. Skorzystaj z instrukcji i ' +
          'dokumentacji na stronie internetowej lub skontaktuj się z nami, aby uzyskać pomoc.',
        button_apply: 'złożyć wniosek',
        button_manual: 'instrukcja',
      },
      start_now: {
        header: 'Zacznij teraz',
        application_item: {
          header: 'Skontaktuj się z nami',
          text: 'Połączenie z Rulla zajmuje minimum czasu. Jak najszybciej zacznij sprzedawać więcej.'
        },
        widget_item: {
          header: 'Elastyczna płatność',
          text: 'Zaoferuj swoim klientom transparentny system płatności, ' +
            'dzięki któremu mogą sobie pozwolić na więcej. Żadnych pożyczek i nadpłat.'
        },
        orders_item: {
          header: 'Odbieraj zamówienia',
          text: 'Twoi klienci będą mogli kupić towar z odroczoną płatnością' +
            ' i zapłacić w wybranym terminie i według własnego uznania. Twój sklep natychmiast otrzyma ' +
            'pełną kwotę od firmy Rulla.'
        },
        charts_item: {
          header: 'Optymizuj swój biznes',
          text: 'Wszystkie analizy sprzedaży za pomocą Rulla ' +
            'są dostępne na Twoim koncie osobistym. Zbadaj dane ' +
            'i wykorzystaj je do rozwoju.'
        },
      },
      how_it_works: {
        header: 'JAK TO DZIAŁA',
        text: 'Rulla zostanie dodana do Twojego sklepu internetowego jako metoda płatności.' +
          ' Kiedy klient decyduje się na płatność za pośrednictwem Rulla, ma możliwość ' +
          'zapłacenia za towar później przez okres 35 lub 60 dni. Twój sklep ' +
          'od razu otrzymuje całą kwotę i wysyła towar do klienta. Dla sprzedawcy ' +
          'nie różni się to niczym od zwykłej sprzedaży. '
      },
      partnership: {
        text: 'Współpraca z Rulla to najłatwiejszy sposób, aby zmienić odwiedzających ' +
          'Twój sklep internetowy w klientów. Zdobywasz nowych klientów, którzy kupują ' +
          'u Ciebie coraz częściej. Zawsze otrzymujesz od razu pełną cenę, a' +
          ' Twoi klienci kupują według własnego uznania.',
        volume_increase: 'Wzrost sprzedaży',
        conversion_increase: 'Wzrost konwersji',
        check_increase: 'Wzrost średniego paragonu',
        upto: 'do'
      },
      statistics: {
        header: 'Statystyki światowe pokazują rosnący trend usług buy now pay later:',
        transactions: 'transakcji dziennie',
        users: 'klientów',
        countries: 'krajów',
        mln: 'mln'
      },
      content: {
        header: 'Najważniejsza jest wygoda klienta',
        subheader: 'Zakupy z Rulla wzmacniają zaufanie klientów do Twojej marki. ' +
          'Daj swoim klientom możliwość późniejszego opłacenia zakupów' +
          '  bez odsetek i ukrytych opłat. Jedyna uczciwa rata: ' +
          'natychmiast i bez nadpłat. Wygodne jest kontrolowanie zakupów i płatności ' +
          'na osobistym koncie usługi. Transparentne warunki kupowania towarów od Rulla - najlepsza ' +
          'alternatywa dla kart kredytowych i pożyczek. Zbuduj swój model biznesowy w oparciu o ' +
          'doskonałe wrażenia użytkownika i zdobądź tysiące lojalnych klientów. ',
        text1: 'Usługi płatności online z odroczonym terminem płatności są preferowane przez osoby w wieku 20-35 lat.' +
          ' Dodaj swój sklep do Rulla i nowi klienci znajdą Ci.',
        text2: 'Rejestracja w Rulla po raz pierwszy zajmuje kilka minut. Natychmiastowa decyzja i potwierdzenie  ' +
          ' przez numer telefonu przy dokonywaniu zakupu. Klient płaci później w wybranym terminie. ',
        text3: 'Zarejestrowani użytkownicy Rulla dokonują zakupów od nowych sprzedawców w komforcie' +
          ' stałych klientów. Tacy kupujący nie wątpią. Więcej wydają ' +
          'i częściej wracają.'
      },
      news: {
        header: 'Nowości na blogu Rulla ',
        subheader: 'Kupujący płacą według własnego uznania. Sprzedawcy zyskują więcej zadowolonych klientów. Rulla zadba o wszystko.',
        button_further: 'Czytaj więcej',
        blog: 'Blog Rulla',
      }
    },
    AboutUs: {
      header: 'O nas',
      subheader:
        'Stworzyliśmy usługę  «kup teraz-zapłać później» Rulla\n' +
        'jako wygodną alternatywę dla kart kredytowych i pożyczek,\n' +
        'które zmuszają Cię do przepłacania.\n' +
        'Rulla daje Ci więcej czasu na opłacenie zakupów online,\n' +
        'bez ukrytych opłat.',
      content_center: {
        header: 'Kim jesteśmy?',
        text1: 'Technologiczne rozwiązanie płatnicze firmy Rulla to nowość opracowana przez doświadczonych twórców produktów fintech na rynki europejskie. Celem tego produktu jest zrobienie zakupów online łatwiejszymi i bardziej dostępnymi.',
        text2:
          'Naszą misją jest zapewnienie każdemu możliwości sprzedaży\n' +
          'i kupowania w Internecie tak wygodnie, bezpiecznie i z zyskiem, jak to tylko możliwe. ',
        text3: 'Elastyczna metoda płatności Rulla to nowoczesny instrument finansowy działający w modelu "Kup teraz, zapłać później" (Buy Now Pay Later). Rulla oferuje teraz klientom sklepów internetowych kupowanie towarów z odroczoną płatnością. Użytkownicy serwisu otrzymują możliwość odbioru towaru od razu, a zapłacenia później - w wybranym terminie (od 35 dni od daty zakupu). Jeśli klient nie jest w stanie uiścić pełnej kwoty w wyznaczonym terminie - proponujemy mu dogodny harmonogram miesięcznych spłat.',
        textBank: ''
      },
      contacts: {
        header: 'Kontakt',
        hot_line: '(infolinia)',
        sales_department: '(dział\u00a0sprzedaży)',
        project_manager: '(kierownik\u00a0projektu)',
        address: null,
        email: 'wspolpraca@rulla.com',
        phone: '+48 22 257 2324',
        phoneNo: '+48222572324',
      }
    },
    EmailConfirm: {
      header: 'Potwierdzenie e-mail',
      content: {
        in_process: 'Weryfikujemy e-mail...',
        success: 'E-mail został zweryfikowany',
        failure: 'Chyba coś poszło nie tak'
      }
    },
    TokenRedirect:{
      content:"Wchodzimy na konto osobiste..."
    },
    SupportSeller: {
      header: {
        h1: 'Dzień dobry! Jak możemy ci pomóc?',
        p: 'Tutaj znajdziesz odpowiedzi na pytania dotyczące produktów Rulla, jak zacząć ' +
          'i jak sprzedawać z Rulla. Skontaktuj się z nami, jeśli potrzebujesz pomocy.'
      },
      categories: {
        contact_us: 'Skontaktuj się z nami',
        common: {
          become_partner: {
            header: 'Jak zostać partnerem Rulla?',
            text: 'Aby rozpocząć korzystanie z Rulla, musisz wypełnić prosty formularz zgłoszeniowy. ' +
              'Skontaktujemy się z Tobą w ciągu 24 godzin.'
          },
          term_of_action: {
            header: 'Czy umowa ma datę wygaśnięcia?',
            text: 'Umowa obowiązuje przez 12 miesięcy. ' +
              ' Umowa zostanie automatycznie przedłużona za miesiąc przed końcem bieżącego okresu. ' +
              'Rulla zastrzega sobie prawo do rozwiązania niniejszej umowy w dowolnym momencie.'
          },
          integration: {
            header: ' Jak zintegrować widget Rulla w sklep internetowy?',
            text: 'Po ',
            link: 'zarejestrowaniu konta firmowego',
            text1: ' przejdź do sekcji „Integracja” na swoim koncie osobistym, ' +
              'aby uzyskać dane uwierzytelniające Rulla API. Musisz dodać je do swojej platformy e-commerce, ' +
              'aby zacząć rejestrować zakupy.',
            step1: 'KROK 1.',
            generate_token: 'Wygeneruj unikalny token JWT. Zapisz bezpiecznie swój token.',
            step2: 'KROK 2.',
            send_request: 'Wyślij prośbę o utworzenie sesji widgetu na adres:',
            authorization_header1: 'W nagłówku Authorization należy podać wartość Bearer TOKEN, gdzie zamiast TOKEN ',
            authorization_header2: 'należy wpisać wygenerowany token. ',
            request_body_example: 'Przykładowa treść żądania',
            detailed_information: 'Szczegółowe informacje na temat żądania można znaleźć na stronie API:',
            success_request: 'W przypadku pomyślnego żądania, w odpowiedzi będzie się znajdował identyfikator utworzonej sesji:',
            step3: 'KROK 3.',
            add_widget_on_page: 'Dodaj widget na stronie, podaj src, zawierający otrzymany identyfikator.',
            problems_with_integration: ' Jeśli masz trudności z integracją, skontaktuj się z działem wsparcia Rulla Business.',
            code: `{
  "country": "PL",
  "locale": "pl",
  "client": {
    "phone": "48995550000",
    "email": "customer@rulla.com",
    "surname": "Radziewski",
    "name": "Łukasz",
    "pesel": "52012546124",
    "livingAddress": "Warszawa, ul. Twarda, 1",
    "deliveryAddress": ""
  },
  "order": {
    "id": "2021-06-08-032992",
    "orderLines": [
      {
        "id": "21312312312",
        "name": "NVIDIA GEFORCE RTX 2080 Ti",
        "quantity": 2,
        "unitPrice": 600.00,
        "imageUrl": "https://via.placeholder.com/350x150",
        "productUrl": "https://example.com"
      }
    ],
    "currency": "PLN",
    "total": 1200.00
  }
}`,
          },
          changed_account: {
            header: 'Zmieniłem rachunek bieżący organizacji. Co muszę zrobić?',
            text: 'Aby zmienić szczegóły płatności, skontaktuj się z naszym działem wsparcia Rulla Business.'
          }
        },
        seller_buyer: {
          header: ' Sprzedawca-Nabywca',
          questions: {
            0 : {
              header: ' Kiedy otrzymam pieniądze za towary opłacone w Rulla?',
              text: 'Czas, w którym Rulla przekazuje sprzedawcy pieniądze za' +
                ' towary opłacone za pośrednictwem usługi, jest określony w umowie. ' +
                'Standardowy czas wpływu środków z Rulla ' +
                'na konto sklepu wynosi od 1 do 3 dni. ' +
                'Jeśli masz jakiekolwiek pytania dotyczące płatności,' +
                ' przeczytaj umowę lub skontaktuj się z naszym działem ',
              link: 'Pomocy dla sprzedawców.'
            },
            1 : {
              header: 'Gdy klient otrzyma zwrot pieniędzy po złożeniu wniosku o zwrocie?',
              text: 'Rulla zrealizuje zwrot pieniędzy po zarejestrowaniu zwrotu lub ' +
                'anulowaniu zamówienia. Zwroty pieniędzy kupującemu zwykle zajmują od ' +
                '1 do 3 dni roboczych. Gdy tylko sprzedawca zarejestruje zwrot, wartość towaru zostanie' +
                ' odjęta od całkowitej kwoty zadłużenia klienta. Jeśli całe zamówienie zostanie zwrócone ' +
                'lub w całości anulowane, dług klienta zostanie anulowany.'
            },
            3 : {
              header: 'Kupującego interesuje, o ile więcej trzeba zapłacić. Co odpowiedzieć? ',
              text: 'Poinformuj klienta, że ​​może znaleźć wszystkie informacje dotyczące płatności na ' +
                'swoim koncie osobistym na Rulla.com. Może również skontaktować się z ',
              link: 'Obsługą klienta',
              text1: ' Rulla, aby uzyskać więcej informacji.'
            },
            4 : {
              header: 'A jeśli klient kwestionuje swoje płatności?',
              text: 'Jeśli klient kwestionuje swoje płatności, skieruj ' +
                'go do ',
              link: 'Obsługi klienta',
              text1: ' Rulla, aby nasi specjaliści mogli rozwiązać jego problem.',
            },
            6 : {
              header: 'Gdzie mogę znaleźć moje zamówienia?',
              text: 'Aby wyświetlić wszystkie zamówienia złożone w sklepie,' +
                ' zaloguj się do sekcji  «Zamówienia» na koncie firmowym.'
            },
            7 : {
              header: 'Jak mogę zacząć sprzedawać za pomocą Rulla?',
              text: 'Rozpocznij współpracę z Rulla, wypełniając wniosek na naszej stronie internetowej ',
              link: 'tutaj.'
            },
            8 : {
              header: ' Jaka jest różnica między płatnością ratalną banku a Rulla?',
              text: 'Rulla to rozwiązanie płatnicze dla sklepów internetowych, które umożliwia' +
                ' ich klientom dokonywanie zakupów z płatnością za produkt później - w wybranym przez klienta terminie. ' +
                'Korzystanie z modelu Kup teraz - zapłać później zwiększa konwersje ' +
                'i średni paragon, ponieważ zapewnia klientom bardziej elastyczną metodę płatności.',
              list: {
                0: {
                  header: 'Rulla vs. natychmiastowa rata',
                  text: ' Raty bankowe są pożyczką i wpływają na historię kredytową. ' +
                    'W odróżnieniu od tradycyjnych rat, Rulla nie ma żadnych dodatkowych ' +
                    'prowizji i odsetek, a decyzja o zatwierdzeniu kwoty dla kupowania towaru z odroczoną płatnością' +
                    'zapada w oparciu o technologię machine learning.',
                }
              }
            },
            9 : {
              header: 'Czy istnieje ograniczenie tego, co mogę sprzedawać za pośrednictwem Rulla?',
              text: 'Jeśli sprzedajesz leki, vouchery, usługi ' +
                'lub korzystasz z platformy handlowej, prosimy o kontakt z ',
              link: 'Działem sprzedaży Rulla.'
            },
            10 : {
              header: 'Co to jest płatność ratalna od Rulla?',
              text: 'Rulla — to elastyczne rozwiązanie płatnicze dla e-commerce. To jest nowa metoda płatności: płatność odroczona — kupujący otrzymuje towar natychmiast, a płaci za niego w ciągu 30 dni lub więcej. Rulla zawsze dostaje ulepszenia i nowe funkcje, które zwiększają konwersję i ułatwiają życie zarówno dla Ciebie, jak i Twoim klientom.'
            }
          }
        },
        account_management: {
          header: 'Zarządzanie kontem',
          questions: {
            0: {
              header: 'Jak zaktualizować moje dane kontaktowe?',
              text: 'Możesz zaktualizować swoje dane na osobistym' +
                ' koncie sprzedającego w sekcji «Profil». W przypadku' +
                ' jakichkolwiek trudności prosimy o kontakt z naszym działem ' +
                'Wsparcia sprzedawców, chętnie pomożemy'
            },
            1: {
              header: ' Zmieniłem rachunek bieżący organizacji. Co muszę zrobić?',
              text: 'Aby zmienić szczegóły płatności, skontaktuj się z naszym działem wsparcia Rulla Business.'
            },
            2: {
              header: 'Co to jest login biznesowy?',
              text: 'Login biznesowy to wejście do osobistego konta sprzedawcy (konto firmowe)' +
                '  Dzięki niemu sprzedawcy uzyskują dostęp do systemu realizacji zamówień,' +
                ' opłaconych za pomocą Rulla. Na koncie biznesowym sklepy partnerskie mogą:',
              list: {
                0: {
                  text: 'Przyjmować i realizować zamówienia.'
                },
                1: {
                  text: 'Tworzyć szczegółowe raporty dotyczące sprzedaży za pomocą Rulla.'
                },
                2: {
                  text: 'Patrzyć historię zamówień opłaconych za pośrednictwem Rulla.'
                },
                3: {
                  text: 'Zmieniać ustawienia.'
                }
              }
            },
            3: {
              header: 'Jak wejść na osobiste konto sprzedawcy?',
              text1: 'Aby zalogować się na swoje konto osobiste (konto firmowe),' +
                ' użyj loginu i hasła, które otrzymałeś przy podpisywaniu ' +
                'umowy partnerskiej z Rulla. Kliknij ',
              link1: 'tutaj',
              text2: ' i wprowadź dane.'
            },
            4: {
              header: 'Czy umowa ma datę wygaśnięcia?',
              text: 'Umowa z Rulla obowiązuje przez 12 miesięcy.' +
                ' Umowa zostanie automatycznie przedłużona za' +
                ' miesiąc przed końcem bieżącego okresu. Rulla ' +
                'zastrzega sobie prawo do rozwiązania niniejszej umowy w dowolnym momencie.'
            },
            5: {
              header: 'Jak sporządzić skonsolidowany raport?',
              text: 'Na koncie firmowym zawsze wyświetla' +
                ' podsumowanie z ostatniego miesiąca, ale możesz ustawić ' +
                'żądany okres sprzedaży. Można to zrobić,' +
                ' filtrując dane według daty w sekcji  «Płatności».'
            },
            6: {
              header: 'Co znajduje się w sekcji «Integracja»?',
              text: 'W sekcji «Integracja» możesz wygenerować dane ' +
                'API, które są używane do połączenia  ' +
                'Twojego sklepu z Rulla.'
            },
            7: {
              header: 'Co znajduje się w sekcji «Płatności»?',
              text: 'W sekcji «Płatności» znajdziesz dane sprzedażowe' +
                ' z Rulla. Tutaj możesz ustawić własne zakresy ' +
                'i filtrować informacje według parametrów, aby przeanalizować ' +
                'potrzebne metryki.'
            },
            8: {
              header: 'Co znajduje się w sekcji «Zamówienia»?',
              text: 'Sekcja «Zamówienia» umożliwia przeglądanie wszystkich zamówień ' +
                'i ich historii, a także rejestrowanie zwrotów.'
            },
            9: {
              header: ' Co znajduje się w sekcji «Wiadomości»',
              text: 'W sekcji «Wiadomości» możesz zarządzać  ' +
                'wiadomościami, które Rulla wysyła do Ciebie pocztą.' +
                ' Ustaw wymaganą częstotliwość i zdarzenia, ' +
                'których chcesz otrzymywać wiadomości.'
            },
            10: {
              header: 'Jak mogę uzyskać dane logowania, aby zalogować się na konto firmowe?',
              text: 'Kiedy podpisujesz umowę z Rulla, tworzymy ' +
                ' konto w systemie (konto firmowe). ' +
                'Adres e-mail podany w umowie będzie ' +
                'używany jako nazwa użytkownika. Na  ' +
                'ten adres otrzymasz list powitalny ze swoją nazwą' +
                ' użytkownika i jednorazowym hasłem, którego ' +
                'używasz przy pierwszym logowaniu.'
            },
            11: {
              header: 'Jak zmienić język na koncie firmowym?',
              text: 'Przejdź do strony ustawień na swoim profilu, kliknij przycisk „Profil” w prawym górnym rogu. W ustawieniach wybierz język, który potrzebujesz.'
            },
            14: {
              header: 'Czy mam osobę kontaktową?',
              text: 'Na początkowym etapie współpracy z Rulla będziesz mieć konkretną ' +
                'osobę kontaktową, która może pomóc w razie komplikacji.' +
                ' Następnie możesz skontaktować się z naszym ',
              link: 'Działem Wsparcia Sprzedawcy',
              text1: ' telefonicznie lub e-mailem w celu uzyskania pomocy.'
            },
          }
        },
        integration: {
          header: 'Integracja techniczna',
          questions: {
            0: {
              header: 'Gdzie mogę znaleźć dane Rulla API?',
              text: 'Możesz wygenerować nowe dane uwierzytelniające Rulla API' +
                ' w sekcji « Integracja » na koncie osobistym ' +
                'sprzedawcy (konto firmowe). Pamiętaj, aby od razu' +
                ' zapisać nowe dane logowania Rulla API, ponieważ pojawiają się one tylko raz. '
            },
            1: {
              header: 'Gdzie mogę dostać logo Rulla?',
              text: 'Logo Rulla do umieszczenia w witrynie można pobrać ze strony' +
                ' «Prasa» lub zapytać u osoby kontaktowej firmy Rulla”.'
            },
            2: {
              header: 'Jak działa integracja?',
              text: 'Pierwszym krokiem jest uzyskanie danych logowania Rulla API,' +
                ' które następnie dodajesz do swojej platformy, aby rozpocząć ' +
                'rejestrowanie zakupów. Aby uzyskać dane logowania ' +
                'Rulla API, musisz zalogować się na swoje konto firmowe' +
                ' w sekcji  «Integracja». Tam będziesz mógł wygenerować swoje ' +
                'dane uwierzytelniające Rulla API.'
            },
            3: {
              header: 'Co trzeba zrobić, aby integracja Rulli z moim sklepem została zatwierdzona?',
              text: 'Aby rozpocząć sprzedaż z Rulla, Twoja strona internetowa musi być ' +
                'aktywna i zawierać następujące informacje:',
              list: {
                0: {
                  text: 'Informacje o firmie: zarejestrowana nazwa firmy, ' +
                    'zarejestrowany adres firmy, numer rejestracyjny organizacji.'
                },
                1: {
                  text: 'Regulamin zawierający informacje o dostawie ' +
                    'i zwrocie towaru. Jeśli dostawa jest odpłatna, ' +
                    'należy to wyraźnie zaznaczyć.'
                },
                2: {
                  text: 'Twoje dane kontaktowe działu obsługi klienta, ' +
                    'numer telefonu i adres e-mail.'
                },
                3: {
                  text: 'Ceny towarów i koszty dostawy odpowiadające rzeczywistości.'
                },
              }
            },
            4: {
              header: 'Co muszę zrobić, żeby mógł rozpocząć integrację z Rulla?',
              text: 'Aby rozpocząć integrację z Rulla, musisz mieć' +
                ' działający sklep internetowy. Oznacza to, że ' +
                'strona internetowa powinna zawierać informacje o Twoich produktach / usługach,' +
                ' a także możliwość dodania ich do koszyka.'
            },
            5: {
              header: 'Czy Rulla ma przewodnik po integracji?',
              text: 'Tak, w sekcji «Integracja» na koncie firmowym znajdziesz' +
                ' informacje o integracji Rulla. Jeśli potrzebujesz ' +
                ' pomocy, skontaktuj się ze swoim menadżerem Rulla lub' +
                ' działem wsparciem sprzedawcy.'
            }
          }
        },
      },
      back_button: 'Wróć do strony głównej pomocy technicznej',
      questions_header: 'Często Zadawane Pytania',
    },
    TermsAndConditions: {
      header: 'Правила та умови',
      payments: {
        header: 'Варіанти оплати',
        content: 'Оплата платіжними картками Visa та MasterCard'
      },
      delivery: {
        header: 'Доставка по Україні',
        time: 'Зазвичай доставка триває від 1 до 4 днів у межах усієї України.',
        cost: 'Вартість доставки залежить від умов окремого продавця. '
      },
      refund: {
        header: 'Повернення та обмін',
        text1: 'Згідно із Законом України «Про захист прав споживачів» покупець має право обміняти товар на аналогічний протягом 14 днів, якщо куплений товар не підійшов за формою, габаритами, фасоном, кольором, розміром або з інших причин, і якщо збережено товарний вигляд, ярлики і розрахунковий документ, виданий продавцем разом із товаром. Виняток становлять товари, які не підлягають поверненню за Законом, їх перелік ви можете переглянути за ',
        text2: 'посиланням'
      },
      contacts: {
        header: 'Контактна інформація',
        companyName: 'ТОВ «РУЛЛА УКРАЇНА»',
        companyNumber: 'ЄДРПОУ 43213857',
        address: 'Україна, 69035, Запорізька обл., м. Запоріжжя,\nвул. Феросплавна, будинок 38, кв. 31',
        emailLabel: 'E-mail:',
        email: 'cooperation@rulla.com',
        phoneLabel: 'Tel:',
        phoneNumber: '+48 79 939 9959'
      }
    },
    ThreePayments: {
      header_block: {
        header: 'Kup teraz - zapłać później',
        subheader: 'Nowy wygodny sposób płatności online',
        button: 'Skontaktuj się z nami'
      },
      business: {
        header: 'Rulla rozwija Twój biznes',
        subheader: 'Rozwiązanie płatnicze, które zwiększa sprzedaż, to idealna opcja dla Twojego sklepu internetowego.',
        items: {
          conversion: {
            header: 'Konwersje',
            text: 'Wygodny sposób na szybki zakup towaru z odroczoną płatnością bez prowizji zwiększa prawdopodobieństwo zakupu.'
          },
          average_check: {
            header: 'Średnie zamówienie',
            text: 'Rulla daje Twoim klientom więcej czasu na zapłatę, dla tego kupują więcej.'
          },
          regular_customers: {
            header: 'Stałe klienty',
            text: 'Pozytywne doświadczenie z zakupów zapewnia większą powtarzalność sprzedaży.'
          },
          attendance: {
            header: 'Traffic',
            text: 'Użytkownicy Rulla przechodzą do Twojej strony internetowej z serwisu płatniczego.'
          }
        },
        implemented_top_brands: 'Metoda płatności - kup teraz, zapłać później, została wprowadzona przez najlepsze marki na całym świecie.'
      },
      purchases_control: {
        header: 'Zakupy pod kontrolą',
        subheader: 'Wszystkie zakupy w jednym miejscu na koncie osobistym Rulla.',
        item1: 'Twoi klienci mogą przeglądać historię zamówień, zarządzać płatnościami i planować budżet.',
        item2: 'Możliwość natychmiastowego uzyskania pożądanego produktu i opłacenia go później w dogodnym terminie daje finansową swobodę i pewność.',
        item3: 'Oferując opłacalną alternatywę dla kredytów i rat bankowych, ' +
          'rozwiązanie płatnicze Rulla zwiększa sprzedaż i lojalność klientów. ',
        button_start_now: 'ZACZNIJ TERAZ'
      },
      guarantee: {
        header: 'Gwarancje dla stron umowy',
        text: 'Korzystanie z Rulla do zakupów online jest tak proste, jak ' +
          'płacenie kartą. W takim przypadku obie strony otrzymują' +
          ' świadczenia. Klienci mogą bezpiecznie zwiększyć swoją wypłacalność.' +
          ' Algorytm oceny nie pozwoli wydać więcej, niż są w stanie zapłacić. ' +
          'Sprzedawcy pracują normalnie: po złożeniu zamówienia otrzymują ' +
          'całą kwotę i dostarczają towar. ' +
          'Coś poszło nie tak?' ,
        link: 'Rozwiążemy Twój problem'
      },
      statistics: {
        header: 'Rulla daje Twoim klientom możliwość dokonywania większej liczby zakupów.',
        subheader: 'Kupować czy nie kupować? Kupowanie towaru z odroczoną płatnością rozwiązuje problem braku środków ' +
          'i zapewni komfort zakupów. Więcej zakupów i mniej porzuconych ' +
          'koszyków dzięki elastycznej usłudze płatności od Rulla. ',
        info_pleased: 'ci, którzy skorzystali z funkcji kupowania towaru z odroczonym terminem płatności, zadowoleni z tego doświadczenia',
        info_think: 'użytkownicy usług kup teraz, płacą później, uważają tę metodę za najlepszą',
        info_refused: 'klienci sklepów internetowych odmówiliby zakupu bez możliwości późniejszej zapłaty za towar *',
        info_refused1: '* Według usług płatniczych'
      },
      how_arranged: {
        header: 'Jak jest zorganizowana płatność w Rulla?',
        subheader: 'Kup teraz - zapłać później. Aby zakupy były łatwiejsze, ' +
          'Rulla oferuje transparentny system - kup już dziś, zapłać za 35 dni lub więcej. Żadnych nadpłat.',
        order_registration: {
          header: 'Składanie zamówienia',
          text: 'W sklepie internetowym klient płaci za towar za pośrednictwem firmy Rulla i wyraża zgodę na warunki,' +
            ' potwierdzając zapłatę za towar w terminie 30 dni. Widżet wyświetla kwotę i datę pełnej płatności za przedmiot.'
        },
        delivery: {
          header: 'Dostawa',
          text: 'Po zrealizowaniu zamówienia Rulla płaci sprzedawcy (sklepowi) pełną cenę zakupu. ' +
            'Zadbamy o otrzymanie płatności ' +
            'od klienta.'
        },
        after_purchase: {
          header: 'Po dokonaniu zakupu',
          text: 'Twój klient ma jasny obraz ' +
            'swoich zakupów i może łatwo zarządzać swoimi zamówieniami ' +
            'na koncie osobistym Rulla.'
        }
      },
      connecting: {
        header: 'Połączenie Rulla',
        subheader: 'Instalacja widgetu płatności za pośrednictwem naszego serwisu na stronie sklepu internetowego trwa ' +
          'od kilku godzin. Aby się połączyć, skontaktuj się z nami za pośrednictwem formularza na stronie internetowej.',
        details_table: {
          header: 'Szczegóły',
          market_availability: {
            header: 'Доступність на ринку',
            text: 'Україна'
          },
          payment_options: {
            header: 'Варіанти оплати',
            text: 'Дебетова або кредитна картка'
          },
          payment_period_starts: {
            header: 'Період оплати починається',
            text: 'При обробці замовлення'
          },
          number_of_payments: {
            header: 'Кількість платежів',
            text: '3'
          },
          time_between_payments: {
            header: 'Час між платежами',
            text: '30 днів (стандартно)'
          },
          annual_interest_rate: {
            header: 'Річна процентна ставка',
            text: '0,00001%'
          },
          additional_expenses: {
            header: 'Додаткові витрати',
            text: 'Немає комісій. Жодних витрат при оплаті в строк.'
          },
          payment_method: {
            header: 'Спосіб внесення платежів',
            text: 'Автоматичне списання з зареєстрованої картки'
          },
          delay_in_payment: {
            header: 'Затримка платежу (прострочення)',
            text: 'Може стягуватися невелика плата'
          },
          view_pay_purchases: {
            header: 'Перегляд та оплата покупок',
            text: 'Необхідна авторизація в особистому кабінеті на',
          },
          seller_protection: {
            header: 'Захист продавця',
            text: 'Так'
          },
          buyer_protection: {
            header: 'Захист покупця',
            text: 'Так'
          }

        },
        integration_table: {
          header: 'Інтеграція',
          direct_integration: {
            header: 'Пряма інтеграція',
            text: 'API'
          },
          automatic_updates: {
            header: 'Автоматичні оновлення',
            text: 'Включені'
          },
          developer_resources: {
            header: 'Ресурси для розробників',
            text: 'Інструкція по інтеграції'
          }
        },
        trade_agreement_table: {
          header: 'Торговельна угода',
          standard_terms: {
            header: 'Стандартні умови угоди',
            text: '12 місяців (продовжується автоматично)'
          },
          model_pricing: {
            header: 'Вартість / модель ціноутворення',
            text: 'Зв\'яжіться з нами для отримання додаткової інформації'
          }
        }
      }
    },
    Legal: {
      h1: 'ЮРИДИЧНА ІНФОРМАЦІЯ',
      h2: 'ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ «РУЛЛА УКРАЇНА»',
      text1: 'Код ЄДРПОУ 43213857',
      text2: 'Код КВЕД 64.92 Інші види кредитування (основний).',
      text3: 'Вид фінансової послуги: надання позик, у тому числі на умовах фінансового кредиту.',
      text4: 'Свідоцтво про реєстрацію фінансової установи ФК №1420 від 26.05.2020 р.',
      text5: 'Відомості про ліцензії та дозволи, видані фінансовій установі:',
      text6: 'Вид господарської діяльності, на провадження якого видана ліцензія —' +
        '  НАДАННЯ КОШТІВ У ПОЗИКУ, В ТОМУ ЧИСЛІ І НА УМОВАХ ФІНАНСОВОГО КРЕДИТУ.' +
        '  Ліцензія на здійснення діяльності з надання коштів у позику, в тому' +
        '  числі і на умовах фінансового кредиту від 11.06.2020 р.' +
        '  (Розпорядження НКФУ № 1323 від 11.06.2020 р.)',
      text7: 'Статус ліцензії – чинна.',
      text8: 'Строк дії – безстрокова.',
      text9: 'Дані про порушення провадження по справі про банкрутство, санації:',
      text10: 'Не знаходиться в процесі ліквідації.',
      text11: 'Засновники (учасники) Товариства:',
      text12: 'ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ «ІНСТИТУТ ДОСЛІДЖЕНЬ СТАНДАРТІВ ЯКОСТІ»',
      text13: 'Код ЄДРПОУ засновника: 36977252',
      text14: '69002, Запорізька обл., місто Запоріжжя, Олександрівський район, вулиця Запорізька, будинок 6-А, квартира 47',
      text15: 'ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ «ЦЕНТР ПРАВОВИХ РІШЕНЬ»',
      text16: 'Код ЄДРПОУ засновника: 36977247',
      text17: '69002, Запорізька обл., місто Запоріжжя, Олександрівський район, вулиця Запорізька, будинок 6-А, квартира 47',
      text18: 'Кінцевий бенефіціарний власник (контролер):',
      text19: 'ТИХОНОВА ОЛЕНА ОЛЕКСІЇВНА',
      text20: 'Україна, 69002, місто Запоріжжя, вулиця Запорізька, будинок 6-А, квартира 47',
      text21: 'Відомості про органи управління юридичної особи:',
      text22: 'Загальні збори учасників Товариства.',
      text23: 'Виконавчий орган Товариства — Директор Лєман Наталя Сергіївна.',
      text24: 'Наглядова рада не створювалась.',
      text25: 'Відокремлені підрозділи відсутні.',
      text26: 'Графік роботи',
      text27: 'Пн-пт 9.00-18.00',
      text28: 'Без перерви та вихідних.',
      text29: 'Контактна інформація',
      text30: 'Адреса:',
      text31: 'Україна, 69035, Запорізька обл., місто Запоріжжя, вул. Феросплавна, будинок 38, квартира 31',
      text32: 'Код території за КОАТУУ 2310136900',
      text33: 'Електронна пошта:',
      text34: 'Телефон:',
      text35: 'Механізми захисту прав споживачів фінансових послуг',
      text36: 'Скарги з приводу захисту прав споживачів приймаються:',
      text37: '1) За юридичною адресою Товариства:',
      text38: 'Україна, 69035, Запорізька обл., місто Запоріжжя, вул.Феросплавна, будинок 38, квартира 31',
      text39: '2) Національним банком України:',
      text39_1: 'Адреса: 01601, м. Київ, вул. Інститутська, 9',
      text39_2: 'Телефон відділу звернення громадян: 0 800 505 240',
      text39_3: 'Електронна пошта: nbu@bank.gov.ua',
      text39_4: 'https://bank.gov.ua/ua/consumer-protection',
      text41: '3) Головним управлінням Держпродспоживслужби в Запорізькій області:',
      text41_1: 'Адреса: 69002 м. Запоріжжя, вул. Гоголя, 105 а, кабінет 203',
      text41_2: 'Телефон: (061) 228-04-64 (приймальня)',
      text41_3: 'Електронна пошта: office@gudpss-zp.gov.ua',
      text43: ' Орган, який здійснює державне регулювання щодо діяльності особи, яка надає фінансові послуги:',
      text44: 'Національний банк України',
      text44_1: 'Адреса: 01601, м. Київ, вул. Інститутська, 9',
      text44_2: 'Телефон відділу звернення громадян: 0 800 505 240',
      text44_3: 'Електронна пошта: nbu@bank.gov.ua',
      text45: 'Вартість фінансової послуги визначається в залежності від суми кредиту' +
        ' та зазначається у Договорі, що укладається між Товариством та Клієнтом.' +
        ' Розмір відсотків річних може складати від 0,00001% до 0,1%.',
      text46: 'Товариство не залучає Кредитних посередників.',
      text47: 'Не передбачена наявність гарантійних фондів чи компенсаційних схем' +
        ' при наданні фінансової послуги з надання коштів у позику, в тому числі і на' +
        ' умовах фінансового кредиту.',
      text48: 'Наявні та можливі схеми кредитування: ануїтетна.',
      text49: 'В результаті отримання фінансової послуги не передбачена сплата податків або зборів за рахунок фізичної особи.',
      text50: 'Документи:',
      text51: 'ПРАВИЛА НАДАННЯ ГРОШОВИХ КОШТІВ У ПОЗИКУ, У ТОМУ ЧИСЛІ НА УМОВАХ ФІНАНСОВОГО КРЕДИТУ',
      text52: 'ДОГОВІР  ПРО НАДАННЯ ФІНАНСОВИХ ПОСЛУГ',
      text53: 'СВІДОЦТВО ПРО РЕЄСТРАЦІЮ ФІНАНСОВОЇ УСТАНОВИ',
      text54: 'РОЗПОРЯДЖЕННЯ ПРО ВНЕСЕННЯ ДО ДЕРЖАВНОГО РЕЄСТРУ ФІНАНСОВИХ УСТАНОВ',
      text55: 'ВИПИСКА З ЄДИНОГО ДЕРЖАВНОГО РЕЄСТРУ ЮРИДИЧНИХ ОСІБ',
      text56: 'РОЗПОРЯДЖЕННЯ ПРО ВИДАЧУ ЛІЦЕНЗІЇ НА ПРОВАДЖЕННЯ ГОСПОДАРСЬКОЇ ДІЯЛЬНОСТІ З НАДАННЯ ФІНАНСОВИХ ПОСЛУГ',
    },
    PersonalCabinet: {
      Auth: {
        back: 'Powrót',
        business_login: 'Wejście dla biznesu',
        business: 'Sklep',
        change: 'Zmienić',
        confirm_password: 'Potwierdź hasło',
        customer: 'Kupujący',
        empty_password: 'Błąd: wprowadź hasło',
        enter_new_password: 'Wprowadź nowe hasło, aby zalogować się na swoje konto osobiste',
        enter_number_to_login: 'Wprowadź swój numer, aby zalogować się do swojego konta osobistego',
        start_with_rulla: 'Honoruj ​​swoje zakupy dzięki Rulla',
        enter_number_to_recover: 'Wpisz swój numer, a wyślemy Ci link do strony, na której możesz ustawić nowe hasło',
        enter_sms_code: 'Wprowadź kod z SMS',
        forgot_password: 'Zapomniałeś hasło?',
        invalid_email: 'Błąd: wpisz adres e-mail w formacie a@a.com',
        invalid_password: 'Błąd: nieprawidłowe hasło',
        invalid_phone_number: 'Błąd: format numeru +48 XX XXX XXXX',
        login: 'Wejść',
        new_password: 'Nowe hasło',
        password_saved: 'Twoje hasło zostało zmienione. Możesz kliknąć poniższy link, aby zalogować się na swoje konto osobiste',
        password_sent: 'Link został wysłany na podany numer',
        password: 'Hasło',
        phone: 'Telefon',
        profile_login: 'Wejście dla klientów indywidualnych',
        new_login: 'Dołącz do Rulla',
        return_to_login: 'Wróć do wejścia',
        seconds: 'sekund',
        send_code: 'Wysłać kod',
        send_on: 'Wysłane na',
        send_one_more_time: 'Wysłać ponownie',
        send_password: 'Wysłać',
        sending_speeds: 'Mogą stosować się prędkości przesyłania wiadomości i danych',
        user_not_found: 'Błąd: nie znaleziono użytkownika',
        wait: 'Czekaj',
        your_phone_number: 'Twój numer telefonu',
      },
      Layouts: {
        Sidebar: {
          main: 'Główna',
          orders: 'Zamówienia',
          settings: 'Powiadomienia',
          settlements: 'Płatności',
          integration: 'Integracja',
          hotline: 'Obsługa Klienta',
          favorites: 'Ulubione',
          purchases: 'Zakupy',
          payments: 'Opłaty',
          bank_accounts: 'Konta bankowe',
          offline: 'QR / SMS',
          profile_settings: 'Ustawienia',
          personal: 'Moje dane'
        },
        Footer: {
          main: 'Główna',
          orders: 'Zamówienia',
          settings: 'Powiadomienia',
          settlements: 'Płatności',

          favorites: 'Ulubione',
          purchases: 'Zakupy',
          payments: 'Opłaty',
        },
        MobileMenu: {
          integration: 'Integracja',
          bank_accounts: 'Konta bankowe',
          offline: 'QR / SMS',
          hotline: 'Pomóc',
          profile_settings: 'Ustawienia'
        }
      },
      Business: {
        profile_button: 'Profil',
        Integration: {
          header: 'Ustawienia integracji',
          controls: {
            withdraw: 'Wycofaj wydane tokeny',
            generate: 'Wygenerować'
          },
          popup: {
            your_token: 'Twój token: ',
            save_token: 'Zapisz go, aby dalej skonfigurować integrację z Rulla API',
            copy: 'Kopiuj'
          },
          text: {
            how_integrate: 'Jak zintegrować widget',
            step_1: 'Krok 1.',
            send_request: 'Wyślij prośbę o utworzenie sesji widżetu pod adresem:',
            heading_set_token: 'W nagłówku Authorization należy podać wartość Bearer TOKEN, gdzie zamiast TOKEN należy użyć wygenerowanego tokena.',
            request_body_example: 'Przykład treści żądania',
            detailed_information: 'Szczegółowe informacje o żądaniu można znaleźć na stronie API:',
            legal: '',
            success_request: 'Jeśli żądanie się powiedzie, odpowiedź będzie zawierała identyfikator utworzonej sesji:',
            step_2: 'Krok 2.',
            add_widget_on_page: 'Dodaj widżet do strony, określ src, który zawiera otrzymany identyfikator.',
            example: 'Przykład:',
            example_name: 'Łukasz',
            example_surname: 'Radziewski',
            example_patronymic: 'Іванович',
            example_address: 'Warszawa, ul. Twarda,1',
          },
          api: {
            header: 'Integracja przez API',
            alert: {
              text: 'Dla integracji z Rulla, prosze ustawicz konto bankowe jako domyślnie:',
              link: 'Konta bankowe'
            },
            p1: 'Poniżej możesz wygenerować token dla autentykacji przez API, tutaj możesz zobaczyć kluczek API Key dla sprawdzenia swojego tokena.',
            p2: 'Jeśli twój token jest skompromitowany — możesz jego unieważnić. W takim razie — API Key będzie zmieniony.',
            p3: {
              part1: 'Jeśli nie masz możliwości wykonać integracje przez API — możesz skorzystac ',
              part2: 'generatorem',
              part3: 'kodu spłaty dla konkretnych towarow'
            },
            steps: {
              header: 'Jak integrować widget',
              step1: {
                header: 'Stworzyć zamówienie',
                p1: 'Po utworzeniu zamowienia w twoim systemie potrzebnie wysłać wniosek na stworzenie zamówienia do Rulla pod adresem:',
                p2: 'W nagłówku Authorization potrzebujesz podać Bearer TOKEN, gdzie zamiast TOKEN podać wygenerowany token.',
                p3: {
                  part1: 'Wszystkie szczegóły o wniosku na stworzenie zamówienia znajdziesz na stronie API:'
                },
                p4: 'Przykład ciała wniosku',
                p5: 'Znaczenie <var&gt;legalEntityIdvar&gt; i <var&gt;bankAccountIdvar&gt; znajdziesz w sekcji "Konta bankowe"',
                p6: 'Jeśli wniosek jest akceptowany w odpowiedzi będzie zawarty identyfikator stworzonej sesji:'
              },
              step2: {
                header: 'Podłączyć skrypt',
                p1: 'Dalej potrzebujesz Podłączyć skrypt Rulla na stronie gdzie będzie możliwość opłaty zamowienia',
                p2: 'Teg script najlepiej umieścić w środku тегу head - w ten sposób zapewnisz sobie możliwość dokonywania płatności szybciej i nie czekając na pełne załadowanie się innych skryptów'
              },
              step3: {
                header: 'Przycisk płatności',
                p1: 'Kod przycisku może być umieszczony w dowolnym miejscu, a po kliknięciu wyświetli się okno modalne z widżetem Rulla',
                p2: 'Parametr metody <var>pay</var> jest odebrany identyfikator sesji',
                p3: 'Możesz zmienić styl przycisku i tekst w dowolny sposób.'
              },
              step4: {
                header: 'Frame',
                p1: 'Alternatywnie, możesz pokazać widget Rulla jako część swojej strony internetowej',
                p2: 'Parametrem metody<var>init</var> jest odebrany identyfikator sesji, а <var>element</var> musi zawierać selektor elementu, w którym zostanie umieszczono Frame',
                p3: 'Skrypt inicjalizacyjny nie może być wcześniej wykonany, niż <var>element</var> zostanie wyrenderowany na stronie.'
              }
            }
          },
          product: {
            header: 'Integracja bez backendu',
            alert: {
              text: 'Przed przystąpieniem do integracji należy skonfigurować konto bankowe jako domyślnie:',
              link: 'Konta bankowe'
            },
            p1:
              'Masz możliwość dokonania płatności przez Rulla bez zmiany własnego backendu, ' +
              'Jednak w tym przypadku nie będziesz mógł otrzymywać statusów zamówień w inny sposób niż poprzez powiadomienia.',
            p2: {
              part1: 'Również podczas ',
              part2: 'integracji przez API',
              part3:
                ' masz możliwość ustawienia sprzedaży kilku produktów w koszyku ' +
                'i automatycznego potwierdzania dostępności z własnego systemu.'
            },
            form: {
              product_id: 'Unikalny identyfikator na stronie internetowej',
              product_name: 'Nazwa produktu',
              product_price: 'Cena produktu',
              product_image: 'Link do zdjęcia',
              product_url: 'Link do strony produktu',
            },
            step1: {
              header: 'Przycisk płatności',
              p1: 'Тег script najlepiej umieścić wewnątrz tegu head - dzięki temu użytkownik może zapłacić po kliknięciu na przycisk wcześniej, bez konieczności czekania na pełne załadowanie innych skryptów',
              p2: 'Kod przycisku można umieścić w dowolnym miejscu, kliknięcie na niego spowoduje wyświetlenie okna modalnego z widżetem Rulla',
              p3: 'Możesz zmienić styl i tekst przycisku w dowolny sposób'
            },
            step2: {
              header: 'Frame',
              p1: 'Frame - alternatywny sposób wyświetlania widgetu, gdzie widget jest widoczny od razu jako część Twojej strony'
            },
          }
        },
        Settings: {
          header: 'Powiadomienia',
          hints: [
            'Powiadomienia możesz ustawić dowolnym dogodnym dla Ciebie kanałem: e-mailem, SMS-em lub komunikatorem.',
            'Możesz także dostosować różne kanały, włączyć lub wyłączyć powiadomienia dla różnych podmiotów prawnych w swoim sklepie.',
            'Domyślnie kanały dla osób prawnych będą odpowiadać ustawieniom sklepu. Możesz dodawać i weryfikować nowe kontakty w sekcji "Ustawienia"'
          ],
          legal_entities: 'Podmiot prawny',
          store_defaults: '-- Ustawienia sklepu --',
          phone_number: 'Numer telefonu',
          email: 'E-mail',
          what_notify_about: 'O czym mamy cię informować?',
          new_orders: 'Nowe zamówienia',
          order_confirmation: 'Potwierdzenie zamówienia',
          expiring_orders: 'Zamówienia, które wkrótce wygasną',
          paid_orders: 'Opłacone zamówienia, dla których musisz wysłać towar ',
          unknown_orders: 'Nieznane zamówienia',
          platform_not_notified: 'Zamówienia, o których nie powiadomiono platformy e-commerce.' +
            ' Mogą oznaczać błąd integracji między Rulla API a twoim systemem',
          when_notify: 'Kiedy informować?',
          everyday: 'Codziennie',
          everyday_morning: 'Otrzymuj codzienne podsumowanie każdego ranka',
          turn_off_blank: 'Wyłącz puste e-maile',
          dont_send_blank: 'Nie będziemy wysyłać ci e-maili, w których nie ma żadnych informacji',
          every_week: 'Co tydzień',
          every_week_monday: 'Otrzymuj cotygodniowe podsumowanie w każdy poniedziałek',
          dropdownLabel: 'Język'
        },
        Settlements: {
          header: 'Raport o płatnościach',
          download: 'Ściągnij CSV',
          period: 'Okres sprzedaży',
          empty_table: 'Tutaj będą wyświetlać się płatności',
          table: {
            date: 'Data',
            number: 'Numer',
            customer_name: 'Imię',
            customer_phone: 'Telefon',
            sum: 'Suma, zł'
          }
        },
        Orders: {
          total: 'Razem',
          header: 'Wszystkie zamówienia',
          download_button: 'Ściągnij XLS',
          feedback_button: 'Informacje zwrotne',
          search_field: 'Nazwa klienta, poczta, numer zamówienia',
          period: 'Okres sprzedaży',
          shop: 'Sklep',
          status: 'Status',
          all_statuses: 'Wszystkie statusy',
          statuses: {
            new: 'Nowy',
            waiting_for_payment: 'Oczekujemy płatność',
            paid: 'Opłacono',
            refunding: 'Zwrot kosztów',
            refunded: 'Zwrócono',
            repaid: 'Zapłacono',
            shipped: 'Dostarczono',
            canceled: 'Anulowany',
          },
          apply_button: 'Zastosuj',
          QrModal: {
            header: 'Opłać zamówienie',
            order_sum: 'Zamówienie na kwotę',
            send_button: 'Wysłać',
          },
          NewOrderModal: {
            trigger_button: 'Utwórz zamówienie',
            header: 'Nowe zamówienie',
            phone_label: 'Telefon',
            name_label: 'Nazwa produktu',
            price_label: 'Cena',
            delete_line: 'Usuń produkt',
            add_line: 'Dodaj kolejny produkt',
            skip_confirmation: 'Nie wymaga potwierdzenia',
            modal_text: 'Aby utworzyć zamówienie, wprowadź numer telefonu klienta, nazwę i cenę towaru.',
            create_order: 'Utwórz zamówienie',
            all: 'Łącznie',
            create_error: 'Wystąpił błąd'
          },
          DetailsModal: {
            order_details: 'Szczegóły zamówienia',
            rulla_order_number: 'Numer zamówienia Rulla',
            store_order_number: 'Numer zamówienia w\u00a0sklepie:',
            store_subdivision: 'Sklep:',
            order_date: 'Data i czas zakupu:',
            order_total: 'Kwota całkowita:',
            customer_contacts: 'Dane kontaktowe sprzedawcy',
            surname: 'Nazwisko:',
            name: 'Imię:',
            patronymic: 'Imię ojca:',
            phone: 'Telefon:',
            email: 'E-mail:'
          }
        },
        Offline: {
          Header: 'Złóż zamówienie',
          PhoneNumber: 'Numer telefonu',
          BankAccount: 'Rachunek bieżący',
          NoAccount: '- Nie zaznaczone -',
          Send: 'Wysłać',
          Sent: 'Wysłane',
        },
        BankAccounts: {
          Header: 'Rachunki płatnicze',
          AddLegalEntity: 'Nowa osoba prawna',
          AddBankAccount: 'Dodaj konto',
          SortCode: 'SWIFT',
          LegalEntityModal: {
            Header: 'Nowa osoba prawna',
            Name: 'Nazwa',
            Code: 'KRS',
            Close: 'Zamknij',
            Save: 'Dodaj osobę prawną'
          },
          BankAccountModal: {
            Header: 'Nowe konto',
            AccountNumber: 'Numer konta',
            BankName: 'Bank',
            BankNamePlaceholder: 'PKO Bank Polski',
            Currency: 'Waluta',
            SortCode: 'SWIFT',
            Close: 'Zamknij',
            Save: 'Dodaj konto'
          }
        },
        Main: {
          header: 'Główna',
          roll_up: 'Zwiń',
          watch_all: 'Zobacz wszystko',
          orders: 'Zamówienia',
          sales_volume: ' Wielkość sprzedaży,',
          average_check: ' Średnia kwota paragonu,',
          statistic: 'Statystyki',
          dropdown: {
            1: 'Dzisiaj',
            2: "Bieżący tydzień",
            3: "Bieżący miesiąc",
            4: "Bieżący kwartał"
          },
          sorters: {
            1: 'Według daty',
            2: 'Według kwoty',
            3: 'Według sklepu'
          }
        },
        Profile: {
          heading: 'Profil',
          contact_data: 'Dane kontaktowe',
          logout: 'Wyjście',
          phone: 'Telefon',
          email: 'Email',
          add_phone: 'Dodaj nowy numer',
          add_email: 'Dodaj nowy adres mailow',
          confirm: 'Zweryfikować',
          not_confirmed: 'Nie zweryfikowany',
          change: 'Zmienić',
          verify_phone: {
            enter_code: 'Wprowadź kod z SMS',
            send_on: 'Wysłano na',
            send_one_more: 'Wyślij kod ponownie',
            wait: 'Poczekaj',
            seconds: 'sekund',
            send_code: 'Wysłać kod',
            back: 'Powrót',
            next: 'Następny',
            your_number: 'Twój numer telefonu',
            data_speed: 'Mogą stosować się prędkości przesyłania wiadomości i danych',
            on_what_number: 'Musimy się upewnić, że to ty. Daj nam znać, na jaki numer wysłać kod.',
            phone: 'Telefon'
          },
          verify_email: {
            check_email: 'Sprawdź skrzynkę e-mail',
            on_your_mail1: 'Na twój adres',
            on_your_mail2: 'został wysłany link aktywacyjny. Jeżeli nie widzisz wiadomości, sprawdź zakładkę "Spam"',
            send_one_more: 'Wyślij ponownie',
            personal_cabinet: 'Twoje konto',
            your_email: 'Twoja poczta',
            send_link: 'Wyślij link',
            back: 'Powrót',
            email: 'Poczta',
            what_you_can: 'Możesz tutaj otrzymywać powiadomienia oraz informacje o nowościach i promocjach od naszych partnerów.',
          },
        },
      },
      Profile: {
        Main: {
          heading: 'Główna',
          pay: 'Opłacić',
          delivery: 'Dostawa',
          purchases_history: 'Historia zakupów',
          no_purchases: 'Jeszcze nie masz żadnych zakupów',
          goods: 'Towary',
          total_amount: 'Całkowita kwota',
          order_number: 'Numer zamówienia'
        },
        Favorites: {
          heading: 'Wybrane',
          search: 'Produkt lub sklep',
          stores: 'Sklepy',
          deliveries: 'Dostawy',
          empty_stores: 'Nie znaleźliśmy żadnego sklepu',
          start_shopping: 'Rozpocznij zakupy i dodaj ulubione sklepy do listy',
          empty_deliveries: 'Brak aktualnych dostaw',
          check_delivery_here: 'Jeżeli twój zakup jest w trakcie dostawy, możesz go śledzić tutaj.',
        },
        Orders: {
          all: 'Razem: ',
          paid: 'Opłacono: ',
          nextPayment: 'Następna opłata: ',
          status: 'Status',
          statuses: {
            new: 'Nowy',
            waiting_for_payment: 'Oczekujemy płatność',
            paid: 'Opłacono',
            refunding: 'Zwrot kosztów',
            refunded: 'Zwrócono',
            repaid: 'Zapłacono',
            shipped: 'Dostarczono',
            canceled: 'Anulowany',
          },
          description: 'Opis',
          order_number: 'Zamówienie',
          date: 'Data',
          cost: 'Data',
          payments: 'Płatności',
          store: 'Sklep',
          payments_heading: 'Opłaty',
          products_heading: 'Towary'
        },
        Purchases: {
          heading: 'Zakupy',
          overview: 'Przegląd',
          all_purchases: 'Wszystkie zakupy',
          balance: 'Saldo',
          expenses_by_month: 'Wydatki miesięcznie:',
          charts_statistics: 'Tutaj będą wykresy oraz statystyki wydatków',
          spent: 'Wydano',
          dont_have_purchases: 'Nie masz żadnych zakupów.',
          here_expenses: 'Kiedy coś kupisz, tutaj pojawią się wydatki wg kategorii towarów',
          spent_by_period: 'Wydano za ',
          expenses_by_period: 'Zakupów za ',
          expenses_by_store: 'Wydano wg sklepów:',
          all_empty: {
            header: 'Obecnie nie masz żadnych nieopłaconych zamówień',
            text1: 'Sprawdź partnerskie sklepy Rulla i kup wszystko, co potrzebujesz z wpłatą do 60 dni bez prowizji. ',
            text2: 'Nie widzisz swoich zakupów? Zadzwoń w Obsługa Klienta Rulla',
          },
          balance_empty: {
            header: 'Nieopłacone zakupy',
            text: 'Tutaj możesz zobaczyć zakupy, jaki czeka na opłaty.'
          }
        },
        Payments: {
          header: 'Opłaty',
          relax: 'Usiądź wygodnie i odpręż się',
          find_it_here: 'Jeżeli masz coś do zrobienia, znajdziesz to tutaj.',
          all: 'Razem:',
          pay: 'Opłacić',
          close: 'Zamknij',
          paid: 'Opłacono',
          not_paid: 'Nie opłacono',
          payment_schedule: 'Harmonogram opłat',
          payment_history: 'Historia spłat',
          number: 'Numer:',
          sum: 'Kwota',
          indebtedness: 'Zadłużenie:',
          total_paid: 'Spłacono:',
          total_to_pay: 'Całkowita wpłata:',
          pay_header: 'Opłaty',
          next_payment: 'Kolejna wpłata:',
          since: 'od',
        },
        Personal: {
          contact_data: 'Dane kontaktowe',
          personal_data: 'Dane osobowe',
          passport_data: 'Dane paszportowe',
          address_data: 'Adres',
          surname: 'Nazwisko',
          patronymic: 'Drugie imię',
          name: 'Imię',
          birth: 'Data urodzenia',
          taxNumber: 'Numer identyfikacji podatkowej',
          passport: 'Paszport',
          id_card: 'Dowód osobisty',
          series: 'Seria',
          number: 'Pokój',
          passport_date: 'Data wydania',
          passport_issued_by: 'Wydane przez',
          biometric_passport_issued_by: 'Organ, który wydał',
          registration_address: 'Adres rejestracji',
          addresses_same: 'Rejestracja i adres zamieszkania są takie same',
          living_adress: 'Adres zamieszkania',
          postal_code: 'Kod pocztowy',
          postal_code_placeholder: '00000',
          city: 'Miasto',
          city_placeholder: '',
          street: 'Ulica',
          street_placeholder: '',
          house_number: 'Numer domu',
          house_number_placeholder: '',
          flat_number: 'Numer mieszkania',
          flat_number_placeholder: '',
          save: 'Zapisz',
          saved: 'Dane zapisane',
          edit_contacts: 'Zmień'
        },
        Settings: {
          heading: 'Ustawienia',
          main: 'Główne',
          contact_data: 'Dane kontaktowe',
          subscribe_on_news: 'Subskrybuj wiadomości',
          payment: 'Opłata',
          payment_methods: 'Metody płatności',
          upload_photo: 'Wgraj zdjęcie',
          logout: 'Wyjście',
          phone: 'Telefon',
          email: 'Poczta',
          add_phone: 'Dodaj nowy numer',
          main_phone: 'Główny',
          select_as_main: 'Wybrać głównym',
          legal_entities: 'Dane kontaktowe podmiotów prawnych',
          delete: 'Usunąć',
          confirm: 'Zweryfikować',
          not_confirmed: 'Nie zweryfikowany',
          change: 'Zmienić',
          enter_sms_code: 'Wprowadź kod z SMS',
          send_on: 'Wysłano na',
          send_one_more: 'Wyślij kod ponownie',
          wait: 'Poczekaj',
          seconds: 'sekund',
          send_code: 'Wyślij kod',
          back: 'Powrót',
          next: 'Następny',
          your_number: 'Twój numer telefonu',
          make_sure_thats_you: '',
          speeds_on_sms: 'Musimy się upewnić, że to ty. Daj nam znać, na jaki numer wysłać kod.',
          your_email: 'Twoja poczta',
          on_it_notifications: 'Możesz tutaj otrzymywać powiadomienia oraz informacje o nowościach i promocjach od naszych partnerów',
          send_link: 'Wyślij link',

          personal_cabinet: 'Twoje konto',
          send_one_more_time: 'wyślij ponownie',
          just_sent: 'E-mail właśnie został wysłany',
          check_email: 'Sprawdź pocztę',
          on_your_mail1: 'Do Twojej skrzynki',
          on_your_mail2: 'został wysłany link potwierdzający. Jeśli nie możesz znaleźć wiadomości e-mail, sprawdź folder ze spamem.',
        },
        Modals: {
          refund: {
            name: 'Zwrot',
            header: 'Zwrot towarów',
            text: 'Będziesz musiał zwrócić pieniądze, które zapłaciłeś za dany zakup',
            button: 'Zwrot'
          },
          cancel: {
            name: 'Anulowanie',
            header: 'Anulowanie zamówienia',
            text: 'Aby anulować zamówienie, będziesz musiał zwrócić pieniądze zapłacone za ten zakup.',
            button: 'Potwierdź',
          },
          confirm: {
            name: 'Potwierdzenie',
            link: 'Potwierdzić',
            header: 'Potwierdzenie zamówienia',
            text: 'Potwierdzasz dostępność w zamówieniu i klient może kontynuować przed zapłaceniem za zamówienie',
            button: 'Potwierdź',
          },
          status: {
            name: 'Status',
            header: 'Status dostawy',
            text: 'Wybierz status dostawy:',
            button: 'Potwierdź'
          },
          pay: {
            name: 'Zapłać'
          },
          cancel_button: 'Zamknij'
        }
      }
    },
    Press: {
      header: 'Rulla dla prasy',
      subheader: 'Tutaj możesz zobaczyć komunikaty prasowe Rulla i powiązane materiały, ' +
        ' takie jak informacje o firmie i biblioteka obrazów.',
      publications: {
        header: 'Rulla w publikacjach',
        ain_ua: {
          header: 'В Україні запускається Rulla - платіжний сервіс для вигідного онлайн-шопінгу - ain.ua',
          link: 'https://ain.ua/2021/03/04/v-ukraine-zapuskaetsya-rulla-platezhnyj-servis-dlya-vygodnogo-onlajn-shopinga/'
        },
        rau_ua: {
          header: 'Сервіс для вигідного онлайн-шопінгу Rulla - тепер в Україні',
          link: 'https://rau.ua/novyni/servis-onlajn-shopingu-rulla/'
        },
      },
      press_releases: {
        header: 'Останні прес-релізи',
        first: {
          header: 'Сервіс для вигідного онлайн-шопінгу Rulla - тепер в Україні',
          link: 'https://rulla.com/ua/blog/servis-dlia-vygidnogo-onlajn-shopingu-rulla-teper-v-ukrajini'
        }
      },
      show_more: 'Pobierz więcej',
      image_library: 'Biblioteka obrazów',
      download: 'Pobierz',
      company_info: 'Informacje o firmie',
      download_presentation: 'Pobierz prezentację',
      text1: 'Technologiczne rozwiązanie płatnicze firmy Rulla to nowość opracowana przez doświadczonych twórców ' +
        'produktów fintech na rynki europejskie. Celem tego produktu jest zrobienie ' +
        'zakupów online łatwiejszymi i bardziej dostępnymi. ',
      text2: 'Naszą misją jest zapewnienie każdemu możliwości sprzedaży i ' +
        'kupowania w Internecie tak wygodnie, bezpiecznie i z zyskiem, jak to tylko możliwe. ',
      text3: 'Elastyczna metoda płatności Rulla to nowoczesny instrument finansowy, ' +
        ' działający w modelu "Kup teraz, zapłać później" (Buy Now Pay Later). ' +
        'Rulla oferuje teraz klientom sklepów internetowych kupowanie towarów z odroczoną płatnością. Użytkownicy serwisu ' +
        'mają możliwość zapłaty za zakup później - 35 lub 60 dni po zakupie ' +
        'bez dodatkowych odsetek i opłat.',
      media_requests: 'Zapytania od mediów',
      request_here: 'Wyślij zapytania na e-mail:',
      address_only_media: 'Należy pamiętać, że ten adres kontaktowy służy wyłącznie do kierowania zapytań mediów dotyczących publikacji.',
      have_questions: 'W przypadku pytań związanych z zakupami prosimy o kontakt z',
      support: 'Działem Obsługi Klienta.'
    }
  }
};
