<div class="cookie-consent" *ngIf="!isConsented">
  <p class="text">{{'Cookies.consent_text' | translate}}</p>
  <div class="buttons">
    <app-button-common (click)="agreeWithCookiePolicy()">
      <p>{{'Cookies.agree' | translate}}</p>
    </app-button-common>
    <app-button-common [whiteButton]="true" [routerLink]="'/terms-and-conditions' | localize">
      <p>{{'Cookies.more_details' | translate}}</p>
    </app-button-common>
  </div>
</div>
