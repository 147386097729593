import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { CustomerTokenService } from '../auth/customer-token.service';
import { BusinessTokenService } from '../auth/business-token.service';

@Injectable()
export class AuthErrorInterceptor implements HttpInterceptor {

  constructor(
    private customer: CustomerTokenService,
    private business: BusinessTokenService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        
        if (err.status === 401 && request.url.includes('/api/v1/customer/')) {
          this.customer.removeToken();
          return throwError(err);
        }

        if (err.status === 401 && request.url.includes('/api/v1/store/')) {
          this.business.removeToken();
          return throwError(err);
        }
        
        return throwError(err);
      })
    );
  }
}
