import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { LocalizedRoutingModule } from './routing/localized-routing.module';

import { ErrorService } from './services/error.service';
import { SearchService } from './services/search.service';
import { MyTranslationLoader } from './services/translation-loader.service';
import { MyMissingTranslationHandler } from './services/translation-handler.service';
import { FileDownloaderService } from './services/file-downloader.service';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    LocalizedRoutingModule,
    MatSnackBarModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    LocalizedRoutingModule
  ],
  providers: [
    ErrorService,
    SearchService,
    MyTranslationLoader,
    MyMissingTranslationHandler,
    FileDownloaderService
  ],
})
export class CoreModule { }
