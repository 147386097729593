<div class="field" [class.error]="invalid && touched">
  <label>{{label}}</label>
  <input type="text"
    placeholder="+380 63 111 22 33" 
    mask=" 00 000 00 00"
    minlength="9"
    autocomplete="tel"
    [prefix]="prefix"
    [(ngModel)]="text"
    [ngModelOptions]="{standalone: true}"
    (ngModelChange)="onChange($event)"
    (keyup)="onKeyUp($event)"
    (paste)="onPaste($event)"
    (blur)="onTouched()" />
  
  <div class="error-message" *ngIf="invalid && touched">
    <ng-container *ngIf="errors?.incorrect">
      {{errors.incorrect}}
    </ng-container>

    <ng-container *ngIf="errors?.message">
      {{errors.message}}
    </ng-container>

    <ng-container *ngIf="!errors?.incorrect && !errors?.message">
      {{'PersonalCabinet.Auth.invalid_phone_number' | translate}}
    </ng-container>
  </div>
</div>
