import { RouterModule, Routes } from '@angular/router';

import { AuthorizedStoreGuard } from './core/guards/authorized-store.guard';
import { AuthorizedCustomerGuard } from './core/guards/authorized-customer.guard';
import { UrlResolutionGuard } from './core/guards/url-resolution.guard';

import { WrapperComponent } from './pages/layouts/wrapper/wrapper.component';
import { LanguageSelectionGuard } from './core/guards/language-selection.guard';

const routes: Routes = [
  {
    path: 'u/:code',
    canActivate: [UrlResolutionGuard],
    component: UrlResolutionGuard
  },
  {
    path: 'business',
    canActivate: [AuthorizedStoreGuard],
    loadChildren: () => import('./profile/store/store.module').then(m => m.StoreModule)
  },
  {
    path: 'customer',
    canActivate: [AuthorizedCustomerGuard],
    loadChildren: () => import('./profile/customer/customer.module').then(m => m.CustomerModule)
  },
  {
    path: 'token',
    loadChildren: () => import('./profile/token/token.module').then(m => m.TokenModule)
  },
  {
    path: 'email',
    loadChildren: () => import('./email/email.module').then(m => m.EmailModule)
  },
  {
    path: 'bankid',
    loadChildren: () => import('./bank-id/bank-id.module').then(m => m.BankIdModule)
  },
  {
    path: 'diia',
    loadChildren: () => import('./diia/diia.module').then(m => m.DiiaModule)
  },
  {
    path: '',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: '**',
    data: { hideLanguageSelector: true },
    component: WrapperComponent,
    canActivate: [LanguageSelectionGuard],
    loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule),
  },
];

// must use {initialNavigation: 'enabled'}) - for one load page, without reload
export const AppRoutes = RouterModule.forRoot(routes, { initialNavigation: 'enabled' });
