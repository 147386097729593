import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const request = !req.headers.get('skip')
      ? req.clone({ withCredentials: true, url: environment.apiUrl + req.url })
      : req.clone({ withCredentials: true });

    return next.handle(request);
  }
}
