import { FormControl, FormGroup, ValidatorFn } from "@angular/forms";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

export interface ServerError {
  title: string;
  status: number;
  errors?: { [field: string]: string[] };
}

export class Extensions {
  static toPhoneNumber(countryCode: string, text: string) {
    return countryCode + text.replace(/[^\d]+/g, '');
  };

  static handleServerErrors(error: ServerError, map: (string) => FormControl) {
    for (let field in error.errors) {
      const control = map(field);
      if (control) {
        control.setErrors({ incorrect: error.errors[field][0] });
        control.markAsTouched();
      }
    }
  }
}

export function choose<T>(expr: any, left: Observable<T>, right: Observable<T>) {
  return expr ? left : right
}

// preferred below:

export class FieldControl extends FormControl {
  constructor(public field: string, state: any, validators: ValidatorFn[]) {
    super(state, validators);
  }
}

export type FieldMap = { [field: string]: FieldControl; }

export class FieldGroup extends FormGroup {

  private fields: FieldMap;

  constructor(controls: { [key: string]: FieldControl }) {
    super(controls);

    this.fields = Object
      .values(controls)
      .reduce((dic, control) => { dic[control.field] = control; return dic; }, <FieldMap>{})
  }

  resolve(field: string) {
    return this.fields[field];
  }

  handle(error: ServerError) {
    for (let field in error.errors) {
      const control = this.resolve(field);
      control?.setErrors({ incorrect: error.errors[field][0] });
      control?.markAsTouched();
    }
  }
}
