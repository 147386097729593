<div class="choose_menu">
  <div class="choose_menu__lang">
    <app-header-lang></app-header-lang>
  </div>
  <div class="choose_menu__items_container">
    <div class="container" style="height: 100%">
      <div class="choose_menu__items">
        <button class="choose_menu__items__item" (click)="showMenu('customer')">
          <p>{{'Header.rulla_for_clients' | translate}}</p>
          <img src="assets/icons/chevron-right.svg">
        </button>
        <button class="choose_menu__items__item" (click)="showMenu('business')">
          <p>{{'Header.rulla_for_business' | translate}}</p>
          <img src="assets/icons/chevron-right.svg">
        </button>
      </div>
    </div>
  </div>
</div>
