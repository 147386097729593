<header [ngClass]="{ 'auth_form' : isAuth}" (mouseleave)="onHeaderLeave()">
  <div class="container" style="position: relative">
    <div class="header">
      <div class="header__logo" [routerLink]="'/' | localize">
        <img src="assets/images/header/rulla_logo.png">
      </div>
      <div class="header__nav">
        <div class="header__nav__item"
             (mouseenter)="showMenu('customer')"
             [ngClass]="{'opened_menu' : activeMenu === 'customer'}">
          <p>{{'Header.rulla_for_clients' | translate}}</p>
          <img src="assets/images/header/chevron.svg">
        </div>
        <div class="header__nav__item"
             (mouseenter)="showMenu('business')"
             [ngClass]="{'opened_menu' : activeMenu === 'business'}">
          <p>{{'Header.rulla_for_business' | translate}}</p>
          <img src="assets/images/header/chevron.svg">
        </div>
      </div>
      <div class="d-f a-i-c">
        <div *ngIf="!['pl', 'es'].includes(language)" class="header__lang_desktop">
          <app-header-lang></app-header-lang>
        </div>
        <div class="header__auth">
          <app-header-auth-button (click)="onLoginClick()"></app-header-auth-button>
        </div>
      </div>
      <div class="header__mobile_items">
        <div class="header__mobile_items__lang">
          <app-header-lang></app-header-lang>
        </div>
        <div class="header__mobile_items__menu" *ngIf="activeMenu !== 'choose'" (click)="showMenu('choose')">
          <img src="assets/images/header/mobile_menu.svg">
        </div>
        <div class="header__mobile_items__menu" *ngIf="activeMenu === 'choose'" (click)="hideMenu()">
          <img class="close" src="assets/images/header/close_purple.svg">
        </div>
      </div>
    </div>
    <app-menu-desktop 
      [activeMenu]="activeMenu"
      (cancel)="hideMenu()"
      (navigation)="hideMenu()">
    </app-menu-desktop>
  </div>
</header>
<app-menu-mobile *ngIf="activeMenu"
  [activeMenu]="activeMenu"
  (cancel)="hideMenu()"
  (navigation)="hideMenu()">
</app-menu-mobile>
