<form [formGroup]="form">
  <div class="d-f j-c-s-b">
    <div class="input-container">
      <input 
        #input1
        formControlName="digit1"
        class="input"
        [class.error]="error"
        type="text"
        inputmode="numeric"
        maxlength="1"
        pattern="[0-9]*"
        autofocus
        (focus)="onFocus()"
        (focusout)="onFocusOut()"
        (paste)="onPaste($event, 1)"
        (keydown.backspace)="onBackspace(1)">
    </div>
    <div class="input-container">
      <input
        #input2
        formControlName="digit2"
        class="input"
        [class.error]="error"
        type="text"
        inputmode="numeric"
        maxlength="1"
        pattern="[0-9]*"
        (focus)="onFocus()"
        (focusout)="onFocusOut()"
        (paste)="onPaste($event, 2)"
        (keydown.backspace)="onBackspace(2)">
    </div>
    <div class="input-container">
      <input
        #input3
        formControlName="digit3"
        class="input"
        [class.error]="error"
        type="text"
        inputmode="numeric"
        maxlength="1"
        pattern="[0-9]*"
        (focus)="onFocus()"
        (focusout)="onFocusOut()"
        (paste)="onPaste($event, 3)"
        (keydown.backspace)="onBackspace(3)">
    </div>
    <div class="input-container">
      <input
        #input4
        formControlName="digit4"
        class="input"
        [class.error]="error"
        type="text"
        inputmode="numeric"
        maxlength="1"
        pattern="[0-9]*"
        (focus)="onFocus()"
        (focusout)="onFocusOut()"
        (paste)="onPaste($event, 4)"
        (keydown.backspace)="onBackspace(4)">
    </div>
  </div>
  <div *ngIf="error"
       class="alert alert-danger">
    <div *ngIf="!invalidCode; else invalid">
      {{ 'PIN.error.incomplete' | translate }}
    </div>
    <ng-template #invalid>
      {{ 'PIN.error.invalid' | translate }}
    </ng-template>
  </div>
</form>
