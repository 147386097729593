<div class="content">

  <h2 class="title">{{'PersonalCabinet.Auth.new_login' | translate}}</h2>
  <p class="subtitle">{{'PersonalCabinet.Auth.start_with_rulla' | translate}}</p>

  <form [formGroup]="form" (submit)="signUp()">
    <div class="phone">
      <app-phone-field
        [label]="'PersonalCabinet.Auth.phone' | translate"
        [countryCode]="countryCode"
        [formControl]="ctrl.phoneNumber">
      </app-phone-field>
    </div>
    
    <div class="action">
      <app-button-common type="submit" [disabled]="loading">
        <p>{{'PersonalCabinet.Auth.login' | translate}}</p>
      </app-button-common>
    </div>
      
    <div class="link">
      <a (click)="switchToLogin()">{{'PersonalCabinet.Auth.return_to_login' | translate}}</a>
    </div>
  </form>

</div>
