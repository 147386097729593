<div class="business_menu">
  <div class="business_menu__arrow__container">
    <app-button-arrow class="business_menu__arrow"
      arrowType="arrow"
      [leftArrow]="true"
      (click)="onBackClicked()">
    </app-button-arrow>
  </div>
  <div class="business_menu__items">
    <a class="business_menu__items__item" (click)="onLinkClicked('sell-with-rulla')" [routerLink]="'/sell-with-rulla' | localize">
      <app-button-text>{{'HeaderBusinessMenu.sell_with_rulla' | translate}}</app-button-text>
    </a>
    <a class="business_menu__items__item" (click)="onLinkClicked('seller-support')" [routerLink]="'/seller-support' | localize">
      <app-button-text>{{'HeaderBusinessMenu.support_seller' | translate}}</app-button-text>
    </a>
    <a class="business_menu__items__item" (click)="onLinkClicked('three-payments')" [routerLink]="'/three-payments' | localize">
      <app-button-text>{{'HeaderBusinessMenu.three_payments' | translate}}</app-button-text>
    </a>
  </div>
</div>
