import { Injectable } from '@angular/core';
import { TenantId } from '../models/tenant.model';

@Injectable({ providedIn: 'root' })
export class CustomerTenantService {

  get tenant(): TenantId {
    const value = localStorage.getItem('customer.tenant');
    if (value) {
      return parseInt(value);
    }

    return null;
  }

  set tenant(value: TenantId) {
    localStorage.setItem('customer.tenant', value.toString());
  }
}
