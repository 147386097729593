import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BaseFieldComponent } from '../fields.abstract';

@Component({
  selector: 'app-password-field',
  templateUrl: './password-field.component.html',
  styleUrls: ['../fields.scss', './password-field.component.scss']
})
export class PasswordFieldComponent extends BaseFieldComponent {
  @Input() label: string = "";
  @Input() placeholder: string = "";

  showPassword = false;
}
