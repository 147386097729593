export const locale = {
  lang: 'ru',
  data: {
    PIN: {
      error: {
        incomplete: 'Ошибка ввода: введите код полностью',
        invalid: 'Ошибка ввода: введите код еще раз'
      }
    },
    Common: {
      title: 'Rulla – Онлайн-рассрочка для всех',
      countryCode: '380',
      uah: 'грн',
      pln: 'zł',
      eur: 'евро',
      items: 'шт.'
    },
    Errors: {
      server: 'Произошла ошибка при обработке запроса, пожалуйста, обратитесь в техподдержку, или попробуйте повторить запрос позже'
    },
    Validation:{
      required: 'Это поле обязательное',
      format_error: 'Неверный формат',
      server_busy: 'Сервер загружен, попробуйте позже',
      iban_invalid: 'Правильный формат IBAN: "UA" и 27 цифр',
    },
    Cookies:{
      consent_text: 'Мы используем файлы cookie для совершенствования сайта, измерения нашей аудитории, повышения качества предоставления рекламы на основе вашей активности просмотров и интересов на нашем и сторонних сайтах. Продолжая работу с сайтом, вы соглашаетесь на использование файлов cookie.',
      agree: 'Принять',
      more_details: 'Подробнее'
    },
    Statuses: {
      1: 'Новый',
      3: 'Ожидает подтверждения',
      4: 'Ожидается платёж',
      5: 'Ждет отправки',
      6: 'Возврат средств',
      7: 'Средства возвращены',
      8: 'Выплачено',
      9: 'Отправлено',
      10: 'Доставлено',
      11: 'Отменён'
    },
    SnackBar: {
      copied: 'Скопировано',
    },
    Pending: {
      data_processing: 'Обработка данных',
      loading: 'Идет загрузка. Ожидайте...'
    },
    Header: {
      rulla_for_clients: 'Rulla для клиентов',
      rulla_for_business: 'Rulla для бизнеса',
      registration: 'Регистрация',
      log_in: 'Войти',
    },
    HeaderLoginMenu: {
      personal_cabinet_business: 'Для магазинов',
      personal_cabinet_business_description: 'Войдите в систему, чтобы управлять ' +
        'своими заказами, отчетами о выплатах, статистикой магазина и общими' +
        ' настройками.',
      personal_cabinet_customer: 'Для покупателей',
      personal_cabinet_customer_description: 'Войдите в систему, ' +
        'чтобы получать информацию о своих покупках и предстоящих платежах и легко ' +
        'рассчитываться по ним одним щелчком мыши.'
    },
    HeaderBusinessMenu: {
      sell_with_rulla: 'Продавать с Rulla',
      support_seller: 'Поддержка продавца',
      three_payments: 'Оплата в 3 платежа'
    },
    HeaderClientMenu: {
      pay_later: 'Плати позже с Rulla',
      about_company: 'О компании',
      customer_service: 'Обслуживание клиентов',
      blog: 'Блог',
      rulla_shops: 'Rulla магазины',
      categories: 'Категории',
      watch_all: 'Смотреть все'
    },
    Footer: {
      legal: 'Юридическая информация',
      all_ukraine: 'Работаем по всей Украине',
      contact_us: 'Свяжитесь с нами ',
      about_us: 'О нас',
      about_company: 'О компании',
      shops: 'Магазины',
      pay_later: 'Плати позже с Rulla',
      blog: 'Блог',
      for_business: 'Для бизнеса',
      sell_with_rulla: 'Продавать с Rulla',
      for_clients: 'Для клиентов',
      seller_support: 'Поддержка продавца',
      customer_service: 'Обслуживание клиентов',
      terms_and_conditions: 'Правила и условия',
      press: 'Для прессы',
      financial_services: 'Информация о договоре о Предоставлении финансовых услуг'
    },
    NotFound: {
      heading: 'Кажется, что-то пошло не так!',
      text1: 'Страница, которую вы запрашиваете, не существует. ' +
        'Возможно, она была удалена, или вы набрали неверный адрес. ' +
        'Перейдите на нашу ',
      text2: ' и попробуйте найти необходимую вам информацию там.',
      main_page: 'главную страницу',
    },
    OrderConfirmed: {
      heading: 'Заказ подтверджен',
      confirmed: 'Тепер покупатель может перейти к оплате заказа',
      thanks: 'Спасибо, что воспользовались сервисом Rulla.',
    },
    BankID: {
      heading: 'Bank ID',
      success: 'Проверка BankID прошла успешно',
      failure: 'Что-то пошло не так...'
    },
    Diia: {
      successHeading: 'Успех!',
      success: 'Проверка данных через систему электронной идентификации Дія выполнена успешно.',
      action: 'Вы можете вернуться к\u00a0странице с\u00a0заказом, чтобы продолжить оплату',
      failureHeading: 'Что-то пошло не так...',
      failure: 'Во время проверки данных через систему электронной идентификации Дія произошла ошибка'
    },
    CustomerService: {
      header: {
        support: 'Поддержка',
        text: 'Мы дарим удовольствие от покупок, которого вы заслуживаете'
      },
      categories: {
        heading: 'Категории',
        first: {
          title: 'Доставка и Возврат',
          subtitle: 'Возврат | Доставка | Аннулирование',
          text: 'Срок доставки почтовыми операторами, как правило, составляет от 1 до 3-х дней. ' +
            'Вы можете отслеживать ваши заказы в личном кабинете в разделе «История заказов». ' +
            'В течение 14 дней с момента покупки клиент имеет право на возврат товара без объяснения причин, при условии его надлежащего состояния. ' +
            'Возврат товара регулируется между магазином и клиентом. ' +
            'Если магазин подтверждает возврат товара — рассрочка аннулируется автоматически, клиенту ' +
            'возвращается оплата первого платежа.'
        },
        second: {
          title: 'Платежи',
          subtitle: 'Совершать платежи и управлять ими | Отсрочка платежа',
          text: 'Первый платеж оплачивается клиентом при оформлении покупки, последующие оплаты ' +
            'списываются с карты автоматически. Клиент имеет право изменить процесс оплаты и совершать ' +
            'платежи самостоятельно. Для изменения необходимо перейти в раздел «Профиль» личного ' +
            'кабинета. В личном кабинете вы также найдете историю всех ваших покупок и платежей, сроки ' +
            'погашения и затраты на покупки, сделанные через Rulla.'
        },
        third: {
          title: 'Продукты и Услуги',
          subtitle: 'Как использовать Rulla | Варианты оплаты',
          text: 'Rulla — это платежный инструмент, который позволяет оплачивать товар несколькими частями,\n' +
            'чтобы избавить клиентов от нагрузки на бюджет. Rulla предлагает оплату в 3 платежа, из которых\n' +
            'первый списывается при оформлении, следующие — через каждые 30 дней. Клиент может\n' +
            'оформить неограниченное количество оплат с Rulla, чтоб распределить свои расходы в течение\n' +
            'месяца.'
        },
        forth: {
          title: 'Учетная запись и личные данные',
          subtitle: 'Управление платежными реквизитами | Управление контактными данными',
          text: 'При первой оплате через Rulla клиенту формируется личный кабинет пользователя, в котором ' +
            'находится информация о всех оформленных рассрочках, информация о магазинах и сроках ' +
            'платежей. Также в кабинете можно изменить свои личные данные: контактный телефон, почту ' +
            'или номер платежной карты.'
        }
      }
    },
    PayLater: {
      header: {
        h1: 'Покупай сейчас — плати позже',
        subheader: 'Выбирайте оплату Rulla в любимых магазинах',
        start_button: 'Начать',
        more_button: 'Подробнее'
      },
      subheader: 'Rulla — это самый безопасный способ получить то, что вам нужно, ' +
        'сейчас и заплатить позже. Разделите оплату на ' +
        'ежемесячные платежи. Позвольте себе больше.',
      more: {
        heading: 'Хотите больше Rulla в своей жизни?',
        item1: {
          p: 'Каталог магазинов',
          h2: 'Найдите тысячи украинских магазинов, где можно #rulla.',
          button: 'Найти розничных продавцов'
        },
        item2: {
          p: 'Присоединяйтесь к  сообществу',
          h2: 'Следите за нами в инстаграм; @rullacom',
          button: 'Присоединяйтесь к сообществу'
        }
      },
      widget: {
        header: 'Начать легко. Попробуйте!',
        subheader: 'Использовать Rulla так же просто, как ' +
          'оплачивать покупки картой. ' +
          'Зарегистрируйтесь и получите мгновенную рассрочку.',
        step1: {
          header: 'Выберите оплату через Rulla',
          text: 'Выберите товары в любимом магазине. ' +
            'При оформлении заказа выберите Rulla в качестве способа оплаты.',
        },
        step2: {
          header: 'Подтвердите  номер телефона',
          text: 'Пройдите верификацию в сервисе Rulla за пару' +
            ' минут и совершите покупку. Никаких длинных форм.',
        },
        step3: {
          header: 'Согласитесь с условиями',
          text: 'Вы моментально получите решение об одобрении, и ваш заказ будет доставлен в обычном режиме.',
        },
      },


      how_works: {
        header: 'Как работает Rulla?',
        transparent_payment: {
          header: 'Прозрачный и гибкий способ оплаты',
          subheader: 'Никаких переплат. Rulla — это отличная альтернатива ' +
            'кредитам, которая поможет вам снизить расходы и получить все, ' +
            'что вы хотите.',
          item1: 'Разделите стоимость покупки на 3 беспроцентных платежа,' +
            ' которые списываются с вашей банковской карты каждый месяц.',
          item2: 'Вы сразу знаете точные суммы платежей.',
          item3: 'Никаких дополнительных комиссий, если вы платите вовремя.'
        },
        modern_payment: {
          header: 'Удобный и современный платежный инструмент',
          subheader: 'Оплата частями позволяет вам распределять расходы по ' +
            'времени так, что вы можете покупать то, что хотите, ' +
            'без ущерба для своего бюджета.',
          item1: 'Управляйте покупками и проверяйте предыдущие заказы в личном кабинете.',
          item2: 'Платежи автоматически списываются каждые 30 дней.'
        }
      },
      buy_now: {
        header: 'Покупай сейчас — плати позже',
        subheader: 'Миллионы пользователей гибких платежных решений по всему миру ' +
          'уже оценили их преимущества перед традиционными способами оплаты. ',
        interest_free_header: 'Честная беспроцентная рассрочка',
        interest_free_text: 'Разделите стоимость покупки на 3 платежа. Первый платеж производится' +
          ' в момент покупки, а оставшиеся списываются с карты автоматически через каждые 30 дней. ' +
          'Без переплат, ничего лишнего. Просто больше времени на оплату того, что нравится.',
        legal_information: 'Правовая информация'
      },
      shops_categories: {
        shops_header: 'Магазины',
        shops_subheader: 'Самые выгодные предложения партнеров Rulla всегда у вас под рукой.',
        shops_button: 'в магазины',
        categories_header: 'Категории',
        categories_subheader: 'Найдите то, что вам нужно, и заплатите как удобно. ',
        categories_button: 'смотреть все',
      },
      questions: {
        more_profitable_then_cards: {
          header: 'Почему Rulla выгоднее кредитных карт?',
          text: 'Используя кредитные карты, вы попадаете в замкнутый круг. ' +
            'Большинство банков взимают по ним сложные проценты, а также различные виды комиссий' +
            ' и сборов за обслуживание. Таким образом, чем дольше вы пользуетесь своим ' +
            'балансом по кредитной карте, тем дороже становятся ваши покупки. ' +
            'Вместо этого Rulla предлагает гибкое платежное решение с прозрачными ' +
            'условиями. Мы не берем комиссии и не взимаем проценты. Вы каждый раз ' +
            'принимаете осознанное решение о покупке и берете ровно столько, сколько нужно.' +
            ' Вы всегда точно знаете, сколько должны и когда закончите оплату покупки.'
        },
        different_from_bank: {
          header: 'Чем Rulla отличается от банковской рассрочки?',
          text: 'Все банковские продукты, включая рассрочку, существуют благодаря ' +
            'разнообразным комиссиям. Это могут быть штрафы за просрочку платежа, ' +
            'комиссии за досрочное погашение, регулярные платежи за открытие или закрытие' +
            ' вашего счета, за перевод или снятие средств и так далее. В зависимости от' +
            ' стоимости товара, длительности рассрочки и условий магазина, в котором вы ' +
            'совершаете покупку, ваш план оплаты может включать также проценты. ' +
            'С Rulla вы никогда не будете должны больше, чем заранее согласны заплатить.' +
            ' Стоимость покупки делится на 3 равных платежа, первый из которых оплачивается' +
            ' сразу, а два оставшихся автоматически списываются через 30 и 60 дней. Если эти' +
            ' платежи вносятся на карту в срок, ваша переплата равна нулю.'
        },
        checks_my_solvency: {
          header: 'Как Rulla проверяет мою платежеспособность?',
          text: 'Мы хотим быть уверены, что помогаем нашим клиентам принимать правильные' +
            ' финансовые решения с учетом их жизненных обстоятельств. По этой причине мы' +
            ' собираем и обрабатываем информацию о пользователях сервиса Rulla, чтобы ' +
            'убедиться, что у них не возникнут трудности с оплатой покупок частями. ' +
            'Когда вы в первый раз выбираете в интернет-магазине оплату тремя' +
            ' беспроцентными платежами от Rulla, мы проводим оценку платежеспособности с ' +
            'помощью технологий, основанных на Big Data. Верификация в сервисе Rulla не влияет' +
            ' на ваш кредитный рейтинг. ' +
            'Совершить покупку с помощью Rulla легко, если вы старше 18 лет, и' +
            ' у вас есть постоянный источник дохода.'
        }
      }
    },
    Shops: {
      header: 'Магазины + Rulla',
      subheader: 'Оплата частями от Rulla — это 3 ежемесячных беспроцентных платежа. ' +
        'Гибкое платежное решение для вашего бюджета. Теперь в Украине.',
      categories: 'Категории',
      favorite_shops: 'Избранные магазины',
      sales_text: '* Скидки действительны только в течение ограниченного периода времени' +
        ' и не контролируются Rulla. Rulla может получить комиссию.',
      show_more: 'Показать больше',
      filter_items: {
        search: 'Поиск',
        all: 'Все',
        health: 'Здоровье',
        cloth_shoes: 'Одежда и обувь',
        beauty: 'Красота',
        house: 'Дом',
        hobbies: 'Увлечения',
        car: 'Авто'
      },
    },
    SubFooterBusiness: {
      text: 'У вас есть бизнес и вы хотите продавать с RULLA?',
      button: 'Rulla для бизнеса'
    },
    InfoTiles: {
      tiles: {
        header: 'Свяжитесь с нами',
        help_questions: 'Мы поможем вам с любыми вопросами.',
        made_rulla_purchase: 'Вы совершили покупку с использованием Rulla? Посетите страницу нашей',
        customer_service_page: 'Службы поддержки покупателей.',
        when_will_pay: 'Когда я получу деньги за товары, оплаченные с помощью Rulla?',
        start_sell_rulla: 'Хотите продавать больше вместе с Rulla?',
        start_right_now: 'Начни прямо сейчас!',
      },
      lower_tiles: {
        header_hours: 'Часы работы',
        header_business: 'Службы поддержки Rulla для бизнеса',
        monday_friday: 'Понедельник - Пятница',
        phone: 'Контактный телефон:',
        mail: 'Почта:'
      },
    },
    Blog: {
      header: 'Блог',
      subtitle: 'Этот блог познакомит с новыми трендами и идеями,\nкоторые сделают ваш шоппинг безупречным',
      newsCategories: 'Категории новостей',
      rullaNews: 'Новости от Rulla',
      allNews: 'Все новости',
      loadMore: 'Загрузить больше',
      shareArticle: 'Поделиться статьей',
      readNext: 'Читать следующие статьи',
      all: 'Все',
      another_categories: 'Другие категории блога',
    },
    Home: {
      favorite_stores_header: 'Избранные магазины',
      popular_stores_header: 'Популярные категории',
      fashion_cloth_header: 'Мода и одежда',
      beauty_header: 'Красота',
      home_header: 'Дом',
      watch_all: 'Смотреть все',
      shops_partners: 'Магазины-партнеры',
      shopping_pleasure: {
        header: 'Новый формат выгодного онлайн-шопинга',
        subheader: 'Выбирайте Rulla, чтобы покупать легко. Платите, как удобно вам.',
        button: 'Подробнее',
        no_overpayment: 'Покупки сейчас, оплата потом',
        transparent_process: 'Прозрачные условия',
        get_now: '100% без переплат',
      },
      beauty: {
        header: 'Создавай красоту естественным образом',
        subheader: 'Cпреи и сыворотки для восстановления и питания волос. Косметика на натуральных минералах',
        button: 'Подробнее',
      },
    },
    SellWithRulla: {
      popup: {
        header1: 'Заявка',
        header2: 'на сотрудничество',
        website_adress: 'Адрес сайта-магазина',
        contact_person: 'Контактное лицо',
        email: 'Email',
        phone: 'Телефон',
        button: 'Отправить',
      },
      popup_thanks: {
        header: 'Благодарим за подачу заявки',
        text: 'Мы свяжемся с вами в течение 24 часов.',
      },
      header: {
        h1: 'Подключайте Rulla и получайте больше продаж',
        button_start: 'Начать сейчас',
        button_more: 'Узнать больше',
      },
      benefits: {
        header: 'Никакого риска. Только выгоды:',
        button_go_over: 'перейти',
        items: {
          more_customers: {
            header: 'Больше покупателей',
            text: 'Размещая свой магазин на Rulla.com, ' +
              'вы бесплатно привлекаете целевую аудиторию на свой сайт.'
          },
          coverage: {
            header: 'Поддержка продавца',
            text: 'Наша техподдержка готова ответить на любые ваши вопросы.'
          },
          guard_store: {
            header: 'Защита магазина',
            text: 'Вы ничем не рискуете. Rulla берет ' +
              'на себя проверку платежеспособности клиентов. '
          },
          guard_customer: {
            header: 'Защита покупателя',
            text: 'Наш клиентский сервис оперативно решит проблемы ваших покупателей.'
          },
          conditions: {
            header: 'Выгодные условия оплаты',
            text: 'Удобное платежное решение без переплат повышает доверие к вашему бизнесу.'
          },
          money: {
            header: 'Деньги вперед',
            text: 'Магазин получает от Rulla полную стоимость покупки сразу.'
          }

        }
      },
      integration: {
        header: 'Интеграция Rulla в любой интернет-магазин',
        text: 'Прямое подключение платежного сервиса Rulla ' +
          'к платформам e-commerce по API. Используйте руководство ' +
          'и документацию на сайте или обратитесь к нам за помощью.',
        button_apply: 'подать заявку',
        button_manual: 'руководство',
      },
      start_now: {
        header: 'Начните прямо сейчас',
        application_item: {
          header: 'Свяжитесь с нами',
          text: 'Подключение Rulla занимает минимум времени. ' +
            'Начните продавать больше как можно быстрее.'
        },
        widget_item: {
          header: 'Гибкая оплата',
          text: 'Предложите вашим клиентам прозрачную систему платежей, ' +
            'с которой они смогут позволить себе больше. Без кредитов и переплат.'
        },
        orders_item: {
          header: 'Получайте заказы',
          text: 'Ваши клиенты смогут разделить стоимость покупки на 3 платежа ' +
            'и оплатить как им удобно. Ваш магазин получит от Rulla всю сумму сразу.'
        },
        charts_item: {
          header: 'Оптимизируйте бизнес',
          text: 'Вся аналитика по продажам с использованием Rulla доступна в вашем личном кабинете. ' +
            'Изучайте данные и используйте их для роста.'
        },
      },
      how_it_works: {
        header: 'Как это работает',
        text: 'Rulla добавляется в ваш интернет-магазин как способ оплаты. ' +
          'Когда покупатель выбирает оплату через Rulla, он получает возможность ' +
          'разделить стоимость покупки на 3 ежемесячных платежа. Ваш магазин получает ' +
          'всю сумму сразу и отправляет товар клиенту. Для продавца это ничем не отличается от обычной продажи. '
      },
      partnership: {
        text: 'Партнерство с Rulla — это самый простой способ превратить посетителей вашего' +
          ' онлайн-магазина в покупателей. Вы получаете новых клиентов, которые покупают ' +
          'у вас больше и чаще. Вам всегда платят полную стоимость сразу, а ваши клиенты ' +
          'покупают так, как им удобно.',
        volume_increase: 'Повышение объема продаж',
        conversion_increase: 'Рост конверсии из посетителя в покупателя',
        check_increase: 'Повышение среднего чека',
        upto: 'до'
      },
      statistics: {
        header: 'Мировая статистика показывает растущий тренд сервисов оплаты частями:',
        transactions: 'транзакций в день',
        users: 'пользователей',
        countries: 'стран',
        mln: 'mln'
      },
      content: {
        header: 'Удобство покупателей — в центре внимания',
        subheader: 'Шопинг с Rulla укрепляет доверие покупателей к вашему бренду.' +
          ' Дайте вашим клиентам возможность оплачивать покупки тремя ежемесячными платежами ' +
          'без процентов и скрытых комиссий. Единственная честная рассрочка: мгновенно ' +
          'и без переплат. Удобно контролировать покупки и платежи в личном кабинете сервиса. ' +
          'Прозрачные условия оплаты частями ' +
          'от Rulla — лучшая альтернатива кредитным картам и займам. Постройте свою бизнес-модель ' +
          'на превосходном пользовательском опыте и получите тысячи лояльных клиентов. ',
        text1: 'Сервисы оплаты онлайн-покупок частями предпочитают люди ' +
          'в возрасте 20-35 лет. ' +
          'Разместите свой магазин на Rulla, и новые клиенты сами найдут вас.',
        text2: 'Регистрация в сервисе Rulla впервые занимает пару минут. ' +
          'Мгновенное решение о рассрочке по номеру телефона при оформлении покупки. ' +
          'Платежи списываются автоматически.',
        text3: 'Зарегистрированные пользователи Rulla делают покупки ' +
          'у новых продавцов с комфортом постоянных клиентов. ' +
          'Такие покупатели не сомневаются. Они тратят больше ' +
          'и возвращаются чаще.'

      },
      news: {
        header: 'Кейсы и новости в блоге Rulla',
        subheader: 'Покупатели платят как им удобно. Продавцы ' +
          'получают больше счастливых клиентов. Rulla позаботится обо всем.',
        button_further: 'Читать дальше',
        blog: 'Блог Rulla',
      }
    },
    AboutUs: {
      header: 'О нас',
      subheader:
        'Мы создали сервис оплаты частями Rulla\n' +
        'как удобную альтернативу кредитным картам и займам,\n' +
        'которые вынуждают вас переплачивать.\n' +
        'Rulla дает больше времени на оплату покупок онлайн,\n' +
        'без скрытых комиссий.',
      content_center: {
        header: 'Кто мы?',
        text1: 'Технологичное платежное решение Rulla является новой разработкой опытных создателей финтех-продуктов для европейских рынков. Цель этого продукта — сделать покупки в интернете проще и доступнее.',
        text2:
          'Наша миссия — дать всем желающим возможность продавать\n' +
          'и покупать онлайн максимально удобно, безопасно и выгодно. ',
        text3: 'Гибкий платежный сервис Rulla — это новый для Украины финансовый инструмент, работающий по модели “Покупай сейчас — плати позже” (Buy Now Pay Later). Сейчас Rulla предлагает клиентам онлайн-магазинов оплату частями. Пользователи сервиса получают возможность оплатить покупку тремя платежами, которые автоматически списываются с карты каждые 30 дней без дополнительных процентов и комиссий.',
        textBank: 'ООО "РУЛЛА УКРАИНА" не является банковским учреждением. Процесс регистрации и верификации пользователей требует минимум времени и данных. Для рассмотрения заявок и автоматизации процессов применяются новейшие технологии в области Big Data.',
      },
      contacts: {
        header: 'Контактная информация:',
        hot_line: '(горячая\u00a0линия)',
        sales_department: '(отдел\u00a0продаж)',
        project_manager: '(руководитель\u00a0проекта)',
        address:
          'ООО «РУЛЛА УКРАИНА»\n' +
          'ЕГРПОУ 43213857\n' +
          'Украина, 69035, Запорожская обл., г. Запорожье,\n' +
          'ул. Ферросплавная, дом 38, кв. 31',
        email: 'sales@rulla.com',
        phone: '+38 066 885 4373',
        phoneNo: '+380668854373',
      }
    },
    EmailConfirm: {
      header: 'Подтверждение e-mail',
      content: {
        in_process: 'Проверяем e-mail...',
        success: 'E-mail успешно подтвержден',
        failure: 'Что-то пошло не так'
      }
    },
    TokenRedirect:{
      content:"Входим в личный кабинет..."
    },
    SupportSeller: {
      header: {
        h1: 'Здравствуйте! Как мы можем вам помочь?',
        p: 'Здесь вы найдете ответы на вопросы о продуктах Rulla, ' +
          'о том, как начать работу и как продавать с Rulla. Свяжитесь ' +
          'с нами, если вам понадобится помощь.'
      },
      categories: {
        contact_us: 'Связаться с нами',
        common: {
          become_partner: {
            header: 'Как стать партнером Rulla?',
            text: 'Чтобы начать работу с Rulla, нужно заполнить простую форму заявки. ' +
              'Мы свяжемся с вами в течение 24 часов после этого.'
          },
          term_of_action: {
            header: 'Есть ли у соглашения срок действия?',
            text: 'Срок действия соглашения — 12 месяцев. ' +
              'Договор будет автоматически продлеваться за месяц до конца ' +
              'текущего периода действия. Rulla оставляет за собой право расторгнуть это' +
              ' соглашение в любое время.'
          },
          integration: {
            header: 'Как интегрировать виджет Rulla в интернет-магазин?',
            text: 'После ',
            link: 'регистрации бизнес-аккаунта',
            text1: ' перейдите в раздел «Интеграция» в вашем личном кабинете, чтобы получить свои учетные ' +
              'данные Rulla API. Их нужно добавить на свою e-commerce платформу, чтобы начать регистрировать покупки. ' +
              'Следуйте инструкции:',
            step1: 'ШАГ 1.',
            generate_token: 'Сгенерируйте уникальный JWT token. Сохраните ваш токен надежным способом.',
            step2: 'ШАГ 2.',
            send_request: 'Отправьте запрос на создание сессии виджета по адресу:',
            authorization_header1: 'В заголовке Authorization необходимо указать значение Bearer TOKEN, где вместо',
            authorization_header2: 'TOKEN использовать сгенерированный токен.  ',
            request_body_example: 'Пример тела запроса',
            detailed_information: 'Подробную информацию о запросе можно найти на странице API: ',
            success_request: 'В случае успешного запроса ответ будет содержать идентификатор созданной сессии:',
            step3: 'ШАГ 3.',
            add_widget_on_page: 'Добавьте виджет на страницу, указав src, который содержит полученный идентификатор.',
            problems_with_integration: 'Если у вас возникли трудности с интеграцией, свяжитесь со Службой поддержки Rulla для бизнеса.',
            code: `{
  "country": "UA",
  "locale": "ru",
  "client": {
    "phone": "380995550000",
    "email": "customer@rulla.com",
    "surname": "Иванов",
    "name": "Иван",
    "patronymic": "Иванович",
    "inn": "52012546124",
    "livingAddress": "Киев, ул. Шота Руставели, 30",
    "deliveryAddress": ""
  },
  "order": {
    "id": "2021-06-08-032992",
    "orderLines": [
      {
        "id": "21312312312",
        "name": "NVIDIA GEFORCE RTX 2080 Ti",
        "quantity": 2,
        "unitPrice": 3000.00,
        "imageUrl": "https://via.placeholder.com/350x150",
        "productUrl": "https://example.com"
      }
    ],
    "currency": "UAH",
    "total": 6000.00
  }
}`,
          },
          changed_account: {
            header: 'Я изменил расчетный счет организации. Что мне нужно сделать?',
            text: 'Вы можете управлять платежными реквизитами в разделе “Счета”. Если у вас возникли трудности, свяжитесь с нашей Службой поддержки Rulla для бизнеса.'
          }
        },
        seller_buyer: {
         header: 'Продавец-Покупатель',
          questions: {
           0 : {
             header: 'Когда я получу деньги за товары, оплаченные с помощью Rulla?',
             text: 'Время, в течение которого Rulla перечисляет ' +
               'продавцу деньги за товары, оплаченные через сервис, ' +
               'указано в договоре. Стандартное время поступления ' +
               'средств от Rulla на счет магазина — от 1 до 3 дней. ' +
               'Если у вас есть вопросы, касающиеся платежей, ' +
               'ознакомьтесь с вашим договором или обратитесь в ' +
               'нашу ',
             link: 'Службу поддержки продавца.'
           },
            1 : {
             header: 'Когда клиенту вернут деньги после оформления возврата?',
              text: 'Rulla обработает возврат средств после того, ' +
                'как вы зарегистрируете возврат или отмените заказ. ' +
                'Возврат средств покупателю обычно занимает у Rulla ' +
                'от 1 до 3 рабочих дней. Как только продавец регистрирует ' +
                'возврат, стоимость товара будет вычтена из общей суммы ' +
                'долга клиента. При возврате или отмене всего заказа ' +
                'целиком, первый платеж будет возвращен клиенту, а ' +
                'остальные два аннулируются.'
            },
            2 : {
             header: 'Какой валютой можно расплатиться через Rulla?',
             text: 'Rulla производит все расчеты в украинской гривне (₴). ' +
               'Если валюта платежной карты покупателя отличается, ' +
               'то платежи будут взиматься в эквиваленте суммы в гривнах' +
               ' по курсу на текущую дату. '
            },
            3: {
             header: 'Покупатель интересуется, сколько еще нужно заплатить. Что ответить?',
             text: 'Сообщите клиенту, что он может найти всю информацию ' +
               'по платежам в своем личном кабинете на Rulla.com. ' +
               'Он также может связаться с ',
              link: 'Клиентским сервисом',
              text1: ' Rulla для получения дополнительной информации.'
            },
            4: {
             header: 'Что делать, если клиент оспаривает свои платежи?',
             text: 'Если клиент оспаривает свои платежи, направьте его в ',
              link: 'Клиентский сервис',
              text1: ' Rulla, чтобы наши специалисты могли решить его проблему.',
            },
            5: {
             header: 'Что происходит, когда покупатель возвращает товар?',
             text: 'Если покупатель возвращает покупку, оплаченную' +
               ' с помощью Rulla, он должен сделать это в установленный ' +
               'законом Украины 14-дневный срок. После того, как продавец ' +
               'зарегистрировал возврат, Rulla возвращает платеж, уже ' +
               'полученный за товар, обратно на карту покупателя. Всегда' +
               ' просите клиентов сохранять квитанции о получении и ' +
               'возврате покупок для разрешения возможных спорных ситуаций.'
            },
            6: {
              header: 'Где я могу найти свои заказы?',
              text: 'Чтобы просмотреть все заказы, размещенные в вашем ' +
                'магазине с оплатой через Rulla, войдите в раздел ' +
                '«Заказы» в своем бизнес-аккаунте.'
            },
            7: {
              header: 'Как я могу начать продавать с Rulla?',
              text: 'Начните работу с Rulla без промедления,' +
                ' заполнив заявку на нашем сайте ',
              link: 'здесь.'
            },
            8: {
              header: 'В чем разница между банковской рассрочкой/оплатой частями и Rulla?',
              text: 'Rulla — это платежное решение для интернет-магазинов, позволяющее их клиентам оплачивать покупки онлайн несколькими платежами. Использование модели «Покупай сейчас — плати позже» увеличивает конверсию и средний чек, так как предоставляет клиентам более гибкий способ оплаты. ',
              list: {
                0: {
                  header: 'Rulla vs. мгновенная рассрочка',
                  text: 'Банковская рассрочка является кредитом и влияет на кредитную историю.' +
                    ' В отличие от традиционной рассрочки, в Rulla нет никаких дополнительных ' +
                    'комиссий и процентов, а решение об одобрении суммы для оплаты в 3 платежа ' +
                    'принимается на основе технологии machine learning.',
                },
                1: {
                  header: 'Rulla vs. оплата частями от банков',
                  text: 'Условия использования сервиса Rulla понятны, прозрачны и едины для всех.' +
                    ' Для этого метода оплаты подходят дебетовые карты, а значит, никаких переплат ' +
                    'банкам за пользование кредиткой. Покупатель сразу знает, сколько он должен будет заплатить и когда.'
                }
              }
            },
            9: {
              header: 'Есть ли ограничения на то, что я могу продавать через Rulla?',
              text: 'Если вы продаете табачные изделия, лекарства, ' +
                'путевки, услуги или используете маркетплейс, ' +
                'мы просим вас связаться с ',
              link: 'отделом продаж Rulla.'
            },
            10: {
             header: 'Что такое оплата частями от Rulla?',
             text: 'Rulla — это гибкое платежное решение для e-commerce, которое ' +
               'представлено новым для Украины способом оплаты: тремя ' +
               'беспроцентными платежами с рассрочкой на 2 месяца. ' +
               'Rulla постоянно обновляется с помощью улучшений и ' +
               'функций, которые увеличивают конверсию и упрощают ' +
               'жизнь как вам, так и вашим клиентам.'
            }
          }
        },
        account_management: {
          header: 'Управление аккаунтом',
          questions: {
            0: {
              header: 'Как обновить мои контактные данные?',
              text:
                'Вы можете обновить свои контактные данные в личном кабинете продавца в разделе “Настройки / Контактные данные”. ' +
                'Если у вас возникли трудности, свяжитесь с нашей Службой поддержки продавца, и мы с радостью поможем вам.'
            },
            1: {
              header: 'Я изменил расчетный счет организации. Что мне нужно сделать?',
              text: 'Вы можете управлять платежными реквизитами в разделе “Счета”. Если у вас возникли трудности, свяжитесь с нашей Службой поддержки Rulla для бизнеса.'
            },
            2: {
              header: 'Что такое бизнес-логин?',
              text:
                'Бизнес-логин — это вход в личный кабинет продавца (бизнес-аккаунт). ' +
                'Через него продавцы получают доступ к системе обработки заказов, оплаченных с помощью Rulla. ' +
                'В бизнес-аккаунте магазины-партнеры могут:',
              list: {
                0: {
                  text: 'Получать и обрабатывать заказы.'
                },
                1: {
                  text: 'Составлять детальные отчеты о своих продажах с Rulla.'
                },
                2: {
                  text: 'Просматривать историю заказов, оплаченных через Rulla.'
                },
                3: {
                  text: 'Изменять настройки.'
                },
              }
            },
            3: {
              header: 'Как войти в личный кабинет продавца?',
              text: 'Чтобы войти в свой личный кабинет (бизнес-аккаунт), используйте логин и пароль, полученные при оформлении соглашения о партнерстве с Rulla. Кнопка “Войти” размещена в верхней части сайта.',
              text1:
                'Чтобы войти в свой личный кабинет (бизнес-аккаунт), используйте логин и пароль, ' +
                'полученные при оформлении соглашения о партнерстве с Rulla. ' +
                'Щелкните ',
              link1: 'здесь',
              text2: ' и введите данные.'
            },
            4: {
              header: 'Есть ли у соглашения срок действия?',
              text: 'Срок действия соглашения с Rulla — 12 месяцев. Договор будет автоматически продлеваться за месяц до' +
                ' конца текущего периода действия. Rulla оставляет за собой право расторгнуть это соглашение в любое время.'
            },
            5: {
              header: 'Как составить сводный отчет?',
              text: 'В бизнес-аккаунте по умолчанию отображается сводка ' +
                'за последний месяц, но вы можете установить нужный вам период' +
                ' продаж. Это можно сделать, отфильтровав данные по датам в разделе «Платежи».'
            },
            6: {
              header: 'Что находится в разделе «Интеграция»?',
              text: 'В разделе «Интеграция» вы можете генерировать учетные данные API,' +
                ' которые используются для установки соединения между вашим магазином и Rulla. '
            },
            7: {
              header: 'Что находится в разделе «Платежи»?',
              text: 'В разделе «Платежи» вы найдете данные о продажах с Rulla.' +
                ' Здесь вы можете устанавливать собственные диапазоны и фильтровать ' +
                'информацию по параметрам, чтобы анализировать нужные вам показатели.'
            },
            8: {
              header: 'Что находится в разделе «Заказы»?',
              text: 'Раздел «Заказы» позволяет просматривать все заказы и их историю, а также регистрировать возвраты.'
            },
            9: {
              header: 'Что находится в разделе «Оповещения»?',
              text: 'Вы можете настроить оповещения через любой удобный вам канал: e-mail, SMS, или через мессенджер. Также можно отдельно настроить разные каналы, включить или отключить оповещения для различных юридических лиц в пределах вашего магазина.'
            },
            10: {
              header: 'Как можно получить учетные данные для входа в бизнес-аккаунт?',
              text: 'Когда вы подписываете соглашение с Rulla, мы создаем вашу учетную ' +
                'запись в системе (бизнес-аккаунт). Адрес электронной почты, указанный в' +
                ' соглашении, будет использоваться в качестве имени пользователя. На этот' +
                ' адрес вы получите приветственное письмо с именем пользователя и одноразовым' +
                ' паролем, который вы используете при первом входе в систему.'
            },
            11: {
              header: 'Как изменить язык в бизнес-аккаунте?',
              text: 'Перейдите в раздел “Настройки”, в настройках выберите предпочтительный язык.'
            },
            12: {
              header: 'В каких странах можно использовать Rulla?',
              text: 'Сервис Rulla как способ оплаты частями (в 3 платежа) покупок онлайн доступен в Украине.'
            },
            13: {
              header: 'Каким финансовым органом регулируется Rulla?',
              text: 'ООО «РУЛЛА УКРАИНА» является микрофинансовой организацией, ' +
                'деятельность которой регулируется Национальным банком Украины.'
            },
            14: {
              header: 'У меня есть контактное лицо?',
              text: 'На начальном этапе работы с Rulla у вас будет конкретное контактное лицо,' +
                ' которое сможет вам помочь в случае затруднений. Далее вы можете обращаться ' +
                'в нашу ',
              link: 'Службу поддержки продавца',
              text1: ' по телефону или электронной почте для получения помощи.'
            },
          }
        },
        integration: {
          header: 'Техническая интеграция',
          questions: {
            0: {
              header: 'Где я могу найти учетные данные Rulla API?',
              text: 'Вы можете сгенерировать новые учетные данные Rulla API в разделе' +
                ' «Интеграция» в личном кабинете продавца (бизнес-аккаунте). ' +
                'Не забудьте сразу сохранить новые учетные данные Rulla API,' +
                ' поскольку они появляются только один раз.'
            },
            1: {
              header: 'Где можно взять логотипы Rulla?',
              text: 'Логотипы Rulla для размещения на вашем сайте можно скачать на ' +
                'странице «Пресса» или запросить у вашего менеджера Rulla.'
            },
            2: {
              header: 'Как работает интеграция?',
              text: 'Первым шагом является получение учетных данных Rulla API, ' +
                'которые вы затем добавите на свою платформу, чтобы начать' +
                ' регистрировать покупки. Чтобы получить свои учетные данные' +
                ' Rulla API, вам необходимо войти в свой бизнес-аккаунт в раздел' +
                ' «Интеграция». Там вы сможете сгенерировать свои учетные данные Rulla API.'
            },
            3: {
              header: 'Что нужно, чтобы интеграция Rulla с моим магазином была одобрена?',
              text: 'Чтобы начать продавать с Rulla, ваш веб-сайт должен быть активен и содержать следующую информацию:',
              list: {
                0: {
                  text: 'Информация о компании: зарегистрированное название компании, зарегистрированный ' +
                    'адрес компании, регистрационный номер организации.'
                },
                1: {
                  text: 'Положения и условия, содержащие информацию о доставке и порядке' +
                    ' возврата товаров. Если доставка платная, необходимо четко это указать.'
                },
                2: {
                  text: 'Контактные данные вашей службы поддержки клиентов, номер телефона и адрес электронной почты.'
                },
                3: {
                  text: 'Цены товаров и стоимость доставки, соответствующие действительности.'
                },
              }
            },
            4: {
              header: 'Что нужно сделать, прежде чем я смогу начать интеграцию с Rulla?',
              text: 'Чтобы начать интеграцию с Rulla, у вас должен быть работающий ' +
                'интернет-магазин. Это означает, что на его сайте должна быть информация' +
                ' о ваших продуктах/услугах, а также возможность добавлять их в корзину.'
            },
            5: {
              header: 'Есть ли у Rulla руководство по интеграции?',
              text: 'Да, в разделе «Интеграция» в бизнес-аккаунте вы найдете ' +
                'информацию о том, как интегрировать Rulla. Если вам потребуется ' +
                'помощь, обратитесь к своему менеджеру Rulla или в Службу поддержки продавца.'
            }
          }
        },
      },
      back_button: 'Вернуться на стартовую страницу Поддержки',
      questions_header: 'Часто задаваемые вопросы',
    },
    TermsAndConditions: {
      header: 'Правила и условия',
      documents: {
        rules_20210301: 'Правила предоставления денежных средств в кредит',
        rules_20210714: 'Дополнение к правилам от 14.07.2021'
      },
      payments: {
        header: 'Варианты оплаты',
        content: 'Оплата платежными картами Visa и MasterCard'
      },
      delivery: {
        header: 'Доставка по Украине',
        time: 'Обычно доставка занимает от 1 до 4 дней в пределах всей Украины.',
        cost: 'Стоимость доставки зависит от условий отдельного продавца.'
      },
      refund: {
        header: 'Возврат и обмен',
        text1: 'Согласно Закону Украины «О защите прав потребителей» покупатель имеет право обменять товар на аналогичный в течение 14 дней, если купленный товар не подошел по форме, габаритам, фасону, цвету, размеру или по другим причинам, и если сохранен товарный вид, ярлыки и расчетный документ, выданный продавцом вместе с товаром. Исключение составляют товары, не подлежащие возврату по закону, их перечень вы можете посмотреть по ',
        text2: 'ссылке'
      },
      contacts: {
        header: 'Контактная информация',
        companyName: 'ООО «РУЛЛА УКРАИНА»',
        companyNumber: 'ЕГРПОУ 43213857',
        address: 'Украина, 69035, Запорожская обл., г. Запорожье,\nул. Ферросплавная, дом 38, кв. 31',
        emailLabel: 'E-mail:',
        email: 'sales@rulla.com',
        phoneLabel: 'Тел:',
        phoneNumber: '+38 066-885-43-73'
      }
    },
    ThreePayments: {
      header_block: {
        header: 'Три платежа без процентов',
        subheader: 'Новый удобный способ покупать онлайн',
        button: 'свяжитесь с нами'
      },
      business: {
        header: 'Rulla развивает ваш бизнес',
        subheader: 'Платежное решение, которое увеличивает продажи, — идеальный вариант для вашего интернет-магазина.',
        items: {
          conversion: {
            header: 'Конверсии',
            text: 'Удобная форма оплаты частями без комиссий повышает вероятность покупки.'
          },
          average_check: {
            header: 'Средний чек',
            text: 'Rulla дает вашим клиентам больше времени для оплаты, поэтому они покупают больше.'
          },
          regular_customers: {
            header: 'Постоянные клиенты',
            text: 'Положительный опыт выгодного шопинга приносит больше повторных продаж.'
          },
          attendance: {
            header: 'Посещаемость',
            text: 'Пользователи Rulla переходят на ваш сайт прямо из платежного сервиса.'
          }
        },
        implemented_top_brands: 'Оплату частями внедрили топ-бренды по всему миру.',
      },
      purchases_control: {
        header: 'Покупки под контролем',
        subheader: 'Все покупки в одном месте в личном кабинете Rulla.',
        item1: 'Ваши покупатели могут видеть историю своих заказов, гибко управлять платежами и планировать бюджет.',
        item2: 'Возможность разделять стоимость покупок на 3 беспроцентных платежа дает финансовую свободу и уверенность.',
        item3: 'Предлагая выгодную альтернативу кредитам и банковской рассрочке, платежное' +
          ' решение от Rulla увеличивает продажи и лояльность клиентов.',
        button_start_now: 'НАЧАТЬ СЕЙЧАС'
      },
      guarantee: {
        header: 'Гарантии участникам сделки',
        text: 'Использовать Rulla для покупок онлайн так же просто, ' +
          'как расплачиваться с помощью карты. При этом обе стороны получают преимущества. ' +
          'Покупатели безопасно увеличивают свою платежеспособность. Алгоритм оценки' +
          ' не даст потратить больше, чем они в состоянии заплатить. Продавцы работают ' +
          'как обычно: после оформления заказа получают всю сумму вперед и доставляют товар. ' +
          'Что-то пошло не так? ',
        link: 'Мы разRull’им.'
      },
      statistics: {
        header: 'Rulla дарит вашим клиентам возможность совершать больше покупок. ',
        subheader: 'Покупать или не покупать? Оплата частями решит проблему нехватки средств ' +
          'и подарит опыт комфортного шопинга. Больше покупок и меньше брошенных ' +
          'корзин с гибким платежным сервисом от Rulla.',
        info_pleased: 'тех, кто пользовался функцией оплаты в несколько платежей, довольны этим опытом',
        info_think: 'пользователей сервисов оплаты частями считают этот способ самым лучшим',
        info_refused: 'клиентов онлайн-магазинов отказались бы от покупки без опции оплаты частями*',
        info_refused1: '*По данным платежных сервисов'
      },
      how_arranged: {
        header: 'Как устроена оплата в 3 платежа',
        subheader: 'Покупай сейчас — плати позже. Чтобы сделать покупки проще, ' +
          'Rulla предлагает прозрачную систему оплаты частями. Без переплат.',
        order_registration: {
          header: 'Оформление заказа',
          text: 'В интернет-магазине покупатель выбирает оплату частями от Rulla ' +
            'и вносит первый платеж, используя банковскую карту. В виджете отображаются суммы и график платежей.'
        },
        delivery: {
          header: 'Доставка',
          text: 'После обработки заказа Rulla  выплачивает продавцу (магазину)' +
            ' полную стоимость покупки. Мы сами позаботимся о получении следующих платежей от покупателя.'
        },
        after_purchase: {
          header: 'После покупки',
          text: 'Ваш клиент получает четкое представление о своих покупках ' +
            'и может легко управлять своими заказами в личном кабинете Rulla.'
        }
      },
      connecting: {
        header: 'Подключение Rulla',
        subheader: 'Установка виджета оплаты в 3 платежа на сайт интернет-магазина занимает ' +
          'от пары часов. Для подключения свяжитесь с нами через форму на сайте.',
        details_table: {
          header: 'Детали',
          market_availability: {
            header: 'Доступность на рынке',
            text: 'Украина'
          },
          payment_options: {
            header: 'Варианты оплаты',
            text: 'Дебетовая или кредитная карта'
          },
          payment_period_starts: {
            header: 'Период оплаты начинается',
            text: 'При обработке заказа'
          },
          number_of_payments: {
            header: 'Количество платежей',
            text: '3'
          },
          time_between_payments: {
            header: 'Время между платежами',
            text: '30 дней (стандартно)'
          },
          annual_interest_rate: {
            header: 'Годовая процентная ставка',
            text: '0,00001%'
          },
          additional_expenses: {
            header: 'Дополнительные расходы',
            text: 'Нет комиссий. Никаких затрат при оплате в срок.'
          },
          payment_method: {
            header: 'Способ внесения платежей',
            text: 'Автоматическое списание с зарегистрированной карты'
          },
          delay_in_payment: {
            header: 'Задержка платежа (просрочка)',
            text: 'Может взиматься небольшая плата'
          },
          view_pay_purchases: {
            header: 'Просмотр и оплата покупок',
            text: 'Необходима авторизация в личном кабинете на',
          },
          seller_protection: {
            header: 'Защита продавца',
            text: 'Да'
          },
          buyer_protection: {
            header: 'Защита покупателя',
            text: 'Да'
          }
        },
        integration_table: {
          header: 'Интеграция',
          direct_integration: {
            header: 'Прямая интеграция',
            text: 'API'
          },
          automatic_updates: {
            header: 'Автоматические обновления',
            text: 'Включено'
          },
          developer_resources: {
            header: 'Ресурсы для разработчиков',
            text: 'Руководство по интеграции'
          }
        },
        trade_agreement_table: {
          header: 'Торговое соглашение',
          standard_terms: {
            header: 'Стандартные условия соглашения',
            text: '12 месяцев (продлевается автоматически)'
          },
          model_pricing: {
            header: 'Стоимость / модель ценообразования',
            text: 'Свяжитесь с нами для получения дополнительной информации'
          }
        }
      }
    },
    Legal: {
      h1: 'ЮРИДИЧЕСКАЯ ИНФОРМАЦИЯ',
      h2: 'ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ «РУЛЛА УКРАИНА»',
      text1: 'Код ЕГРПОУ 43213857',
      text2: 'Код КВЭД 64.92 Другие виды кредитования (основной).',
      text3: 'Вид финансовой услуги: предоставление займов, в том числе на условиях финансового кредита.',
      text4: 'Свидетельство о регистрации финансового учреждения ФК №1420 від 26.05.2020 г.',
      text5: 'Сведения о лицензии и разрешения, выданные финансовому учреждению:',
      text6: 'Вид хозяйственной деятельности, на осуществление которого выдана лицензия —' +
        ' ПРЕДОСТАВЛЕНИЕ СРЕДСТВ ВЗАЙМЫ, В ТОМ ЧИСЛЕ И НА УСЛОВИЯХ ФИНАНСОВОГО КРЕДИТА.' +
        'Лицензия на осуществление деятельности по предоставлению средств в заем,' +
        ' в том числе и на условиях финансового кредита от 11.06.2020 г. ' +
        '(Распоряжение НКФУ № 1323 от 11.06.2020 г.)',
      text7: 'Статус лицензии — действующая.',
      text8: 'Срок действия — бессрочная.',
      text9: 'Данные о возбуждении производства по делу о банкротстве, санации:',
      text10: 'Не находится в процессе ликвидации.',
      text11: 'Учредители (участники) Общества:',
      text12: 'ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ «ИНСТИТУТ ИССЛЕДОВАНИЙ СТАНДАРТОВ КАЧЕСТВА»',
      text13: 'Код ЕГРПОУ учредителя: 36977252',
      text14: '69002, Запорожская обл., город Запорожье, Александровский район, улица Запорожская, дом 6-А, квартира 47',
      text15: 'ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ «ЦЕНТР ПРАВОВЫХ РЕШЕНИЙ»',
      text16: 'Код ЕГРПОУ учредителя: 36977247',
      text17: '69002, Запорожская обл., город Запорожье, Александровский район, улица Запорожская, дом 6-А, квартира 47',
      text18: 'Конечный бенефициарный владелец (контроллер):',
      text19: 'ТИХОНОВА ЕЛЕНА АЛЕКСЕЕВНА',
      text20: 'Украина, 69002, г. Запорожье, улица Запорожская, дом 6-А, квартира 47',
      text21: 'Сведения об органах управления юридического лица:',
      text22: 'Общее собрание участников Общества.',
      text23: 'Исполнительный орган Общества — Директор Леман Наталья Сергеевна.',
      text24: 'Наблюдательный совет не создавался.',
      text25: 'Обособленные подразделения отсутствуют.',
      text26: 'График работы',
      text27: 'Пн-пт 9.00-18.00',
      text28: 'Без перерыва и выходных.',
      text29: 'Контактная информация',
      text30: 'Адрес:',
      text31: 'Украина, 69035, Запорожская обл., город Запорожье, ул. Ферросплавная, дом 38, квартира 31',
      text32: 'Код территории по КОАТУУ 2310136900',
      text33: 'Электронная почта:',
      text34: 'Телефон:',
      text35: 'Механизмы защиты прав потребителей финансовых услуг',
      text36: 'Жалобы по поводу защиты прав потребителей принимаются:',
      text37: '1) По юридическому адресу Общества:',
      text38: 'Украина, 69035, Запорожская обл., город Запорожье, ул. Ферросплавная, дом 38, квартира 31',
      text39: '2) Национальным банком Украины:',
      text39_1: 'Адрес: 01601, г. Киев, ул. Институтская, 9 ',
      text39_2: 'Телефон отдела обращений граждан: 0 800 505 240',
      text39_3: 'Электронная почта: nbu@bank.gov.ua ',
      text39_4: 'https://bank.gov.ua/ua/consumer-protection',
      text41: '3) Главным управлением Держпродспоживслужбы в Запорожской области:',
      text41_1: 'Адрес: 69002 г. Запорожье, ул. Гоголя, 105 а, кабинет 203',
      text41_2: 'Телефон: (061) 228-04-64 (приемная)',
      text41_3: 'Электронная почта: office@gudpss-zp.gov.ua',
      text43: 'Орган, осуществляющий государственное регулирование в отношении деятельности лица, оказывающего финансовые услуги:',
      text44: 'Национальный банк Украины',
      text44_1: 'Адрес: 01601, г. Киев, ул. Институтская, 9',
      text44_2: 'Телефон отдела обращений граждан: 0 800 505 240',
      text44_3: 'Электронная почта: nbu@bank.gov.ua',
      text45: 'Стоимость финансовой услуги определяется в зависимости от суммы кредита и укаывается в Договоре, который заключается между Обществом и Клиентом. Размер процентов годовых может составлять от 0,00001% до 0,1%.',
      text46: 'Общество не привлекает Кредитных посредников.',
      text47: 'Не предусмотрено наличие гарантийных фондов или компенсационных схем при предоставлении финансовой услуги по предоставлению средств в заем, в том числе и на условиях финансового кредита.',
      text48: 'Имеющиеся и возможные схемы кредитования: аннуитетная.',
      text49: 'В результате получения финансовой услуги не предусмотрена уплата налогов или сборов за счет физического лица.',
      text50: 'Документы:',
      text51: 'ПРАВИЛА ПРЕДОСТАВЛЕНИЯ ДЕНЕЖНЫХ СРЕДСТВ В ЗАЕМ, В ТОМ ЧИСЛЕ НА УСЛОВИЯХ ФИНАНСОВОГО КРЕДИТА',
      text52: 'ДОГОВОР О ПРЕДОСТАВЛЕНИИ ФИНАНСОВЫХ УСЛУГ',
      text53: 'СВИДЕТЕЛЬСТВО О РЕГИСТРАЦИИ ФИНАНСОВОГО УЧРЕЖДЕНИЯ',
      text54: 'РАСПОРЯЖЕНИЕ О ВНЕСЕНИИ В ГОСУДАРСТВЕННЫЙ РЕЕСТР ФИНАНСОВЫХ УЧРЕЖДЕНИЙ',
      text55: 'ВЫПИСКА ИЗ ЕДИНОГО ГОСУДАРСТВЕННОГО РЕЕСТРА ЮРИДИЧЕСКИХ ЛИЦ',
      text56: 'РАСПОРЯЖЕНИЕ О ВЫДАЧЕ ЛИЦЕНЗИИ НА ОСУЩЕСТВЛЕНИЕ ХОЗЯЙСТВЕННОЙ ДЕЯТЕЛЬНОСТИ ПО ПРЕДОСТАВЛЕНИЮ ФИНАНСОВЫХ УСЛУГ',
    },
    PersonalCabinet: {
      Auth: {
        customer: 'Покупатель',
        business: 'Магазин',
        business_login: 'Вход для магазина',
        profile_login: 'Вход для покупателя',
        new_login: 'Присоединиться к Rulla',
        enter_number_to_login: 'Введите ваш номер, чтобы войти в личный кабинет',
        start_with_rulla: 'Начните покупки с Rulla',
        enter_number_to_recover: 'Введите ваш номер, и мы отправим вам ссылку на страницу, где вы сможете установить новый пароль',
        password_saved: 'Ваш пароль был изменён. Вы можете перейти по ссылке ниже, чтобы войти в свой личный кабинет',
        password_sent: 'Ссылка була отправлена на указанный номер',
        enter_new_password: 'Введите новый пароль для входа в личный кабинет',
        phone: 'Телефон',
        password: 'Пароль',
        new_password: 'Новый пароль',
        confirm_password: 'Подтвердите пароль',
        login: 'Войти',
        forgot_password: 'Забыли пароль?',
        sign_up: 'Зарегистрироваться',
        return_to_login: 'Вернуться ко входу',
        your_phone_number: 'Ваш номер телефона',
        sending_speeds: 'Могут применяться скорости передачи сообщений и данных',
        send_code: 'Отправить код',
        send_password: 'Отправить',
        send_on: 'Отправлено на',
        send_one_more_time: 'Отправить код еще раз',
        enter_sms_code: 'Введите код из СМС',
        back: 'Назад',
        wait: 'Ожидайте',
        seconds: 'секунд',
        invalid_password: 'Ошибка ввода: неправильный пароль',
        invalid_phone_number: 'Ошибка: формат номера +380 XX XXX XX XX',
        invalid_email: 'Ошибка: введите e-mail в формате a@a.com',
        empty_password: 'Ошибка ввода: введите пароль',
        user_not_found: 'Ошибка: пользователь не найден'
      },
      Layouts: {
        Sidebar: {
          main: 'Главная',
          orders: 'Заказы',
          settings: 'Оповещения',
          settlements: 'Платежи',
          integration: 'Интеграция',
          hotline: 'Горячая линия',
          favorites: 'Избранное',
          purchases: 'Покупки',
          payments: 'Оплаты',
          bank_accounts: 'Счета',
          offline: 'QR / SMS',
          profile_settings: 'Настройки',
          personal: 'Мои данные'
        },
        Footer: {
          main: 'Главная',
          orders: 'Заказы',
          settings: 'Оповещения',
          settlements: 'Платежи',
          favorites: 'Избранное',
          purchases: 'Покупки',
          payments: 'Оплаты',
        },
        MobileMenu: {
          integration: 'Интеграция',
          bank_accounts: 'Счета',
          offline: 'QR / SMS',
          hotline: 'Горячая линия',
          profile_settings: 'Настройки'
        }
      },
      Business: {
        profile_button: 'Профиль',
        Telegram: {
          telegram: 'Telegram',
          add_account: 'Добавить аккаунт',
          popup_title: 'Подтверждение аккаунта',
          popup_1: 'Чтобы начать получать оповещения в telegram необходимо отправить сообщение с кодом подтверждения нашему боту',
          popup_2: 'Начните общение с ботом по ссылке:',
          popup_3: 'Нажмите кнопку "Старт" и отправьте ему следующее сообщение:',
        },
        Integration: {
          header: 'Настройки интеграции',
          controls: {
            withdraw: 'Отозвать выданные токены',
            generate: 'Сгенерировать'
          },
          popup: {
            your_token: 'Ваш токен: ',
            save_token: 'Сохраните его для дальнейшей настройки интеграции с Rulla API',
            copy: 'Копировать'
          },
          text: {
            how_integrate: 'Как интегрировать виджет',
            step_1: 'Шаг 1.',
            send_request: 'Отправить запрос на создание сессии виджета по адресу:',
            heading_set_token: 'В заголовке Authorization необходимо указать значение Bearer TOKEN, ' +
              'где вместо TOKEN использовать сгенерированный токен.',
            request_body_example: 'Пример тела запроса',
            detailed_information: 'Подробную информацию о запросе можно найти на странице API: ',
            legal: 'Значения legalEntityId и bankAccountId можно найти в разделе "Счета"',
            success_request: 'В случае успешного запроса ответ будет содержать идентификатор созданной сессии:',
            step_2: 'Шаг 2. ',
            add_widget_on_page: 'Добавить виджет на страницу, указать src, который содержит полученый идентификатор.',
            example: 'Например:',
            example_name: 'Иван',
            example_surname: 'Иванов',
            example_patronymic: 'Иванович',
            example_address: 'Киев, ул. Шота Руставели',
          },
          api: {
            header: 'Интеграция через API',
            alert: {
              text: 'Прежде чем приступить к интеграции, необходимо настроить банковский счёт по умолчанию:',
              link: 'Банковские счета'
            },
            p1: 'Ниже вы можете сгенерировать токен для аутентификации через API, также здесь показан ваш ключ API Key, с помощью которого можно проверить ваш токен',
            p2: 'В случае компроментации вашего токена - вы можете отозвать его, в таком случае API Key будет изменён',
            p3: {
              part1: 'Если у вас нет возможности сделать интеграцию через API - вы можете воспользоваться ',
              part2: 'генератором',
              part3: ' кода оплаты для конкретных товаров'
            },
            steps: {
              header: 'Как интегрировать виджет',
              step1: {
                header: 'Создать заказ',
                p1: 'После создания заказа в вашей системе, отправить запрос на создание заказа на Rulla по адресу:',
                p2: 'В заголовке Authorization необходимо указать значение Bearer TOKEN, где вместо TOKEN использовать сгенерированный токен.',
                p3: {
                  part1: 'Подробную информацию о запросе можно найти на странице API:'
                },
                p4: 'Пример тела запроса',
                p5: 'Значения <var>legalEntityId</var> и <var>bankAccountId</var> можно найти в разделе "Счета"',
                p6: 'В случае успешного запроса ответ будет содержать идентификатор созданной сессии:'
              },
              step2: {
                header: 'Подключить скрипт',
                p1: 'Далее нужно подключить скрипт Rulla на странице, где будет возможность оплаты заказа',
                p2: 'Тег script лучше всего расположить внутри тега head - таким образом вы обеспечите возможность оплаты по нажатию на кнопку как можно раньше, не ожидая полной загрузки других скриптов'
              },
              step3: {
                header: 'Кнопка оплаты',
                p1: 'Код кнопки можно расположить где угодно, при нажатии на нее – будет отображено модальное окно с виджетом Rulla',
                p2: 'Параметром метода <var>pay</var> является полученный идентификатор сессии',
                p3: 'Вы можете любым образом изменять стиль и текст кнопки'
              },
              step4: {
                header: 'Фрейм',
                p1: 'Альтернативно, вы можете показать виджет Rulla как часть страницы вашего сайта',
                p2: 'Параметром метода <var>init</var> является полученный идентификатор сессии, а <var>element</var> должен содержать селетор элемента, в который будет помещен фрейм',
                p3: 'Скрипт инициализации должен быть выполнен не ранее, чем <var>element</var> будет отрендерирован на странице'
              }
            }
          },
          product: {
            header: 'Интеграция без бекенда',
            alert: {
              text: 'Прежде чем приступить к интеграции, необходимо настроить банковский счёт по умолчанию:',
              link: 'Банковские счета'
            },
            p1:
              'У вас есть возможность произвести оплату через Rulla не изменяя собственного бекенда, ' +
              'однако, в этом случае у вас не будет возможности получать статусы заказов, кроме как через нотификации.',
            p2: {
              part1: 'Также при ',
              part2: 'интеграции через API',
              part3:
                ' у вас есть возможность настраивать продажу нескольких товаров в корзине, ' +
                'и делать подтверждение наличия автоматически из собственной системы.'
            },
            form: {
              product_id: 'Уникальный идентификатор на сайте',
              product_name: 'Название продукта',
              product_price: 'Цена продукта',
              product_image: 'Ссылка на изображение',
              product_url: 'Ссылка на страницу продукта',
            },
            step1: {
              header: 'Кнопка оплаты',
              p1: 'Тег script лучше всего расположить внутри тега head - таким образом, вы обеспечите возможность оплаты по нажатию на кнопку как можно раньше, не ожидая полной загрузки других скриптов',
              p2: 'Код кнопки можно расположить где угодно, при нажатии на нее – будет отображено модальное окно с виджетом Rulla',
              p3: 'Вы можете любым образом изменять стиль и текст кнопки'
            },
            step2: {
              header: 'Фрейм',
              p1: 'Фрейм - это альтернативный способ отображения виджета, когда виджет виден сразу, как часть вашего сайта'
            },
          }
        },
        Settings: {
          header: 'Оповещения',
          hints: [
            'Вы можете настроить оповещения через любой удобный вам канал: e-mail, SMS, или через мессенджер.',
            'Также можно отдельно настроить разные каналы, включить или отключить оповещения для различных юридических лиц в пределах вашего магазина.',
            'По умолчанию каналы для юр.лиц будут соответствовать настройкам магазина. Добавить и проверифицировать новые контакты можно в разделе "Настройки"'
          ],
          store_defaults: '-- Настройки магазина --',
          legal_entities: 'Юридическое лицо',
          phone_number: 'Номер телефона',
          email: 'E-mail',
          what_notify_about: 'О чем сообщать?',
          new_orders: 'Новые заказы',
          order_confirmation: 'Подтверждение заказа',
          expiring_orders: 'Заказы, на которые истекает срок',
          paid_orders: 'Оплаченные заказы, по которым необходимо отправить товар',
          unknown_orders: 'Неизвестные заказы',
          platform_not_notified: 'Заказы, о которых е-комерс платформа или система не оповещены. ' +
            'Могут обозначать ошибку интеграции между Rulla API и вашей системой',
          when_notify: 'Когда сообщать?',
          everyday: 'Ежедневно',
          everyday_morning: 'Получайте ежедневную сводку каждое утро',
          turn_off_blank: 'Отключить пустые письма',
          dont_send_blank: 'Мы не будем отправлять вам письма, когда неочем сообщать',
          every_week: 'Еженедельно',
          every_week_monday: 'Получайте еженедельнкую сводку каждый понедельник',
          dropdownLabel: 'Язык'
        },
        Settlements: {
          header: 'Отчеты по платежам',
          download: 'Скачать CSV',
          period: 'Период продаж',
          empty_table: 'Здесь будут отображаться платежи',
          table: {
            date: 'Дата',
            number: 'Номер',
            customer_name: 'Покупатель',
            customer_phone: 'Телефон',
            sum: 'Сумма'
          }
        },
        Orders: {
          total: 'Всего',
          header: 'Все заказы',
          download_button: 'Скачать XLS',
          feedback_button: 'Обратная связь',
          search_field: 'Имя клиента, почта, номер заказа',
          period: 'Период продаж',
          shop: 'Магазин',
          all_statuses: 'Все статусы',
          status: 'Статус',
          statuses: {
            new: 'Новый',
            waiting_for_payment: 'Ожидается платёж',
            paid: 'Ждёт отправки',
            refunding: 'Возврат средств',
            refunded: 'Возврат средств',
            repaid: 'Погашен',
            shipped: 'Доставлено',
            shipping: 'Отправлено',
            canceled: 'Отменён',
          },
          apply_button: 'Применить',
          QrModal: {
            header: 'Оплатить заказ',
            order_sum: 'Заказ на сумму',
            send_button: 'Отправить',
          },
          NewOrderModal: {
            trigger_button: 'Добавить заказ',
            header: 'Новый заказ',
            phone_label: 'Телефон',
            name_label: 'Наименование товара',
            price_label: 'Цена',
            delete_line: 'Удалить товар',
            add_line: 'Добавить еще один товар',
            skip_confirmation: 'Не требует подтверждения',
            modal_text: 'Для создания заказа введите номер телефона клиента, наименование и цену товаров.',
            create_order: 'Создать заказ',
            all: 'Всего',
            create_error: 'Произошла ошибка',
            amount_invalid: 'Этот способ оплаты доступен для заказов от {{min}} до {{max}}'
          },
          DetailsModal: {
            order_details: 'Детали заказа',
            rulla_order_number: 'Номер заказа на\u00a0Rulla:',
            store_order_number: 'Номер заказа в\u00a0магазине:',
            store_subdivision: 'Магазин:',
            order_date: 'Дата и время покупки:',
            order_total: 'Сумма всего:',
            customer_contacts: 'Контактные данные покупателя',
            surname: 'Фамилия:',
            name: 'Имя:',
            patronymic: 'Отчество:',
            phone: 'Телефон:',
            email: 'E-mail:'
          }
        },
        Offline: {
          Header: 'Оформить заказ',
          PhoneNumber: 'Номер телефона',
          BankAccount: 'Рассчётный счёт',
          NoAccount: '- Не выбран -',
          Send: 'Отправить',
          Sent: 'Отправлено'
        },
        BankAccounts: {
          Header: 'Счета для выплат',
          AddLegalEntity: 'Новое юр.лицо',
          AddBankAccount: 'Добавить счёт',
          SortCode: 'МФО',
          LegalEntityModal: {
            Header: 'Новое юр.лицо',
            Name: 'Название',
            Code: 'ЕГРПОУ',
            Close: 'Закрыть',
            Save: 'Добавить юр.лицо'
          },
          BankAccountModal: {
            Header: 'Новый счёт',
            AccountNumber: 'Номер счёта',
            BankName: 'Банк',
            BankNamePlaceholder: 'ПриватБанк',
            Currency: 'Валюта',
            SortCode: 'МФО',
            Close: 'Закрыть',
            Save: 'Добавить счёт'
          }
        },
        Main: {
          header: 'Главная',
          roll_up: 'Свернуть',
          watch_all: 'Смотреть все',
          orders: 'Заказы',
          sales_volume: 'Объем продаж,',
          average_check: 'Средний чек,',
          statistic: 'Статистика',
          dropdown: {
            1: 'Продажи сегодня',
            2: 'Продажи за неделю',
            3: 'Продажи за месяц',
            4: 'Продажи за квартал'
          },
          sorters: {
            1: 'По дате',
            2: 'По сумме',
            3: 'По магазину'
          }
        },
        Profile: {
          heading: 'Профиль',
          contact_data: 'Контактные данные',
          logout: 'Выйти',
          phone: 'Телефон',
          email: 'Почта',
          add_phone: 'Добавить новый номер',
          add_email: 'Добавить новую почту',
          confirm: 'Подтвердить',
          not_confirmed: 'Не подтверждён',
          change: 'Изменить',
          verify_phone: {
            enter_code: 'Введите код из СМС',
            send_on: 'Отправлено на',
            send_one_more: 'Отправить код еще раз',
            wait: 'Ожидайте',
            seconds: 'секунд',
            send_code: 'Отправить код',
            back: 'Назад',
            next: 'Далее',
            your_number: 'Ваш номер телефона',
            data_speed: 'Могут применяться скорости передачи сообщений и данных',
            on_what_number: 'Нам нужно убедиться, что это – вы. Сообщите нам, на какой номер отправить код.',
            phone: 'Телефон'
          },
          verify_email: {
            check_email: 'Проверьте почту',
            on_your_mail1: 'На вашу почту',
            on_your_mail2: 'отправлена ссылки для подтверждения. Если не можете найти письмо, проверьте папку “Спам”',
            send_one_more: 'Отправить еще раз',
            personal_cabinet: 'Личный кабинет',
            your_email: 'Ваша почта',
            send_link: 'Отправить ссылку',
            back: 'Назад',
            email: 'Почта',
            what_you_can: 'На нее вы сможете получать оповещения, а также новости и акции от наших партнеров',
          },
        },
      },
      Profile: {
        Main: {
          heading: 'Главная',
          pay: 'Оплатить',
          delivery: 'Доставка',
          purchases_history: 'История покупок',
          no_purchases: 'Вы еще не совершали покупок',
          goods: 'Товары',
          total_amount: 'Общая сумма',
          order_number: 'Номер заказа'
        },
        Favorites: {
          heading: 'Избранное',
          search: 'Товар или магазин',
          stores: 'Магазины',
          deliveries: 'Доставки',
          empty_stores: 'Мы не нашли ни одного магазина',
          start_shopping: 'Начните шопинг и добавьте любимые магазины в список',
          empty_deliveries: 'Нет текущих доставок',
          check_delivery_here: 'Если у вас есть покупка в процессе доставкой, вы сможете отследить ее здесь.',
        },
        Orders: {
          all: 'Всего: ',
          paid: 'Оплачено: ',
          nextPayment: 'Следующая оплата: ',
          status: 'Статус',
          statuses: {
            new: 'Новый',
            waiting_for_payment: 'Ожидается платёж',
            paid: 'Оплачен',
            refunding: 'Возврат средств',
            refunded: 'Возврат средств',
            repaid: 'Погашен',
            shipped: 'Доставлено',
            canceled: 'Отменён',
          },
          description: 'Описание',
          order_number: 'Номер заказа',
          date: 'Дата',
          cost: 'Стоимость',
          payments: 'Платежи',
          store: 'Магазин',
          payments_heading: 'Оплаты',
          products_heading: 'Товары'
        },
        Purchases: {
          heading: 'Покупки',
          overview: 'Обзор',
          all_purchases: 'Все покупки',
          balance: 'Баланс',
          expenses_by_month: 'Расходы по месяцам:',
          charts_statistics: 'Здесь будет статистика расходов с графиками',
          spent: 'Потрачено',
          dont_have_purchases: 'У вас нет покупок.',
          here_expenses: 'Когда вы их совершите, здесь появятся расходы по категориям товара',
          spent_by_period: 'Потрачено за ',
          expenses_by_period: 'Покупок за ',
          expenses_by_store: 'Расходы по магазинам:',
          current_purchases: 'Текущие покупки',
          all_empty: {
            header: 'Вы еще не совершали покупки с Rulla',
            text1: 'Нет зарегистрированных покупок, связанных с ',
            text2: ' Проверьте, не использовали ли вы другой адрес электронной почты',
          },
          balance_empty: {
            header: 'Текущие покупки',
            text: 'Здесь будут отображаться неоплаченные товары'
          }
        },
        Payments: {
          header: 'Оплаты',
          relax: 'Устройтесь поудобнее и расслабьтесь',
          find_it_here: 'Если вам нужно что-то сделать, вы найдете это здесь.',
          all: 'Всего:',
          pay: 'Оплатить',
          close: 'Закрыть',
          paid: 'Оплачено',
          not_paid: 'Не оплачено',
          payment_schedule: 'График оплат',
          payment_history: 'История оплат',
          number: 'Номер:',
          sum: 'Сумма',
          indebtedness: 'Задолженность:',
          fines: 'Штрафы:',
          total_paid: 'Всего оплачено:',
          total_to_pay: 'Всего к оплате:',
          pay_header: 'Оплаты',
          next_payment: 'Следующий платеж:',
          since: 'с',
        },
        Personal: {
          contact_data: 'Контактные данные',
          personal_data: 'Персональные данные',
          passport_data: 'Паспортные данные',
          address_data: 'Адрес',
          surname: 'Фамилия',
          patronymic: 'Отчество',
          name: 'Имя',
          birth: 'Дата рождения',
          taxNumber: 'ИНН',
          passport: 'Паспорт',
          id_card: 'ID карта',
          series: 'Серия',
          number: 'Номер',
          passport_date: 'Дата выдачи',
          passport_issued_by: 'Кем выдан',
          biometric_passport_issued_by: 'Орган который выдал',
          registration_address: 'Адрес регистрации',
          addresses_same: 'Адрес регистрации и проживания совпадают',
          living_adress: 'Адрес проживания',
          save: 'Сохранить',
          saved: 'Данные сохранены',
          edit_contacts: 'Редактировать контакты'
        },
        Settings: {
          heading: 'Настройки',
          main: 'Основные',
          contact_data: 'Контактные данные',
          subscribe_on_news: 'Подписка на новости',
          payment: 'Оплата',
          payment_methods: 'Способы оплаты',
          upload_photo: 'Загрузить фото',
          logout: 'Выйти',
          phone: 'Телефон',
          email: 'Почта',
          add_phone: 'Добавить новый номер',
          main_phone: 'Главный',
          select_as_main: 'Выбрать главным',
          legal_entities: 'Контакты отдельных юр.лиц',
          delete: 'Удалить',
          confirm: 'Подтвердить',
          not_confirmed: 'Не подтверждён',
          change: 'Изменить',
          enter_sms_code: 'Введите код из СМС',
          send_on: 'Отправлено на',
          send_one_more: 'Отправить код еще раз',
          wait: 'Ожидайте',
          seconds: 'секунд',
          send_code: 'Отправить код',
          back: 'Назад',
          next: 'Далее',
          your_number: 'Ваш номер телефона',
          make_sure_thats_you: 'Нам нужно убедиться, что это – вы. Сообщите нам, на какой номер отправить код.',
          speeds_on_sms: 'Могут применяться скорости передачи сообщений и данных',
          your_email: 'Ваша почта',
          on_it_notifications: 'На нее вы сможете получать оповещения, а также новости и акции от наших партнеров',
          send_link: 'Отправить ссылку',

          personal_cabinet: 'Личный кабинет',
          send_one_more_time: 'Отправить еще раз',
          just_sent: 'E-mail был только что отправлен',
          check_email: 'Проверьте почту',
          on_your_mail1: 'На вашу почту',
          on_your_mail2: 'отправлена ссылки для подтверждения. Если не можете найти письмо, проверьте папку “Спам”',
        },
        Modals: {
          refund: {
            name: 'Возврат',
            header: 'Возврат товара',
            text: 'Для возврата необходимо будет вернуть деньги, оплаченные за данную покупку',
            button: 'Вернуть'
          },
          cancel: {
            name: 'Отмена',
            header: 'Отмена покупки',
            text: 'Для отмены покупки необходимо будет вернуть деньги, оплаченные за данную покупку',
            button: 'Отменить покупку',
          },
          confirm: {
            name: 'Подтверждение',
            link: 'Подтвердить',
            header: 'Подтверждение заказа',
            text: 'Вы подтверждаете наличие товара в заказе и покупатель может перейти к оплате заказа',
            button: 'Подтвердить',
          },
          status: {
            name: 'Статус',
            header: 'Статус доставки',
            text: 'Выберите статус доставки:',
            button: 'Подтвердить'
          },
          pay: {
            name: 'Оплатить'
          },
          cancel_button: 'Закрыть'
        }
      }
    },
    Press: {
      header: 'Rulla для прессы',
      subheader: 'Здесь вы найдете пресс-релизы Rulla ' +
        'и сопутствующие материалы, такие как ' +
        'информация о компании и библиотека изображений.',
      publications: {
        header: 'Rulla в публикациях',
        links: [
          {
            title: 'Покупай сейчас — плати позже. Гибкий способ оплаты покупок в онлайн-магазинах от Rulla',
            url: 'https://news.finance.ua/ru/news/-/493201/pokupaj-sejchas-plati-pozzhe-gibkij-sposob-oplaty-pokupok-v-onlajn-magazinah-ot-rulla'
          },
          {
            title: 'Платежный сервис Rulla: как оплата частями увеличивает выручку интернет-магазина',
            url: 'https://minfin.com.ua/2021/05/19/65043346/'
          },
          {
            title: 'Как бесплатная рассрочка от Rulla увеличивает продажи Stylus.ua',
            url: 'https://www.epravda.com.ua/rus/news/2021/10/12/678625/'
          },
          {
            title: 'Сервис для продажи в рассрочку в интернете, для которого не нужен сайт. Рассказываем детали о Rulla ',
            url: 'https://itc.ua/partner-news/servis-dlya-prodaji-v-rassrochku-v-internete-dlya-kotorogo-ne-nujen-sayt-rasskazyvaem-o-rulla/'
          },
          {
            title: 'Как сервис оплаты покупок частями Rulla развивает в Украине глобальный тренд e-commerce',
            url: 'https://ain.ua/2021/05/05/kak-servis-oplaty-pokupok-chastyami-rulla-razvivaet-v-ukraine-globalnyj-trend-e-commerce/'
          },
          {
            title: 'Сервис оплаты покупок частями без процентов от Rulla: как это работает',
            url: 'https://biz.liga.net/all/all/novosti/servis-oplaty-pokupok-chastyami-bez-protsentov-ot-rulla-kak-eto-rabotaet'
          },
          {
            title: 'Сервис для выгодного онлайн-шопинга Rulla — теперь в Украине',
            url: 'https://rau.ua/ru/news/servis-onlajn-shopingu-rulla/'
          },
          {
            title: 'В Украине запускается Rulla — платежный сервис для выгодного онлайн-шопинга — ain.ua',
            url: 'https://ain.ua/2021/03/04/v-ukraine-zapuskaetsya-rulla-platezhnyj-servis-dlya-vygodnogo-onlajn-shopinga/'
          }
        ]
      },
      releases: {
        header: 'Последние пресс-релизы',
        links: [
          {
            title: 'Как бесплатная рассрочка от Rulla увеличивает продажи Stylus.ua',
            url: 'https://rulla.com/ru/blog/kak-besplatnaja-rassrochka-ot-rulla-uvelichivaet-prodazhi-stylusua'
          },
          {
            title: 'Выгоднее, чем эквайринг: новый универсальный платежный сервис Rulla',
            url: 'https://rulla.com/ru/blog/oplata-cherez-rulla-vygodneje-dlia-magazinov-chem-ekvajring-detali'
          },
          {
            title: 'Cервис для выгодного онлайн-шопинга Rulla — теперь в Украине',
            url: 'https://rulla.com/ru/blog/servis-dlia-vygodnogo-onlajn-shopinga-rulla-teper-v-ukraine'
          }
        ]
      },
      show_more: 'Показать больше',
      image_library: 'Библиотека изображений',
      download: 'Скачать',
      company_info: 'Информация о компании',
      download_presentation: 'Скачать презентацию',
      text1: 'Rulla является частью международного холдинга, ' +
        'занятого разработкой финтех-продуктов для европейского рынка. ' +
        'Технологичное платежное решение от Rulla создано, ' +
        'чтобы сделать покупки в интернете проще и доступнее. ',
      text2: 'Наша миссия — дать всем желающим возможность продавать и покупать ' +
        'онлайн максимально удобно, безопасно и выгодно. ',
      text3: 'Гибкий платежный сервис Rulla — это новый для Украины финансовый инструмент, ' +
        'работающий по модели “Покупай сейчас — плати позже” (Buy Now Pay Later). Сейчас Rulla ' +
        'предлагает клиентам онлайн-магазинов оплату частями. Пользователи сервиса получают ' +
        'возможность оплатить покупку тремя платежами, которые автоматически списываются с ' +
        'карты каждые 30 дней без дополнительных процентов и комиссий.',
      text4: 'Rulla не является банковским учреждением. ' +
        'Процесс регистрации и верификации пользователей требует минимум времени и данных. ' +
        'Для рассмотрения заявок и автоматизации процессов применяются новейшие технологии в области Big Data.',
      media_requests: 'Запросы СМИ',
      request_here: 'Запросы направляйте на e-mail:',
      address_only_media: 'Обратите внимание, что этот контактный адрес предназначен ' +
        'только для запросов представителей СМИ, касающихся публикаций.',
      have_questions: 'Если у вас есть вопросы, связанные с покупками, обратитесь в',
      support: 'Службу поддержки покупателей.'
    }
  }
};
