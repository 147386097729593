import { AbstractControl, ValidationErrors } from "@angular/forms";

export class CustomValidators {
  static requiredNotWhitespace(control: AbstractControl): ValidationErrors {
    return !control.value || control.value.trim() == ''
      ? { 'required': true }
      : null;
  }

  static IBAN(control: AbstractControl): ValidationErrors {
    return /^UA\d{27}$/.test(control.value) 
      ? null 
      : { ibanInvalid: true };
  }
}