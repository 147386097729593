import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AreaSignInData, AreaSignInResult, OtpSignInData, OtpSignInResult, TokenResult } from 'src/app/core/models/auth.model';

@Injectable({ providedIn: 'root' })
export class CustomerAuthService {
  constructor(private http: HttpClient) { }

  signIn(data: AreaSignInData) {
    return this.http.post<CustomerAreaSignInResult>('api/v1/customer/auth/sign-in', data);
  }

  verifyCode(data: OtpSignInData) {
    return this.http.post<CustomerOtpSignInResult>('api/v1/customer/auth/verify-pin', data);
  }

  refreshToken() {
    return this.http.post<TokenResult>('api/v1/customer/auth/token/refresh', null);
  }
}

export interface CustomerAreaSignInResult extends AreaSignInResult {
  hasUnpaidOrders: boolean;
}

export interface CustomerOtpSignInResult extends OtpSignInResult {
  hasUnpaidOrders: boolean;
}
