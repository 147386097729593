export const environment = {
  production: true,
  apiUrl: "https://testapi.rulla.com/",
  widgetUrl: "https://testwidget.rulla.com/",
  baseUrl: "https://test.rulla.com/",
  i18n: {
    countryCode: '380',
    language: 'ua',
    languages: ['ua', 'ru', 'pl', 'es']
  },
  locations: {
    "test.rulla.com": {
      language: "ua",
      languages: ['ua', 'ru', 'pl', 'es']
    }
  },
  telegram: {
    bot: 'RullaTestBot'
  },
  socialMedia: {
    facebookAppId: 319601956431758
  }
};
