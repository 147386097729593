export const locale = {
  lang: 'ua',
  data: {
    PIN: {
      error: {
        incomplete: 'Помилка: введіть код повністю',
        invalid: 'Помилка: невірний код'
      }
    },
    Common: {
      title: 'Rulla – Онлайн-розстрочка для всіх',
      countryCode: '380',
      uah: 'грн',
      pln: 'zł',
      eur: 'євро',
      items: 'шт.'
    },
    Errors: {
      server: 'Сталася помилка при обробці запиту, будь ласка, зверніться в техпідтримку, або спробуйте повторити запит пізніше'
    },
    Validation:{
      required: 'Це поле є обов\'язковим',
      format_error: 'Невірний формат',
      server_busy: 'Сервер завантажений, спробуйте пізніше',
      iban_invalid: 'Правильний формат IBAN: "UA" та 27 цифр',
    },
    Cookies:{
      consent_text: 'Ми використовуємо файли cookie для вдосконалення веб-сайту, порівняння аудиторій, підвищення якості надання рекламних оголошень на основі вашої активності, переглядів та інтересів на наших та сторонніх сайтах. Продовжуючи роботу з сайтом, ви погоджуєтесь з використанням файлів cookie.',
      agree: 'Прийняти',
      more_details: 'Детальніше'
    },
    Statuses: {
      1: 'Нове',
      3: 'Очікує на підтвердження',
      4: 'Очікується платіж',
      5: 'Чекає відправки',
      6: 'Повернення коштів',
      7: 'Кошти повернуті',
      8: 'Виплачено',
      9: 'Відправлено',
      10: 'Доставлено',
      11: 'Скасовано'
    },
    SnackBar: {
      copied: 'Скопійовано',
    },
    Pending: {
      data_processing: 'Обробка даних',
      loading: 'Йде завантаження. Чекайте ...'
    },
    Header: {
      rulla_for_clients: 'Rulla для клієнтів',
      rulla_for_business: 'Rulla для бізнесу',
      registration: 'Реєстрація',
      log_in: 'Увійти',
    },
    HeaderLoginMenu: {
      personal_cabinet_business: 'Для магазинів',
      personal_cabinet_business_description: 'Увійдіть в систему, щоб керувати' +
        ' своїми замовленнями, звітами про виплати, статистикою магазину ' +
        'і загальними настройками.',
      personal_cabinet_customer: 'Для покупців',
      personal_cabinet_customer_description: 'Увійдіть в систему, щоб ' +
        'отримувати інформацію про свої покупки й майбутні платежі та легко' +
        ' розраховуватися по них одним клацанням миші.'
    },
    HeaderBusinessMenu: {
      sell_with_rulla: 'Продавати з Rulla',
      support_seller: 'Підтримка продавця',
      three_payments: 'Оплата в 3 платежі'
    },
    HeaderClientMenu: {
      pay_later: 'Сплачуй пізніше з Rulla',
      about_company: 'Про компанію',
      customer_service: 'Обслуговування клієнтів',
      blog: 'Блог',
      rulla_shops: 'Rulla магазини',
      categories: 'Категорії',
      watch_all: 'Дивитись все'
    },
    NotFound: {
      heading: 'Здається, щось пішло не так!',
      text1: 'Сторінка, яку ви запитуєте, не існує. Можливо, ' +
        'вона була видалена, або ви набрали неправильну адресу. ' +
        'Перейдіть на нашу ',
      text2: ' і спробуйте знайти необхідну вам інформацію там.',
      main_page: 'головну сторінку',
    },
    OrderConfirmed: {
      heading: 'Замовлення підтверджено',
      confirmed: 'Тепер покупець може перейти до сплати замовлення',
      thanks: 'Дякуємо, що скористалися сервісом Rulla.',
    },
    BankID: {
      heading: 'Bank ID',
      success: 'Перевірку BankID виконано успішно',
      failure: 'Щось пішло не так...'
    },
    Diia: {
      successHeading: 'Успіх!',
      success: 'Перевірку даних через систему електронної ідентифікації Дія виконано успішно.',
      action: 'Ви можете повернутись до\u00a0сторінки с\u00a0замовленням, щоб продовжити оплату',
      failureHeading: 'Щось пішло не так...',
      failure: 'Під час перевірки даних через систему електронної ідентифікації Дія сталася помилка'
    },
    Footer: {
      legal: 'Юридична інформація',
      all_ukraine: 'Працюємо по всій Україні',
      contact_us: 'Зв\'яжіться з нами',
      about_us: 'Про нас',
      about_company: 'Про компанію',
      shops: 'Магазини',
      pay_later: 'Сплачуй пізніше з Rulla',
      blog: 'Блог',
      for_business: 'Для бізнесу',
      sell_with_rulla: 'Продавати з Rulla',
      for_clients: 'Для клієнтів',
      seller_support: 'Підтримка продавця',
      customer_service: 'Обслуговування клієнтів',
      terms_and_conditions: 'Правила та умови',
      press: 'Для преси',
      financial_services: 'Інформація про договір про надання фінансових послуг'
    },
    CustomerService: {
      header: {
        support: 'Підтримка',
        text: 'Ми даруємо задоволення від покупок, на яке ви заслуговуєте'
      },
      categories: {
        heading: 'Категорії',
        first: {
          title: 'Доставка і Повернення',
          subtitle: 'Повернення | Доставка | Анулювання',
          text: 'Термін доставки поштовими операторами, як правило, становить від 1 до 3-х днів. ' +
            'Ви можете відстежувати Ваші замовлення в особистому кабінеті в розділі «Історія замовлень». ' +
            'Протягом 14 днів з дня придбання ви маєте право на повернення товару без пояснення причин, за ' +
            'умови його належного стану. Повернення товару регулюється між магазином і клієнтом. Якщо ' +
            'магазин підтверджує повернення товару — розстрочка анулюється автоматично, клієнту ' +
            'повертається оплата першого платежу.'
        },
        second: {
          title: 'Платежі',
          subtitle: 'Здійснювати платежі та керувати ними | Відстрочка платежу',
          text: 'Перший платіж сплачується клієнтом при оформленні покупки, подальші оплати списуються з ' +
            'картки автоматично. Клієнт має право змінити процес оплати та здійснювати платежі самостійно. ' +
            'Для зміни необхідно перейти в розділ «Профіль» особистого кабінету. В особистому кабінеті ви ' +
            'також знайдете історію всіх ваших покупок і платежів, терміни погашення і витрати на покупки, ' +
            'зроблені через Rulla.'
        },
        third: {
          title: 'Продукти та Послуги',
          subtitle: 'Як використовувати Rulla | Варіанти оплати',
          text: 'Rulla — це платіжний інструмент, який дозволяє оплачувати товар кількома частинами, щоб ' +
            'позбавити клієнтів від навантаження на бюджет. Rulla пропонує оплату в 3 платежі, з яких перший ' +
            'списується при оформленні, наступні — через кожні 30 днів. Клієнт може оформити необмежену ' +
            'кількість оплат з Rulla, щоб розподілити свої витрати протягом місяця.'
        },
        forth: {
          title: 'Обліковий запис і особисті дані',
          subtitle: 'Керування платіжними реквізитами | Керування контактними даними',
          text: 'Під час першої оплати через Rulla клієнту формується особистий кабінет користувача, в якому ' +
            'знаходиться інформація про всі оформлені розстрочки, інформація про магазини і терміни ' +
            'платежів. Також у кабінеті можна змінити свої особисті дані: контактний телефон, пошту або ' +
            'номер платіжної картки.'
        }
      }
    },
    PayLater: {
      header: {
        h1: 'Купуй зараз — сплачуй пізніше',
        subheader: 'Обирайте оплату Rulla в улюблених магазинах',
        start_button: 'Почати',
        more_button: 'докладніше'
      },
      subheader: 'Rulla — це найбезпечніший спосіб отримати те, що вам потрібно, ' +
        'зараз і сплатити пізніше. Розділіть оплату на щомісячні платежі. ' +
        'Дозвольте собі більше.',
      more: {
        heading: 'Хочете більше Rulla в своєму житті?',
        item1: {
          p: 'Каталог магазинів',
          h2: 'Знайдіть тисячі українських магазинів, де можна #rulla',
          button: 'Знайти роздрібних продавців'
        },
        item2: {
          p: 'Приєднуйтесь до спільноти',
          h2: 'Слідкуйте за нами в інстаграм; @rullacom',
          button: 'Приєднуйтесь до спільноти'
        }
      },
      widget: {
        header: 'Почати легко. Спробуйте!',
        subheader: 'Користуватися Rulla так само просто, ' +
          'як сплачувати за покупки карткою. ' +
          'Зареєструйтеся та отримайте миттєву розстрочку.',
        step1: {
          header: 'Виберіть оплату через Rulla',
          text: 'Виберіть товари в улюбленому магазині. При оформленні замовлення виберіть Rulla як спосіб оплати.',
        },
        step2: {
          header: 'Підтвердіть номер телефону',
          text: 'Пройдіть верифікацію в сервісі Rulla за пару хвилин і зробіть покупку. Ніяких довгих форм.',
        },
        step3: {
          header: 'Погодьтеся з умовами',
          text: 'Ви миттєво отримаєте рішення про схвалення, і ваше замовлення буде доставлене в звичайному режимі.',
        },
      },


      how_works: {
        header: 'Як працює Rulla?',
        transparent_payment: {
          header: 'Прозорий та гнучкий спосіб оплати',
          subheader: 'Жодних переплат. Rulla — це відмінна альтернатива кредитам, яка допоможе вам знизити витрати й отримати все, що ви хочете.',
          item1: 'Розділіть вартість покупки на 3 безвідсоткових платежі, ' +
            'які списуються з вашої банківської картки щомісяця.',
          item2: 'Ви відразу знаєте точні суми платежів.',
          item3: 'Жодних додаткових комісій, якщо ви сплачуєте вчасно.'
        },
        modern_payment: {
          header: 'Зручний та сучасний платіжний інструмент',
          subheader: 'Оплата частинами дозволяє вам розподіляти витрати ' +
            'за часом так, що ви можете купувати те, що хочете, ' +
            'без шкоди для свого бюджету.',
          item1: 'Керуйте покупками та перевіряйте попередні замовлення в особистому кабінеті.',
          item2: 'Платежі автоматично списуються кожні 30 днів.'
        }
      },
      buy_now: {
        header: 'Купуй зараз — сплачуй пізніше',
        subheader: 'Мільйони користувачів гнучких платіжних рішень у всьому світі ' +
          'вже оцінили їх переваги перед традиційними способами оплати.',
        interest_free_header: 'Чесна безвідсоткова розстрочка',
        interest_free_text: 'Розділіть вартість покупки на 3 платежі. Перший платіж' +
          ' здійснюється в момент покупки, а останні списуються ' +
          'з картки автоматично через кожні 30 днів. Без переплат, ' +
          'нічого зайвого. Просто більше часу на оплату того, що подобається.',
        legal_information: 'Правова інформація'
      },
      shops_categories: {
        shops_header: 'Магазини',
        shops_subheader: 'Найвигідніші пропозиції партнерів Rulla завжди у вас під рукою.',
        shops_button: 'до магазинів',
        categories_header: 'Категорії',
        categories_subheader: 'Знайдіть те, що вам потрібно, і сплатіть як зручно.',
        categories_button: 'дивитися все',
      },
      questions: {
        more_profitable_then_cards: {
          header: 'Чому Rulla вигідніша за кредитні картки?',
          text: 'Використовуючи кредитні картки, ви потрапляєте в замкнене коло.' +
            ' Більшість банків стягують по ним складні відсотки, а також різні види' +
            ' комісій та зборів за обслуговування. Таким чином, чим довше ви користуєтеся ' +
            'своїм балансом по кредитній картці, тим дорожчими стають ваші покупки. ' +
            'Замість цього Rulla пропонує гнучке платіжне рішення з прозорими ' +
            'умовами. Ми не беремо комісії та не стягуємо відсотки. Ви кожен раз ' +
            'приймаєте усвідомлене рішення про покупку й берете саме стільки,' +
            ' скільки потрібно. Ви завжди точно знаєте, скільки винні та коли закінчите сплачувати за покупку.'
        },
        different_from_bank: {
          header: 'Чим Rulla відрізняється від банківської розстрочки?',
          text: 'Всі банківські продукти, включаючи розстрочку, існують завдяки різноманітним ' +
            'комісіям. Це можуть бути штрафи за прострочення платежу, комісії за дострокове ' +
            'погашення, регулярні платежі за відкриття або закриття вашого рахунку, за переказ ' +
            'або зняття коштів і так далі. Залежно від вартості товару, тривалості розстрочки ' +
            'й умов магазину, в якому ви робите покупку, ваш план сплати може містити також відсотки. ' +
            'З Rulla ви ніколи не будете винні більше, ніж наперед ' +
            'згодні заплатити. Вартість покупки ділиться на 3 рівних платежі,' +
            ' перший з яких сплачується відразу, а решта автоматично списуються ' +
            'через 30 і 60 днів. Якщо ці платежі вносяться на картку в строк, ваша переплата дорівнює нулю.'
        },
        checks_my_solvency: {
          header: 'Як Rulla перевіряє мою платоспроможність?',
          text: 'Ми хочемо бути впевнені, що допомагаємо нашим клієнтам приймати правильні фінансові ' +
            'рішення з урахуванням їхніх життєвих обставин. Через це ми збираємо та обробляємо інформацію ' +
            'про користувачів сервісу Rulla, щоб переконатися, що в них не виникнуть труднощі з ' +
            'оплатою покупок частинами. ' +
            'Коли ви в перший раз обираєте в інтернет-магазині оплату трьома безвідсотковими платежами ' +
            'від Rulla, ми проводимо оцінку платоспроможності за допомогою технологій, заснованих на Big' +
            ' Data. Верифікація в сервісі Rulla не впливає на ваш кредитний рейтинг. ' +
            'Здійснити покупку за допомогою Rulla легко, якщо ви старші за 18 років, і у вас є ' +
            'постійне джерело доходу.'
        }
      }
    },
    Shops: {
      header: 'Магазини + Rulla',
      subheader: 'Оплата частинами від Rulla — це 3 щомісячні безпроцентні платежі. ' +
        'Гнучке платіжне рішення для вашого бюджету. Тепер в Україні.',
      categories: 'Категорії',
      favorite_shops: 'Вибрані магазини',
      sales_text: '* Знижки дійсні тільки протягом обмеженого періоду часу ' +
        'і не контролюються Rulla. Rulla може отримати комісію.',
      show_more: 'Показати більше',
      filter_items: {
        search: 'Пошук',
        all: 'Усе',
        health: 'Здоров\'я',
        cloth_shoes: 'Одяг та взуття',
        beauty: 'Краса',
        house: 'Дім',
        hobbies: 'Захоплення',
        car: 'Авто'
      },
    },
    SubFooterBusiness: {
      text: 'У вас є бізнес і ви хочете продавати з RULLA?',
      button: 'Rulla для бізнесу',
    },
    InfoTiles: {
      tiles: {
        header: 'Зв\'яжіться з нами',
        help_questions: 'Ми допоможемо вам з будь-якими питаннями.',
        made_rulla_purchase: 'Ви здійснили покупку з використанням Rulla? Відвідайте сторінку нашої ',
        customer_service_page: 'Служби підтримки покупців.',
        when_will_pay: 'Коли я отримаю гроші за товари, оплачені за допомогою Rulla?',
        start_sell_rulla: 'Хочете продавати більше разом з Rulla?',
        start_right_now: 'Почніть просто зараз!',
      },
      lower_tiles: {
        header_hours: 'Час роботи',
        header_business: 'Служби підтримки Rulla для бізнесу',
        monday_friday: 'Понеділок - П\'ятниця',
        phone: 'Контактний телефон:',
        mail: 'Пошта:'
      },
    },
    Blog: {
      header: 'Блог',
      subtitle: 'Цей блог познайомить з новими трендами та ідеями,\nякі зроблять ваш шопінг бездоганним',
      newsCategories: 'Категорії новин',
      rullaNews: 'Новини від Rulla',
      allNews: 'Всі новини',
      loadMore: 'Завантажити більше',
      shareArticle: 'Поділитися статтею',
      readNext: 'Читати наступні статті',
      all: 'Всі',
      another_categories: 'Інші категорії блогу',
    },
    Home: {
      favorite_stores_header: 'Вибрані магазини',
      popular_stores_header: 'Популярні категорії',
      fashion_cloth_header: 'Мода та одяг',
      beauty_header: 'Краса',
      home_header: 'Дім',
      watch_all: 'Дивитись все',
      shops_partners: 'Магазини-партнери',
      shopping_pleasure: {
        header: 'Новий формат вигідного онлайн-шопінгу',
        subheader: 'Обирайте Rulla, щоб купувати легко. Платіть, як зручно вам.',
        button: 'Докладніше',
        no_overpayment: 'Покупки зараз, оплата потім',
        transparent_process: 'Прозорі умови',
        get_now: '100% без переплат',
      },
      beauty: {
        header: 'Створюй красу природним чином',
        subheader: 'Cпреї та сироватки для відновлення і живлення волосся. Косметика на натуральних мінералах',
        button: 'Докладніше',
      },
    },
    SellWithRulla: {
      popup: {
        header1: 'Заявка',
        header2: 'на співпрацю',
        website_adress: 'Адреса сайту-магазину',
        contact_person: 'Контактна особа',
        email: 'Email',
        phone: 'Телефон',
        button: 'Надіслати',
      },
      popup_thanks: {
        header: 'Дякуємо за подачу заявки',
        text: 'Ми зв\'яжемося з вами протягом 24 годин.',
      },
      header: {
        h1: 'Підключайте Rulla та отримуйте більше продажів',
        button_start: 'Почати Зараз',
        button_more: 'Дізнатися більше',
      },
      benefits: {
        header: 'Ніякого ризику. Тільки вигоди:',
        button_go_over: 'перейти',
        items: {
          more_customers: {
            header: 'Більше покупців',
            text: 'Розмістивши свій магазин на Rulla.com, ' +
              'ви безкоштовно залучаєте цільову аудиторію на свій сайт.'
          },
          coverage: {
            header: 'Підтримка продавця',
            text: 'Наша техпідтримка готова відповісти на будь-які ваші питання.'
          },
          guard_store: {
            header: 'Захист магазину',
            text: 'Ви нічим не ризикуєте. Rulla бере на себе перевірку платоспроможності клієнтів.'
          },
          guard_customer: {
            header: 'Захист покупця',
            text: 'Наш клієнтський сервіс оперативно вирішить проблеми ваших покупців.'
          },
          conditions: {
            header: 'Вигідні умови оплати',
            text: 'Зручне платіжне рішення без переплат підвищує довіру до вашого бізнесу.'
          },
          money: {
            header: 'Гроші вперед',
            text: 'Магазин отримує від Rulla повну вартість покупки відразу.'
          }

        }
      },
      integration: {
        header: 'Інтеграція Rulla у будь-який інтернет-магазин',
        text: 'Пряме підключення платіжного сервісу Rulla до ' +
          'платформ e-commerce по API. Скористайтеся інструкцією та ' +
          'документацією на сайті або зверніться до нас по допомогу.',
        button_apply: 'подати заявку',
        button_manual: 'інструкція',
      },
      start_now: {
        header: 'Почніть просто зараз',
        application_item: {
          header: 'Зв\'яжіться з нами',
          text: 'Підключення Rulla займає мінімум часу. Почніть продавати більше якомога швидше.'
        },
        widget_item: {
          header: 'Гнучка оплата',
          text: 'Запропонуйте вашим клієнтам прозору систему платежів, ' +
            'з якою вони зможуть дозволити собі більше. Без кредитів і переплат.'
        },
        orders_item: {
          header: 'Отримуйте замовлення',
          text: 'Ваші клієнти зможуть розділити вартість покупки на' +
            ' 3 платежі та сплатити як їм зручно. Ваш магазин отримає ' +
            'від Rulla всю суму відразу.'
        },
        charts_item: {
          header: 'Оптимізуйте бізнес',
          text: 'Вся аналітика з продажів з використанням Rulla ' +
            'доступна у вашому особистому кабінеті. Вивчайте дані ' +
            'та використовуйте їх для зростання.'
        },
      },
      how_it_works: {
        header: 'Як це працює',
        text: 'Rulla додається у ваш інтернет-магазин як спосіб оплати.' +
          ' Коли покупець вибирає оплату через Rulla, він отримує можливість ' +
          'розділити вартість покупки на 3 щомісячних платежі. Ваш магазин ' +
          'отримує всю суму відразу та відправляє товар клієнту. Для продавця ' +
          'це нічим не відрізняється від звичайного продажу. '
      },
      partnership: {
        text: 'Партнерство з Rulla — це найпростіший спосіб перетворити відвідувачів ' +
          'вашого онлайн-магазину в покупців. Ви отримуєте нових клієнтів, які купують ' +
          'у вас більше й частіше. Вам завжди платять повну вартість відразу, а' +
          ' ваші клієнти купують так, як їм зручно.',
        volume_increase: 'Підвищення обсягу продажів',
        conversion_increase: 'Зростання конверсії з відвідувача в покупця',
        check_increase: 'Підвищення середнього чека',
        upto: 'до'
      },
      statistics: {
        header: 'Світова статистика показує зростаючий тренд сервісів оплати частинами:',
        transactions: 'транзакцій у день',
        users: 'користувачів',
        countries: 'країн',
        mln: 'mln'
      },
      content: {
        header: 'Зручність покупців — у центрі уваги',
        subheader: 'Шопінг з Rulla зміцнює довіру покупців до вашого бренду. ' +
          'Дайте вашим клієнтам можливість сплачувати за покупки трьома щомісячними ' +
          'платежами без відсотків і прихованих комісій. Єдина чесна розстрочка: ' +
          'миттєво та без переплат. Зручно контролювати покупки й платежі в особистому ' +
          'кабінеті сервісу. Прозорі умови оплати частинами від Rulla — найкраща ' +
          'альтернатива кредитним карткам і позикам. Побудуйте свою бізнес-модель на ' +
          'чудовому користувальному досвіді та отримайте тисячі лояльних клієнтів.',
        text1: 'Сервісам оплати онлайн-покупок частинами віддають перевагу люди у віці 20-35 років.' +
          ' Додайте свій магазин на Rulla, і нові клієнти самі знайдуть вас.',
        text2: 'Реєстрація в сервісі Rulla вперше займає пару хвилин. Миттєве рішення про ' +
          'розстрочку за номером телефону при оформленні покупки. Платежі списуються автоматично. ',
        text3: 'Зареєстровані користувачі Rulla здійснюють покупки у нових продавців з комфортом' +
          ' постійних клієнтів. Такі покупці не сумніваються. Вони витрачають більше та ' +
          'повертаються частіше.'
      },
      news: {
        header: 'Кейси та новини в блозі Rulla',
        subheader: 'Покупці платять як їм зручно. Продавці отримують більше щасливих клієнтів. Rulla подбає про все.',
        button_further: 'Читати далі',
        blog: 'Блог Rulla',
      }
    },
    AboutUs: {
      header: 'Про нас',
      subheader:
        'Ми створили сервіс оплати частинами Rulla\n' +
        'як зручну альтернативу кредитним карткам та позикам,\n' +
        'які змушують вас переплачувати.\n' +
        'Rulla дає більше часу на оплату покупок онлайн,\n' +
        'без прихованих комісій.',
      content_center: {
        header: 'Хто ми?',
        text1: 'Технологічне платіжне рішення Rulla є новою розробкою досвідчених творців фінтех-продуктів для європейських ринків. Мета цього продукту — зробити покупки в інтернеті простіше та доступніше.',
        text2:
          'Наша місія — дати всім бажаючим можливість продавати\n' +
          'та купувати онлайн максимально зручно, безпечно і вигідно. ',
        text3: 'Гнучкий платіжний сервіс Rulla — це новий для України фінансовий інструмент, який працює за моделлю "Купуй зараз — плати пізніше" (Buy Now Pay Later). Зараз Rulla пропонує клієнтам онлайн-магазинів оплату частинами. Користувачі сервісу отримують можливість сплатити за покупку трьома платежами, які автоматично списуються з картки кожні 30 днів без додаткових відсотків і комісій.',
        textBank: 'ТОВ "РУЛЛА УКРАИНА" не є банківською установою. Процес реєстрації та верифікації користувачів вимагає мінімум часу і даних. Для розгляду заявок та автоматизації процесів застосовуються новітні технології в галузі Big Data.',
      },
      contacts: {
        header: 'Контактна інформація:',
        hot_line: '(гаряча\u00a0лінія)',
        sales_department: '(відділ\u00a0продажу)',
        project_manager: '(керівник\u00a0проекту)',
        address:
          'ТОВ «РУЛЛА УКРАЇНА»\n' +
          'ЄДРПОУ 43213857\n' +
          'Україна, 69035, Запорізька обл., м. Запоріжжя,\n' +
          'вул. Феросплавна, будинок 38, кв. 31',
        email: 'sales@rulla.com',
        phone: '+38 066 885 4373',
        phoneNo: '+380668854373'
      }
    },
    EmailConfirm: {
      header: 'Пiдтвердження e-mail',
      content: {
        in_process: 'Перевіряємо e-mail...',
        success: 'E-mail успiшно пiдтверджено',
        failure: 'Щось пішло не так'
      }
    },
    TokenRedirect:{
      content:"Входимо до особистого кабiнету..."
    },
    SupportSeller: {
      header: {
        h1: 'Вітаємо! Як ми можемо вам допомогти?',
        p: 'Тут ви знайдете відповіді на питання про продукти Rulla, про те, як почати роботу ' +
          'і як продавати з Rulla. Зв\'яжіться з нами, якщо вам знадобиться допомога.'
      },
      categories: {
        contact_us: 'Зв\'язатися з нами',
        common: {
          become_partner: {
            header: 'Як стати партнером Rulla?',
            text: 'Щоб почати роботу з Rulla, потрібно заповнити просту форму заявки. ' +
              'Ми зв\'яжемося з вами протягом 24 годин після цього.'
          },
          term_of_action: {
            header: 'Чи є в угоди термін дії?',
            text: 'Термін дії угоди — 12 місяців. ' +
              'Договір буде автоматично продовжуватися за місяць до кінця поточного періоду дії. ' +
              'Rulla залишає за собою право розірвати цю угоду в будь-який час.'
          },
          integration: {
            header: 'Як інтегрувати віджет Rulla в інтернет-магазин?',
            text: 'Після ',
            link: 'реєстрації бізнес-аккаунта',
            text1: ' перейдіть в розділ «Інтеграція» в вашому особистому кабінеті, ' +
              'щоб отримати свої облікові дані Rulla API. Їх потрібно додати на свою e-commerce платформу, ' +
              'щоб почати реєструвати покупки.',
            step1: 'КРОК 1.',
            generate_token: 'Згенеруйте унікальний JWT token. Збережіть ваш токен у надійний спосіб.',
            step2: 'КРОК 2.',
            send_request: 'Надішліть запит на створення сесії віджета за адресою:',
            authorization_header1: 'У заголовку Authorization необхідно вказати значення Bearer TOKEN, де замість TOKEN ',
            authorization_header2: 'використати згенерований токен.  ',
            request_body_example: 'Приклад тіла запиту',
            detailed_information: 'Детальну інформацію про запит можна знайти на сторінці API:',
            success_request: 'У разі успішного запиту відповідь буде містити ідентифікатор створеної сесії:',
            step3: 'КРОК 3.',
            add_widget_on_page: 'Додайте віджет на сторінку, вказавши src, який містить отриманий ідентифікатор.',
            problems_with_integration: 'Якщо у вас виникли труднощі з інтеграцією, зв\'яжіться зі Службою підтримки Rulla для бізнесу. ',
            code: `{
  "country": "UA",
  "locale": "ua",
  "client": {
    "phone": "380995550000",
    "email": "customer@rulla.com",
    "surname": "Іванов",
    "name": "Іван",
    "patronymic": "Іванович",
    "inn": "52012546124",
    "livingAddress": "Київ, вул. Шота Руставелі, 30",
    "deliveryAddress": ""
  },
  "order": {
    "id": "2021-06-08-032992",
    "orderLines": [
      {
        "id": "21312312312",
        "name": "NVIDIA GEFORCE RTX 2080 Ti",
        "quantity": 2,
        "unitPrice": 3000.00,
        "imageUrl": "https://via.placeholder.com/350x150",
        "productUrl": "https://example.com"
      }
    ],
    "currency": "UAH",
    "total": 6000.00
  }
}`,
          },
          changed_account: {
            header: 'Я змінив розрахунковий рахунок організації. Що мені потрібно зробити?',
            text: 'Ви можете керувати платіжними реквізитами у розділі "Рахунки". Якщо у вас виникли труднощі, зв’яжіться з нашою Службою підтримки Rulla для бізнесу.'
          }
        },
        seller_buyer: {
          header: 'Продавець-Покупець',
          questions: {
            0 : {
              header: 'Коли я отримаю гроші за товари, оплачені за допомогою Rulla?',
              text: 'Час, протягом якого Rulla перераховує продавцеві гроші за' +
                ' товари, оплачені через сервіс, зазначено в договорі. ' +
                'Стандартний час надходження коштів від Rulla на ' +
                'рахунок магазину — від 1 до 3 днів. ' +
                'Якщо у вас є питання, що стосуються платежів,' +
                ' ознайомтеся з вашим договором або зверніться в нашу ',
              link: 'Службу підтримки продавця.'
            },
            1 : {
              header: 'Коли клієнтові повернуть гроші після оформлення повернення?',
              text: 'Rulla опрацює повернення коштів після того, як ви зареєструєте повернення або ' +
                'скасуєте замовлення. Повернення коштів покупцеві зазвичай займає у Rulla від ' +
                '1 до 3 робочих днів. Щойно продавець реєструє повернення, вартість товару буде' +
                ' вирахувана із загальної суми боргу клієнта. У разі повернення або скасування ' +
                'всього замовлення повністю, перший платіж буде повернено клієнтові, а решту анульовано.'
            },
            2 : {
              header: 'Якою валютою можна розрахуватися через Rulla?',
              text: 'Rulla провадить усі розрахунки в українській гривні (₴). Якщо валюта' +
                ' платіжної картки покупця відрізняється, то платежі будуть стягуватися' +
                ' в еквіваленті суми в гривнях за курсом на поточну дату.'
            },
            3 : {
              header: 'Покупець цікавиться, скільки ще потрібно сплатити. Що відповісти?',
              text: 'Повідомте клієнту, що він може знайти всю інформацію щодо платежів у ' +
                'своєму особистому кабінеті на Rulla.com. Він також може зв’язатися з ',
              link: 'Клієнтським сервісом',
              text1: ' Rulla для отримання додаткової інформації.'
            },
            4 : {
              header: 'Що робити, якщо клієнт оскаржує свої платежі?',
              text: 'Якщо клієнт оскаржує свої платежі, спрямуйте ' +
                'його до ',
              link: 'Клієнтського сервісу',
              text1: ' Rulla, щоби наші фахівці могли вирішити його проблему.',
            },
            5 : {
              header: 'Що відбувається, коли покупець повертає товар?',
              text: 'Якщо покупець повертає покупку, оплачену за допомогою Rulla, ' +
                'він повинен зробити це у встановлений законом України 14-денний ' +
                'термін. Після того, як продавець зареєстрував повернення, Rulla' +
                ' повертає платіж, уже отриманий за товар, назад на картку покупця.' +
                ' Завжди просіть клієнтів зберігати квитанції про отримання та ' +
                'повернення покупок для вирішення можливих спірних ситуацій.'
            },
            6 : {
              header: 'Де я можу знайти свої замовлення?',
              text: 'Щоби переглянути всі замовлення, розміщені у вашому магазині,' +
                ' увійдіть у розділ «Замовлення» у своєму бізнес-акаунті.'
            },
            7 : {
              header: 'Як я можу почати продавати з Rulla?',
              text: 'Почніть роботу з Rulla без зволікання, заповнивши заявку на нашому сайті ',
              link: 'тут.'
            },
            8 : {
              header: 'У чому різниця між банківською розстрочкою / оплатою частинами та Rulla?',
              text: 'Rulla — це платіжне рішення для інтернет-магазинів, що дозволяє' +
                ' їхнім клієнтам сплачувати за покупки онлайн кількома платежами. ' +
                'Використання моделі «Купуй зараз — сплачуй пізніше» збільшує конверсію ' +
                'й середній чек, оскільки надає клієнтам більш гнучкий спосіб оплати.',
              list: {
                0: {
                  header: 'Rulla vs. миттєва розстрочка',
                  text: 'Банківська розстрочка є кредитом і впливає на кредитну історію. ' +
                    'На відміну від традиційної розстрочки, в Rulla немає ніяких додаткових ' +
                    'комісій та відсотків, а рішення про схвалення суми для оплати в 3 платежі ' +
                    'приймається на основі технології machine learning.',
                },
                1: {
                  header: 'Rulla vs. оплата частинами від банків',
                  text: 'Умови використання сервісу Rulla зрозумілі, прозорі та єдині для всіх. ' +
                    'Для цього способу оплати підходять дебетові картки, а отже, ніяких переплат ' +
                    'банкам за користування кредиткою. Покупець одразу знає, скільки він повинен буде сплачувати й коли.'
                }
              }
            },
            9 : {
              header: 'Чи є обмеження на те, що я можу продавати через Rulla?',
              text: 'Якщо ви продаєте тютюнові вироби, ліки, путівки, послуги ' +
                'або використовуєте маркетплейс, ми просимо вас зв’язатися з ',
              link: 'відділом продажів Rulla.'
            },
            10 : {
              header: 'Що таке оплата частинами від Rulla?',
              text: 'Rulla — це гнучке платіжне рішення для e-commerce, ' +
                'яке представлено новим для України способом оплати: ' +
                'трьома безпроцентними платежами з розстрочкою на 2 місяці. ' +
                'Rulla постійно оновлюється за допомогою поліпшень ' +
                'і функцій, які збільшують конверсію та спрощують ' +
                'життя як вам, так і вашим клієнтам.'
            },
          }
        },
        account_management: {
          header: 'Керування аккаунтом',
          questions: {
            0: {
              header: 'Як оновити мої контактні дані?',
              text:
                'Ви можете оновити свої дані в особистому кабінеті продавця в розділі «Налаштування / Контактні дані». ' +
                'Якщо у вас виникли труднощі, зв’яжіться з нашою Службою підтримки продавця, і ми з радістю допоможемо вам.'
            },
            1: {
              header: 'Я змінив розрахунковий рахунок організації. Що мені потрібно зробити?',
              text: 'Ви можете керувати платіжними реквізитами у розділі "Рахунки". Якщо у вас виникли труднощі, зв’яжіться з нашою Службою підтримки Rulla для бізнесу.'
            },
            2: {
              header: 'Що таке бізнес-логін?',
              text: 'Бізнес-логін — це вхід в особистий кабінет продавця (бізнес-аккаунт).' +
                ' Через нього продавці отримують доступ до системи обробки замовлень,' +
                ' сплачених за допомогою Rulla. У бізнес-акаунті магазини-партнери можуть:',
              list: {
                0: {
                  text: 'Отримувати та обробляти замовлення.'
                },
                1: {
                  text: 'Складати детальні звіти про свої продажі з Rulla.'
                },
                2: {
                  text: 'Переглядати історію замовлень, сплачених через Rulla.'
                },
                3: {
                  text: 'Змінювати налаштування.'
                }
              }
            },
            3: {
              header: 'Як увійти в особистий кабінет продавця?',
              text: 'Щоб увійти у свій особистий кабінет (бізнес-аккаунт), використовуйте логін і пароль, отримані при оформленні угоди про партнерство з Rulla. Кнопка "Увійти" розміщена у верхній частині сайту.',
              text1:
              'Щоб увійти у свій особистий кабінет (бізнес-аккаунт), використовуйте логін і пароль, ' +
              'отримані при оформленні угоди про партнерство з Rulla. Клацніть ',
              link1: 'тут',
              text2: ' і введіть дані.'
            },
            4: {
              header: 'Чи є в угоди термін дії?',
              text: 'Термін дії угоди з Rulla — 12 місяців.' +
                ' Договір буде автоматично продовжуватися за' +
                ' місяць до кінця поточного періоду дії. Rulla ' +
                'залишає за собою право розірвати цю угоду в будь-який час.'
            },
            5: {
              header: 'Як скласти зведений звіт?',
              text: 'У бізнес-акаунті за замовчуванням відображається' +
                ' зведення за останній місяць, але ви можете встановити ' +
                'потрібний вам період продажів. Це можна зробити,' +
                ' відфільтрувавши дані за датами в розділі «Платежі».'
            },
            6: {
              header: 'Що знаходиться в розділі «Інтеграція»?',
              text: 'У розділі «Інтеграція» ви можете генерувати облікові дані ' +
                'API, які використовуються для встановлення з’єднання ' +
                'між вашим магазином і Rulla.'
            },
            7: {
              header: 'Що знаходиться в розділі «Платежі»?',
              text: 'У розділі «Платежі» ви знайдете дані про продажі з' +
                ' Rulla. Тут ви можете встановлювати власні діапазони ' +
                'та фільтрувати інформацію за параметрами, щоб аналізувати ' +
                'потрібні вам показники.'
            },
            8: {
              header: 'Що знаходиться в розділі «Замовлення»?',
              text: 'Розділ «Замовлення» дозволяє переглядати всі замовлення ' +
                'та їхню історію, а також реєструвати повернення.'
            },
            9: {
              header: 'Що знаходиться в розділі «Сповіщення»?',
              text: 'Ви можете налаштувати сповіщення через будь-який зручний вам канал: e-mail, SMS, або через мессенджер. Також можна окремо налаштувати різні канали, включити або відключити сповіщення для різних юридичних осіб в межах вашого магазину.'
            },
            10: {
              header: 'Як можна отримати реєстраційні дані для входу в бізнес-аккаунт?',
              text: 'Коли ви підписуєте угоду з Rulla, ми створюємо' +
                ' ваш обліковий запис у системі (бізнес-аккаунт). ' +
                'Адреса електронної пошти, вказана в угоді, буде ' +
                'використовуватися в якості імені користувача. На ' +
                'цю адресу ви отримаєте вітальний лист з ім’ям' +
                ' користувача й одноразовим паролем, який ви ' +
                'використаєте при першому вході в систему.'
            },
            11: {
              header: 'Як змінити мову в бізнес-акаунті?',
              text: 'Перейдіть до розділу "Налаштування", у налаштуваннях виберіть бажану мову.'
            },
            12: {
              header: 'У яких країнах можна використовувати Rulla?',
              text: 'Сервіс Rulla як спосіб оплати частинами (в 3 платежі) покупок онлайн є доступним в Україні.'
            },
            13: {
              header: 'Яким фінансовим органом регулюється Rulla?',
              text: 'ТОВ «РУЛЛА УКРАЇНА» є мікрофінансовою організацією, діяльність якої регулюється Національним банком України.'
            },
            14: {
              header: 'У мене є контактна особа?',
              text: 'На початковому етапі роботи з Rulla у вас буде конкретна ' +
                'контактна особа, яка зможе вам допомогти в разі ускладнень.' +
                ' Далі ви можете звертатися в нашу ',
              link: 'Службу підтримки продавця',
              text1: ' за телефону або електронною поштою для отримання допомоги.'
            },
          }
        },
        integration: {
          header: 'Технічна інтеграція',
          questions: {
            0: {
              header: 'Де я можу знайти облікові дані Rulla API?',
              text: 'Ви можете згенерувати нові облікові дані Rulla API' +
                ' в розділі «Інтеграція» в особистому кабінеті ' +
                'продавця (бізнес-акаунті). Не забудьте відразу' +
                ' зберегти нові облікові дані Rulla API, оскільки вони з\'являються тільки один раз.'
            },
            1: {
              header: 'Де можна взяти логотипи Rulla?',
              text: 'Логотипи Rulla для розміщення на вашому сайті можна скачати на сторінці' +
                ' «Преса» або запитати у вашого менеджера Rulla.'
            },
            2: {
              header: 'Як працює інтеграція?',
              text: 'Першим кроком є отримання облікових даних Rulla API,' +
                ' які ви потім додасте на свою платформу, щоб почати ' +
                'реєструвати покупки. Щоб отримати свої облікові дані ' +
                'Rulla API, вам необхідно увійти в свій бізнес-акаунт' +
                ' в розділ «Інтеграція». Там ви зможете згенерувати свої ' +
                'облікові дані Rulla API.'
            },
            3: {
              header: 'Що потрібно, щоб інтеграція Rulla з моїм магазином була схвалена?',
              text: 'Щоб почати продавати з Rulla, ваш веб-сайт повинен бути ' +
                'активним та містити наступну інформацію:',
              list: {
                0: {
                  text: 'Інформація про компанію: зареєстрована назва компанії, ' +
                    'зареєстрована адреса компанії, реєстраційний номер організації.'
                },
                1: {
                  text: 'Положення й умови, що містять інформацію про доставку ' +
                    'та порядок повернення товарів. Якщо доставка є платною, ' +
                    'необхідно чітко це вказати.'
                },
                2: {
                  text: 'Контактні дані вашої служби підтримки клієнтів, ' +
                    'номер телефону та адреса електронної пошти.'
                },
                3: {
                  text: 'Ціни товарів і вартість доставки, які відповідають дійсності.'
                },
              }
            },
            4: {
              header: 'Що потрібно зробити, перш ніж я зможу почати інтеграцію з Rulla?',
              text: 'Щоб почати інтеграцію з Rulla, у вас повинен бути' +
                ' працюючий інтернет-магазин. Це означає, що на його ' +
                'сайті повинна бути інформація про ваші продукти/послуги,' +
                ' а також можливість додавати їх в корзину.'
            },
            5: {
              header: 'Чи є у Rulla посібник по інтеграції?',
              text: 'Так, в розділі «Інтеграція» в бізнес-акаунті ви знайдете' +
                ' інформацію про те, як інтегрувати Rulla. Якщо вам потрібна' +
                ' допомога, зверніться до свого менеджера Rulla або в Службу' +
                ' підтримки продавця.'
            }
          }
        },
      },
      back_button: 'Повернутися на стартову сторінку Підтримки',
      questions_header: 'Поширені запитання',
    },
    TermsAndConditions: {
      header: 'Правила та умови',
      documents: {
        rules_20210301: 'Правила надання грошових коштів у позику',
        rules_20210714: 'Доповнення до правил від 14.07.2021'
      },
      payments: {
        header: 'Варіанти оплати',
        content: 'Оплата платіжними картками Visa та MasterCard'
      },
      delivery: {
        header: 'Доставка по Україні',
        time: 'Зазвичай доставка триває від 1 до 4 днів у межах усієї України.',
        cost: 'Вартість доставки залежить від умов окремого продавця. '
      },
      refund: {
        header: 'Повернення та обмін',
        text1: 'Згідно із Законом України «Про захист прав споживачів» покупець має право обміняти товар на аналогічний протягом 14 днів, якщо куплений товар не підійшов за формою, габаритами, фасоном, кольором, розміром або з інших причин, і якщо збережено товарний вигляд, ярлики і розрахунковий документ, виданий продавцем разом із товаром. Виняток становлять товари, які не підлягають поверненню за Законом, їх перелік ви можете переглянути за ',
        text2: 'посиланням'
      },
      contacts: {
        header: 'Контактна інформація',
        companyName: 'ТОВ «РУЛЛА УКРАЇНА»',
        companyNumber: 'ЄДРПОУ 43213857',
        address: 'Україна, 69035, Запорізька обл., м. Запоріжжя,\nвул. Феросплавна, будинок 38, кв. 31',
        emailLabel: 'E-mail:',
        email: 'sales@rulla.com',
        phoneLabel: 'Тел:',
        phoneNumber: '+38 066-885-43-73'
      }
    },
    ThreePayments: {
      header_block: {
        header: 'Три платежі без відсотків',
        subheader: 'Новий зручний спосіб купувати онлайн',
        button: 'зв\'яжіться з нами'
      },
      business: {
        header: 'Rulla розвиває ваш бізнес',
        subheader: 'Платіжне рішення, що збільшує продажі, — ідеальний варіант для вашого інтернет-магазину.',
        items: {
          conversion: {
            header: 'Конверсії',
            text: 'Зручна форма оплати частинами без комісій підвищує ймовірність купівлі.'
          },
          average_check: {
            header: 'Середній чек',
            text: 'Rulla дає вашим клієнтам більше часу для сплати, тому вони купують більше.'
          },
          regular_customers: {
            header: 'Постійні клієнти',
            text: 'Позитивний досвід вигідного шопінгу приносить більше повторних продажів.'
          },
          attendance: {
            header: 'Відвідуваність',
            text: 'Користувачі Rulla переходять на ваш сайт просто з платіжного сервісу.'
          }
        },
        implemented_top_brands: 'Оплату частинами впровадили топ-бренди у всьому світі.'
      },
      purchases_control: {
        header: 'Покупки під контролем',
        subheader: 'Усі покупки в одному місці в особистому кабінеті Rulla.',
        item1: 'Ваші покупці можуть бачити історію своїх замовлень, гнучко керувати платежами та планувати бюджет.',
        item2: 'Можливість розділяти вартість покупок на 3 безвідсоткові платежі дає фінансову свободу й впевненість.',
        item3: 'Пропонуючи вигідну альтернативу кредитам і банківській розстрочці, ' +
          'платіжне рішення від Rulla збільшує продажі та лояльність клієнтів.',
        button_start_now: 'ПОЧАТИ ЗАРАЗ'
      },
      guarantee: {
        header: 'Гарантії учасникам угоди',
        text: 'Використовувати Rulla для покупок онлайн так само просто, як ' +
          'розраховуватися за допомогою картки. При цьому обидві сторони отримують' +
          ' переваги. Покупці безпечно збільшують свою платоспроможність. ' +
          'Алгоритм оцінювання не дасть витратити більше, ніж вони в змозі заплатити. ' +
          'Продавці працюють як зазвичай: після оформлення замовлення отримують ' +
          'усю суму наперед і доставляють товар. ' +
          'Щось пішло не так?' ,
        link: 'Ми розRull’имо.'
      },
      statistics: {
        header: 'Rulla дарує вашим клієнтам можливість здійснювати більше покупок.',
        subheader: 'Купувати чи не купувати? Оплата частинами вирішить проблему нестачі коштів ' +
          'і подарує досвід комфортного шопінгу. Більше покупок і менше покинутих ' +
          'кошиків з гнучким платіжним сервісом від Rulla.',
        info_pleased: 'тих, хто користувався функцією оплати в кілька платежів, задоволені цим досвідом',
        info_think: 'користувачів сервісів оплати частинами вважають цей спосіб найкращим',
        info_refused: 'клієнтів онлайн-магазинів відмовилися б від купівлі без опції оплати частинами*',
        info_refused1: '*За даними платіжних сервісів'
      },
      how_arranged: {
        header: 'Як влаштована оплата в 3 платежі',
        subheader: 'Купуй зараз — сплачуй пізніше. Щоб зробити купівлю простіше, ' +
          'Rulla пропонує прозору систему оплати частинами. Без переплат.',
        order_registration: {
          header: 'Оформлення замовлення',
          text: 'В інтернет-магазині покупець обирає оплату частинами від Rulla та вносить перший платіж,' +
            ' використовуючи банківську картку. У віджеті відображаються суми й графік платежів.'
        },
        delivery: {
          header: 'Доставка',
          text: 'Після обробки замовлення Rulla сплачує продавцю (магазину) повну вартість покупки. ' +
            'Ми самі подбаємо про отримання наступних платежів ' +
            'від покупця.'
        },
        after_purchase: {
          header: 'Після покупки',
          text: 'Ваш клієнт отримує чітке уявлення ' +
            'про свої покупки та може легко керувати своїми замовленнями ' +
            'в особистому кабінеті Rulla.'
        }
      },
      connecting: {
        header: 'Підключення Rulla',
        subheader: 'Установка віджету оплати в 3 платежі на сайт інтернет-магазину займає ' +
          'від кількох годин. Для підключення зв\'яжіться з нами через форму на сайті.',
        details_table: {
          header: 'Деталі',
          market_availability: {
            header: 'Доступність на ринку',
            text: 'Україна'
          },
          payment_options: {
            header: 'Варіанти оплати',
            text: 'Дебетова або кредитна картка'
          },
          payment_period_starts: {
            header: 'Період оплати починається',
            text: 'При обробці замовлення'
          },
          number_of_payments: {
            header: 'Кількість платежів',
            text: '3'
          },
          time_between_payments: {
            header: 'Час між платежами',
            text: '30 днів (стандартно)'
          },
          annual_interest_rate: {
            header: 'Річна процентна ставка',
            text: '0,00001%'
          },
          additional_expenses: {
            header: 'Додаткові витрати',
            text: 'Немає комісій. Жодних витрат при оплаті в строк.'
          },
          payment_method: {
            header: 'Спосіб внесення платежів',
            text: 'Автоматичне списання з зареєстрованої картки'
          },
          delay_in_payment: {
            header: 'Затримка платежу (прострочення)',
            text: 'Може стягуватися невелика плата'
          },
          view_pay_purchases: {
            header: 'Перегляд та оплата покупок',
            text: 'Необхідна авторизація в особистому кабінеті на',
          },
          seller_protection: {
            header: 'Захист продавця',
            text: 'Так'
          },
          buyer_protection: {
            header: 'Захист покупця',
            text: 'Так'
          }

        },
        integration_table: {
          header: 'Інтеграція',
          direct_integration: {
            header: 'Пряма інтеграція',
            text: 'API'
          },
          automatic_updates: {
            header: 'Автоматичні оновлення',
            text: 'Включені'
          },
          developer_resources: {
            header: 'Ресурси для розробників',
            text: 'Інструкція по інтеграції'
          }
        },
        trade_agreement_table: {
          header: 'Торговельна угода',
          standard_terms: {
            header: 'Стандартні умови угоди',
            text: '12 місяців (продовжується автоматично)'
          },
          model_pricing: {
            header: 'Вартість / модель ціноутворення',
            text: 'Зв\'яжіться з нами для отримання додаткової інформації'
          }
        }
      }
    },
    Legal: {
      h1: 'ЮРИДИЧНА ІНФОРМАЦІЯ',
      h2: 'ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ «РУЛЛА УКРАЇНА»',
      text1: 'Код ЄДРПОУ 43213857',
      text2: 'Код КВЕД 64.92 Інші види кредитування (основний).',
      text3: 'Вид фінансової послуги: надання позик, у тому числі на умовах фінансового кредиту.',
      text4: 'Свідоцтво про реєстрацію фінансової установи ФК №1420 від 26.05.2020 р.',
      text5: 'Відомості про ліцензії та дозволи, видані фінансовій установі:',
      text6: 'Вид господарської діяльності, на провадження якого видана ліцензія —' +
        '  НАДАННЯ КОШТІВ У ПОЗИКУ, В ТОМУ ЧИСЛІ І НА УМОВАХ ФІНАНСОВОГО КРЕДИТУ.' +
        '  Ліцензія на здійснення діяльності з надання коштів у позику, в тому' +
        '  числі і на умовах фінансового кредиту від 11.06.2020 р.' +
        '  (Розпорядження НКФУ № 1323 від 11.06.2020 р.)',
      text7: 'Статус ліцензії – чинна.',
      text8: 'Строк дії – безстрокова.',
      text9: 'Дані про порушення провадження по справі про банкрутство, санації:',
      text10: 'Не знаходиться в процесі ліквідації.',
      text11: 'Засновники (учасники) Товариства:',
      text12: 'ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ «ІНСТИТУТ ДОСЛІДЖЕНЬ СТАНДАРТІВ ЯКОСТІ»',
      text13: 'Код ЄДРПОУ засновника: 36977252',
      text14: '69002, Запорізька обл., місто Запоріжжя, Олександрівський район, вулиця Запорізька, будинок 6-А, квартира 47',
      text15: 'ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ «ЦЕНТР ПРАВОВИХ РІШЕНЬ»',
      text16: 'Код ЄДРПОУ засновника: 36977247',
      text17: '69002, Запорізька обл., місто Запоріжжя, Олександрівський район, вулиця Запорізька, будинок 6-А, квартира 47',
      text18: 'Кінцевий бенефіціарний власник (контролер):',
      text19: 'ТИХОНОВА ОЛЕНА ОЛЕКСІЇВНА',
      text20: 'Україна, 69002, місто Запоріжжя, вулиця Запорізька, будинок 6-А, квартира 47',
      text21: 'Відомості про органи управління юридичної особи:',
      text22: 'Загальні збори учасників Товариства.',
      text23: 'Виконавчий орган Товариства — Директор Лєман Наталя Сергіївна.',
      text24: 'Наглядова рада не створювалась.',
      text25: 'Відокремлені підрозділи відсутні.',
      text26: 'Графік роботи',
      text27: 'Пн-пт 9.00-18.00',
      text28: 'Без перерви та вихідних.',
      text29: 'Контактна інформація',
      text30: 'Адреса:',
      text31: 'Україна, 69035, Запорізька обл., місто Запоріжжя, вул. Феросплавна, будинок 38, квартира 31',
      text32: 'Код території за КОАТУУ 2310136900',
      text33: 'Електронна пошта:',
      text34: 'Телефон:',
      text35: 'Механізми захисту прав споживачів фінансових послуг',
      text36: 'Скарги з приводу захисту прав споживачів приймаються:',
      text37: '1) За юридичною адресою Товариства:',
      text38: 'Україна, 69035, Запорізька обл., місто Запоріжжя, вул.Феросплавна, будинок 38, квартира 31',
      text39: '2) Національним банком України:',
      text39_1: 'Адреса: 01601, м. Київ, вул. Інститутська, 9',
      text39_2: 'Телефон відділу звернення громадян: 0 800 505 240',
      text39_3: 'Електронна пошта: nbu@bank.gov.ua',
      text39_4: 'https://bank.gov.ua/ua/consumer-protection',
      text41: '3) Головним управлінням Держпродспоживслужби в Запорізькій області:',
      text41_1: 'Адреса: 69002 м. Запоріжжя, вул. Гоголя, 105 а, кабінет 203',
      text41_2: 'Телефон: (061) 228-04-64 (приймальня)',
      text41_3: 'Електронна пошта: office@gudpss-zp.gov.ua',
      text43: ' Орган, який здійснює державне регулювання щодо діяльності особи, яка надає фінансові послуги:',
      text44: 'Національний банк України',
      text44_1: 'Адреса: 01601, м. Київ, вул. Інститутська, 9',
      text44_2: 'Телефон відділу звернення громадян: 0 800 505 240',
      text44_3: 'Електронна пошта: nbu@bank.gov.ua',
      text45: 'Вартість фінансової послуги визначається в залежності від суми кредиту' +
        ' та зазначається у Договорі, що укладається між Товариством та Клієнтом.' +
        ' Розмір відсотків річних може складати від 0,00001% до 0,1%.',
      text46: 'Товариство не залучає Кредитних посередників.',
      text47: 'Не передбачена наявність гарантійних фондів чи компенсаційних схем' +
        ' при наданні фінансової послуги з надання коштів у позику, в тому числі і на' +
        ' умовах фінансового кредиту.',
      text48: 'Наявні та можливі схеми кредитування: ануїтетна.',
      text49: 'В результаті отримання фінансової послуги не передбачена сплата податків або зборів за рахунок фізичної особи.',
      text50: 'Документи:',
      text51: 'ПРАВИЛА НАДАННЯ ГРОШОВИХ КОШТІВ У ПОЗИКУ, У ТОМУ ЧИСЛІ НА УМОВАХ ФІНАНСОВОГО КРЕДИТУ',
      text52: 'ДОГОВІР  ПРО НАДАННЯ ФІНАНСОВИХ ПОСЛУГ',
      text53: 'СВІДОЦТВО ПРО РЕЄСТРАЦІЮ ФІНАНСОВОЇ УСТАНОВИ',
      text54: 'РОЗПОРЯДЖЕННЯ ПРО ВНЕСЕННЯ ДО ДЕРЖАВНОГО РЕЄСТРУ ФІНАНСОВИХ УСТАНОВ',
      text55: 'ВИПИСКА З ЄДИНОГО ДЕРЖАВНОГО РЕЄСТРУ ЮРИДИЧНИХ ОСІБ',
      text56: 'РОЗПОРЯДЖЕННЯ ПРО ВИДАЧУ ЛІЦЕНЗІЇ НА ПРОВАДЖЕННЯ ГОСПОДАРСЬКОЇ ДІЯЛЬНОСТІ З НАДАННЯ ФІНАНСОВИХ ПОСЛУГ',
    },
    PersonalCabinet: {
      Auth: {
        customer: 'Покупець',
        business: 'Магазин',
        business_login: 'Вхід для магазину',
        profile_login: 'Вхід для покупця',
        new_login: 'Приєднатися до Rulla',
        enter_number_to_login: 'Введіть ваш номер, щоб увійти в особистий кабінет',
        start_with_rulla: 'Почніть покупки з Rulla – введіть ваш номер телефону для входу в особистий кабінет',
        enter_number_to_recover: 'Введіть ваш номер, і ми надійшлемо вам посилання на сторінку, де ви зможете встановити новий пароль',
        password_saved: 'Ваш пароль було змінено. Ви можете перейти за посиланням нижче, щоб увійти в свій особистий кабінет',
        password_sent: 'Посилання було надіслано на вказаний номер',
        enter_new_password: 'Введіть новий пароль для входу в особистий кабінет',
        phone: 'Телефон',
        password: 'Пароль',
        new_password: 'Новий пароль',
        confirm_password: 'Підтвердити пароль',
        login: 'Увійти',
        forgot_password: 'Забули пароль?',
        sign_up: 'Зареєструватися',
        return_to_login: 'Повернутись до входу',
        your_phone_number: 'Ваш номер телефону',
        sending_speeds: 'Можуть застосовуватися швидкості передачі повідомлень і даних',
        change: 'Змінити',
        send_code: 'Надіслати код',
        send_password: 'Надіслати',
        send_on: 'Відправлено на',
        send_one_more_time: 'Надіслати код ще раз',
        enter_sms_code: 'Введіть код з СМС',
        back: 'Назад',
        wait: 'Чекайте',
        seconds: 'секунд',
        invalid_password: 'Помилка: невірний пароль',
        invalid_phone_number: 'Помилка: формат номеру +380 XX XXX XXXX',
        invalid_email: 'Помилка: введіть e-mail у форматі a@a.com',
        empty_password: 'Помилка: введіть пароль',
        user_not_found: 'Помилка: користувач не знайдений'
      },
      Layouts: {
        Sidebar: {
          main: 'Головна',
          orders: 'Замовлення',
          settings: 'Сповіщення',
          settlements: 'Платежі',

          integration: 'Інтеграція',
          hotline: 'Підтримка',

          favorites: 'Обране',
          purchases: 'Покупки',
          payments: 'Оплати',
          bank_accounts: 'Рахунки',
          offline: 'QR / SMS',
          profile_settings: 'Налаштування',
          personal: 'Мої дані'
        },
        Footer: {
          main: 'Головна',
          orders: 'Замовлення',
          settings: 'Сповіщення',
          settlements: 'Платежі',

          favorites: 'Обране',
          purchases: 'Покупки',
          payments: 'Оплати',
        },
        MobileMenu: {
          integration: 'Інтеграція',
          bank_accounts: 'Рахунки',
          offline: 'QR / SMS',
          hotline: 'Підтримка',
          profile_settings: 'Налаштування'
        }
      },
      Business: {
        profile_button: 'Профіль',
        Telegram: {
          telegram: 'Telegram',
          add_account: 'Додати аккаунт',
          popup_title: 'Підтвердження аккаунту',
          popup_1: 'Щоб почати отримувати сповіщення в telegram необхідно відправити повідомлення з кодом підтвердження нашому боту',
          popup_2: 'Почніть чат з ботом за посиланням:',
          popup_3: 'Натисніть кнопку "Старт" і відправте йому наступне повідомлення:',
        },
        Integration: {
          header: 'Налаштування інтеграції',
          controls: {
            withdraw: 'Відкликати видані токени',
            generate: 'Згенерувати'
          },
          popup: {
            your_token: 'Ваш токен: ',
            save_token: 'Збережіть його для подальшого налаштування інтеграції з Rulla API',
            copy: 'Копіювати'
          },
          text: {
            how_integrate: 'Як інтегрувати віджет',
            step_1: 'Крок 1.',
            send_request: 'Відправити запит на створення сесії віджета за адресою:',
            heading_set_token: 'У заголовку Authorization необхідно вказати значення Bearer TOKEN, де замість TOKEN використати згенерований токен.',
            request_body_example: 'Приклад тіла запиту',
            detailed_information: 'Детальну інформацію про запит можна знайти на сторінці API:',
            legal: 'Значення legalEntityId і bankAccountId можна знайти в розділі "Рахунки"',
            success_request: 'У разі успішного запиту відповідь буде містити ідентифікатор створеної сесії:',
            step_2: 'Крок 2.',
            add_widget_on_page: 'Додати віджет на сторінку, вказати src, який містить отриманий ідентифікатор.',
            example: 'Наприклад:',
            example_name: 'Іван',
            example_surname: 'Іванов',
            example_patronymic: 'Іванович',
            example_address: 'Київ, вул. Шота Руставелі',
          },
          api: {
            header: 'Інтеграція через API',
            alert: {
              text: 'Перш ніж приступити до інтеграції, необхідно налаштувати банківський рахунок за замовчуванням:',
              link: 'Банківські рахунки'
            },
            p1: 'Нижче ви можете згенерувати токен для аутентифікації через API, також тут показано ваш ключ API Key за допомогою якого можно перевірити ваш токен',
            p2: 'У разі компроментації вашого токена - ви можете відкликати його, в такому разі API Key буде змінено',
            p3: {
              part1: 'Якщо у вас немає можливості зробити інтеграцію через API - ви можете скористуватись ',
              part2: 'генератором',
              part3: ' коду оплати для конкретних товарів'
            },
            steps: {
              header: 'Як інтегрувати віджет',
              step1: {
                header: 'Створити замовлення',
                p1: 'Після створення замовлення в вашій системі, відправити запит на створення замовлення на Rulla за адресою:',
                p2: 'У заголовку Authorization необхідно вказати значення Bearer TOKEN, де замість TOKEN використати згенерований токен.',
                p3: {
                  part1: 'Детальну інформацію про запит можна знайти на сторінці API:'
                },
                p4: 'Приклад тіла запиту',
                p5: 'Значення <var>legalEntityId</var> і <var>bankAccountId</var> можна знайти в розділі "Рахунки"',
                p6: 'У разі успішного запиту відповідь буде містити ідентифікатор створеної сесії:'
              },
              step2: {
                header: 'Підключити скрипт',
                p1: 'Далі треба підключити скрипт Rulla на сторінці де буде можливість оплати замовлення',
                p2: 'Тег script краще всього розташувати всередині тегу head - таким чином ви забезпечите можливість оплати по натисканню на кнопку якомога раніше, не очікуючи повного завантаження інших скриптів'
              },
              step3: {
                header: 'Кнопка оплати',
                p1: 'Код кнопки можна розташувати де завгодно, при натисканні на неї - буде відображено модальне вікно з віджетом Rulla',
                p2: 'Параметром метода <var>pay</var> є отриманий ідентифікатор сесії',
                p3: 'Ви можете будь-яким чином змінювати стиль та текст кнопки'
              },
              step4: {
                header: 'Фрейм',
                p1: 'Альтернативно, ви можете показати віджет Rulla як частину сторінки вашого сайту',
                p2: 'Параметром метода <var>init</var> є отриманий ідентифікатор сесії, а <var>element</var> має містити селетор елемента, в який буде поміщено фрейм',
                p3: 'Скрипт ініціалізації має буде виконано не раніше, ніж <var>element</var> буде відрендерено на сторінці'
              }
            }
          },
          product: {
            header: 'Інтеграція без бекенду',
            alert: {
              text: 'Перш ніж приступити до інтеграції, необхідно налаштувати банківський рахунок за замовчуванням:',
              link: 'Банківські рахунки'
            },
            p1:
              'У вас є можливість зробити оплату через Rulla не змінюючи власного бекенду, ' +
              'проте у цьому випадку у вас не буде можливості отримувати статуси замовлень окрім як через нотифікації.',
            p2: {
              part1: 'Також при ',
              part2: 'інтеграції через API',
              part3:
                ' у вас є можливість налаштовувати продаж декількох товарів у корзині ' +
                'та робити підтвердження наявності автоматично з власної системи.'
            },
            form: {
              product_id: 'Унікальний ідентифікатор на сайті',
              product_name: 'Назва продукту',
              product_price: 'Ціна продукту',
              product_image: 'Посилання на зображення',
              product_url: 'Посилання на сторінку продукту',
            },
            step1: {
              header: 'Кнопка оплати',
              p1: 'Тег script краще всього розташувати всередині тегу head - таким чином ви забезпечите можливість оплати по натисканню на кнопку якомога раніше, не очікуючи повного завантаження інших скриптів',
              p2: 'Код кнопки можна розташувати де завгодно, при натисканні на неї - буде відображено модальне вікно з віджетом Rulla',
              p3: 'Ви можете будь яким чином змінювати стиль та текст кнопки'
            },
            step2: {
              header: 'Фрейм',
              p1: 'Фрейм - це альтернативний спосіб відображення віджета, коли віджет видно відразу, як частину вашого сайту'
            },
          }
        },
        Settings: {
          header: 'Сповіщення',
          hints: [
            'Ви можете налаштувати cповіщення через будь-який зручний вам канал: e-mail, SMS, або через месенджер.',
            'Також можна окремо налаштувати різні канали, включити або відключити сповіщення для різних юридичних осіб в межах вашого магазину.',
            'За замовчуванням канали для юр.осіб будуть відповідати налаштуванням магазину. Додати і верифікувати нові контакти можна в розділі "Налаштування"'
          ],
          store_defaults: '-- Налаштування магазину --',
          legal_entities: 'Юридична особа',
          phone_number: 'Номер телефону',
          email: 'E-mail',
          what_notify_about: 'Про що повідомляти?',
          new_orders: 'Нові замовлення',
          order_confirmation: 'Підтвердження замовлення',
          expiring_orders: 'Замовлення, у яких закінчується термін',
          paid_orders: 'Оплачені замовлення, за якими необхідно відправити товар',
          unknown_orders: 'Невідомі замовлення',
          platform_not_notified: 'Замовлення, про які e-commerce платформа або система не сповіщені.' +
            ' Можуть позначати помилку інтеграції між Rulla API і вашою системою',
          when_notify: 'Коли повідомляти?',
          everyday: 'Щодня',
          everyday_morning: 'Отримуйте щоденне зведення щоранку',
          turn_off_blank: 'Відключити порожні листи',
          dont_send_blank: 'Ми не будемо відправляти вам листи, коли нема про що повідомляти',
          every_week: 'Щотижня',
          every_week_monday: 'Отримуйте щотижневе зведення щопонеділка',
          dropdownLabel: 'Мова'
        },
        Settlements: {
          header: 'Звіти по платежах',
          download: 'Завантажити CSV',
          period: 'Період продажів',
          empty_table: 'Тут будуть відображатися платежі',
          table: {
            date: 'Дата',
            number: 'Номер',
            customer_name: 'Покупець',
            customer_phone: 'Телефон',
            sum: 'Сума'
          }
        },
        Orders: {
          total: 'Всього',
          header: 'Всі замовлення',
          download_button: 'Скачати XLS',
          feedback_button: 'Зворотній зв\'язок',
          search_field: 'Ім\'я клієнта, пошта, номер замовлення',
          period: 'Період продажів',
          shop: 'Магазин',
          all_statuses: 'Всі статуси',
          status: 'Статус',
          statuses: {
            new: 'Новий',
            waiting_for_payment: 'Очікується платіж',
            paid: 'Чекає відправки',
            refunding: 'Повернення коштів',
            refunded: 'Кошти повернуті',
            repaid: 'Погашений',
            shipping: 'Відправлено',
            shipped: 'Доставлено',
            canceled: 'Скасовано',
          },
          apply_button: 'Застосувати',
          QrModal: {
            header: 'Оплатити замовлення',
            order_sum: 'Замовлення на суму',
            send_button: 'Відправити',
          },
          NewOrderModal: {
            trigger_button: 'Створити замовлення',
            header: 'Нове замовлення',
            phone_label: 'Телефон',
            name_label: 'Найменування товару',
            price_label: 'Ціна',
            delete_line: 'Видалити товар',
            add_line: 'Додати ще один товар',
            skip_confirmation: 'Не потребує підтвердження',
            modal_text: 'Для створення замовлення введіть номер телефону клієнта, найменування та ціну товарів.',
            create_order: 'Створити замовлення',
            all: 'Всього',
            create_error: 'Виникла помилка',
            amount_invalid: 'Цей спосіб оплати доступний для замовлень від {{min}} до {{max}}'
          },
          DetailsModal: {
            order_details: 'Деталі замовлення',
            rulla_order_number: 'Номер замовлення на\u00a0Rulla:',
            store_order_number: 'Номер замовлення в\u00a0магазині:',
            store_subdivision: 'Магазин:',
            order_date: 'Дата і час покупки:',
            order_total: 'Сума всього:',
            customer_contacts: 'Контактні дані покупця',
            surname: 'Прізвище:',
            name: 'Ім\'я:',
            patronymic: 'По батькові:',
            phone: 'Телефон:',
            email: 'E-mail:'
          }
        },
        Offline: {
          Header: 'Оформити замовлення',
          PhoneNumber: 'Номер телефону',
          BankAccount: 'Розрахунковий рахунок',
          NoAccount: '- Не обрано -',
          Send: 'Надіслати',
          Sent: 'Надіслано',
        },
        BankAccounts: {
          Header: 'Рахунки для виплат',
          AddLegalEntity: 'Нова юр.особа',
          AddBankAccount: 'Додати рахунок',
          SortCode: 'МФО',
          LegalEntityModal: {
            Header: 'Нова юр.особа',
            Name: 'Назва',
            Code: 'ЄДРПОУ',
            Close: 'Закрити',
            Save: 'Додати юр.особу'
          },
          BankAccountModal: {
            Header: 'Новий рахунок',
            AccountNumber: 'Номер рахунку',
            BankName: 'Банк',
            BankNamePlaceholder: 'ПриватБанк',
            Currency: 'Валюта',
            SortCode: 'МФО',
            Close: 'Закрити',
            Save: 'Додати рахунок'
          }
        },
        Main: {
          header: 'Головна',
          roll_up: 'Згорнути',
          watch_all: 'Дивитись все',
          orders: 'Замовлення',
          sales_volume: 'Обсяг продажів,',
          average_check: 'Середній чек,',
          statistic: 'Статистика',
          dropdown: {
            1: 'Продажі сьогодні',
            2: 'Продажі за тиждень',
            3: 'Продажі за місяць',
            4: 'Продажі за квартал'
          },
          sorters: {
            1: 'По даті',
            2: 'По сумі',
            3: 'По магазину'
          }
        },
        Profile: {
          heading: 'Профіль',
          contact_data: 'Контактні дані',
          logout: 'Вийти',
          phone: 'Телефон',
          email: 'Пошта',
          add_phone: 'Додати новий номер',
          add_email: 'Додати нову пошту',
          confirm: 'Підтвердити',
          not_confirmed: 'Не підтверджений',
          change: 'Змінити',
          verify_phone: {
            enter_code: 'Введіть код з СМС',
            send_on: 'Відправлено на',
            send_one_more: 'Надіслати код ще раз',
            wait: 'Чекайте',
            seconds: 'секунд',
            send_code: 'Надіслати код',
            back: 'Назад',
            next: 'Далі',
            your_number: 'Ваш номер телефону',
            data_speed: 'Можуть застосовуватися швидкості передачі повідомлень і даних',
            on_what_number: 'Нам потрібно переконатися, що це — ви. Повідомте нам, на який номер відправити код.',
            phone: 'Телефон'
          },
          verify_email: {
            check_email: 'Перевірте пошту',
            on_your_mail1: 'На вашу пошту',
            on_your_mail2: 'відправлено посилання для підтвердження. Якщо не можете знайти лист, перевірте папку "Спам"',
            send_one_more: 'Надіслати ще раз',
            personal_cabinet: 'Особистий кабінет',
            your_email: 'Ваша пошта',
            send_link: 'Надіслати посилання',
            back: 'Назад',
            email: 'Пошта',
            what_you_can: 'На неї ви зможете отримувати сповіщення, а також новини та акції від наших партнерів',
          },
        },
      },
      Profile: {
        Main: {
          heading: 'Головна',
          pay: 'Сплатити',
          delivery: 'Доставка',
          purchases_history: 'Історія покупок',
          no_purchases: 'Ви ще не здійснювали покупок',
          goods: 'Товари',
          total_amount: 'Загальна сума',
          order_number: 'Номер замовлення'
        },
        Favorites: {
          heading: 'Обране',
          search: 'Товар або магазин',
          stores: 'Магазини',
          deliveries: 'Доставки',
          empty_stores: 'Ми не знайшли жодного магазину',
          start_shopping: 'Почніть шопінг і додайте улюблені магазини в список',
          empty_deliveries: 'Нема поточних доставок',
          check_delivery_here: 'Якщо у вас є покупка в процесі доставкою, ви зможете відстежити її тут.',
        },
        Orders: {
          all: 'Всього: ',
          paid: 'Сплачено: ',
          nextPayment: 'Наступний платіж: ',
          status: 'Статус',
          statuses: {
            new: 'Новий',
            waiting_for_payment: 'Очікується платіж',
            paid: 'Оплачено',
            refunding: 'Повернення коштів',
            refunded: 'Кошти повернуті',
            repaid: 'Погашений',
            shipped: 'Доставлено',
            canceled: 'Скасовано',
          },
          description: 'Опис',
          order_number: 'Замовлення',
          date: 'Дата',
          cost: 'Вартість',
          payments: 'Платежі',
          store: 'Магазин',
          payments_heading: 'Оплати',
          products_heading: 'Товари'
        },
        Purchases: {
          heading: 'Покупки',
          overview: 'Огляд',
          all_purchases: 'Всі покупки',
          balance: 'Баланс',
          expenses_by_month: 'Витрати по місяцях:',
          charts_statistics: 'Тут буде статистика витрат з графіками',
          spent: 'Витрачено',
          dont_have_purchases: 'У вас немає покупок.',
          here_expenses: 'Коли ви їх зробите, тут з\'являться витрати за категоріями товару',
          spent_by_period: 'Витрачено за ',
          expenses_by_period: 'Покупок за ',
          expenses_by_store: 'Витрати по магазинах:',
          current_purchases: 'Поточні покупки',
          all_empty: {
            header: 'Ви ще не здійснювали покупки з Rulla',
            text1: 'Немає зареєстрованих покупок, пов\'язаних з ',
            text2: ' Перевірте, чи не використовували ви іншу адресу електронної пошти',
          },
          balance_empty: {
            header: 'Поточні покупки',
            text: 'Тут будуть відображатися неоплачені товари'
          }
        },
        Payments: {
          header: 'Оплати',
          relax: 'Влаштуйтеся зручніше і розслабтеся',
          find_it_here: 'Якщо вам потрібно щось зробити, ви знайдете це тут.',
          all: 'Всього:',
          pay: 'Сплатити',
          close: 'Закрити',
          paid: 'Сплачено',
          not_paid: 'Не сплачено',
          payment_schedule: 'Графік оплат',
          payment_history: 'Історія оплат',
          number: 'Номер:',
          sum: 'Сума',
          indebtedness: 'Заборгованість:',
          fines: 'Штрафи:',
          total_paid: 'Всього сплачено:',
          total_to_pay: 'Всього до сплати:',
          pay_header: 'Оплати',
          next_payment: 'Наступний платіж:',
          since: 'з',
        },
        Personal: {
          contact_data: 'Контактні дані',
          personal_data: 'Особисті дані',
          passport_data: 'Паспортні дані',
          address_data: 'Адреса',
          surname: 'Прізвище',
          patronymic: 'По батькові',
          name: 'Ім\'я',
          birth: 'Дата народження',
          taxNumber: 'ІНН',
          passport: 'Паспорт',
          id_card: 'ID картка',
          series: 'Серія',
          number: 'Номер',
          passport_date: 'Дата видачі',
          passport_issued_by: 'Ким виданий',
          biometric_passport_issued_by: 'Орган який видав',
          registration_address: 'Адреса реєстрації',
          addresses_same: 'Адреса реєстрації та проживання однакові',
          living_adress: 'Адреса проживання',
          save: 'Зберегти',
          saved: 'Дані збережено',
          edit_contacts: 'Редагувати контакти'
        },
        Settings: {
          heading: 'Налаштування',
          main: 'Основні',
          contact_data: 'Контактні дані',
          subscribe_on_news: 'Підписка на новини',
          payment: 'Оплата',
          payment_methods: 'Способи оплати',
          upload_photo: 'Завантажити фото',
          logout: 'Вийти',
          phone: 'Телефон',
          email: 'Пошта',
          add_phone: 'Додати новий номер',
          main_phone: 'Головний',
          select_as_main: 'Вибрати головним',
          legal_entities: 'Контакти окремих юр.осіб',
          delete: 'Видалити',
          confirm: 'Підтвердити',
          not_confirmed: 'Не підтверджений',
          change: 'Змінити',
          enter_sms_code: 'Введіть код з СМС',
          send_on: 'Відправлено на',
          send_one_more: 'Надіслати код ще раз',
          wait: 'Очікуйте',
          seconds: 'секунд',
          send_code: 'Надіслати код',
          back: 'Назад',
          next: 'Далі',
          your_number: 'Ваш номер телефону',
          make_sure_thats_you: 'Нам потрібно переконатися, що це — ви. Повідомте нам, на який номер відправити код.',
          speeds_on_sms: 'Можуть застосовуватися швидкості передачі повідомлень і даних',
          your_email: 'Ваша пошта',
          on_it_notifications: 'На неї ви зможете отримувати оповіщення, а також новини та акції від наших партнерів',
          send_link: 'Надіслати посилання',

          personal_cabinet: 'Особистий кабінет',
          send_one_more_time: 'надіслати ще раз',
          just_sent: 'E-mail було щойно відправлено',
          check_email: 'Перевірте пошту',
          on_your_mail1: 'На вашу пошту',
          on_your_mail2: 'було відправлено посилання для підтвердження. Якщо не можете знайти лист, перевірте папку "Спам"',
        },
        Modals: {
          refund: {
            name: 'Повернення',
            header: 'Повернення товару',
            text: 'Для повернення необхідно буде повернути гроші, сплачені за дану покупку',
            button: 'Повернути'
          },
          cancel: {
            name: 'Скасування',
            header: 'Скасування замовлення',
            text: 'Для скасування замовлення необхідно буде повернути гроші, сплачені за дану покупку',
            button: 'Підтвердити',
          },
          confirm: {
            name: 'Підтвердження',
            link: 'Підтвердити',
            header: 'Підтвердження замовлення',
            text: 'Ви підтверджуєте наявність товару в замовленні і покупець може перейти до оплати замовлення',
            button: 'Підтвердити',
          },
          status: {
            name: 'Статус',
            header: 'Статус доставки',
            text: 'Виберіть статус доставки:',
            button: 'Підтвердити'
          },
          pay: {
            name: 'Сплатити'
          },
          cancel_button: 'Закрити'
        }
      }
    },
    Press: {
      header: 'Rulla для преси',
      subheader: 'Тут ви можете переглянути прес-релізи Rulla і супутні матеріали, ' +
        'як-от інформацію про компанію та бібліотеку зображень.',
      publications: {
        header: 'Rulla в публікаціях',
        links: [
          {
            title: 'Купуй зараз — сплачуй пізніше. Гнучкий спосіб сплати за покупки в онлайн-магазинах від Rulla',
            url: 'https://news.finance.ua/ua/news/-/493201/kupuj-zaraz-splachuj-piznishe-gnuchkyj-sposib-splaty-za-pokupky-v-onlajn-magazynah-vid-rulla'
          },
          {
            title: 'Платіжний сервіс Rulla: як оплата частинами збільшує виручку інтернет-магазину',
            url: 'https://minfin.com.ua/ua/2021/05/19/65043346/'
          },
          {
            title: 'Як безкоштовна розстрочка від Rulla збільшує продажі Stylus.ua',
            url: 'https://www.epravda.com.ua/news/2021/10/12/678625/'
          },
          {
            title: 'Як миттєво підключити розстрочку своїм покупцям та збільшити продажі',
            url: 'https://ain.ua/2021/12/07/yak-myttyevo-pidklyuchyty-rozstrochku-svoyim-pokupczyam-ta-zbilshyty-prodazhi/'
          },
          {
            title: 'Как сервис оплаты покупок частями Rulla развивает в Украине глобальный тренд e-commerce',
            url: 'https://ain.ua/2021/05/05/kak-servis-oplaty-pokupok-chastyami-rulla-razvivaet-v-ukraine-globalnyj-trend-e-commerce/'
          },
          {
            title: 'Сервіс оплати покупок частинами без відсотків від Rulla: як це працює',
            url: 'https://ua-news.liga.net/all/news/servis-oplati-pokupok-chastinami-bez-vidsotkiv-vid-rulla-yak-tse-pratsyue'
          },
          {
            title: 'Сервіс для вигідного онлайн-шопінгу Rulla — тепер в Україні',
            url: 'https://rau.ua/novyni/servis-onlajn-shopingu-rulla/'
          },
          {
            title: 'В Україні запускається Rulla — платіжний сервіс для вигідного онлайн-шопінгу — ain.ua',
            url: 'https://ain.ua/2021/03/04/v-ukraine-zapuskaetsya-rulla-platezhnyj-servis-dlya-vygodnogo-onlajn-shopinga/'
          }
        ]
      },
      releases: {
        header: 'Останні прес-релізи',
        links: [
          {
            title: 'Як безкоштовна розстрочка від Rulla збільшує продажі Stylus.ua',
            url: 'https://rulla.com/ua/blog/jak-bezkoshtovna-rozstrochka-vid-rulla-zbilshuje-prodazhi-stylusua'
          },
          {
            title: 'Вигідніше за еквайринг: новий універсальний платіжний сервіс Rulla',
            url: 'https://rulla.com/ua/blog/oplata-cherez-rulla-vygidnisha-dlia-magazyniv-nizh-ekvajring-detali'
          },
          {
            title: 'Сервіс для вигідного онлайн-шопінгу Rulla — тепер в Україні',
            url: 'https://rulla.com/ua/blog/servis-dlia-vygidnogo-onlajn-shopingu-rulla-teper-v-ukrajini'
          }
        ]
      },
      show_more: 'Завантажити більше',
      image_library: 'Бібліотека зображень',
      download: 'Завантажити',
      company_info: 'Інформація про компанію',
      download_presentation: 'Завантажити презентацію',
      text1: 'Технологічне платіжне рішення Rulla є новою розробкою досвідчених творців ' +
        'фінтех-продуктів для європейських ринків. Мета цього продукту — зробити ' +
        'покупки в інтернеті простіше та доступніше. ',
      text2: 'Наша місія — дати всім бажаючим можливість продавати та ' +
        'купувати онлайн максимально зручно, безпечно і вигідно. ',
      text3: 'Гнучкий платіжний сервіс Rulla — це новий для України фінансовий інструмент, ' +
        'який працює за моделлю "Купуй зараз — плати пізніше" (Buy Now Pay Later). Зараз ' +
        'Rulla пропонує клієнтам онлайн-магазинів оплату частинами. Користувачі сервісу ' +
        'отримують можливість сплатити за покупку трьома платежами, які автоматично списуються з ' +
        'картки кожні 30 днів без додаткових відсотків і комісій.',
      text4: 'ТОВ "РУЛЛА УКРАЇНА" не є банківською установою. ' +
        'Процес реєстрації та верифікації користувачів ' +
        'вимагає мінімум часу і даних. Для розгляду заявок та автоматизації процесів ' +
        'застосовуються новітні технології в галузі Big Data.',
      media_requests: 'Запити ЗМІ',
      request_here: 'Запити надсилайте на e-mail:',
      address_only_media: 'Зверніть увагу на те, що ця контактна адреса призначена лише для запитів представників ЗМІ, що стосуються публікацій.',
      have_questions: 'Якщо у вас є питання, пов\'язані з покупками, зверніться в',
      support: 'Службу підтримки покупців.'
    }
  }
};
