import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalizedRouterService } from 'src/app/core/routing/localized-router.service';

@Component({
  selector: 'app-header-lang',
  templateUrl: './header-lang.component.html',
  styleUrls: ['./header-lang.component.scss'],
})
export class HeaderLangComponent implements OnInit {

  visible = false;

  get language() { return this.translate.currentLang; }

  constructor(
    private translate: TranslateService,
    private localize: LocalizedRouterService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.route.data.subscribe(data => this.visible = !data.hideLanguageSelector);
  }

  changeLang(lang: 'ua' | 'ru' | 'pl' | 'es') {
    let child = this.route.snapshot;
    while (child.firstChild) {
      child = child.firstChild;
    }
    if (child.data.customNavigation) {
      this.translate.use(lang);
    } else {
      const url = new URL(this.router.url, window.location.href);
      const path = this.localize.changeLanguage(url.pathname, lang);
      this.translate.use(lang);
      this.router.navigateByUrl(path);
    }
  }
}
