import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

import { locale as ua } from 'src/assets/i18n/ua';
import { locale as ru } from 'src/assets/i18n/ru';
import { locale as pl } from 'src/assets/i18n/pl';
import { locale as es } from 'src/assets/i18n/es';

export interface Locale {
  lang: string;
  data: object;
}

@Injectable()
export class MyTranslationLoader implements TranslateLoader {

  translations: { [lang: string]: any } = {
    "ua": ua.data,
    "ru": ru.data,
    "pl": pl.data,
    "es": es.data,
  };

  getTranslation(lang: string): Observable<any> {
    return of(this.translations[lang]);
  }
}
