<ng-container *ngIf="!otpSent" [ngSwitch]="mode">

  <app-auth-login *ngSwitchCase="'sign-in'"
    #signIn
    [authType]="authType"
    [phoneNumber]="phoneNumber"
    (signedIn)="onSignIn($event)"
    (otpSent)="onOtpSent($event)"
    (typeChanged)="changeType($event)"
    (signUpRequested)="switchToSignUp($event)"
    (recoverRequested)="switchToRecover($event)">
  </app-auth-login>

  <app-auth-sign-up *ngSwitchCase="'sign-up'"
    [phoneNumber]="phoneNumber"
    (otpSent)="onOtpSent($event)"
    (loginRequested)="switchToSignIn($event)">
  </app-auth-sign-up>

  <app-auth-recover *ngSwitchCase="'recover'"
    [authType]="authType"
    [phoneNumber]="phoneNumber"
    (loginRequested)="switchToSignIn($event)">
  </app-auth-recover>

</ng-container>

<app-auth-code *ngIf="otpSent" 
  [authType]="authType"
  [authMode]="mode"
  [verificationId]="verificationId"
  [version]="version"
  [phoneNumber]="phoneNumber"
  (otpSent)="onOtpSent($event)"
  (signedIn)="onSignIn($event)"
  (backClicked)="otpSent = false">
</app-auth-code>
