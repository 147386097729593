import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Extensions } from 'src/app/core/core.extensions';
import { environment } from 'src/environments/environment';
import { AuthType, SwitchRequest } from '../models/auth.model';
import { RestorePasswordService } from '../services/restore-password.service';

@Component({
  selector: 'app-auth-recover',
  templateUrl: './auth-recover.component.html',
  styleUrls: ['../auth.scss', './auth-recover.component.scss']
})
export class AuthRecoverComponent implements OnInit {

  @Input() authType: AuthType;
  @Input() phoneNumber: string;

  @Output() loginRequested = new EventEmitter<SwitchRequest>();

  ctrl = {
    phoneNumber: new FormControl('', [Validators.required]),
  }
  
  form = new FormGroup(this.ctrl);
  passwordSent = false;
  loading = false;
  
  get validPhoneNumber() { 
    return this.ctrl.phoneNumber.valid 
      ? this.ctrl.phoneNumber.value 
      : null;
  }

  get language() { return this.translate.currentLang; }
  get countryCode() { return this.translate.instant("Common.countryCode") ?? environment.i18n.countryCode; }

  get area() {
    switch(this.authType) {
      case 'customer': return 'customer';
      case 'business': return 'store';
      default: 
        return null;
    }
  }

  constructor(
    private service: RestorePasswordService,
    private translate: TranslateService) { }

  ngOnInit() {
    if (this.phoneNumber)
      this.ctrl.phoneNumber.setValue(this.phoneNumber);
  }
  
  switchToLogin() {
    this.passwordSent = false;
    this.loginRequested.emit({ authType: this.authType, phoneNumber: this.validPhoneNumber });
    this.form.reset();
  }

  recover() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.loading = true;
    this.service
      .restorePassword(
        this.area,
        this.language,
        Extensions.toPhoneNumber(this.countryCode, this.ctrl.phoneNumber.value))
      .subscribe(
        response => this.onSuccess(response), 
        response => this.onError(response))
  }

  private onSuccess(response: any) {
    this.loading = false; 
    this.passwordSent = true 
  }
  
  private onError(response: any) {
    switch (response.status) {
      case 400:
        this.ctrl.phoneNumber.setErrors({'message': this.translate.instant('PersonalCabinet.Auth.user_not_found')});
        break;

      case 429:
        this.ctrl.phoneNumber.setErrors({'message': this.translate.instant('Validation.server_busy')})
        break;
    }
    this.ctrl.phoneNumber.markAsTouched();
    this.loading = false; 
  }
}
