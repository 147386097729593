import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { TokenService } from './token.service';
import { CustomerAuthService } from './customer-auth.service';

@Injectable({ providedIn: 'root' })
export class CustomerTokenService {

  private tokenChanges = new BehaviorSubject(this.token);
  
  get token() {
    return localStorage.getItem('customer.token');
  }
  
  set token(value) {
    this.tokenChanges.next(value);
    localStorage.setItem('customer.token', value);
  }

  constructor(
    private tokens: TokenService,
    private service: CustomerAuthService) { }

  async refreshTokenIfAboutToExpire(token: string | null): Promise<string | null> {
    if(token && this.tokens.isAboutToExpire(token) && !this.tokens.isInvalidOrExpired(token)){
      var data = await this.service.refreshToken().toPromise();
      this.token = data.token;
      return data.token;
    }
    return token;
  }

  removeToken() {
    localStorage.removeItem('customer.token');
  }
}
