import { Observable, from } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { CustomerTokenService } from '../auth/customer-token.service';
import { BusinessTokenService } from '../auth/business-token.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private customer: CustomerTokenService,
    private business: BusinessTokenService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handleAsync(request, next))
  }

  async handleAsync(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {

    if (request.headers.has('Authorization'))
      return next.handle(request).toPromise();

    const applyToken = (token: string | null) =>
      token
        ? request.clone({ setHeaders: { Authorization: `Bearer ${token}` } })
        : request;

    // store
    if (request.url.includes('/api/v1/store/')) {
      const token = this.business.token;
      if (request.url.endsWith('auth/token/refresh') ||
          request.url.endsWith('auth/sign-in')) {
        return next.handle(applyToken(token)).toPromise();
      }

      var newToken = await this.business.refreshTokenIfAboutToExpire(token);
      return next.handle(applyToken(newToken)).toPromise();
    }
    // customer
    else if (request.url.includes('/api/v1/customer/')) {
      const token = this.customer.token;
      if (request.url.endsWith('auth/token/refresh') ||
          request.url.endsWith('auth/sign-in')) {
        return next.handle(applyToken(token)).toPromise();
      }

      var newToken = await this.customer.refreshTokenIfAboutToExpire(token);
      return next.handle(applyToken(newToken)).toPromise();
    }
    else {
      return next.handle(request).toPromise();
    }
  }
}
