import { Directive, Optional, Self } from "@angular/core";
import { ControlValueAccessor, NgControl } from "@angular/forms";

@Directive()
export abstract class BaseFieldComponent implements ControlValueAccessor {

  public text: string;

  constructor(@Self() @Optional() public control: NgControl) {
    this.control.valueAccessor = this;
  }

  get touched() { return this.control.touched; }
  get invalid() { return this.control.invalid; }
  get errors() { return  this.control.errors; }

  public onChange = (x: any) => { };
  public onTouched = () => {};

  writeValue(obj: any) { this.text = obj; }
  registerOnChange(fn: any) { this.onChange = fn; }
  registerOnTouched(fn: any) { this.onTouched = fn; }
}
