import { Component, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { StringTools } from 'src/app/shared/string.tools';
import { environment } from 'src/environments/environment';
import { BaseFieldComponent } from '../fields.abstract';

@Component({
  selector: 'app-phone-field',
  templateUrl: './phone-field.component.html',
  styleUrls: ['../fields.scss', './phone-field.component.scss']
})
export class PhoneFieldComponent extends BaseFieldComponent implements OnInit {
  @Input() label: string = "";
  @Input() countryCode: string = environment.i18n.countryCode;

  get prefix() { return "+" + this.countryCode; }

  ngOnInit() {
    this.control.control.setValidators([
      Validators.required,
      Validators.pattern(/^(\s*\d\s*){9}$/)
    ]);
    this.control.control.updateValueAndValidity();
  }

  onKeyUp(e: KeyboardEvent) {
    const input = e.target as HTMLInputElement;
    if (input.selectionStart == 0 && input.selectionEnd == 0) {
      input.selectionStart = this.prefix.length;
    }
  }

  onPaste(e: ClipboardEvent) {
    const raw = e.clipboardData?.getData('text');
    if (!raw) return;

    const clean = StringTools.getCleanPhoneNumber(raw);

    if (clean) {
      e.preventDefault();
      this.control.control.setValue(clean);
    }
  }
}
