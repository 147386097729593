import { Pipe, PipeTransform } from "@angular/core";
import { LocalizedRouterService } from "./localized-router.service";

@Pipe({name: 'localize', pure: false})
export class LocalizedRouterPipe implements PipeTransform {

  constructor(private localize: LocalizedRouterService) { }

  transform(value: string | any[], ...args: any[]) {
    const after = this.localize.translateRoute(value);
    return after;
  }
}
