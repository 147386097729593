import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export type MenuType = 'customer' | 'business' | 'choose';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  
  @Input() isAuth = false;

  @Output() loginClicked = new EventEmitter<boolean>();
  @Output() menuToggled = new EventEmitter<MenuType>();

  activeMenu?: MenuType;

  get language() { return this.translate.currentLang; }

  constructor(private translate: TranslateService) { }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (event.target.innerWidth > 991) {
      this.hideMenu();
    }
  }

  onLoginClick() {
    this.loginClicked.emit(true);
  }

  onHeaderLeave() {
    if (window.innerWidth > 991) {
      this.hideMenu();
    }
  }
  
  showMenu(menuType: MenuType) {
    this.activeMenu = menuType;
    this.menuToggled.emit(menuType);
  }

  hideMenu() {
    this.activeMenu = null;
    this.menuToggled.emit(null);
  }
}
