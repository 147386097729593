import { Route } from "@angular/router";
import { environment } from "src/environments/environment";
import { LanguageSelectionGuard } from "../guards/language-selection.guard";

type Localization = { [key: string]: string };
type Localizations = { [language: string]: Localization };

const localizations: Localizations = {
  ua: {
    'pay-later': 'shho-take-rulla',
    'customer-service': 'klientskyj-servis',
    'shops': 'magazyny',
    'sell-with-rulla': 'prodavaty-z-rulla',
    'about-us': 'pro-nas',
    'seller-support': 'pidtrymka-prodavcia',
    'seller-buyer': 'prodavec-pokupec',
    'account-management': 'keruvannia-akauntom',
    'integration': 'tehnichna-integracija',
    'terms-and-conditions': 'pravyla-ta-umovy',
    'three-payments': 'oplata-chastynamy',
    'legal': 'legal',
    'blog': 'blog',
  },
  ru: {
    'pay-later': 'chto-takoe-rulla',
    'customer-service': 'klientskij-servis',
    'shops': 'magaziny',
    'sell-with-rulla': 'prodavat-s-rulla',
    'about-us': 'o-nas',
    'seller-support': 'podderzhka-prodavca',
    'seller-buyer': 'prodavec-pokupatel',
    'account-management': 'upravlenie-akkauntom',
    'integration': 'tehnicheskaja-integracija',
    'terms-and-conditions': 'pravila-i-uslovija',
    'three-payments': 'oplata-chastiami',
    'legal': 'legal',
    'blog': 'blog',
  },
  pl: {
    'pay-later': 'what-is-rulla',
    'customer-service': 'customer-service',
    'shops': 'shops',
    'sell-with-rulla': 'sell-with-rulla',
    'about-us': 'about-us',
    'seller-support': 'seller-support',
    'seller-buyer': 'seller-buyer',
    'account-management': 'account-management',
    'integration': 'integration',
    'terms-and-conditions': 'terms-and-conditions',
    'three-payments': 'three-payments',
    'legal': 'legal',
    'blog': 'blog',
  },
  es: {
    'pay-later': 'what-is-rulla',
    'customer-service': 'customer-service',
    'shops': 'shops',
    'sell-with-rulla': 'sell-with-rulla',
    'about-us': 'about-us',
    'seller-support': 'seller-support',
    'seller-buyer': 'seller-buyer',
    'account-management': 'account-management',
    'integration': 'integration',
    'terms-and-conditions': 'terms-and-conditions',
    'three-payments': 'three-payments',
    'legal': 'legal',
    'blog': 'blog',
  },
}

const invert = '';

export class RouteLocalizer {

  private static inverted = RouteLocalizer.invert(localizations);

  static forRoot(routes: Route[]): Route[] {
    const result: Route[] = [];
    for(let language of environment.i18n.languages) {
      const route: Route = {
        path: language,
        data: { language: language },
        children: this.forChild(routes, language),
        canActivate: [LanguageSelectionGuard],
      };
      result.push(route);
    }
    return result;
  }

  static forChild(routes: Route[], language: string): Route[] {
    const result: Route[] = [];
    for(let route of routes) {
      const clone: Route = {...route};
      clone.data = {...route.data, original: route.path};
      clone.path = this.translatePath(route.path, language).join('/');
      clone.children = route.children ? this.forChild(route.children, language) : null;
      result.push(clone);
    }
    return result;
  }

  static translatePath(path: string, language: string): string[] {
    return path
      .replace(/\/+$/, '')
      .replace(/\/+/g, '/')
      .split('/')
      .map(x => this.translateSegment(x, language));
  }

  static invertPath(path: string): string[] {
    const segments = path
      .replace(/\/+$/, '')
      .replace(/\/+/g, '/')
      .split('/');

    if (segments.length < 2) return [];

    const empty = segments[0];
    const language = segments[1];
    const keys = segments.splice(2).map(x => this.invertSegment(x, language));

    return [empty, ...keys];
  }

  static translateSegment(segment: string, language: string) {
    const localization = localizations[language];
    return localization ? localization[segment] ?? segment : segment;
  }
  
  static invertSegment(segment: string, language: string) {
    const localization = this.inverted[language];
    return localization ? localization[segment] ?? segment : segment;
  }

  private static invert(original: Localizations) {
    const inverted: Localizations = {};
    for (let language in original) {
      inverted[language] = {};
      for (let key in original[language]) {
        inverted[language][original[language][key]] = key;
      }
    }
    return inverted;
  }
}
