import { Component, EventEmitter, Output } from '@angular/core';

type MenuType = 'customer' | 'business';

@Component({
  selector: 'app-menu-choose',
  templateUrl: './menu-choose.component.html',
  styleUrls: ['./menu-choose.component.scss'],
})
export class MenuChooseComponent {
  @Output() chosen = new EventEmitter<MenuType>();

  showMenu(menuType: MenuType) {
    this.chosen.emit(menuType);
  }
}
