import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { CustomerTokenService } from '../auth/customer-token.service';
import { TokenService } from '../auth/token.service';
import { LocalizedRouterService } from '../routing/localized-router.service';

@Injectable({ providedIn: 'root' })
export class AuthorizedCustomerGuard implements CanActivate {

  constructor(
    private tokens: TokenService,
    private customer: CustomerTokenService,
    private localize: LocalizedRouterService,
    private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const expired = this.tokens.isInvalidOrExpired(this.customer.token);
    if (!expired)
      return true;
    
    localStorage.authType = 'customer';
    const language = this.localize.language;
    this.router.navigate(['/', language, 'auth']);
    return false;
  }
}
