<div class="content">

  <ng-container [ngSwitch]="authType">
    <h2 *ngSwitchCase="'business'" class="title">{{'PersonalCabinet.Auth.business_login' | translate}}</h2>
    <h2 *ngSwitchCase="'customer'" class="title">{{'PersonalCabinet.Auth.profile_login' | translate}}</h2>
  </ng-container>

  <p class="subtitle">{{'PersonalCabinet.Auth.enter_number_to_login' | translate}}</p>
  
  <form [formGroup]="form" (submit)="login()">
  
    <div class="swiper">
      <app-swiper-ex
        leftValue="customer"
        rightValue="business"
        [leftLabel]="'PersonalCabinet.Auth.customer' | translate"
        [rightLabel]="'PersonalCabinet.Auth.business' | translate"
        [value]="authType"
        (change)="ctrl.password.reset();typeChanged.emit($event)">
      </app-swiper-ex>
    </div>

    <div class="phone">
      <app-phone-field
        [label]="'PersonalCabinet.Auth.phone' | translate"
        [countryCode]="countryCode"
        [formControl]="ctrl.phoneNumber">
      </app-phone-field>
    </div>

    <div class="password">
      <app-password-field
        [label]="'PersonalCabinet.Auth.password' | translate"
        [formControl]="ctrl.password">
      </app-password-field>
    </div>
    
    <div class="action">
      <app-button-common type="submit" [disabled]="loading"><p>{{'PersonalCabinet.Auth.login' | translate}}</p></app-button-common>
    </div>

    <div class="link">
      <a (click)="switchToRestore()">{{'PersonalCabinet.Auth.forgot_password' | translate}}</a>
    </div>

    <div class="link" *ngIf="authType == 'customer'">
      <a (click)="switchToSignUp()">{{'PersonalCabinet.Auth.sign_up' | translate}}</a>
    </div>

  </form>
</div>
