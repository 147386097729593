import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-button-arrow',
  templateUrl: './button-arrow.component.html',
  styleUrls: ['./button-arrow.component.scss'],
})
export class ButtonArrowComponent {
  @Input() leftArrow: boolean;
  @Input() disabled: boolean;
  @Input() arrowType: 'chevron' | 'chevron_cirlce' | 'arrow' = 'chevron';
}
