import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RestorePasswordService {
  constructor(private http: HttpClient) { }

  restorePassword(area: 'customer' | 'store', language: string, phoneNumber: string): Observable<any> {
    return this.http
      .post<any>(`api/v1/${area}/auth/restore-password`, <RestorePasswordRequest> { phoneNumber, language })
      .pipe(take(1));
  }

  changePassword(area: 'customer' | 'store', password: string, token: string): Observable<any> {
    return this.http
      .post<any>(`api/v1/${area}/auth/change-password`, <ChangePasswordRequest> { password }, { headers: { Authorization: `Bearer ${token}` } })
      .pipe(take(1));
  }
}

export interface RestorePasswordRequest {
  phoneNumber: string; 
  language: string;
}

export interface ChangePasswordRequest {
  password: string;
}
