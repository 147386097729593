import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { stringify } from '@angular/compiler/src/util';

@Injectable({ providedIn: 'root' })
export class FileDownloaderService {

  constructor(private http: HttpClient) { }

  download(path: string, params?: any, fileName?: string, mimetype?: string): void {
    (this.http.get(path, { params: params, responseType: 'blob', observe: 'response' })).subscribe(resp => {
      if (!fileName || !fileName.trim().length) {
        var contentDisposition = resp.headers.get('content-disposition');
        fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
      }
      if (!mimetype || !mimetype.trim().length) {
        mimetype = resp.headers.get('content-type');
      }

      const blob = new Blob([resp.body], { type: mimetype });
      var a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.download = fileName;
      a.click();
    },
      (error) => {
        console.log(`File downloading error. Path: ${path}. Params:${JSON.stringify(params) } \r\n`);
        console.error(error);
      });
  }
}
