<div class="field" [class.error]="invalid && touched">
  <label>{{label}}</label>

  <img src="assets/icons/eye2.svg"
    class="show-password" 
    [class.active]="showPassword"
    (click)="showPassword = !showPassword" />

  <input type="text"
    [type]="showPassword ? 'text' : 'password'"
    [placeholder]="placeholder"
    [(ngModel)]="text"
    [ngModelOptions]="{standalone: true}"
    (ngModelChange)="onChange($event)"
    (blur)="onTouched()" />
  
  <div class="error-message" *ngIf="invalid && touched">
    {{'PersonalCabinet.Auth.invalid_password' | translate}}
  </div>
</div>
