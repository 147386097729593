<div class="content">

  <h2 class="title" *ngIf="authType === 'business'">{{'PersonalCabinet.Auth.business_login' | translate}}</h2>
  <h2 class="title" *ngIf="authType === 'customer'">{{'PersonalCabinet.Auth.profile_login' | translate}}</h2>

  <p class="subtitle">{{'PersonalCabinet.Auth.enter_number_to_recover' | translate}}</p>
  <p class="subtitle" *ngIf="passwordSent">{{'PersonalCabinet.Auth.password_sent' | translate}}</p>

  <form *ngIf="!passwordSent" [formGroup]="form">
    <div class="phone">
      <app-phone-field
        [label]="'PersonalCabinet.Auth.phone' | translate"
        [countryCode]="countryCode"
        [formControl]="ctrl.phoneNumber">
      </app-phone-field>
    </div>

    <div class="action">
      <app-button-common (click)="recover()" [disabled]="loading">
        <p>{{'PersonalCabinet.Auth.send_password' | translate}}</p>
      </app-button-common>
    </div>
  </form>
  
  <div class="link">
    <a (click)="switchToLogin()">{{'PersonalCabinet.Auth.return_to_login' | translate}}</a>
  </div>
</div>
