import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class SearchService {

  public placeholders = new ReplaySubject<string>(1);
  public searchExpression = new ReplaySubject<string>(1);

  setPlaceholder(placeholder: string) {
    this.placeholders.next(placeholder);
  }

  search(text: string) {
    this.searchExpression.next(text.toLowerCase());
  }
}
