import { Injectable } from '@angular/core';
import { TenantId } from '../models/tenant.model';

@Injectable({ providedIn: 'root' })
export class BusinessTenantService {

  get tenant(): TenantId {
    const value = localStorage.getItem('business.tenant');
    if (value) {
      return parseInt(value);
    }

    return null;
  }

  set tenant(value: TenantId) {
    localStorage.setItem('business.tenant', value.toString());
  }
}
