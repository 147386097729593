import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { filter, map } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-code-input',
  templateUrl: './code-input.component.html',
  styleUrls: ['./code-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CodeInputComponent),
      multi: true
    }
  ]
})
export class CodeInputComponent implements ControlValueAccessor, OnInit, OnChanges, OnDestroy {

  @Input() invalidCode = false;

  @Output() codeCompleted: EventEmitter<any> = new EventEmitter<any>();
  
  @ViewChild('input1') input1: ElementRef;
  @ViewChild('input2') input2: ElementRef;
  @ViewChild('input3') input3: ElementRef;
  @ViewChild('input4') input4: ElementRef;

  destroy = new ReplaySubject<any>(1);
  error = false;
  form = new FormGroup({
    digit1: new FormControl(''),
    digit2: new FormControl(''),
    digit3: new FormControl(''),
    digit4: new FormControl(''),
  });
  
  get digit1() { return this.form.get('digit1'); }
  get digit2() { return this.form.get('digit2'); }
  get digit3() { return this.form.get('digit3'); }
  get digit4() { return this.form.get('digit4'); }

  constructor() { }

  ngOnInit() {
    const whenSingle = filter((value: string) => value.length === 1);
      
    this.digit1?.valueChanges.pipe(whenSingle).subscribe(() => this.input2.nativeElement.focus());
    this.digit2?.valueChanges.pipe(whenSingle).subscribe(() => this.input3.nativeElement.focus());
    this.digit3?.valueChanges.pipe(whenSingle).subscribe(() => this.input4.nativeElement.focus());
  }

  ngOnChanges() {
    this.error = this.invalidCode;
  }

  onBackspace(position: Number) {
    if (position === 4 && this.digit4?.value === '') {
      this.digit3?.patchValue('');
      this.input3.nativeElement.focus();
    }

    if (position === 3 && this.digit3?.value === '') {
      this.digit2?.patchValue('');
      this.input2.nativeElement.focus();
    }

    if (position === 2 && this.digit2?.value === '') {
      this.digit1?.patchValue('');
      this.input1.nativeElement.focus();
    }
  }

  onFocus() {
  }

  onFocusOut() {
  }

  writeValue(value: string) {
    if (value) {
      const pin = value.substring(0, 4);
      this.digit1?.patchValue(pin[0] ?? '');
      this.digit2?.patchValue(pin[1] ?? '');
      this.digit3?.patchValue(pin[2] ?? '');
      this.digit4?.patchValue(pin[3] ?? '');
    }
  }

  registerOnChange(fn: any) {
    this.form.valueChanges.pipe(map(_ => this.getPIN())).subscribe(fn);
  }

  registerOnTouched(fn: any) {
  }

  getPIN(): string {
    return "" +
      this.getCharacter("digit1") +
      this.getCharacter("digit2") +
      this.getCharacter("digit3") +
      this.getCharacter("digit4");
  }

  onPaste(event: any, fieldNumber: number) {
    const clipboardData = event.clipboardData || (<any>window)['clipboardData'];
    const pastedText = clipboardData.getData('text');
    for (let i = 0; i < pastedText.length; i++) {
      if (fieldNumber === 1 ) {
        this.digit1?.patchValue(pastedText[i], {emitEvent: false});

        this.form.patchValue({digit1: pastedText[i]}, {emitEvent: false});
        fieldNumber++;
      } else if (fieldNumber === 2 ) {
        this.form.patchValue({digit2: pastedText[i]}, {emitEvent: false});
        this.input2.nativeElement.focus();
        fieldNumber++;
      } else if (fieldNumber === 3 ) {
        this.form.patchValue({digit3: pastedText[i]}, {emitEvent: false});
        this.input3.nativeElement.focus();
        fieldNumber++;
      } else if (fieldNumber === 4 ) {
        this.form.patchValue({digit4: pastedText[i]}, {emitEvent: false});
        this.input4.nativeElement.focus();
        break;
      }
    }
  }

  getCharacter(field: string): string {
    return this.form.get(field)?.value || "";
  }

  ngOnDestroy() {
    this.destroy.next(null);
    this.destroy.complete();
  }
}
