import { Injectable } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { EMPTY, of } from "rxjs";
import { Extensions, ServerError } from "../core.extensions";

@Injectable()
export class ErrorService {

  constructor(
    private translate: TranslateService,
    private snacks: MatSnackBar) { }

  handle(response: { error: ServerError, status: number }, map: (field: string) => FormControl) {
    if (response.status == 400) {
      Extensions.handleServerErrors(response.error, map);
      return true; // handled
    } 
    else {
      this.popup(30000);
      return false;
    }
  }

  handleRequestError() {
    this.popup(5000);
    return EMPTY;
  }

  popup(duration: number) {
    const translation = this.translate.instant('Errors.server');
    this.snacks.open(translation, null, { duration: duration, panelClass: 'error' });
  }
}
