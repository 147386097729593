import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-swiper-ex',
  templateUrl: './swiper-ex.component.html',
  styleUrls: ['./swiper-ex.component.scss']
})
export class SwiperExComponent implements OnInit {

  @Input() leftLabel: string = 'Left'
  @Input() rightLabel: string = 'Right'

  @Input() leftValue: any = 'left';
  @Input() rightValue: any = 'right';

  @Input() value: any = 'left';

  @Output() change = new EventEmitter<any>();

  get leftSelected() { return this.value == this.leftValue; }
  get rightSelected() { return this.value == this.rightValue; }

  constructor() { }

  ngOnInit() {
    if (
      this.value != this.leftValue && 
      this.value != this.rightValue) {
        this.value = this.leftValue;
        this.change.emit(this.value);
      }
  }

  toggle() {
    this.value = this.leftSelected ? this.rightValue : this.leftValue;
    this.change.emit(this.value);
  }
}
