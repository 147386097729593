import * as moment from 'moment';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { LocalizedRouterService } from './core/routing/localized-router.service';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private localize: LocalizedRouterService,
    private renderer: Renderer2,
    private translate: TranslateService) {

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        let r = route.snapshot; 
        while (r.firstChild) {
          r = r.firstChild;
        }
        if (true || !r.data.subpage) {
          document.body.style.overflow = 'auto';
          window.scrollTo(0, 0);
        }
        if (r.data.language) {
          this.setTranslations();
        }
        if (r.data.title) {
          document.title = 'Rulla – ' + this.translate.instant(r.data.title);
        } else if (!r.data.customNavigation) {
          document.title = this.translate.instant('Common.title');
        }
        gtag('config', 'UA-191152839-1', { 'page_path': evt.urlAfterRedirects });
      }
    });
  }

  ngOnInit() {
    this.updateLanguage(this.localize.language);
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => this.updateLanguage(event.lang));
  }

  updateLanguage(language: string) {
    const locale = language == 'ua' ? 'uk' : language;
    moment.locale(locale);
    document.documentElement.setAttribute('lang', locale);
    localStorage.setItem("language", language);
  }

  setTranslations() {
    for (let language of this.localize.languages) {
      const url = new URL(this.router.url, window.location.href);
      const path = this.localize.changeLanguage(url.pathname, language);
      const translationUrl = new URL(path, window.location.href);
      this.setTranslation(language, translationUrl.href);
    }
  }

  setTranslation(language: string, url: string) {
    const elements = Array.from(document.head.children);
    const existing = elements.find(e => 
      e.getAttribute("rel") == "alternate" && 
      e.getAttribute("hreflang") == language);

    if (existing) {
      this.renderer.setAttribute(existing, "href", url);

    } else {
      const alternate = this.renderer.createElement('link');
      this.renderer.setAttribute(alternate, 'rel', 'alternate');
      this.renderer.setAttribute(alternate, 'hreflang', language);
      this.renderer.setAttribute(alternate, 'href', url);
      this.renderer.appendChild(document.head, alternate);
    }
  }
}
