import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class UrlResolutionGuard implements CanActivate {

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const code = route.params.code;
    if (code.match(/^[a-zA-Z0-9]{6,10}$/)){
      window.location.href = environment.apiUrl + 'u/' + code;
      return true;
    } else {
      return false;
      //this.router.navigate(['not-found'], { skipLocationChange: true });
    }
  }
}
