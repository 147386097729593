import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-menu-customer',
  templateUrl: './menu-customer.component.html',
  styleUrls: ['./menu-customer.component.scss'],
})
export class MenuCustomerComponent {

  @Output() linkClicked = new EventEmitter<string>();
  @Output() backClicked = new EventEmitter<any>();
  
  onLinkClicked(link: string) {
    this.linkClicked.emit(link);
  }

  onBackClicked() {
    this.backClicked.emit();
  }
}
