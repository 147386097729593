<section id="main-wrapper" [class.no_overflow_mobile]="showDesktopMenu">
  <div class="main-content">
    <div class="opened-auth-menu" [class.hide]="showDesktopMenu">
    </div>
    <app-header [isAuth]="isAuthPage" (loginClicked)="login.show()" (menuToggled)="toggleDesktopMenu($event)">
    </app-header>
    <div id="overflow-wrapper" [ngClass]="{'auth-page': isAuthPage}">
      <router-outlet></router-outlet>
    </div>
    <app-footer *ngIf="!isAuthPage"></app-footer>
    <app-cookie-consent></app-cookie-consent>
  </div>
</section>
<app-auth-modal #login>
</app-auth-modal>