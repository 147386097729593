import * as moment from 'moment';
import jwt_decode, { JwtPayload } from "jwt-decode";
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TokenService {

  isAboutToExpire(token: string | null): boolean {
    if(!token) return true;

    const tokenData = jwt_decode<RullaJwtPayload>(token);
    if(!tokenData) return true;
    if(!tokenData.refreshAt) return false;
    return moment().utc().unix() >= parseInt(tokenData.refreshAt);
  }

  isInvalidOrExpired(token: string | null): boolean {
    if(!token) return true;

    const data = jwt_decode<JwtPayload>(token);
    if (!data) return true;
    if (!data.exp) return true;

    return moment().utc().unix() >= data.exp
  }
}

export interface RullaJwtPayload extends JwtPayload {
  refreshAt: string;
}
