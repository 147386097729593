import { environment } from 'src/environments/environment';
import { ReplaySubject } from 'rxjs';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { Extensions } from 'src/app/core/core.extensions';
import { AreaSignInResult, SignInStatus } from 'src/app/core/models/auth.model';
import { CustomerAuthService } from 'src/app/core/auth/customer-auth.service';
import { BusinessAuthService } from 'src/app/core/auth/business-auth.service';
import { AuthType, SwitchRequest } from '../models/auth.model';
import { CustomValidators } from 'src/app/shared/ui-kit/fields/custom.validators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-auth-login',
  templateUrl: './auth-login.component.html',
  styleUrls: ['../auth.scss', './auth-login.component.scss'],
})
export class AuthLoginComponent implements OnInit, OnDestroy{
  
  @Input() authType: AuthType;
  @Input() phoneNumber: string;

  @Output() recoverRequested = new EventEmitter<SwitchRequest>();
  @Output() signUpRequested = new EventEmitter<SwitchRequest>();
  @Output() typeChanged = new EventEmitter<AuthType>();
  @Output() otpSent = new EventEmitter<AreaSignInResult>();
  @Output() signedIn = new EventEmitter<AreaSignInResult>();

  ctrl = {
    phoneNumber: new FormControl('', [Validators.required]),
    password: new FormControl('', [CustomValidators.requiredNotWhitespace])
  }

  form = new FormGroup(this.ctrl);
  loading = false;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);

  get countryCode() { return this.translate.instant("Common.countryCode"); }
  get validPhoneNumber() { 
    return this.ctrl.phoneNumber.valid 
      ? this.ctrl.phoneNumber.value 
      : null;
  }

  constructor(
    private customer: CustomerAuthService,
    private business: BusinessAuthService,
    private translate: TranslateService) { }

  ngOnInit() {
    if (this.phoneNumber)
      this.ctrl.phoneNumber.setValue(this.phoneNumber);
  }
  
  switchToRestore() {
    this.recoverRequested.emit({ authType: this.authType, phoneNumber: this.validPhoneNumber });
    this.form.reset();
  }

  switchToSignUp() {
    this.signUpRequested.emit({ authType: this.authType, phoneNumber: this.validPhoneNumber });
    this.form.reset();
  }

  login() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.form.markAsDirty();
      return;
    }

    const request = {
      phoneNumber: Extensions.toPhoneNumber(this.countryCode, this.ctrl.phoneNumber.value),
      password: this.ctrl.password.value,
    };

    switch(this.authType) {
      case 'customer':
        this.signInCustomer(request.phoneNumber, request.password);
        break;
      
      case 'business':
        this.signInBusiness(request.phoneNumber, request.password);
        break;
    }
  }

  private signInCustomer(phoneNumber: string, password: string) {
    this.loading = true;
    this.customer
      .signIn({ phoneNumber, password })
      .subscribe(
        response => this.onSuccess(response),
        response => this.onError(response));
  }

  private signInBusiness(phoneNumber: string, password: string) {
    this.loading = true;
    this.business
      .signIn({ phoneNumber, password })
      .subscribe(
        response => this.onSuccess(response),
        response => this.onError(response));
  }

  private onSuccess(response: AreaSignInResult) {

    switch(response.status) {
      case SignInStatus.Failure:
        this.ctrl.password.setErrors({'server': true});
        this.ctrl.password.markAsTouched();
        break;

      case SignInStatus.OtpSent:
        this.otpSent.emit(response);
        break;

      case SignInStatus.Success:
        this.signedIn.emit(response);
        break;
    }
    
    this.loading = false;
  }

  private onError(response: any) {
    if (response.status == 400) {
      this.ctrl.phoneNumber.setErrors({'server': true});
      this.ctrl.phoneNumber.markAsTouched();
    }
    this.loading = false;
  }

  ngOnDestroy() {
    this.destroy.next(null);
    this.destroy.complete();
  }
}
