<form [formGroup]="form" (submit)="verifyCode()">
  <div class="content">
    <h2 class="title">{{'PersonalCabinet.Auth.enter_sms_code' | translate}}</h2>
    <p class="subtitle">{{'PersonalCabinet.Auth.send_on' | translate}} +{{phoneNumber}}</p>
    <app-code-input [formControl]="controls.pin" [invalidCode]="authError || alreadyAuthed"></app-code-input>
    <div class="resend" *ngIf="timerValue === 0; else timerBlock">
      <app-button-text (click)="resend()">
        <img src="assets/icons/reload.svg">
        <p>{{'PersonalCabinet.Auth.send_one_more_time' | translate}}</p>
      </app-button-text>
    </div>
    <ng-template #timerBlock>
      <div class="timer">
        <p>{{'PersonalCabinet.Auth.wait' | translate}} <i>{{timerValue}} {{'PersonalCabinet.Auth.seconds' | translate}}</i></p>
      </div>
    </ng-template>
    <div class="send">
      <app-button-common type="submit">
        <p>{{'PersonalCabinet.Auth.send_code' | translate}}</p>
      </app-button-common>
    </div>
    <div class="back">
      <app-button-common type="button" [whiteButton]="true" (click)="back()">
        <p>{{'PersonalCabinet.Auth.back' | translate}}</p>
      </app-button-common>
    </div>
  </div>
</form>
