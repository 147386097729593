<footer>
  <div class="container">
    <div class="footer">
      <div class="footer__column">
        <div class="footer__column__top_items">
          <div class="footer__column_logo">
            <img src="assets/images/footer/footer_logo.png">
          </div>
          <p>{{'Footer.all_ukraine' | translate}}</p>
        </div>
        <div class="footer__column__lower_items">
          <p>Rulla © {{currentYear}}</p>
        </div>
      </div>
      <div class="footer__column">
        <div class="footer__column__top_items">
          <h3>Rulla</h3>
          <a *ngIf="language != 'pl'" [routerLink]="'/legal' | localize">{{'Footer.legal' | translate}}</a>
          <a [routerLink]="'/about-us' | localize">{{'Footer.about_company' | translate}}</a>
          <a [routerLink]="'/shops' | localize">{{'Footer.shops' | translate}}</a>
          <a [routerLink]="'/pay-later' | localize">{{'Footer.pay_later' | translate}}</a>
          <a [routerLink]="'/blog' | localize">{{'Footer.blog' | translate}}</a>
          <a *ngIf="language != 'pl'" [routerLink]="'/press' | localize">{{'Footer.press' | translate}}</a>
        </div>
        <div class="footer__column__lower_items">
          <div class="phone">
            <img src="assets/images/footer/footer_phone.svg">
            <p><a href="tel:0800504955">0 800 504 955</a> {{'AboutUs.contacts.hot_line' | translate}}</p>
          </div>
          <div class="phone">
            <img src="assets/images/footer/footer_phone.svg">
            <p><a href="tel:+380667331817">+380 66 733 1817</a> {{'AboutUs.contacts.sales_department' | translate}}</p>
          </div>
        </div>
      </div>
      <div class="footer__column">
        <div class="footer__column__top_items">
          <h3>{{'Footer.for_business' | translate}}</h3>
          <a [routerLink]="'/sell-with-rulla' | localize">{{'Footer.sell_with_rulla' | translate}}</a>
          <a [routerLink]="'/seller-support' | localize">{{'Footer.seller_support' | translate}}</a>
        </div>
      </div>
      <div class="footer__column">
        <div class="footer__column__top_items">
          <h3>{{'Footer.for_clients' | translate}}</h3>
          <a [routerLink]="'/customer-service' | localize">{{'Footer.customer_service' | translate}}</a>
          <a *ngIf="language != 'pl'" [routerLink]="'/terms-and-conditions' | localize">{{'Footer.terms_and_conditions' | translate}}</a>
          <a *ngIf="language != 'pl'" href="https://rulla.com/shared/documents/informaciya_pro_dohovir_pro_nadannya_finansovykh_posluh.docx">{{'Footer.financial_services' | translate}}</a>
        </div>
        <div class="footer__column__lower_items">
          <div class="email" *ngIf="['ua', 'ru'].includes(language)">
            <img src="assets/images/footer/footer_mail.svg">
            <a href="mailto:sales@rulla.com">sales@rulla.com</a>
          </div>
          <div class="email" *ngIf="['pl'].includes(language)">
            <img src="assets/images/footer/footer_mail.svg">
            <a href="mailto:wspolpraca@rulla.com">wspolpraca@rulla.com</a>
          </div>
          <div class="email" *ngIf="['es'].includes(language)">
            <img src="assets/images/footer/footer_mail.svg">
            <a href="mailto:info@prestamorapido.es">info@prestamorapido.es</a>
          </div>
          <div class="smm">
            <img src="assets/icons/smm/facebook.svg">
            <a href="https://www.facebook.com/rullacom" target="_blank">facebook</a>
          </div>
          <div class="smm">
            <img src="assets/icons/smm/instagram.svg">
            <a href="https://www.instagram.com/rullacom/" target="_blank">instagram</a>
          </div>
          <div class="smm">
            <img src="assets/icons/smm/twitter.svg">
            <a href="https://twitter.com/RullaUkraine" target="_blank">twitter</a>
          </div>
          <div class="smm">
            <img src="assets/icons/smm/youtube.svg">
            <a href="https://www.youtube.com/channel/UClIVmRsDX3-OYRygoH84CzQ" target="_blank">youtube</a>
          </div>
          <div class="smm">
            <img src="assets/icons/smm/producthunt.svg">
            <a href="https://www.producthunt.com/@rulla" target="_blank">producthunt</a>
          </div>
        </div>
      </div>
      <div class="footer__column__mobile">
        <div class="phone" *ngIf="['ua', 'ru'].includes(language)">
          <img src="assets/images/footer/footer_phone.svg">
          <p><a href="tel:0800504955">0 800 504 955</a> {{'AboutUs.contacts.hot_line' | translate}}</p>
        </div>
        <div class="phone" *ngIf="['ua', 'ru', 'es'].includes(language)">
          <img src="assets/images/footer/footer_phone.svg">
          <p><a href="tel:+380667331817">+380 66 733 1817</a> {{'AboutUs.contacts.sales_department' | translate}}</p>
        </div>
        <div class="phone" *ngIf="['pl'].includes(language)">
          <img src="assets/images/footer/footer_phone.svg">
          <p><a href="tel:+48222572324">+48 22 257 2324</a> {{'AboutUs.contacts.sales_department' | translate}}</p>
        </div>
        <div class="email" *ngIf="['ua', 'ru'].includes(language)">
          <img src="assets/images/footer/footer_mail.svg">
          <a href="mailto:sales@rulla.com">sales@rulla.com</a>
        </div>
        <div class="email" *ngIf="['pl'].includes(language)">
          <img src="assets/images/footer/footer_mail.svg">
          <a href="mailto:wspolpraca@rulla.com">wspolpraca@rulla.com</a>
        </div>
        <div class="email" *ngIf="['es'].includes(language)">
          <img src="assets/images/footer/footer_mail.svg">
          <a href="mailto:info@prestamorapido.es">info@prestamorapido.es</a>
        </div>
        <div class="smm" *ngIf="['ua', 'ru', 'es'].includes(language)">
          <img src="assets/icons/smm/facebook.svg">
          <a href="https://www.facebook.com/rullacom" target="_blank">facebook</a>
        </div>
        <div class="smm" *ngIf="['ua', 'ru', 'es'].includes(language)">
          <img src="assets/icons/smm/instagram.svg">
          <a href="https://www.instagram.com/rullacom/" target="_blank">instagram</a>
        </div>
        <div class="smm" *ngIf="['ua', 'ru', 'es'].includes(language)">
          <img src="assets/icons/smm/twitter.svg">
          <a href="https://twitter.com/RullaUkraine" target="_blank">twitter</a>
        </div>
        <div class="smm" *ngIf="['ua', 'ru', 'es'].includes(language)">
          <img src="assets/icons/smm/youtube.svg">
          <a href="https://www.youtube.com/channel/UClIVmRsDX3-OYRygoH84CzQ" target="_blank">youtube</a>
        </div>
        <div class="smm" *ngIf="['ua', 'ru', 'es'].includes(language)">
          <img src="assets/icons/smm/producthunt.svg">
          <a href="https://www.producthunt.com/@rulla" target="_blank">producthunt</a>
        </div>
        <p>Rulla © {{currentYear}}</p>
      </div>
    </div>
  </div>
</footer>
