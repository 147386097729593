import { Component, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';

type MenuType = 'customer' | 'business' | 'choose'

@Component({
  selector: 'app-menu-mobile',
  templateUrl: './menu-mobile.component.html',
  styleUrls: ['./menu-mobile.component.scss'],
})
export class MenuMobileComponent {

  @Input() activeMenu?: MenuType;

  @Output() navigation = new EventEmitter<any>();
  @Output() cancel = new EventEmitter<any>();

  @ViewChild('insideMenu') insideMenu;
  @ViewChild('insideMenuContainer') insideMenuContainer;
  
  showMenu(menuType: MenuType) {
    this.activeMenu = menuType;
  }
  
  onLinkClick() {
    this.navigation.emit();
  }
  
  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    const clickedInsideMenu = this.insideMenu.nativeElement.contains(targetElement);
    const clickedInsideContainer = this.insideMenuContainer.nativeElement.contains(targetElement);
    if (!clickedInsideMenu && clickedInsideContainer) {
      this.cancel.emit();
      this.activeMenu = null;
    }
  }
}
