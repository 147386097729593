<div class="client_menu">
  <div class="client_menu__items">
    <div class="client_menu__items_left">
      <app-button-arrow class="client_menu__items_left__arrow"
        arrowType="arrow"
        [leftArrow]="true"
        (click)="onBackClicked()">
      </app-button-arrow>
      <a class="client_menu__items_left__item" (click)="onLinkClicked('pay-later')" [routerLink]="'/pay-later' | localize">
        <app-button-text>{{'HeaderClientMenu.pay_later' | translate}}</app-button-text>
      </a>
      <a class="client_menu__items_left__item" (click)="onLinkClicked('about-us')"  [routerLink]="'/about-us' | localize">
        <app-button-text>{{'HeaderClientMenu.about_company' | translate}}</app-button-text>
      </a>
      <a class="client_menu__items_left__item" (click)="onLinkClicked('customer-service')" [routerLink]="'/customer-service' | localize">
        <app-button-text>{{'HeaderClientMenu.customer_service' | translate}}</app-button-text>
      </a>
      <a class="client_menu__items_left__item" (click)="onLinkClicked('blog')" [routerLink]="'/blog' | localize">
        <app-button-text>{{'HeaderClientMenu.blog' | translate}}</app-button-text>
      </a>
    </div>
    <div class="client_menu__items_right">
      <a class="client_menu__items_right__item" (click)="onLinkClicked('shops')" [routerLink]="'/shops' | localize">
        <app-button-text>{{'HeaderClientMenu.rulla_shops' | translate}}</app-button-text>
      </a>
      <div class="client_menu__items_right__categories">
        <h4>{{'HeaderClientMenu.categories' | translate}}</h4>
        <div class="client_menu__items_right__categories__images">
          <div class="client_menu__items_right__categories__images__item">
            <div class="client_menu__items_right__categories__images__item__shadow"></div>
            <img src="assets/images/client-menu/img_1.png">
          </div>
          <div class="client_menu__items_right__categories__images__item">
            <div class="client_menu__items_right__categories__images__item__shadow"></div>
            <img src="assets/images/client-menu/img_2.png">
          </div>
          <div class="client_menu__items_right__categories__images__item">
            <div class="client_menu__items_right__categories__images__item__shadow"></div>
            <img src="assets/images/client-menu/img_3.png">
          </div>
          <div class="client_menu__items_right__categories__images__item">
            <div class="client_menu__items_right__categories__images__item__shadow"></div>
            <img src="assets/images/client-menu/img_4.png">
          </div>
          <div class="client_menu__items_right__categories__images__item">
            <div class="client_menu__items_right__categories__images__item__shadow"></div>
            <img src="assets/images/client-menu/img_5.png">
          </div>
          <div class="client_menu__items_right__categories__images__item">
            <div class="client_menu__items_right__categories__images__item__shadow"></div>
            <img src="assets/images/client-menu/img_6.png">
          </div>
        </div>
      </div>
      <div class="client_menu__items_right__watch_all">
        <app-button-text [withArrow]="true" (click)="onLinkClicked('shops')"  [routerLink]="'/shops' | localize" fragment="categories">
          {{'HeaderClientMenu.watch_all' | translate}}
        </app-button-text>
      </div>
    </div>
  </div>
</div>
