export type AuthType = 'customer' | 'business';

export type AuthMode = 'sign-in' | 'sign-up' | 'recover';

export function resolveAuthType(type: string): AuthType {
  switch (type) {
    case 'customer': return 'customer';
    case 'business': return 'business';
    default:
      return 'customer';
  }
}

export interface SwitchRequest {
  authType: AuthType;
  phoneNumber: string;
}
