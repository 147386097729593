import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-menu-business',
  templateUrl: './menu-business.component.html',
  styleUrls: ['./menu-business.component.scss'],
})
export class MenuBusinessComponent {
  @Output() linkClicked = new EventEmitter<string>();
  @Output() backClicked = new EventEmitter<any>();

  onLinkClicked(link: string) {
    this.linkClicked.emit(link);
  }

  onBackClicked() {
    this.backClicked.emit();
  }
}
