import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { AuthType, resolveAuthType } from '../models/auth.model';

@Component({
  selector: 'app-auth-modal',
  templateUrl: './auth-modal.component.html',
  styleUrls: ['./auth-modal.component.scss']
})
export class AuthModalComponent implements OnInit {

  authType: AuthType = 'customer';
  visible = false;

  constructor() { }

  ngOnInit() { 
  }

  show() {
    this.authType = resolveAuthType(localStorage.authType);
    this.visible = true;
    document.body.style.overflow = 'hidden';
  }

  close() {
    this.visible = false;
    document.body.style.overflow = 'auto';
  }
  
  @HostListener('window:keyup', ['$event'])
  onKeyUp(event) {
    if (event.key == 'Escape') {
      this.close();
    }
  }
}
