import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button-common',
  templateUrl: './button-common.component.html',
  styleUrls: ['./button-common.component.scss'],
})
export class ButtonCommonComponent {
  @Input() disabled = false;
  @Input() whiteButton = false;
  @Input() type: 'button' | 'submit' = 'button';

  @Output() clicked = new EventEmitter<any>()
}
