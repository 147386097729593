import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {

  @Input() visible = false;
  @Input() title: String;

  @Output() cancel = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }
  
  close() {
    this.cancel.emit();
  }
}
