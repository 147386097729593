import { Component, EventEmitter, Input, Output } from '@angular/core';

type MenuType = 'customer' | 'business' | 'choose'

@Component({
  selector: 'app-menu-desktop',
  templateUrl: './menu-desktop.component.html',
  styleUrls: ['./menu-desktop.component.scss'],
})
export class MenuDesktopComponent {

  @Input() activeMenu?: MenuType;

  @Output() navigation: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  onLinkClick() {
    this.navigation.emit();
  }
}
