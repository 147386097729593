import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AreaSignInResult, OtpSignInData, OtpSignInResult, TokenResult } from 'src/app/core/models/auth.model';

@Injectable({ providedIn: 'root' })
export class CustomerSignUpService {
  constructor(private http: HttpClient) { }

  signUp(data: RegistrationData) {
    return this.http.post<AreaSignInResult>('api/v1/customer/sign-up', data);
  }

  verifyCode(data: OtpSignInData) {
    return this.http.post<OtpSignInResult>('api/v1/customer/sign-up/verify-pin', data);
  }
}

export interface RegistrationData {
  phoneNumber: string;
}