// requests

import {TenantId} from './tenant.model';

export interface AreaSignInData extends PhoneSignIn {
  password: string;
}

export interface PhoneSignIn {
  phoneNumber: string;
}

export interface OtpSignInData {
  verificationId: number;
  version: number;
  pin: string;
}

// responses

export interface SignInBase {
  status: SignInStatus;
  token: string;
  locale: string;
  tenant: TenantId;
  email: string;
  phoneNumber: string;
}

export enum SignInStatus {
  Success = 0,
  OtpSent = 1,
  Failure = 2,
}

export interface AreaSignInResult extends SignInBase {
  verificationId: number;
  version: number;
}

export interface OtpSignInResult extends SignInBase {
}

export interface TokenResult {
  token: string;
}
