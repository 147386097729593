<div class="menus__mobile" #insideMenuContainer>
  <div class="menus__mobile__inside" #insideMenu>
    <app-menu-choose *ngIf="activeMenu === 'choose'" 
      (chosen)="showMenu($event)">
    </app-menu-choose>

    <app-menu-customer *ngIf="activeMenu === 'customer'"
      (backClicked)="showMenu('choose')"
      (linkClicked)="onLinkClick()">
    </app-menu-customer>

    <app-menu-business *ngIf="activeMenu === 'business'"
      (backClicked)="showMenu('choose')"
      (linkClicked)="onLinkClick()">
    </app-menu-business>
  </div>
</div>
